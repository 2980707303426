const getSelectors = state => ({
  getState() {
    return state
  },
  isLoading() {
    return state.loading
  },
  getErrorCode() {
    return state.error.code
  },
  hasError() {
    return state.error.status
  },
  getJobsites() {
    return state.data ? state.data : []
  },
  getJobsite(id) {
    return state.data && state.data.find(job => job.jobsiteId === id)
  },
  getSearchResults(query, searchFields) {
    if (!state.data) {
      return []
    }
    const normalizedQuery = query.toLowerCase()
    return state.data.filter(item => (
      searchFields.findIndex(field => typeof item[field] === 'string' && item[field].toLowerCase().indexOf(normalizedQuery) > -1) > -1
    ))
  },
  getSelectedJobsiteId() {
    return state.selectedJobsiteId
  },
  getSelectedJobsitesList() {
    return state.selectedJobsitesList
  }
})

export default getSelectors
