import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

import useTranslate from '../../../../Utils/Hooks/useTranslate'

import UserContext from '../../../../State/User/Context'
import Spinner from '../../../Spinner'


const PrivateRoute = ({ component: Component, ...rest }) => {
  const translate = useTranslate('navigator')
  const { selectors } = useContext(UserContext)

  if (!selectors.isInitialized()) {
    return <Spinner message={translate('loading')} />
  }

  return (
    <Route
      {...rest}
      render={props => (selectors.isLoggedIn()
        ? <Component {...props} />
        : <Redirect to="/" />
      )}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired
}

export default PrivateRoute
