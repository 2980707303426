import useValidationSchema from '../../Hooks/useValidationSchema'
import useConfigTransform from '../../Hooks/useConfigTransform'
import CoVTemperatureComponent from './Components/CoVTemperatureComponent'

const CoVTemperatureField = () => {
  const { getCurrentSchema } = useValidationSchema()
  const { getCurrentTransform } = useConfigTransform()

  return {
    validationSchema: getCurrentSchema('attributes/covTemperature'),
    configTransform: getCurrentTransform('attributes/reportingEnabled'),
    Component: () => null,
    CoVComponent: CoVTemperatureComponent
  }
}

export default CoVTemperatureField
