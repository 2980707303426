import React from 'react'
import PropTypes from 'prop-types'

import { TableCell } from '../../../Table'

const Address = ({ row }) => {
  const { address } = row.original

  return <TableCell data-testid="jobs-address">{address.displayAddress || <i>Undefined</i>}</TableCell>
}

Address.propTypes = {
  row: PropTypes.object.isRequired
}

export default Address
