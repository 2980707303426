import React, { memo, useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import usePrevious from '../../../Utils/Hooks/usePrevious'

import { Input } from './Styles'

const SingleInputComponent = ({ focus, ...rest }) => {
  const inputRef = useRef(null)
  const prevFocus = usePrevious(!!focus)
  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus) {
        inputRef.current.focus()
      }
      if (focus && focus !== prevFocus) {
        inputRef.current.focus()
        inputRef.current.select()
      }
    }
  }, [focus, prevFocus])

  return <Input ref={inputRef} {...rest} />
}

SingleInputComponent.propTypes = {
  focus: PropTypes.bool
}

SingleInputComponent.defaultProps = {
  focus: false
}

const SingleInput = memo(SingleInputComponent)
export default SingleInput
