import styled, { css, keyframes } from 'styled-components'

const openAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-120%);
  },
  to {
    transform: translateY(0);
  }
`

const closeAnimation = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.9);
    opacity: 0;
  }
`

export const ToasterContainer = styled.div({
  maxWidth: 560,
  margin: '0 auto',
  top: 0,
  left: 0,
  right: 0,
  position: 'fixed',
  zIndex: '11'
})

export const ToastContainer = styled.div(
  ({ theme }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    margin: 8,
    padding: '1rem',
    borderRadius: '4px',
    fontSize: '16px',
    letterSpacing: '0.3px',
    lineHeight: '1.5rem',
    backgroundColor: 'white',
    maxWidth: 560,
    boxShadow: '0 2px 4px rgba(0,0,0,0.16)',
    transition: 'all 240ms cubic-bezier(0.0, 0.0, 0.2, 1)',
    border: '1px solid',
    borderColor: theme.colors.n04,
    '& > span:nth-of-type(2)': {
      flex: 1
    },
    '& span > svg': {
      marginRight: '0.5rem',
      verticalAlign: '-3px'
    },
    '& .MuiCircularProgress-root': {
      marginRight: '0.5rem',
      color: theme.colors.n07,
      verticalAlign: '-3px'
    },
    button: {
      border: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      svg: {
        verticalAlign: '-3px',
        color: theme.colors.n08
      }
    }
  }),
  css`
    &[data-state="entering"] {
      animation: ${openAnimation} 240ms cubic-bezier(0.175, 0.885, 0.320, 1.175) both;
    }
    &[data-state="entered"] {
      animation: 240ms cubic-bezier(0.175, 0.885, 0.320, 1.175) both;
    }
    &[data-state="exiting"] {
      animation: ${closeAnimation} 240ms cubic-bezier(0.0, 0.0, 0.2, 1) both;
    }
  `
)
