import * as firebase from 'firebase/app'
import 'firebase/firestore'

const templatesClient = {
  getTemplatesData(orgId, filters) {
    let query = firebase.firestore()
      .collection('templates')
      .where('organizationId', 'in', [orgId, 'default'])

    if ('status' in filters) {
      query = query.where('status', '==', filters.status)
    }

    return query.get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.reduce((accumulator, current) => {
          accumulator.push({ templateId: current.id, ...current.data() })
          return accumulator
        }, [])
        return data
      })
  },
  fetchJobsites(organizationId) {
    const query = firebase.firestore()
      .collection('jobsites')
      .where('organizationId', '==', organizationId)
    return query.get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.reduce((accumulator, current) => {
          const jobsite = current.data()
          accumulator.push({ jobsiteId: current.id, name: jobsite.name })
          return accumulator
        }, [])
        return data
      })
  },
  async duplicateTemplate(templateData) {
    return firebase.firestore().collection('templates').add(templateData)
  },
  updateTemplate(templateId, formData) {
    return firebase.firestore().doc(`templates/${templateId}`).update(formData)
  },
  fetchTemplateData(templateId) {
    return firebase.firestore().doc(`templates/${templateId}`).get().then(snapshot => snapshot.data())
  },
  fetchTemplate(templateId) {
    return firebase.firestore().doc(`templates/${templateId}`).get().then(doc => doc.data())
  },
  updateAttribute(templateId, attributeId, formData) {
    return firebase.firestore().doc(`templates/${templateId}`).update(`config.sensors.${attributeId}`, formData)
  },
  deleteTemplates(templates) {
    const db = firebase.firestore()
    const batch = db.batch()
    templates.forEach((template) => {
      const { templateId } = template
      const templateRef = db.doc(`templates/${templateId}`)
      batch.delete(templateRef)
    })
    return batch.commit()
  },
  async deactivateTemplatesByOrgAsync(organization) {
    const db = firebase.firestore()
    const batch = db.batch()
    const templates = await firebase.firestore().collection('templates').get().then(({ docs }) => docs)
    const orgTemplates = templates.filter(template => template.data().organizationId === organization.id)
    orgTemplates.forEach((template) => {
      const templateRef = db.doc(`templates/${template.id}`)
      batch.update(templateRef, {
        status: 'inactive'
      })
    })
    return batch.commit()
  },
  async deleteTemplatesByOrgAsync(organization) {
    const db = firebase.firestore()
    const batch = db.batch()
    const templates = await firebase.firestore().collection('templates').get().then(({ docs }) => docs)
    const orgTemplates = templates.filter(template => template.data().organizationId === organization.id)
    orgTemplates.forEach((template) => {
      const templateRef = db.doc(`templates/${template.id}`)
      batch.delete(templateRef)
    })
    return batch.commit()
  }
}

export default templatesClient
