import React from 'react'
import PropTypes from 'prop-types'
import { mdiInformationOutline } from '@mdi/js'

import useTranslate from '../../../../Utils/Hooks/useTranslate'

import { createRadioButtonLabel } from '../../utils'
import ExpandGroup from '../../../Formik/ExpandGroup'
import RadioGroup from '../../../Formik/RadioGroup'
import Text from '../../../Formik/Text'
import Delimited from '../../../Formik/Delimited'
import {
  DisabledSelect, RadioLabelContainer, RadioLabelBody, RadioLableHeader
} from './Styles'


const MQTTSettings = ({ values, setFieldValue, submitCount }) => {
  const createMultilineLabel = createRadioButtonLabel(RadioLabelContainer, RadioLableHeader, RadioLabelBody)
  const translate = useTranslate('configForm')

  const reportTypeOptions = [
    {
      label: createMultilineLabel(translate('mqttSettings.changeOfValue'), translate('mqttSettings.changeOfValue.description')),
      value: 'changeOfValue'
    },
    {
      label: createMultilineLabel(translate('mqttSettings.fixedSampleRate'), translate('mqttSettings.fixedSampleRate.description')),
      value: 'fixed'
    }
  ]

  return (
    <ExpandGroup
      type="toggle"
      heading={translate('mqttSettings.heading')}
      expandCondition={values.mqtt.isEnabled}
      confirmModal="mqtt-opt-out/request"
      toggleName="mqtt.isEnabled"
      hasTooltip
      tooltipContent={translate('mqttSettings.tooltip')}
      tooltipIcon={mdiInformationOutline}
      data-testid="mqtt-toggle"
      toggleTestid="mqtt-toggle-div"
    >
      <DisabledSelect
        disabled
        id="mqtt-broker-dropdown"
        name="mqtt.broker.brokerType"
        label={translate('mqttSettings.brokerTypeInputLabel')}
        tiled
        options={[
          {
            value: 'default',
            label: translate('mqttSettings.brokerTypeInputOption.default')
          },
          {
            value: 'custom',
            label: translate('mqttSettings.brokerTypeInputOption.custom')
          }
        ]}
        onChange={(e) => {
          const { target: { value } } = e
          setFieldValue('mqtt.broker.brokerType', value)
          setFieldValue('mqtt.broker.addressType', (values.mqtt.broker && values.mqtt.broker.addressType) || 'brokerIp')
        }}
        testId="mqtt-broker-type"
      />
      {values.mqtt.broker && values.mqtt.broker.brokerType === 'custom' && (
        <>
          <RadioGroup
            name="mqtt.broker.addressType"
            title={translate('mqttSettings.addressTypeInputLabel')}
            tiled
            data-testid="mqtt-address-type"
            options={[
              {
                value: 'brokerIp',
                label: translate('mqttSettings.addressTypeInputOption.ip')
              },
              {
                value: 'brokerUrl',
                label: translate('mqttSettings.addressTypeInputOption.url')
              }
            ]}
          />
          {values.mqtt.broker.addressType === 'brokerUrl' ? (
            <Text
              label={translate('mqttSettings.urlInputLabel')}
              name="mqtt.broker.brokerUrl"
              placeholder={translate('mqttSettings.urlInputPlaceholder')}
              tooltipIcon={mdiInformationOutline}
              data-testid="mqtt-broker-url"
              submitCount={submitCount}
            />
          ) : (
            <Delimited
              delimiter="."
              delimitedTestId="mqtt-settings"
              regions={4}
              label={translate('mqttSettings.ipInputLabel')}
              name="mqtt.broker.brokerIp"
              type="number"
              data-testid="mqtt-broker-ip"
              submitCount={submitCount}
            />
          )}
          <Text
            label={translate('mqttSettings.portInputLabel')}
            name="mqtt.broker.port"
            placeholder={translate('mqttSettings.portInputPlaceholder')}
            type="number"
            tooltipIcon={mdiInformationOutline}
            min="1"
            data-testid="mqtt-broker-port"
            submitCount={submitCount}
          />
        </>
      )}
      <RadioGroup
        title={translate('mqttSettings.sensorReportTypeInputLabel')}
        options={reportTypeOptions}
        name="mqtt.sensorReportType"
        alignToTop
        data-testid="reportTypes"
      />
      { values.mqtt.sensorReportType === 'fixed' && (
        <Text
          label={translate('hubReportingRates.sampleRateInputLabel')}
          name="mqtt.fixedSampleRate"
          type="number"
          min="5"
          max="1440"
          data-testid="reporting-sample-rate"
          accessoryText={translate('hubReportingRates.reportRateUnit')}
          textHelper={translate('mqttSettings.fixedSampleRateValue.range')}
          submitCount={submitCount}
        />
      )}
      <Text
        label={translate('hubReportingRates.reportRateInputLabel')}
        name="mqtt.sensorReportRate"
        type="number"
        min="5"
        max="1440"
        hasTooltip
        tooltipContent={translate('hubReportingRates.reportRateTooltip')}
        tooltipIcon={mdiInformationOutline}
        data-testid="reporting-report-rate"
        accessoryText={translate('hubReportingRates.reportRateUnit')}
        textHelper={translate('mqttSettings.sensorReportRate.range')}
        submitCount={submitCount}
      />
    </ExpandGroup>
  )
}

MQTTSettings.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func,
  submitCount: PropTypes.number
}

MQTTSettings.defaultProps = {
  setFieldValue: () => null,
  submitCount: undefined
}

export default MQTTSettings
