const initialState = {
  isOpen: false,
  currentView: undefined,
  isStale: false,
  isClickOutsideDisabled: false
}

const types = {
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  CHANGE_VIEW: 'CHANGE_VIEW',
  SET_SUBMITTED: 'SET_SUBMITTED'
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case (types.OPEN_MODAL):
      return {
        ...state,
        isOpen: true
      }
    case (types.CLOSE_MODAL):
      return {
        ...state,
        isOpen: false,
        isStale: false,
        isClickOutsideDisabled: false
      }
    case (types.CHANGE_VIEW):
      return {
        ...state,
        currentView: action.view,
        isClickOutsideDisabled: action.isClickOutsideDisabled,
        isStale: false
      }
    case (types.SET_SUBMITTED):
      return {
        ...state,
        isStale: true
      }
    default:
      return state
  }
}

export {
  reducer,
  types,
  initialState
}
