const EditIOAttribute = (values, { name }) => {
  const {
    attributeId, isCustomDescription, description, ...rest
  } = values

  const isCustomName = rest.isCustomName === 'true'

  const config = {
    ...rest,
    isCustomName,
    ...!isCustomName && { name },
    description: isCustomDescription ? description : ''
  }
  return config
}

export default EditIOAttribute
