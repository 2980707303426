import styled from 'styled-components'


export const TextareaContainer = styled.div({
  margin: '0 0 1.5rem'
})

export const StyledTextArea = styled.textarea(({ theme, hasError }) => ({
  margin: '0 0 .5rem',
  padding: '.5rem .5rem 0',
  width: '100%',
  height: '50px',
  border: 'none',
  borderBottom: `1px solid ${theme.colors.n08}`,
  background: 'none',
  color: theme.colors.n08,
  fontWeight: '300',
  lineHeight: '1rem',
  maxHeight: '160px',
  ':focus': {
    outline: 'none'
  },
  ':disabled': {
    color: theme.colors.n06,
    borderBottomColor: theme.colors.n06,
    cursor: 'not-allowed'
  },
  '::placeholder': {
    color: theme.colors.n05
  },
  ...(hasError ? { borderBottomColor: theme.colors.s05 } : {}),
  [`&::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button`
  ]: {
    '-webkit-appearance': 'none',
    margin: '0'
  },
  '&[type=number]': {
    '-moz-appearance': 'textfield'
  }
}))

export const Label = styled.label(({ theme }) => ({
  display: 'block',
  color: theme.colors.n09,
  fontSize: '.875rem',
  fontWeight: 500
}))
