import DevicesClient from './Client'
import ZonesClient from '../Zones/Client'
import { types } from './Reducer'

const actions = (state, dispatch, toast) => {
  const fetchDevices = async (jobsiteId, filters = {}, namingConvention = 'prefix') => {
    try {
      dispatch({ type: types.FETCH_DEVICES_PENDING })
      const zones = await ZonesClient.fetchZones(jobsiteId)
      const response = await DevicesClient.fetchDevices(jobsiteId, filters, zones, namingConvention)
      dispatch({ type: types.FETCH_DEVICES_SUCCESS, payload: response })
    } catch (error) {
      dispatch({ type: types.FETCH_DEVICES_FAILURE, payload: error })
    }
  }

  const fetchDevice = async (deviceId) => {
    try {
      dispatch({ type: types.FETCH_DEVICE_PENDING })
      const device = await DevicesClient.fetchDevice(deviceId)
      const zone = await ZonesClient.fetchZone(device.zoneId)
      const deviceNamingConvention = device.namingConvention || { isEnabled: true, value: 'prefix' }
      const displayName = deviceNamingConvention.value === 'suffix' ? `${device.name} ${zone.name}` : `${zone.name} ${device.name}`
      dispatch({ type: types.FETCH_DEVICE_SUCCESS, payload: { ...device, displayName } })
    } catch (error) {
      dispatch({ type: types.FETCH_DEVICE_FAILURE, payload: error })
    }
  }

  const createDevice = async (formData, notificationMessages) => {
    const res = await DevicesClient.createDevice(formData)
    if (!res || !res.ok) {
      toast.addNotification(notificationMessages.operationFailure, 'error', 2)
    }
    return res
  }
  const setSelectedDevice = data => dispatch({ type: types.SET_SELECTED_DEVICE, payload: data })
  const setSelectedDeviceId = id => dispatch({ type: types.SET_SELECTED_DEVICE_ID, payload: id })
  const setSelectedDeviceList = data => dispatch({ type: types.SET_SELECTED_DEVICE_LIST, payload: data })
  const createZone = zoneData => DevicesClient.createZone(zoneData)
  const updateDevice = config => DevicesClient.updateDevice(config)
  const updateDeviceAttribute = (deviceId, attributeId, config) => DevicesClient.updateDeviceAttribute(deviceId, attributeId, config)
  const bulkUpdateFirmware = async (deviceList, firmware) => DevicesClient.bulkUpdateFirmware(deviceList, firmware)
  const setFilters = filters => dispatch({ type: types.SET_FILTERS, payload: filters })
  const toggleDeviceStatus = devices => DevicesClient.toggleDeviceStatus(devices)
  const deleteDevices = devices => DevicesClient.deleteDevices(devices)
  return {
    fetchDevices,
    fetchDevice,
    createDevice,
    setSelectedDevice,
    setSelectedDeviceId,
    setSelectedDeviceList,
    createZone,
    updateDevice,
    updateDeviceAttribute,
    bulkUpdateFirmware,
    setFilters,
    toggleDeviceStatus,
    deleteDevices
  }
}


export default actions
