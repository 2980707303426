import React from 'react'
import PropTypes from 'prop-types'

import useTranslate from '../../../../Utils/Hooks/useTranslate'

import Fieldset from '../../../Form/Fieldset'
import Text from '../../../Formik/Text'
import RadioGroup from '../../../Formik/RadioGroup'
import Select from '../../../Formik/Select'

const ZoneSelection = ({
  values, setFieldValue, zones, isCreateDevice, submitCount
}) => {
  const translate = useTranslate('devicePanel')
  const zoneOptions = [
    {
      value: 'false',
      label: translate('zoneTypeOption.existing'),
      hasNoZones: zones.length === 0,
      isExistingCheckbox: true
    },
    {
      value: 'true',
      label: translate('zoneTypeOption.new'),
      hasNoZones: zones.length === 0,
      isExistingCheckbox: false
    }
  ]
  return (
    <Fieldset>
      <RadioGroup
        name="zone.isNewZone"
        title={translate('zoneTypeInputLabel')}
        tiled
        options={isCreateDevice ? zoneOptions.reverse() : zoneOptions}
        data-testid="device-zone-type"
      />
      {values.zone.isNewZone === 'true' ? (
        <Text
          label={translate('zoneNameInputLabel')}
          name="zone.zoneName"
          placeholder={translate('zoneNameInputPlaceholder')}
          data-testid="zone-name"
          submitCount={submitCount}
        />
      ) : (
        <Select
          id="select-zone"
          label={translate('zoneNameInputLabel')}
          onChange={(e) => {
            const { target: { selectedIndex, value, options } } = e
            setFieldValue('zone.zoneName', options[selectedIndex].text)
            setFieldValue('zone.id', value)
          }}
          name="zone.id"
          options={zones}
          data-testid="zone-existing-zone-name"
          testId="zone-existing"
        />
      )}
      <Text
        name="device.deviceName"
        label={translate('hubLabelInputLabel')}
        placeholder={translate('hubLabelInputPlaceholder')}
        data-testid="device-name"
        submitCount={submitCount}
      />
    </Fieldset>
  )
}

ZoneSelection.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  zones: PropTypes.array.isRequired,
  isCreateDevice: PropTypes.bool,
  submitCount: PropTypes.number.isRequired
}

ZoneSelection.defaultProps = {
  isCreateDevice: false
}

export default ZoneSelection
