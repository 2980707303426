import { string } from 'yup'
import JobsiteClient from '../../../State/Jobs/Client'

async function test(value = '') {
  const { jobsiteId } = this.parent
  const zoneNameCount = await JobsiteClient.verifyUniqueZoneName(jobsiteId, value)
  return zoneNameCount === 0
}

const UniqueZoneName = (conditionalFieldKey, conditionalFieldValue, requiredMessage) => string().when(conditionalFieldKey, {
  is: conditionalFieldValue,
  then: string().required(requiredMessage)
    .test(
      'name',
      'The zone name must not be the same as another in the jobsite',
      test
    ),
  otherwise: string().notRequired()
})

export default UniqueZoneName
