import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'

import { StyledButton } from './Styles'

const Button = ({
  children, size, disabled, type, fullWidth, inverted, destructive, isLoading, ...rest
}) => (
  <StyledButton
    size={size}
    disabled={disabled}
    type={type}
    inverted={inverted}
    destructive={destructive}
    fullWidth={fullWidth}
    isLoading={isLoading}
    data-testid="button"
    {...rest}
  >
    {isLoading && <CircularProgress />}
    {children}
  </StyledButton>
)

Button.propTypes = {
  size: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  inverted: PropTypes.bool,
  destructive: PropTypes.bool,
  isLoading: PropTypes.bool
}

Button.defaultProps = {
  size: undefined,
  type: 'button',
  disabled: false,
  fullWidth: false,
  inverted: false,
  destructive: false,
  isLoading: false
}

export default Button
