import React from 'react'
import PropTypes from 'prop-types'

import Button from '../../../Button'

const SubmitButton = ({
  labels, handleSubmit, errors, isSubmitting, testIdRoot, ...rest
}) => {
  const { submittingLabel, buttonLabel } = labels

  if (isSubmitting) {
    return <Button type="submit" disabled isLoading data-testid={`${testIdRoot}-submit-button`} {...rest}>{submittingLabel}</Button>
  }

  if (errors && Object.keys(errors).length !== 0) {
    return <Button type="submit" disabled data-testid={`${testIdRoot}-submit-button`} {...rest}>{buttonLabel}</Button>
  }

  return <Button type="submit" onClick={handleSubmit} data-testid={`${testIdRoot}-submit-button`} {...rest}>{buttonLabel}</Button>
}

SubmitButton.propTypes = {
  labels: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
  testIdRoot: PropTypes.string
}

SubmitButton.defaultProps = {
  handleSubmit: () => null,
  isSubmitting: false,
  errors: undefined,
  testIdRoot: undefined
}

export default SubmitButton
