import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Icon from '@mdi/react'
import {
  mdiDomain, mdiPackageDown, mdiPlusCircleOutline, mdiDeleteOutline
} from '@mdi/js'

import useTranslate from '../../../Utils/Hooks/useTranslate'
import useTableCheckboxState from '../../../Utils/Hooks/useTableCheckboxState'

import FirmwareContext from '../../../State/Firmware/Context'
import JobsContext from '../../../State/Jobs/Context'
import ModalContext from '../../../State/Modal/Context'
import PanelContext from '../../../State/Panel/Context'

import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'
import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'

import Breadcrumbs from '../../Breadcrumbs'
import Button from '../../Button'
import ButtonGroup from '../../ButtonGroup'
import PageHeader from '../../PageHeader'
import PageInfo from '../../PageInfo'
import PageCaption from '../../PageCaption'
import PageMetaData from '../../PageMetaData'
import Panel from '../../Panel'
import JobsitesTable from './JobsitesTable'

const Jobs = ({ setState, setOrgSelectState }) => {
  const { isBulkActionDisabled, selectedCheckboxes, setSelectedCheckboxes } = useTableCheckboxState()
  const { actions: { changeView } } = useContext(PanelContext)
  const { actions: { changeModalView } } = useContext(ModalContext)
  const { actions: { setSelectedJobsitesList, toggleJobsiteStatus } } = useContext(JobsContext)
  const { actions: { fetchFirmware } } = useContext(FirmwareContext)
  const [, , , result, hasError] = useAsyncCallback(toggleJobsiteStatus)
  useErrorNotifier(hasError, result)
  const [status, setStatus] = useState('active')
  const translate = useTranslate('jobsPage')
  const translateMetaData = useTranslate('pageMetaData')
  setState('Content/Managing_Jobs.html')
  setOrgSelectState('jobs')

  const openPanel = () => {
    changeView('jobs/create')
  }

  const handleArchiveButton = async () => {
    if (status === 'active') {
      setSelectedJobsitesList(selectedCheckboxes)
      changeModalView('jobs/archive')
    } else {
      setSelectedJobsitesList(selectedCheckboxes)
      changeModalView('jobs/unarchive')
    }
  }

  const handleDeleteButton = () => {
    setSelectedJobsitesList(selectedCheckboxes)
    changeModalView('jobs/delete')
  }

  useEffect(() => {
    fetchFirmware()
  }, []) // eslint-disable-line

  const archiveString = status === 'active' ? 'archiveJobButton' : 'unarchiveJobButton'

  return (
    <>
      <PageMetaData pageMetaData={{ title: `${translateMetaData('jobsitesTitle')}`, description: 'TODO' }} />
      <Breadcrumbs />
      <PageHeader>
        <PageInfo>
          <Icon path={mdiDomain} size={1} />
          <PageCaption>
            <h1 data-testid="jobs-page-header">{translate('heading')}</h1>
            <h4>
              <span data-testid="jobs-page-sub-header">{translate('description')}</span>
            </h4>
          </PageCaption>
        </PageInfo>
        <ButtonGroup data-testid="jobs-button-group">
          <Button onClick={handleArchiveButton} inverted data-testid="jobs-cancel-archive" disabled={isBulkActionDisabled}>
            <Icon path={mdiPackageDown} size={1} />{translate(archiveString)}
          </Button>
          <Button
            onClick={handleDeleteButton}
            destructive
            inverted
            data-testid="jobs-delete-button"
            disabled={isBulkActionDisabled}
          >
            <Icon path={mdiDeleteOutline} size={1} />{translate('deleteJobButton')}
          </Button>
          <Button onClick={openPanel} data-testid="jobs-create-job">
            <Icon path={mdiPlusCircleOutline} size={1} />{translate('createJobButton')}
          </Button>
        </ButtonGroup>
        <Panel />
      </PageHeader>
      <JobsitesTable
        selectedCheckboxes={selectedCheckboxes}
        setSelectedCheckboxes={setSelectedCheckboxes}
        status={status}
        setStatus={setStatus}
      />
    </>
  )
}

Jobs.propTypes = {
  setState: PropTypes.func.isRequired,
  setOrgSelectState: PropTypes.func
}

Jobs.defaultProps = {
  setOrgSelectState: () => {}
}

export default Jobs
