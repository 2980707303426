import styled from 'styled-components'
import Button from '../Button'
import { StyledSelect } from '../Form/Select/Styles'

export const PaginationButton = styled(Button)({
  border: 0,
  height: 30,
  padding: '0 0.3rem',
  svg: {
    marginLeft: 0,
    marginRight: 0
  }
})

export const PaginationSelect = styled(StyledSelect)({
  borderBottom: 0,
  marginBottom: 0,
  maxWidth: 100
})

export const StyledPagination = styled.div(({ theme }) => ({
  alignItems: 'center',
  color: theme.colors.n08,
  display: 'flex',
  height: 60,
  justifyContent: 'space-between',
  span: {
    marginLeft: 8
  },
  strong: {
    fontWeight: 500,
    marginLeft: 3
  }
}))
