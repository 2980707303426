import * as firebase from 'firebase/app'
import 'firebase/firestore'
import useTranslateWithLanguage from '../../Hooks/useTranslateWithLanguage'

const DuplicateTemplate = ({
  createdAt, updatedAt, templateId, ...rest
}, organizationId) => {
  const translate = useTranslateWithLanguage('duplicateTemplate')
  return Object.assign(
    {},
    { ...rest },
    { organizationId },
    { isDefaultTemplate: false },
    { name: `${rest.name} (${translate('copy')})` },
    { updatedAt: firebase.firestore.Timestamp.fromDate(new Date()) }
  )
}

export default DuplicateTemplate
