import styled from 'styled-components'

export const PublicFormContainer = styled.div(({ theme, isCentered }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: isCentered ? 'center' : 'flex-start',
  alignItems: isCentered ? 'center' : 'flex-start',
  textAlign: isCentered ? 'center' : 'left',
  padding: isCentered ? '0 1.5rem' : '2.5rem 1.5rem',
  width: '100%',
  form: {
    width: '100%'
  },
  a: {
    color: theme.colors.b01
  },
  [`@media(min-width: ${theme.breakpoints.sm.minWidth})`]: {
    padding: 0,
    margin: isCentered ? '0 auto' : '2.5rem auto',
    maxWidth: '24rem'
  },
  [`@media(min-width: ${theme.breakpoints.lg.minWidth})`]: {
    alignItems: 'flex-start',
    textAlign: 'left',
    margin: isCentered ? '0 auto' : '4rem auto'
  }
}))
