import * as firebase from 'firebase/app'
import 'firebase/firestore'
import Config from '../../Config'


const DevicesClient = {
  fetchDevices(jobsiteId, filters, zones, namingConvention) {
    let query = firebase.firestore()
      .collection('devices')
      .where('jobsiteId', '==', jobsiteId)

    if ('status' in filters) {
      query = query.where('status', '==', filters.status)
    }

    if ('phase' in filters && filters.phase !== 'all') {
      switch (filters.phase) {
        case 'configured':
          query = query.where('phase', 'in', ['configured', 'issue'])
          break
        default:
          query = query.where('phase', '==', 'draft')
          break
      }
    }

    return query.get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.reduce((accumulator, current) => {
          const { zoneId, name, ...rest } = current.data()
          const zoneName = zones[zoneId] && zones[zoneId].name
          accumulator.push({
            name,
            zoneId,
            deviceId: current.id,
            zoneName,
            displayName: (namingConvention === 'prefix' || namingConvention === '') ? `${zoneName} ${name}` : `${name} ${zoneName}`,
            ...rest
          })
          return accumulator
        }, [])
        return data
      })
  },
  fetchDevice(deviceId) {
    return firebase.firestore().collection('devices').doc(deviceId).get()
      .then(doc => doc.data())
  },
  getIdToken() {
    return firebase.auth().currentUser.getIdToken()
  },
  async createDevice(formData) {
    const url = `${Config.cloud.api.host}/devices`
    const token = await this.getIdToken()
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      cache: 'no-cache',
      credentials: 'omit',
      body: JSON.stringify(formData)
    })
      .catch(() => null)
  },
  async verifyUniqueDeviceNumber(savedDeviceNumber, jobsiteId, bacnetDeviceNumber) {
    const query = firebase.firestore()
      .collection('devices')
      .where('jobsiteId', '==', jobsiteId)

    const queryLength = await query.get().then(querySnapshot => querySnapshot.docs.filter((current) => {
      const { config: { bacnet: { deviceNumber: currentDeviceId } } } = current.data()
      const validId = savedDeviceNumber === bacnetDeviceNumber ? 0 : currentDeviceId === bacnetDeviceNumber
      return validId
    }))
    return queryLength.length
  },
  createZone(zoneData) {
    return firebase.firestore().collection('zones').add(zoneData)
      .then(docRef => docRef.id)
  },
  updateDevice(config) {
    const { deviceId } = config
    return firebase.firestore().collection('devices').doc(deviceId).update(config)
  },
  updateDeviceAttribute(deviceId, attributeId, config) {
    return firebase.firestore().doc(`devices/${deviceId}`).update(`config.sensors.${attributeId}`, config)
  },
  bulkUpdateFirmware(deviceList, firmware) {
    const db = firebase.firestore()
    const batch = db.batch()
    deviceList.forEach((device) => {
      const { deviceId } = device
      const deviceRef = db.collection('devices').doc(deviceId)
      batch.update(deviceRef, { 'config.firmware': firmware })
    })
    batch.commit()
  },
  toggleDeviceStatus(devices) {
    const db = firebase.firestore()
    const batch = db.batch()
    devices.forEach((device) => {
      const { deviceId, status, config } = device
      const deviceRef = db.doc(`devices/${deviceId}`)
      const mqttIsEnabled = config.mqtt.isEnabled

      batch.update(deviceRef, {
        status: status === 'active' ? 'inactive' : 'active',
        'config.mqtt.isEnabled': !mqttIsEnabled
      })
    })
    return batch.commit()
  },
  deleteDevices(devices) {
    const db = firebase.firestore()
    const batch = db.batch()
    devices.forEach((device) => {
      const { deviceId } = device
      const deviceRef = db.doc(`devices/${deviceId}`)
      batch.delete(deviceRef)
    })
    return batch.commit()
  }
}

export default DevicesClient
