const initialState = {
  loading: true,
  error: {
    status: false,
    code: null
  },
  data: null
}

const types = {
  FETCH_ZONES_PENDING: 'FETCH_ZONES_PENDING',
  FETCH_ZONES_SUCCESS: 'FETCH_ZONES_SUCCESS',
  FETCH_ZONES_FAILURE: 'FETCH_ZONES_FAILURE',
  SET_SELECTED_ZONE: 'SET_SELECTED_ZONE'
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case (types.FETCH_ZONES_PENDING): {
      return {
        ...state,
        loading: true
      }
    }
    case (types.FETCH_ZONES_SUCCESS): {
      return {
        ...state,
        loading: false,
        data: action.payload
      }
    }
    case (types.FETCH_ZONES_FAILURE): {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }
    case (types.SET_SELECTED_ZONE):
      return {
        ...state,
        selectedZone: action.payload
      }
    default:
      return state
  }
}

export {
  reducer,
  types,
  initialState
}
