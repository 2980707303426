import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Icon from '@mdi/react'
import { mdiEye, mdiEyeOff } from '@mdi/js'

import {
  Container,
  Input,
  Label,
  Field,
  VisibilityButton,
  AccessoryTextContainer,
  PrefixTextContainer,
  HelperText
} from './Styles'
import Error from '../Error'
import Tooltip from '../../Tooltip'

const Text = ({
  label,
  error,
  id,
  name,
  isPassword,
  autoComplete,
  disabled,
  hasTooltip,
  tooltipContent,
  tooltipIcon,
  hidden,
  prefixText,
  accessoryText,
  isDelimited,
  textHelper,
  errorPadding,
  ...rest
}) => {
  const [isHidden, setIsHidden] = useState(isPassword)
  const toggleHidden = () => setIsHidden(!isHidden)
  return (
    <Container hidden={hidden} isDelimited={isDelimited}>
      { label && (
        <Label htmlFor={name} data-testid="text-label">
          {label}
          {hasTooltip && (
            <Tooltip content={tooltipContent} icon={tooltipIcon} size={0.75} data-testid="text-tooltip" />
          )}
        </Label>
      )}
      <Field>
        {prefixText && (
          <PrefixTextContainer>
            {prefixText}
          </PrefixTextContainer>
        )}
        <Input
          type={isHidden ? 'password' : 'text'}
          id={id || name}
          name={name}
          aria-label={name}
          disabled={disabled}
          autoComplete={autoComplete}
          hasError={!!error}
          hasPrefix={!!prefixText}
          {...rest}
        />
        {isPassword && (
          <VisibilityButton type="button" onClick={toggleHidden} error={error} data-testid="text-visibility-button">
            <Icon path={isHidden ? mdiEyeOff : mdiEye} size={1} data-testid="text-visibility-icon" />
          </VisibilityButton>
        )}
        {accessoryText && (
          <AccessoryTextContainer>
            <p>{accessoryText}</p>
          </AccessoryTextContainer>
        )}
      </Field>
      {textHelper && !error && (
        <HelperText>{textHelper}</HelperText>
      )}
      { error && <Error testId="text-error" error={error} errorPadding={errorPadding} /> }
    </Container>
  )
}

Text.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string,
  isPassword: PropTypes.bool,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  tooltipContent: PropTypes.string,
  tooltipIcon: PropTypes.node,
  hidden: PropTypes.bool,
  prefixText: PropTypes.string,
  accessoryText: PropTypes.string,
  isDelimited: PropTypes.bool,
  textHelper: PropTypes.string,
  errorPadding: PropTypes.bool
}

Text.defaultProps = {
  id: null,
  label: null,
  isPassword: false,
  error: null,
  name: undefined,
  autoComplete: 'off',
  disabled: false,
  hasTooltip: false,
  tooltipContent: undefined,
  tooltipIcon: undefined,
  hidden: false,
  prefixText: undefined,
  accessoryText: undefined,
  isDelimited: false,
  textHelper: null,
  errorPadding: true
}

export default Text
