import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'

import useTranslate from '../../../Utils/Hooks/useTranslate'
import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'

import PanelContext from '../../../State/Panel/Context'
import DevicesContext from '../../../State/Devices/Context'
import FirmwareContext from '../../../State/Firmware/Context'

import Firmware from '../Components/Firmware'
import PanelFooter from '../Components/PanelFooter'

const UpdateFirmware = ({ setIsDirty }) => {
  const { actions: { closePanel, setSubmitted } } = useContext(PanelContext)
  const { actions: { bulkUpdateFirmware }, selectors: { getSelectedDeviceList } } = useContext(DevicesContext)
  const { selectors: { getFirmwares } } = useContext(FirmwareContext)
  const firmwares = getFirmwares()
  const selectedDevices = getSelectedDeviceList()
  const [execute, , , result, hasError] = useAsyncCallback(bulkUpdateFirmware)
  const translate = useTranslate('devicePanel')
  useErrorNotifier(hasError, result)

  const [latestFirmware] = firmwares || {}
  const { name: version, path: location } = latestFirmware

  const labels = {
    buttonLabel: translate('saveButton'),
    submittingLabel: translate('submittingButton'),
    cancelLabel: translate('cancelButton')
  }

  const initialValues = {
    firmware: {
      isEnabled: true,
      version,
      location
    }
  }

  const onSubmit = async (values) => {
    await execute(selectedDevices, values.firmware)
    setSubmitted()
    closePanel()
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        onReset={closePanel}
      >
        {({
          values, errors, isSubmitting, handleSubmit, setFieldValue, handleReset, isValid, dirty, submitCount
        }) => (
          <Form onChange={setIsDirty(dirty)}>
            <Firmware values={values} firmwares={firmwares} setFieldValue={setFieldValue} isEditPanel status="configured" />
            <PanelFooter
              handleReset={handleReset}
              handleSubmit={handleSubmit}
              errors={errors}
              labels={labels}
              isSubmitting={isSubmitting}
              isValid={isValid}
              submitCount={submitCount}
              testIdRoot="update-firmware"
            />
          </Form>
        )}
      </Formik>

    </>
  )
}

UpdateFirmware.propTypes = {
  setIsDirty: PropTypes.func.isRequired
}

export default UpdateFirmware
