import React from 'react'
import PropTypes from 'prop-types'

import { TabGroupContainer } from './Styles'

const TabGroup = ({ children }) => (
  <TabGroupContainer data-testid="tab-group">
    {children}
  </TabGroupContainer>
)

TabGroup.propTypes = {
  children: PropTypes.node.isRequired
}

export default TabGroup
