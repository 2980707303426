import React from 'react'
import PropTypes from 'prop-types'

import { PageInfoContainer } from './Styles'

const PageInfo = ({ children }) => (
  <PageInfoContainer data-testid="page-info-container">
    {children}
  </PageInfoContainer>
)

PageInfo.propTypes = {
  children: PropTypes.node.isRequired
}

export default PageInfo
