import en from '../../Languages/i18n/en.json'
import fr from '../../Languages/i18n/fr.json'
import es from '../../Languages/i18n/es.json'
import de from '../../Languages/i18n/de.json'

import useLanguage from './useLanguage'

export const translations = {
  en,
  fr,
  es,
  de
}

const defaultLanguage = useLanguage()

const useTranslateWithLanguage = (scope, language = defaultLanguage) => key => translations[language][`${scope}.${key}`] || '! MISSING TRANSLATION !'

export default useTranslateWithLanguage
