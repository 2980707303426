import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { useParams } from 'react-router-dom'
import { mdiInformationOutline } from '@mdi/js'

import PanelFooter from '../Components/PanelFooter'
import useTranslate from '../../../Utils/Hooks/useTranslate'
import useValidationSchema from '../../../Utils/Hooks/useValidationSchema'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'
import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useConfigTransform from '../../../Utils/Hooks/useConfigTransform'
import TemplateContext from '../../../State/Templates/Context'
import PanelContext from '../../../State/Panel/Context'
import Text from '../../Formik/Text'
import Select from '../../Formik/Select'
import Fieldset from '../../Form/Fieldset'
import ExpandGroup from '../../Formik/ExpandGroup'
import RadioGroup from '../../Formik/RadioGroup'
import PanelRibbon from '../Components/PanelRibbon'

const EditTemplate = ({ setIsDirty }) => {
  const { organizationId } = useParams()
  const translate = useTranslate('templatePanel')
  const translateConfig = useTranslate('configForm')
  const buttonLabels = {
    submittingLabel: translate('buttons.save'),
    cancelLabel: translate('buttons.cancel'),
    buttonLabel: translate('buttons.save')
  }
  const { actions: { updateTemplate, fetchTemplates }, selectors: { getSelectedTemplateId, getTemplate } } = useContext(TemplateContext)
  const { actions: { closePanel, setSubmitted, resetView } } = useContext(PanelContext)
  const [execute, , , result, hasError] = useAsyncCallback(updateTemplate)
  const templateId = getSelectedTemplateId()
  const template = getTemplate(templateId)
  useErrorNotifier(hasError, result)

  const { getCurrentSchema } = useValidationSchema()
  const { getCurrentTransform } = useConfigTransform()
  const configTransform = getCurrentTransform('template/edit')

  const {
    name,
    description,
    namingConvention,
    config: {
      unitsOfMeasure
    }
  } = template

  const initialValues = {
    name,
    description,
    namingConvention,
    unitsOfMeasure
  }

  const namingOptions = [
    {
      value: 'prefix',
      label: translate('hubNamingConvention.namingConventionInputOption.prefix')
    },
    {
      value: 'suffix',
      label: translate('hubNamingConvention.namingConventionInputOption.suffix')
    }
  ]

  const onSubmit = async (values) => {
    const formData = await configTransform(values, template)
    await execute(templateId, formData)
    setSubmitted()
    resetView()
    closePanel()
    fetchTemplates(organizationId, { status: 'active' })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={getCurrentSchema('templates/edit')}
    >
      {(
        {
          values,
          handleSubmit,
          isSubmitting,
          errors,
          isValid,
          dirty,
          submitCount
        }
      ) => (
        <Form onChange={setIsDirty(dirty)}>
          <PanelRibbon errors={errors} submitCount={submitCount} />
          <Fieldset>
            <Text
              name="name"
              label={translate('nameInputLabel')}
              placeholder={translate('nameInputPlaceholder')}
              data-testId="edit-template-name"
              submitCount={submitCount}
            />
            <Text
              name="description"
              label={translate('descriptionInputLabel')}
              placeholder={translate('descriptionInputPlaceholder')}
              submitCount={submitCount}
            />
          </Fieldset>
          <Fieldset
            heading={translate('templateDefaultsHeading')}
            hasBorder
            isOnlyHeading
          />
          <Fieldset
            heading={translateConfig('unitsOfMeasure.heading')}
            hasTooltip
            tooltipIcon={mdiInformationOutline}
            tooltipContent={translate('unitsOfMeasureInfoTooltip')}
          >
            <Select
              name="unitsOfMeasure.temperature"
              label={translateConfig('unitsOfMeasure.temperatureInputLabel')}
              options={[
                {
                  value: 'C',
                  label: `${translateConfig('unitsOfMeasure.temperatureInputOption.celsius')} (C°)`
                },
                {
                  value: 'F',
                  label: `${translateConfig('unitsOfMeasure.temperatureInputOption.fahrenheit')} (F°)`
                }
              ]}
              testId="temperature-units"
            />
            <RadioGroup
              name="unitsOfMeasure.light"
              tiled
              title={translateConfig('unitsOfMeasure.lightLevelsInputLabel')}
              options={[
                {
                  value: 'lx',
                  label: `${translateConfig('unitsOfMeasure.lightLevelsInputOption.lux')} (lx)`
                },
                {
                  value: 'fc',
                  label: `${translateConfig('unitsOfMeasure.lightLevelsInputOption.footCandle')} (fc)`
                }
              ]}
              data-testid="light-units"
            />
          </Fieldset>
          <ExpandGroup
            type="toggle"
            heading={translate('hubNamingConvention.heading')}
            expandCondition={values.namingConvention.isEnabled}
            toggleName="namingConvention.isEnabled"
            data-testid="naming-convention-toggle"
            toggleTestid="edit-template-toggle-div"
            hasBorder={false}
          >
            <RadioGroup
              name="namingConvention.value"
              tiled
              data-testid="naming-convention-type"
              options={namingOptions}
            />
          </ExpandGroup>
          <PanelFooter
            labels={buttonLabels}
            handleReset={closePanel}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            isValid={isValid}
            errors={errors}
            testIdRoot="edit-template"
            submitCount={submitCount}
          />
        </Form>
      )}
    </Formik>
  )
}

EditTemplate.propTypes = {
  setIsDirty: PropTypes.func.isRequired
}

export default EditTemplate
