import { types } from './Reducer'
import TemplateClient from './Client'

const getActions = (state, dispatch) => {
  const fetchTemplates = async (orgId, filters = {}) => {
    try {
      dispatch({ type: types.FETCH_TEMPLATES_PENDING })
      const response = await TemplateClient.getTemplatesData(orgId, filters)
      dispatch({ type: types.FETCH_TEMPLATES_SUCCESS, payload: response })
      return response
    } catch (error) {
      dispatch({ type: types.FETCH_TEMPLATES_FAILURE, payload: error })
      return error
    }
  }
  const setSelectedTemplate = selectedTemplate => dispatch({ type: types.SET_SELECTED_TEMPLATE, payload: selectedTemplate })
  const setSelectedTemplateId = id => dispatch({ type: types.SET_SELECTED_TEMPLATE_ID, id })
  const duplicateTemplate = async (templateData) => {
    try {
      dispatch({ type: types.DUPLICATE_TEMPLATES_PENDING })
      await TemplateClient.duplicateTemplate(templateData)
      dispatch({ types: types.DUPLICATE_TEMPLATES_SUCCESS })
    } catch (error) {
      dispatch({ type: types.DUPLICATE_TEMPLATES_FAILURE, payload: error })
    }
  }
  const updateTemplate = (templateId, formData) => TemplateClient.updateTemplate(templateId, formData)
  const fetchTemplateData = async (templateId) => {
    try {
      const response = await TemplateClient.fetchTemplate(templateId)
      return response
    } catch (error) {
      return error
    }
  }
  const setSelectedAttribute = selectedAttribute => dispatch({ type: types.SET_SELECTED_ATTRIBUTE, payload: selectedAttribute })
  const setSelectedTemplatesList = selectedTemplatesList => dispatch({ type: types.SET_SELECTED_TEMPLATES_LIST, payload: selectedTemplatesList })
  const updateAttribute = (templateId, attributeId, formData) => TemplateClient.updateAttribute(templateId, attributeId, formData)
  const deleteTemplates = templates => TemplateClient.deleteTemplates(templates)
  return {
    fetchTemplates,
    setSelectedTemplate,
    setSelectedTemplateId,
    duplicateTemplate,
    updateTemplate,
    fetchTemplateData,
    setSelectedAttribute,
    setSelectedTemplatesList,
    updateAttribute,
    deleteTemplates
  }
}


export default getActions
