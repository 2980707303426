import { number } from 'yup'
import DevicesClient from '../../../State/Devices/Client'

async function test(value) {
  const { jobsiteId, deviceId, deviceNumber } = this.parent
  const deviceNumberCount = await DevicesClient.verifyUniqueNetworkNumberAndDeviceNumber(deviceId, jobsiteId, value, deviceNumber)
  return deviceNumberCount === 0
}

const VerifyNetworkNumber = (messages) => {
  const {
    requiredMessage,
    rangeMessage,
    uniqueMessage
  } = messages

  return number()
    .required(requiredMessage)
    .min(1, rangeMessage)
    .max(465534, rangeMessage)
    .test(
      'networkNumber',
      uniqueMessage,
      test
    )
}

export default VerifyNetworkNumber
