import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

import useTranslate from '../../Utils/Hooks/useTranslate'
import favIcon16 from '../../Images/favicon_16.png'
import favIcon32 from '../../Images/favicon_32.png'
import favIcon48 from '../../Images/favicon_48.png'

const PageMetaData = ({ pageMetaData }) => {
  const translate = useTranslate('pageMetaData')
  const checkTitle = pageMetaData && pageMetaData.title
  const checkDescription = pageMetaData && pageMetaData.description

  return (
    <Helmet>
      <title>{checkTitle ? `${translate('mainTitle')} | ${pageMetaData.title}` : `${translate('mainTitle')}`}</title>
      <meta name="description" content={checkDescription ? pageMetaData.description : translate('todo')} />
      <link rel="icon" sizes="16x16" href={favIcon16} />
      <link rel="icon" sizes="32x32" href={favIcon32} />
      <link rel="icon" sizes="48x48" href={favIcon48} />
    </Helmet>
  )
}

PageMetaData.propTypes = {
  pageMetaData: PropTypes.object.isRequired
}

export default PageMetaData
