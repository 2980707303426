import { useState } from 'react'

const useAsyncCallback = (callback) => {
  const [isInit, setIsInit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [result, setResult] = useState(null)
  const [hasError, setHasError] = useState(null)

  const success = (res) => {
    setResult(res || true)
    setHasError(false)
    setIsLoading(false)
  }

  const error = (res) => {
    setResult(res)
    setHasError(true)
    setIsLoading(false)
  }

  const execute = (...args) => {
    setIsInit(true)
    setIsLoading(true)
    return callback(...args)
      .then(success)
      .catch(error)
  }

  return [execute, isInit, isLoading, result, hasError]
}
export default useAsyncCallback
