import styled from 'styled-components'

export const ConfirmationLabel = styled.strong({
  fontSize: '.875rem',
  fontWeight: '500'
})

export const CheckboxGroupContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  padding: '1rem 0 1.5rem'
})

export const CheckboxContainer = styled.div({
  padding: '.5rem 0 0'
})
