import { string, object } from 'yup'
import { UniqueEmail, MinimumJobsites } from '../Helpers'
import useTranslateWithLanguage from '../../Hooks/useTranslateWithLanguage'

const InviteUserSchema = (language) => {
  const translate = useTranslateWithLanguage('formValidation', language)
  const emailMessages = {
    requiredMessage: translate('emailIsRequired'),
    emailMessage: translate('emailIsNotValid'),
    disabledEmailMessage: translate('disabledEmail'),
    uniqueEmailMessage: translate('uniqueEmail')
  }
  return object().shape({
    email: UniqueEmail(emailMessages),
    role: string().matches(/(admin|installer)/),
    jobsites: MinimumJobsites('role', 'installer', translate('minimumJobsites'))
  })
}

export default InviteUserSchema
