import React from 'react'
import PropTypes from 'prop-types'

import { TableCell } from '../../../../Table'
import formatTime from '../../../../../Utils/formatTime'

const DateCreated = ({ row }) => {
  const { createdAt } = row.original

  return <TableCell>{formatTime(createdAt)}</TableCell>
}

DateCreated.propTypes = {
  row: PropTypes.object.isRequired
}

export default DateCreated
