import React, {
  useContext, useEffect, useMemo, useState
} from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import useTranslate from '../../../Utils/Hooks/useTranslate'
import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useFeatureFlag from '../../../Utils/Hooks/useFeatureFlag'
import useSorter from '../../../Utils/Hooks/useSorter'
import useLocalStorage from '../../../Utils/Hooks/useLocalStorage'

import TemplatesContext from '../../../State/Templates/Context'
import PanelContext from '../../../State/Panel/Context'
import ModalContext from '../../../State/Modal/Context'
import FilterWrapper from '../../FilterWrapper'
import { Tab, TabGroup } from '../../TabGroup'
import Search from '../../Search'
import Table from '../../Table'
import HeaderCheckbox from '../../Table/Components/HeaderCheckbox'
import RowCheckbox from '../../Table/Components/RowCheckbox'
import {
  TemplateName, Description, LastModified, Details
} from './Components'

const searchFields = ['name']

const TemplatesTable = ({ selectedCheckboxes, setSelectedCheckboxes }) => {
  const { actions: { fetchTemplates }, selectors: { getTemplates } } = useContext(TemplatesContext)
  const { selectors: { dataIsStale } } = useContext(ModalContext)
  const { selectors: { dataIsStale: templateDataIsStale } } = useContext(PanelContext)
  const { organizationId } = useParams()
  const [searchQuery, setSearchQuery] = useState('')
  const [, setFilters] = useState({})
  const [status, setStatus] = useState('active')
  const [execute,, isLoading, templateList, hasError] = useAsyncCallback(fetchTemplates)
  const [filteredResults, setFilteredResults] = useState([])
  const [, setTemplateList] = useLocalStorage('templates_list', null)
  const isFiltered = !!searchQuery
  const translate = useTranslate('templatesPage')
  const translateTable = useTranslate('tableFiltering')
  const isArchivingEnabled = useFeatureFlag('archivingEnabled')
  const sortTemplateName = useSorter('rowName')

  useEffect(() => {
    execute(organizationId, { status })
  }, [organizationId, status, dataIsStale(), templateDataIsStale()]) // eslint-disable-line

  useEffect(() => {
    if (templateList && !hasError) {
      const normalizedQuery = searchQuery.toLowerCase()
      const results = templateList.filter(item => (
        searchFields.findIndex(field => item[field].toLowerCase().indexOf(normalizedQuery) > -1) > -1
      ))
      setFilteredResults(results)
      setTemplateList(getTemplates())
    }
  }, [searchQuery, templateList]) // eslint-disable-line
  const templateIds = selectedCheckboxes.map(({ templateId }) => templateId)
  const isDisabled = ({ templateId, isDefaultTemplate }) => {
    if (templateIds.includes(templateId)) {
      return false
    }
    return isDefaultTemplate && selectedCheckboxes.length > 0
  }
  const columns = useMemo(
    () => [
      {
        id: 'selection',
        isFirstRow: true,
        Header: HeaderCheckbox,
        Cell: ({ row, ...rest }) => <RowCheckbox row={row} disabled={isDisabled(row.original)} {...rest} />, // eslint-disable-line
        width: 56
      },
      {
        Header: translate('tableNameColumn'),
        accessor: 'name',
        Cell: TemplateName,
        width: 578,
        flex: true,
        sortType: sortTemplateName
      },
      {
        Header: translate('tableDescriptionColumn'),
        accessor: 'description',
        Cell: Description,
        width: 280
      },
      {
        Header: translate('tableLastModifiedColumn'),
        accessor: 'updatedAt.seconds',
        Cell: LastModified,
        width: 169
      },
      {
        Header: translate('tableDetailsColumn'),
        id: 'edit',
        Cell: Details,
        width: 78
      }
    ], [filteredResults, selectedCheckboxes] // eslint-disable-line
  )

  const onSearch = (value) => {
    setSearchQuery(value)
  }

  const onFilter = (value) => {
    setFilters(value)
  }

  const emptyList = {
    filteredMessage: translate('noResults'),
    unfilteredMessage: translate('emptyListMessage'),
    emptyArchive: translate('emptyArchive')
  }

  return (
    <>
      { isArchivingEnabled && (
        <TabGroup>
          <Tab active={status === 'active'} type="button" onClick={() => setStatus('active')} data-testid="templates-active-tab">
            {translateTable('active')}
          </Tab>
          <Tab active={status === 'archived'} type="button" onClick={() => setStatus('archived')} data-testid="templates-archive-tab">
            {translateTable('archived')}
          </Tab>
        </TabGroup>
      )}
      <FilterWrapper>
        <Search
          searchFields={searchFields}
          onSearch={onSearch}
          onFilter={onFilter}
          data-testid="templates-filter-wrapper"
          placeholderText={translateTable('filterByName')}
          searchTestId="templates"
        />
      </FilterWrapper>
      <Table
        isLoading={isLoading}
        columns={columns}
        data={filteredResults}
        isFiltered={isFiltered}
        emptyList={emptyList}
        status={status}
        setSelectedCheckboxes={setSelectedCheckboxes}
        initialSortBy="name"
      />
    </>
  )
}

TemplatesTable.propTypes = {
  selectedCheckboxes: PropTypes.array.isRequired,
  setSelectedCheckboxes: PropTypes.func.isRequired
}


export default TemplatesTable
