import React from 'react'
import PropTypes from 'prop-types'

import RadioGroup from '../../../../Components/Formik/RadioGroup'
import Text from '../../../../Components/Formik/Text'

const ProgressiveComponent = ({ translate, values }) => {
  const nameOptions = [
    {
      value: 'default',
      label: translate('labels.hubDefault'),
      testid: 'hub-default-value'
    },
    {
      value: 'custom',
      label: translate('labels.custom'),
      testId: 'custom-value-option'
    }
  ]
  return (
    <>
      <RadioGroup
        name="value.isCustomValue"
        options={nameOptions}
        title={translate('labels.value')}
        tiled
        hasBottomMargin={false}
      />
      {values.value.isCustomValue !== 'default' && (
        <Text
          name="value.customValue"
          placeholder={translate('labels.customThresholdValue')}
          textHelper={translate('labels.timeHelper')}
          type="number"
          min="0"
          max="65536"
          data-testid="custom-value-input"
        />
      )}
    </>
  )
}

ProgressiveComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
}

export default ProgressiveComponent
