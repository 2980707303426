import { useContext } from 'react'

import UserContext from '../../State/User/Context'
import en from '../../Languages/i18n/en.json'
import fr from '../../Languages/i18n/fr.json'
import es from '../../Languages/i18n/es.json'
import de from '../../Languages/i18n/de.json'

export const locales = [
  { label: 'English (US)', value: 'en' },
  { label: 'French (Français)', value: 'fr' },
  { label: 'German (Deutsch)', value: 'de' },
  { label: 'Spanish (Español)', value: 'es' }]

export const translations = {
  en,
  fr,
  es,
  de
}

const useTranslate = (scope) => {
  const { selectors: { getLanguagePreference } } = useContext(UserContext)
  const browserLanguage = window.navigator.language.slice(0, 2)
  const defaultLanguage = browserLanguage && translations[browserLanguage] ? browserLanguage : 'en'
  const language = getLanguagePreference() || defaultLanguage
  return (key, overrideLanguage) => translations[overrideLanguage || language][`${scope}.${key}`] || 'MISSING_TRANSLATION'
}

export default useTranslate
