import { useContext, useState, useEffect } from 'react'

import UserContext from '../../State/User/Context'
import useTranslate from './useTranslate'

const useErrorHandler = (firebaseError) => {
  const translate = useTranslate('errorHandling')
  const {
    selectors: {
      getLanguagePreference
    }
  } = useContext(UserContext)
  const [translatedError, setTranslatedError] = useState(null)

  useEffect(() => {
    let firebaseErrorArray = []

    if (!firebaseError) {
      setTranslatedError(null)
    } else {
      if (typeof firebaseError === 'string') {
        firebaseErrorArray = firebaseError.split('/')
      }
      if (firebaseError.code) {
        firebaseErrorArray = firebaseError.code.split('/')
      }

      const kebabCaseError = firebaseErrorArray[1] || firebaseErrorArray[0]
      const key = kebabCaseError && kebabCaseError.replace(/-([a-z])/g, g => g[1].toUpperCase())
      if (key && translate(key) !== 'MISSING_TRANSLATION') {
        setTranslatedError(translate(key))
      } else {
        setTranslatedError(firebaseError.message)
      }
    }
  }, [firebaseError, getLanguagePreference()]) // eslint-disable-line

  return translatedError
}

export default useErrorHandler
