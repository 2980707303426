const initialState = {
  loading: false,
  error: {
    status: false,
    code: null
  },
  data: null,
  filters: null,
  selectedDevice: null,
  selectedDeviceId: null,
  selectedDeviceList: []
}

const types = {
  FETCH_DEVICES_PENDING: 'FETCH_DEVICES_PENDING',
  FETCH_DEVICES_SUCCESS: 'FETCH_DEVICES_SUCCESS',
  FETCH_DEVICES_FAILURE: 'FETCH_DEVICES_FAILURE',
  FETCH_DEVICE_PENDING: 'FETCH_DEVICE_PENDING',
  FETCH_DEVICE_SUCCESS: 'FETCH_DEVICE_SUCCESS',
  FETCH_DEVICE_FAILURE: 'FETCH_DEVICE_FAILURE',
  SET_SELECTED_DEVICE: 'SET_SELECTED_DEVICE',
  SET_SELECTED_DEVICE_ID: 'SET_SELECTED_DEVICE_ID',
  SET_SELECTED_DEVICE_LIST: 'SET_SELECTED_DEVICE_LIST',
  SET_FILTERS: 'SET_FILTERS'
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case (types.FETCH_DEVICES_PENDING):
    case (types.FETCH_DEVICE_PENDING):
      return {
        ...state,
        loading: true
      }
    case (types.FETCH_DEVICES_SUCCESS):
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: {
          status: false,
          code: null
        }
      }
    case (types.FETCH_DEVICE_SUCCESS):
      return {
        ...state,
        loading: false,
        selectedDevice: action.payload,
        error: {
          status: false,
          code: null
        }
      }
    case (types.FETCH_DEVICES_FAILURE):
      return {
        ...state,
        loading: false,
        data: null,
        error: {
          status: true,
          code: action.payload
        }
      }
    case (types.FETCH_DEVICE_FAILURE):
      return {
        ...state,
        loading: false,
        selectedDevice: null,
        error: {
          status: true,
          code: action.payload
        }
      }
    case (types.SET_SELECTED_DEVICE):
      return {
        ...state,
        selectedDevice: action.payload
      }
    case (types.SET_SELECTED_DEVICE_ID):
      return {
        ...state,
        selectedDeviceId: action.payload
      }
    case (types.SET_SELECTED_DEVICE_LIST):
      return {
        ...state,
        selectedDeviceList: action.payload
      }
    case (types.SET_FILTERS):
      return {
        ...state,
        filters: action.payload
      }
    default:
      return state
  }
}

export {
  reducer,
  types,
  initialState
}
