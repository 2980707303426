import styled from 'styled-components'
import Checkbox from '@material-ui/core/Checkbox'

export const MaterialCheckbox = styled(Checkbox)({
  padding: '0 !important',
  borderRadius: '10% !important'
})

export const ToggleWrapper = styled.div(({ theme }) => ({
  '& > label': {
    padding: '10px 16px',
    margin: 0,
    [`@media(min-width: ${theme.breakpoints.md.minWidth})`]: {
      padding: 0,
      margin: '0 0 0 1.5rem'
    },
    '& > span': {
      fontWeight: 400
    }
  }
}))
