import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import useTranslate from '../../../Utils/Hooks/useTranslate'
import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'

import UserContext from '../../../State/User/Context'
import NotificationsContext from '../../../State/Notifications/Context'
import OrgContext from '../../../State/Organization/Context'
import Logo from '../../Logo'
import BackgroundImage from '../../BackgroundImage'
import PageBoundary from '../../PageBoundary'
import PublicForm from '../../PublicForm'
import ExternalLink from '../../ExternalLink'
import appStore from '../../../Images/app-store.svg'
import googlePlay from '../../../Images/google-play.svg'
import PageMetaData from '../../PageMetaData'
import Spinner from '../../Spinner'
import ContentWrapper from '../../ContentWrapper'
import Footer from '../../Footer'

import {
  Header, BadgeContainer, FooterText, FooterHeader, FooterContainer, UserInfoContainer, SignOutButton, UserEmail
} from './Styles'

const DownloadApp = () => {
  const {
    actions: { logout, resetSuccessInvite }, selectors: {
      isLoggedIn, isLoading, isInitialized, getUser, isFromSuccessInvite, getInviteOrgId
    }
  } = useContext(UserContext)
  const { actions: { addNotification } } = useContext(NotificationsContext)
  const { actions: { fetchOrganizationInfo } } = useContext(OrgContext)
  const [username, setUsername] = useState(null)
  const [userInfoContainer, setUserInfoContainer] = useState(null)
  const [isEmailTruncated, setIsEmailTruncated] = useState(false)
  const [isUsernameTruncated, setIsUsernameTruncated] = useState(false)
  const [executeFetchOrgInfo, , inviteOrgIsLoading, organization] = useAsyncCallback(fetchOrganizationInfo)
  const { name: organizationName } = organization || {}
  const history = useHistory()
  const isAuthenticated = isLoggedIn()
  const translate = useTranslate('downloadApp')
  const translateInvite = useTranslate('invites')
  const translateNavigator = useTranslate('navigator')
  const translateMetaData = useTranslate('pageMetaData')

  useEffect(() => {
    setUsername(document.getElementById('username'))
    setUserInfoContainer(document.getElementById('info-container'))
  }, [isAuthenticated])

  useEffect(() => {
    if (username !== null && userInfoContainer !== null) {
      if (username.offsetWidth + 20 >= userInfoContainer.offsetWidth) {
        setIsUsernameTruncated(true)
      }
      userInfoContainer.innerText.split('').forEach(() => {
        if (userInfoContainer.scrollWidth > userInfoContainer.offsetWidth) {
          userInfoContainer.innerText = userInfoContainer.innerText.slice(0, -1)
          setIsEmailTruncated(true)
        }
      })
    }
  }, [username, userInfoContainer])

  useEffect(() => {
    if (isEmailTruncated) {
      userInfoContainer.innerText = userInfoContainer.innerText.slice(0, -3)
      userInfoContainer.innerText += '...)'
    }
    if (isUsernameTruncated) {
      userInfoContainer.innerText = userInfoContainer.innerText.slice(0, -4)
      userInfoContainer.innerText += '...'
    }
  }, [isEmailTruncated, isUsernameTruncated]) // eslint-disable-line

  useEffect(() => {
    if (!isAuthenticated && isInitialized()) {
      history.push('/')
    }
  }, [isAuthenticated]) // eslint-disable-line

  useEffect(() => {
    const retrieveOrgName = async (orgId) => {
      await executeFetchOrgInfo(orgId)
    }
    if (isFromSuccessInvite()) {
      retrieveOrgName(getInviteOrgId())
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!inviteOrgIsLoading && organizationName) {
      addNotification(`${translateInvite('successInviteNotification')} ${organizationName}`, 'success', 3)
      resetSuccessInvite()
    }
  }, [inviteOrgIsLoading]) // eslint-disable-line

  if (!isInitialized() || isLoading()) {
    return <Spinner message={translateNavigator('loading')} />
  }

  return (
    <>
      <PageMetaData pageMetaData={{ title: `${translateMetaData('downloadTitle')}` }} />
      <PageBoundary>
        <ContentWrapper>
          <PublicForm isCentered>
            <Logo />
            <Header>
              <h2>{translate('heading')}</h2>
              <span>{translate('description')}</span>
            </Header>
            <BadgeContainer>
              <ExternalLink href="https://www.apple.com/ca/ios/app-store/">
                <img src={appStore} alt="app-store-link" />
              </ExternalLink>
              <ExternalLink href="https://play.google.com/store/apps/details?id=com.deltacontrols.o3hub">
                <img src={googlePlay} alt="google-play-store-link" />
              </ExternalLink>
            </BadgeContainer>
            {isLoggedIn() && (
              <FooterContainer>
                <FooterHeader>{translate('footerHeader')}</FooterHeader>
                <FooterText>
                  <UserInfoContainer id="info-container">
                    <span id="username">{`${getUser().firstName} ${getUser().lastName} `}</span>
                    <UserEmail>{`(${getUser().email})`}</UserEmail>
                  </UserInfoContainer>
                  <SignOutButton id="signout" onClick={logout} data-testid="signout-button">{translate('signOut')}</SignOutButton>
                </FooterText>
              </FooterContainer>
            )}
          </PublicForm>
          <Footer />
        </ContentWrapper>
        <BackgroundImage />
      </PageBoundary>
    </>
  )
}

export default DownloadApp
