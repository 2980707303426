import React from 'react'
import PropTypes from 'prop-types'
import { TableCell } from '../../../../Table'

import useTranslate from '../../../../../Utils/Hooks/useTranslate'

const ConfiguredPercentage = ({ cell: { value }, row }) => {
  const { devices } = row.original
  const translate = useTranslate('tableFiltering')

  return (
    <TableCell align="right">
      {devices && devices.length > 0 ? `${value}%` : <i>{translate('noHubs')}</i>}
    </TableCell>
  )
}

ConfiguredPercentage.propTypes = {
  cell: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired
}

export default ConfiguredPercentage
