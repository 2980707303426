import React, { useContext, useEffect, useState } from 'react'

import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useTranslate from '../../../Utils/Hooks/useTranslate'

import OrganizationContext from '../../../State/Organization/Context'
import ModalContext from '../../../State/Modal/Context'
import TableContext from '../../../State/Table/Context'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'

import ModalContent from '../../Modal/Components/ModalContent'
import ModalFooter from '../../Modal/Components/ModalFooter'
import SubmitButton from '../../Modal/Components/SubmitButton'
import Button from '../../Button'

const DeleteOrganizations = () => {
  const { actions: { deleteOrganizations, clearCheckedUsers } } = useContext(OrganizationContext)
  const { selectors: { getSelectedCheckboxes } } = useContext(TableContext)
  const { actions: { closeModal, setSubmitted } } = useContext(ModalContext)
  const [checkedOrganizations, setCheckedOrganizations] = useState(getSelectedCheckboxes())
  const translate = useTranslate('deleteOrganizationsModal')
  const [execute, , isLoading, result, hasError] = useAsyncCallback(deleteOrganizations)
  useErrorNotifier(hasError, result)
  const labels = {
    submittingLabel: translate('labels.submitting'),
    buttonLabel: translate('labels.submit')
  }

  const notificationMessages = {
    operationSuccess: translate('notifications.success'),
    operationFailure: translate('notifications.fail')
  }

  useEffect(() => {
    setCheckedOrganizations(getSelectedCheckboxes())
  }, [result, hasError])

  const handleClick = async () => {
    await execute(checkedOrganizations, notificationMessages)
    closeModal()
    setSubmitted()
    clearCheckedUsers()
  }

  return (
    <>
      <ModalContent>
        <p>{translate('copy')}</p>
      </ModalContent>
      <ModalFooter>
        <Button onClick={closeModal} inverted data-testid="remove-invite-link-cancel">{translate('labels.cancel')}</Button>
        <SubmitButton handleSubmit={handleClick} labels={labels} isSubmitting={isLoading} testIdRoot="remove-invite-link" />
      </ModalFooter>
    </>
  )
}

export default DeleteOrganizations
