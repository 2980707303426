import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { mdiAlertCircleOutline } from '@mdi/js'
import { Icon } from '@mdi/react'
import { ThemeContext } from 'styled-components'

import useTranslate from '../../../Utils/Hooks/useTranslate'

import PageMetaData from '../../PageMetaData'

import {
  PageContainer, NotFoundHeader, HttpCode, ReturnLink
} from './Styles'

const NotFound = ({ isFromAuthed }) => {
  const theme = useContext(ThemeContext)
  const translate = useTranslate('notFoundPage')
  const translateMetaData = useTranslate('pageMetaData')
  return (
    <>
      <PageMetaData pageMetaData={{ title: `${translateMetaData('pageNotFoundTitle')}` }} />
      <PageContainer>
        <Icon path={mdiAlertCircleOutline} size={1.25} color={theme.colors.n07} />
        <NotFoundHeader>{translate('header')}</NotFoundHeader>
        <HttpCode>{translate('httpCode')}</HttpCode>
        {!isFromAuthed && (
          <ReturnLink to="/">{translate('returnLink')}</ReturnLink>
        )}
      </PageContainer>
    </>
  )
}

NotFound.propTypes = {
  isFromAuthed: PropTypes.bool
}

NotFound.defaultProps = {
  isFromAuthed: false
}

export default NotFound
