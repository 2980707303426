import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Dropdown from '../Dropdown'
import SelectButton from './SelectButton'

import { contentStyles } from './Styles'

const Select = ({
  name, options, onChange, optionsTestIDRoot, ...rest
}) => {
  const [selectedOption, setSelectedOption] = useState(options[0])
  const [shouldClose, setShouldClose] = useState(false)

  const handleParentState = (input) => {
    setShouldClose(input)
  }

  const handleSelect = (option) => {
    setSelectedOption(option)
    onChange(option)
    setShouldClose(true)
  }

  return (
    <Dropdown
      ButtonComponent={SelectButton}
      name={name}
      selectedOption={selectedOption}
      contentStyles={contentStyles}
      shouldClose={shouldClose}
      updateParent={handleParentState}
      {...rest}
    >
      {options.map(option => (
        <button
          type="button"
          onClick={() => handleSelect(option)}
          key={option.value}
          data-testid={`${optionsTestIDRoot}-${option.value}`}
        >
          {option.label}
        </button>
      ))}
    </Dropdown>
  )
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  optionsTestIDRoot: PropTypes.string
}

Select.defaultProps = {
  optionsTestIDRoot: 'option'
}

export default Select
