const EditTemplate = async (values, template) => {
  const { ...rest } = template

  const configValues = {
    config: {
      ...rest.config,
      unitsOfMeasure: values.unitsOfMeasure
    }
  }
  return {
    ...rest, ...configValues, name: values.name.trim(), description: values.description.trim(), namingConvention: values.namingConvention
  }
}

export default EditTemplate
