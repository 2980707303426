import styled from 'styled-components'

export const CheckboxContainer = styled.label(({ theme }) => ({
  display: 'block',
  cursor: 'pointer',
  '& > input': {
    display: 'none'
  },
  color: theme.colors.n08,
  fontSize: '1rem',
  marginBottom: '.5rem'
}))

export const Checked = styled.span(({ theme, disabled }) => ({
  margin: '0 .5rem 0',
  display: 'none',
  order: '1',
  svg: {
    color: disabled ? theme.colors.n05 : theme.colors.n08,
    cursor: disabled ? 'not-allowed' : 'pointer'
  }
}))

export const UnChecked = styled.span(({ theme, disabled }) => ({
  margin: '0 .5rem 0',
  order: '2',
  display: 'inline-block',
  svg: {
    color: disabled ? theme.colors.n05 : theme.colors.n08,
    cursor: disabled ? 'not-allowed' : 'pointer'
  }
}))

export const CheckboxInput = styled.input(
  {
    order: '3',
    appearance: 'none',
    [`:checked ~ ${Checked}`]: {
      display: 'inline-block'
    },
    [`:checked ~ ${UnChecked}`]: {
      display: 'none'
    }
  }
)

export const LabelText = styled.span({
  verticalAlign: 'super'
})
