import { useState, useCallback, useEffect } from 'react'

const getDimensionObject = (node) => {
  const rect = node.getBoundingClientRect()

  return {
    width: rect.width,
    height: rect.height,
    top: 'x' in rect ? rect.x : rect.top,
    left: 'y' in rect ? rect.y : rect.left,
    x: 'x' in rect ? rect.x : rect.left,
    y: 'y' in rect ? rect.y : rect.top,
    right: rect.right,
    bottom: rect.bottom,
    scrollbarWidth: rect.width - node.clientWidth
  }
}

function useScrollbarWidth(isLoading) {
  const [dimensions, setDimensions] = useState({})
  const [node, setNode] = useState(null)

  const ref = useCallback((refNode) => {
    setNode(refNode)
  }, [node]) // eslint-disable-line

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (node) {
      const measure = () => window.requestAnimationFrame(() => setDimensions(getDimensionObject(node)))
      measure()

      window.addEventListener('resize', measure)
      window.addEventListener('scroll', measure)

      return () => {
        window.removeEventListener('resize', measure)
        window.removeEventListener('scroll', measure)
      }
    }
  }, [node, isLoading]) // eslint-disable-line

  return [ref, dimensions, node]
}

export default useScrollbarWidth
