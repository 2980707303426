import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledAddIcon, StyledRemoveIcon, StyledSpan, StyledLink
} from './Styles'

const LinkToggle = ({
  label, isExpanded, onClick, testId
}) => (
  <StyledLink onClick={onClick} data-testid="link-toggle">
    <StyledSpan data-testid="link-toggle-label">
      {isExpanded ? (<StyledRemoveIcon data-testid={`${testId}-collapse-icon`} />) : (<StyledAddIcon data-testid={`${testId}-expand-icon`} />)} {label}
    </StyledSpan>
  </StyledLink>
)

LinkToggle.propTypes = {
  label: PropTypes.string,
  isExpanded: PropTypes.bool,
  onClick: PropTypes.func,
  testId: PropTypes.string
}

LinkToggle.defaultProps = {
  label: undefined,
  isExpanded: false,
  onClick: undefined,
  testId: null
}

export default LinkToggle
