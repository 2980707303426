import React from 'react'
import PropTypes from 'prop-types'
import { mdiInformationOutline } from '@mdi/js'


import useTranslate from '../../../../Utils/Hooks/useTranslate'
import TextArea from '../../../Formik/TextArea'
import ExpandGroup from '../../../Formik/ExpandGroup'

const Description = ({ values }) => {
  const translate = useTranslate('editAttributePanel')
  return (
    <>
      <ExpandGroup
        type="toggle"
        heading={translate('labels.descriptionHeader')}
        expandCondition={values.isCustomDescription}
        toggleName="isCustomDescription"
        hasTooltip
        tooltipContent={translate('labels.descriptionTooltip')}
        tooltipIcon={mdiInformationOutline}
        toggleTestId="desription-toggle"
      >
        <TextArea
          name="description"
          placeholder={translate('labels.description')}
          textHelper={translate('labels.descriptionHelper')}
          hasOptionalLabel
          wrap="soft"
          maxLength="255"
        />
      </ExpandGroup>
    </>
  )
}

Description.propTypes = {
  values: PropTypes.object.isRequired
}

export default Description
