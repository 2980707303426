import styled from 'styled-components'

export const ExpandControlContainer = styled.div(({ theme, isToggle }) => ({
  ...!isToggle && {
    backgroundColor: `${theme.colors.n01}`,
    borderBottom: `1px solid ${theme.colors.n04}`,
    padding: isToggle ? '0' : '1.5rem 1.5rem 0'
  }
}))

export const ChildrenContainer = styled.div(({ isToggle }) => ({
  padding: isToggle ? '0' : '1.5rem 1.5rem 0'
}))
