import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import ErrorPanel from '../../../PanelViews/ErrorPanel'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = props.hasError
  }

  componentDidUpdate() {
    const { hasError } = this.state
    const { hasError: parentHasError } = this.props
    if (hasError && !parentHasError) {
      // I know this is dangerous, but we need to keep the two states in sync.
      // This all could have been avoided if we could use functional error boundaries.
      this.setState({ hasError: false }) // eslint-disable-line
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.log(error) /* eslint-disable-line */
    console.log(errorInfo.componentStack) /* eslint-disable-line */
  }


  render() {
    const { setHasError, children } = this.props
    const { hasError } = this.state
    const updateHasError = () => {
      setHasError(false)
      this.setState({ hasError: false })
    }

    if (hasError) {
      setHasError(true)
      return (
        <ErrorPanel hasError={hasError} setHasError={updateHasError} />
      )
    }

    return children
  }
}

ErrorBoundary.propTypes = {
  hasError: PropTypes.bool.isRequired,
  setHasError: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

export default withRouter(ErrorBoundary)
