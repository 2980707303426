import React from 'react'
import PropTypes from 'prop-types'

import { FilterWrapperContainer } from './Styles'

const FilterWrapper = ({ children }) => (
  <FilterWrapperContainer data-testid="filter-wrapper">
    {children}
  </FilterWrapperContainer>
)

FilterWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default FilterWrapper
