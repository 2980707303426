const initialState = {
  selectedTemplate: null,
  selectedTemplatesList: null,
  loading: true,
  error: {
    status: false,
    code: null
  },
  data: null
}

const types = {
  SET_SELECTED_TEMPLATE: 'SET_SELECTED_TEMPLATE',
  SET_SELECTED_ATTRIBUTE: 'SET_SELECTED_ATTRIBUTE',
  SET_SELECTED_TEMPLATE_ID: 'SET_SELECTED_TEMPLATE_ID',
  SET_SELECTED_TEMPLATES_LIST: 'SET_SELECTED_TEMPLATES_LIST',
  FETCH_TEMPLATES_PENDING: 'FETCH_TEMPLATES_PENDING',
  FETCH_TEMPLATES_SUCCESS: 'FETCH_TEMPLATES_SUCCESS',
  FETCH_TEMPLATES_FAILURE: 'FETCH_TEMPLATES_FAILURE',
  DUPLICATE_TEMPLATES_PENDING: 'DUPLICATE_TEMPLATES_PENDING',
  DUPLICATE_TEMPLATES_SUCCESS: 'DUPLICATE_TEMPLATES_SUCCESS',
  DUPLICATE_TEMPLATES_FAILURE: 'DUPLICATE_TEMPLATES_FAILURE'
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_TEMPLATES_PENDING:
    case types.DUPLICATE_TEMPLATES_PENDING:
      return {
        ...state,
        loading: true
      }
    case types.DUPLICATE_TEMPLATES_FAILURE:
    case types.FETCH_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          status: true,
          code: action.payload
        }
      }
    case types.FETCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      }
    case types.DUPLICATE_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case types.SET_SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.payload
      }
    case types.SET_SELECTED_TEMPLATES_LIST:
      return {
        ...state,
        selectedTemplatesList: action.payload
      }
    case types.SET_SELECTED_ATTRIBUTE:
      return {
        ...state,
        selectedAttribute: action.payload
      }
    case types.SET_SELECTED_TEMPLATE_ID:
      return {
        ...state,
        selectedTemplateId: action.id
      }
    default:
      return state
  }
}

export {
  reducer,
  initialState,
  types
}
