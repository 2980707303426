import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '../../Tooltip'
import { Container, HeadingContainer } from './Styled'
import Heading from '../../Heading'


const Fieldset = ({
  children, heading, hasTooltip, tooltipContent, tooltipIcon, hasBorder, isToggle, isExpandGroup, isOnlyHeading
}) => (
  <Container hasBorder={hasBorder} isToggle={isToggle} isExpandGroup={isExpandGroup} isOnlyHeading={isOnlyHeading}>
    {heading && (
      <HeadingContainer isOnlyHeading={isOnlyHeading}>
        <Heading tag="h4" level={isOnlyHeading ? 4 : 2} data-testid="fieldset-header" isOnlyHeading={isOnlyHeading}>
          {heading}
          {hasTooltip && (
            <Tooltip content={tooltipContent} icon={tooltipIcon} size={1} data-testid="fieldset-tooltip" />
          )}
        </Heading>
      </HeadingContainer>
    )}

    {children}
  </Container>
)


Fieldset.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string,
  hasTooltip: PropTypes.bool,
  tooltipContent: PropTypes.string,
  tooltipIcon: PropTypes.node,
  hasBorder: PropTypes.bool,
  isToggle: PropTypes.bool,
  isExpandGroup: PropTypes.bool,
  isOnlyHeading: PropTypes.bool
}

Fieldset.defaultProps = {
  heading: null,
  hasTooltip: false,
  tooltipContent: undefined,
  tooltipIcon: undefined,
  hasBorder: true,
  isToggle: false,
  isExpandGroup: false,
  isOnlyHeading: false
}

export default Fieldset
