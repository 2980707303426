import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { useParams } from 'react-router-dom'

import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useTranslate from '../../../Utils/Hooks/useTranslate'
import useValidationSchema from '../../../Utils/Hooks/useValidationSchema'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'
import useConfigTransform from '../../../Utils/Hooks/useConfigTransform'

import PanelContext from '../../../State/Panel/Context'
import JobsContext from '../../../State/Jobs/Context'
import FirmwareContext from '../../../State/Firmware/Context'
import PanelFooter from '../Components/PanelFooter'
import RadioGroup from '../../Formik/RadioGroup'
import ExpandGroup from '../../Formik/ExpandGroup'
import MQTTSettings from '../Components/MQTTSettings'
import Address from '../Components/Address'
import NetworkSettings from '../Components/NetworkSettings'
import BACnetSettings from '../Components/BACnetSettings'
import BLEPin from '../Components/BLEPin'
import PanelRibbon from '../Components/PanelRibbon'
import Firmware from '../Components/Firmware'

const CreateJobSite = ({ setIsDirty }) => {
  const { actions: { closePanel, setSubmitted } } = useContext(PanelContext)
  const { actions: { createJobsite } } = useContext(JobsContext)
  const { selectors: { getFirmwares } } = useContext(FirmwareContext)
  const { organizationId } = useParams()
  const [execute, , , result, hasError] = useAsyncCallback(createJobsite)
  const { getCurrentTransform } = useConfigTransform()
  const configTransform = getCurrentTransform('jobsite/create')
  const { getCurrentSchema } = useValidationSchema()
  const translate = useTranslate('jobPanel')
  const translateConfig = useTranslate('configForm')
  const firmwares = getFirmwares()
  useErrorNotifier(hasError, result)

  const initialValues = {
    name: '',
    address: {
      streetAddress: '',
      city: '',
      country: ''
    },
    network: {
      type: 'dhcp',
      ipAddress: '',
      subnetMask: '',
      gatewayIp: '',
      dnsIp: '',
      dnsIp2: ''
    },
    bacnet: {
      protocol: 'ethernet',
      deviceNumber: '',
      address: ''
    },
    mqtt: {
      isEnabled: true,
      broker: {
        brokerType: 'default',
        addressType: 'brokerIp',
        brokerUrl: '',
        port: ''
      },
      sensorReportType: 'changeOfValue',
      fixedSampleRate: 5,
      sensorReportRate: 60
    },
    reporting: {
      sample: 5,
      rate: 60,
      change: 5
    },
    firmware: {
      isEnabled: false,
      version: null,
      location: null
    },
    namingConvention: 'prefix'
  }

  const labels = {
    buttonLabel: translate('createButton'),
    submittingLabel: translate('submittingButton'),
    cancelLabel: translate('cancelButton')
  }

  const onSubmit = async (values) => {
    const formData = configTransform(values, organizationId)
    await execute(formData)
    setSubmitted()
    closePanel()
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getCurrentSchema('jobsites/create')}
      onSubmit={onSubmit}
    >
      {({
        values, handleSubmit, errors, isSubmitting, isValid, dirty, setFieldValue, submitCount
      }) => (
        <Form onChange={setIsDirty(dirty)}>
          <PanelRibbon errors={errors} submitCount={submitCount} />
          <Address submitCount={submitCount} />
          <NetworkSettings values={values} submitCount={submitCount} />
          <BACnetSettings values={values} isCreateJob submitCount={submitCount} />
          <BLEPin submitCount={submitCount} />
          <MQTTSettings values={values} setFieldValue={setFieldValue} submitCount={submitCount} />
          <Firmware values={values} firmwares={firmwares} setFieldValue={setFieldValue} />
          <ExpandGroup
            type="link"
            heading={translateConfig('hubNamingConvention.heading')}
            data-testid="hub-naming-header"
            testId="naming-convention"
          >
            <RadioGroup
              name="namingConvention"
              tiled
              title={translateConfig('hubNamingConvention.namingConventionInputLabel')}
              options={[
                {
                  value: 'prefix',
                  label: translateConfig('hubNamingConvention.namingConventionInputOption.prefix')
                },
                {
                  value: 'suffix',
                  label: translateConfig('hubNamingConvention.namingConventionInputOption.suffix')
                }
              ]}
              data-testid="hub-naming-convention"
            />
          </ExpandGroup>
          <PanelFooter
            handleReset={closePanel}
            handleSubmit={handleSubmit}
            errors={errors}
            labels={labels}
            isSubmitting={isSubmitting}
            isValid={isValid}
            testIdRoot="create-jobsite"
            submitCount={submitCount}
          />
        </Form>
      )}
    </Formik>
  )
}

CreateJobSite.propTypes = {
  setIsDirty: PropTypes.func.isRequired
}

export default CreateJobSite
