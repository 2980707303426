import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from '../../../Form/Checkbox'

import { ConfirmationLabel, CheckboxGroupContainer, CheckboxContainer } from './Styles'

const ConfirmationCheckboxes = ({ confirmationLabel, checkboxes }) => (
  <>
    <ConfirmationLabel>{confirmationLabel}</ConfirmationLabel>
    <CheckboxGroupContainer>
      {checkboxes.map(({
        testId, checked, label, disabled, onChange
      }) => (
        <CheckboxContainer key={testId}>
          <Checkbox checked={checked} label={label} disabled={disabled} onChange={onChange} />
        </CheckboxContainer>
      ))}
    </CheckboxGroupContainer>
  </>
)

ConfirmationCheckboxes.propTypes = {
  confirmationLabel: PropTypes.string.isRequired,
  checkboxes: PropTypes.array.isRequired
}

export default ConfirmationCheckboxes
