import React, { useContext } from 'react'

import useTranslate from '../../../Utils/Hooks/useTranslate'
import PanelContext from '../../../State/Panel/Context'
import TemplatesContext from '../../../State/Templates/Context'

import PanelFooter from '../../Panel/Components/PanelFooter'
import Button from '../../Button'
import Fieldset from '../../Form/Fieldset'
import ReadOnlyFields from '../Components/ReadOnlyFields'

const ViewAttribute = () => {
  const { actions: { closePanel } } = useContext(PanelContext)
  const { selectors: { getSelectedAttribute } } = useContext(TemplatesContext)
  const translate = useTranslate('viewAttributePanel')
  const translateAttributeTypes = useTranslate('templateAttributesPage')
  const {
    isEnabled, displayValue, name, bacnetId, type, about, description, reporting
  } = getSelectedAttribute()

  const getReportingAttributeFields = () => [
    {
      ...(reporting && reporting !== null) && {
        header: translate('covEnabledLabel'),
        value: reporting.isEnabled ? translateAttributeTypes('onValue') : translateAttributeTypes('offValue'),
        testId: 'view-attribute-reporting-enabled'
      }
    },
    {
      ...(reporting && reporting !== null) && reporting.isEnabled && {
        header: translate('covValueLabel'),
        value: reporting.value,
        testId: 'view-attribute-reporting-value'
      }
    }
  ]


  const attributeFields = [
    {
      header: translate('statusLabel'),
      value: isEnabled ? translate('status.included') : translate('status.excluded'),
      testId: 'view-attribute-stauts'
    },
    {
      header: translate('nameLabel'),
      value: name,
      testId: 'view-attribute-name'
    },
    {
      ...description && {
        header: translate('nameLabel'),
        value: description,
        testId: 'view-attribute-escription'
      }
    },
    {
      header: translate('typeLabel'),
      value: translateAttributeTypes(`${type}TypeOption`),
      testId: 'view-attribute-type'
    },
    {
      header: translate('valueLabel'),
      value: displayValue,
      testId: 'view-attribute-value'
    },
    ...getReportingAttributeFields(),
    {
      ...(about && about !== null) && {
        header: translate('aboutLabel'),
        value: about,
        testId: 'view-attribute-about'
      }
    },
    {
      header: translate('bacnetLabel'),
      value: bacnetId,
      testId: 'view-attribute-bacnetId'
    }
  ]
  return (
    <>
      <Fieldset hasBorder={false}>
        <ReadOnlyFields fields={attributeFields} />
      </Fieldset>
      <PanelFooter>
        <Button onClick={closePanel} inverted data-testid="view-attribute-close-button">{translate('buttons.close')}</Button>
      </PanelFooter>
    </>
  )
}

export default ViewAttribute
