import Styled from 'styled-components'

export const Container = Styled.div(({ theme }) => ({
  padding: '1.5rem',
  borderTop: `1px solid ${theme.colors.n04}`,
  boxShadow: '0px -6px 10px 0px rgba(0,0,0,0.16)',
  textAlign: 'right',
  '& > button': {
    marginRight: '1rem'
  },
  '& > button:last-child': {
    marginRight: 0
  }
}))
