import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@mdi/react'
import { mdiInformationOutline } from '@mdi/js'
import { ThemeContext } from 'styled-components'

import { ErrorContainer } from './Styles'

const Error = ({ error, testId, errorPadding }) => {
  const theme = useContext(ThemeContext)
  return (
    <ErrorContainer data-testid={testId} errorPadding={errorPadding}>
      <Icon path={mdiInformationOutline} size={0.875} color={theme.colors.s05} /><p>{error}</p>
    </ErrorContainer>
  )
}

Error.propTypes = {
  error: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
  errorPadding: PropTypes.bool
}

Error.defaultProps = {
  errorPadding: true
}

export default Error
