import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'

import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useTranslate from '../../../Utils/Hooks/useTranslate'
import useValidationSchema from '../../../Utils/Hooks/useValidationSchema'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'
import useConfigTransform from '../../../Utils/Hooks/useConfigTransform'

import PanelContext from '../../../State/Panel/Context'
import JobsContext from '../../../State/Jobs/Context'
import FirmwareContext from '../../../State/Firmware/Context'
import PanelFooter from '../Components/PanelFooter'
import ExpandGroup from '../../Formik/ExpandGroup'
import MQTTSettings from '../Components/MQTTSettings'
import Address from '../Components/Address'
import NetworkSettings from '../Components/NetworkSettings'
import BACnetSettings from '../Components/BACnetSettings'
import BLEPin from '../Components/BLEPin'
import ReadOnlyFields from '../Components/ReadOnlyFields'
import PanelRibbon from '../Components/PanelRibbon'
import Firmware from '../Components/Firmware'
import Ribbon from '../../Ribbon'

const EditJobSite = ({ setIsDirty }) => {
  const { actions: { closePanel, setSubmitted, resetView } } = useContext(PanelContext)
  const { selectors: { getJobsite, getSelectedJobsiteId }, actions: { updateJobsite } } = useContext(JobsContext)
  const { selectors: { getFirmwares } } = useContext(FirmwareContext)
  const jobsiteId = getSelectedJobsiteId()
  const jobsite = getJobsite(jobsiteId)
  const [execute, , , result, hasError] = useAsyncCallback(updateJobsite)
  const { getCurrentTransform } = useConfigTransform()
  const configTransform = getCurrentTransform('jobsite/edit')
  const { getCurrentSchema } = useValidationSchema()
  const translate = useTranslate('jobPanel')
  const translateConfig = useTranslate('configForm')
  const firmwares = getFirmwares()
  useErrorNotifier(hasError, result)

  const jobSiteWithDefaults = {
    ...jobsite,
    config: {
      ...jobsite.config,
      firmware: {
        isEnabled: false,
        version: null,
        location: null,
        ...jobsite.config.firmware
      }
    }
  }

  const {
    name,
    address,
    namingConvention,
    config: {
      network, bacnet, blePin, mqtt: {
        isEnabled, sensorReportType, fixedSampleRate, sensorReportRate, broker
      },
      firmware: {
        isEnabled: isFirmwareEnabled,
        version,
        location
      }
    }
  } = jobSiteWithDefaults

  const {
    brokerType, addressType, ipAddress, url, port
  } = broker || {}

  const isDeprecatedModel = !broker

  const initialValues = {
    name,
    address,
    network,
    bacnet,
    mqtt: {
      isEnabled,
      sensorReportType: sensorReportType || 'changeOfValue',
      fixedSampleRate: fixedSampleRate || 5,
      sensorReportRate: sensorReportRate || 60,
      broker: {
        brokerType: brokerType || 'default',
        ...brokerType === 'custom' ? {
          addressType: addressType === 'ip' ? 'brokerIp' : 'brokerUrl',
          port,
          ...addressType === 'ip' ? { brokerIp: ipAddress } : { brokerUrl: url }
        } : {}
      }
    },
    firmware: {
      isEnabled: isFirmwareEnabled,
      version,
      location
    },
    namingConvention,
    blePin
  }

  const onSubmit = async (values) => {
    const formData = configTransform(values)
    await execute(jobsiteId, formData, isDeprecatedModel ? ['config.mqtt'] : [])
    setSubmitted()
    resetView()
    closePanel()
  }
  const labels = {
    buttonLabel: translate('saveButton'),
    submittingLabel: translate('submittingButton'),
    cancelLabel: translate('cancelButton')
  }

  const namingConventionFields = [
    {
      header: `${translateConfig('hubNamingConvention.namingConventionInputLabel')}:`,
      value: jobsite.namingConvention,
      testId: 'naming-convention'
    }
  ]

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getCurrentSchema('jobsites/create')}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({
        values, handleSubmit, isSubmitting, errors, isValid, dirty, setFieldValue, submitCount
      }) => (
        <Form onChange={setIsDirty(dirty)}>
          <PanelRibbon errors={errors} submitCount={submitCount} />
          {isDeprecatedModel && <Ribbon type="warning">{translateConfig('deprecatedModelWarning')}</Ribbon>}
          <Address submitCount={submitCount} />
          <NetworkSettings values={values} submitCount={submitCount} />
          <BACnetSettings values={values} submitCount={submitCount} />
          <BLEPin submitCount={submitCount} />
          <MQTTSettings values={values} setFieldValue={setFieldValue} submitCount={submitCount} />
          <Firmware values={values} firmwares={firmwares} setFieldValue={setFieldValue} />
          <ExpandGroup
            type="link"
            heading={translateConfig('hubNamingConvention.heading')}
            testId="naming-convention"
          >
            <ReadOnlyFields fields={namingConventionFields} />
          </ExpandGroup>
          <PanelFooter
            handleReset={closePanel}
            handleSubmit={handleSubmit}
            errors={errors}
            labels={labels}
            isSubmitting={isSubmitting}
            isValid={isValid}
            testIdRoot="edit-jobsite"
            submitCount={submitCount}
          />
        </Form>
      )}
    </Formik>
  )
}

EditJobSite.propTypes = {
  setIsDirty: PropTypes.func.isRequired
}

export default EditJobSite
