const config = {
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: `${process.env.REACT_APP_GCP_PROJECT_ID}.firebaseapp.com`,
    databaseURL: `https://${process.env.REACT_APP_GCP_PROJECT_ID}.firebaseio.com`,
    projectId: process.env.REACT_APP_GCP_PROJECT_ID,
    stackDriverApiKey: process.env.REACT_APP_STACKDRIVER_API_KEY,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    dcOrgId: process.env.REACT_APP_FIREBASE_DC_ORGANIZATION_ID
  },
  cloud: {
    api: {
      host: process.env.REACT_APP_CLOUD_API_HOST
    }
  },
  featureFlags: {
    templatesEnabled: true,
    displaySupportLink: true,
    archivingEnabled: false,
    orgMgmtEnabled: false,
    deleteHubsEnabled: true
  },
  support: {
    domain: process.env.REACT_APP_SUPPORT_DOMAIN
  },
  firmware: {
    apiKey: process.env.REACT_APP_CLOUD_FIRMWARE_APIKEY,
    host: process.env.REACT_APP_CLOUD_FIRMWARE_HOST
  }
}

export default config
