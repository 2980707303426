import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import { mdiPencilOutline, mdiFileDocumentBoxOutline } from '@mdi/js'

import Icon from '@mdi/react'
import PanelContext from '../../../../../State/Panel/Context'
import DevicesContext from '../../../../../State/Devices/Context'
import ZonesContext from '../../../../../State/Zones/Context'
import JobsContext from '../../../../../State/Jobs/Context'

const EditButton = ({ row }) => {
  const { original } = row
  const { organizationId, jobsiteId } = useParams()
  const rowIndex = row.index + 1
  const { actions: { changeView } } = useContext(PanelContext)
  const { actions: { setSelectedDevice } } = useContext(DevicesContext)
  const { actions: { setSelectedZone } } = useContext(ZonesContext)
  const { actions: { fetchJobsites }, selectors: { getJobsite } } = useContext(JobsContext)
  const isZone = () => row.canExpand
  const jobsite = getJobsite(jobsiteId) || {}
  const { status: jobsiteStatus } = jobsite
  const isConfigured = original && (original.phase === 'configured' || original.phase === 'issue')
  const hubIsArchived = original && (original.status === 'inactive') ? original.status === 'inactive' : false
  const jobIsArchived = jobsiteStatus && jobsiteStatus === 'inactive' ? jobsiteStatus === 'inactive' : false
  const hubIsEditable = !hubIsArchived && !jobIsArchived && !isConfigured

  const handleClick = () => {
    if (isZone()) {
      setSelectedZone(row)
      changeView('zones/edit')
    } else {
      setSelectedDevice(original)
      changeView(hubIsEditable ? 'device/edit' : 'device/view')
    }
  }

  useEffect(() => {
    fetchJobsites(organizationId)
  }, []) // eslint-disable-line

  return (
    <button
      type="button"
      onClick={handleClick}
      data-testid={`devices-edit${isZone() ? `zone-${rowIndex}` : `hub-${rowIndex}`}`}
    >
      <Icon path={hubIsEditable ? mdiPencilOutline : mdiFileDocumentBoxOutline} size={1} />
    </button>
  )
}

EditButton.propTypes = {
  row: PropTypes.object.isRequired
}

export default EditButton
