import React from 'react'
import PropTypes from 'prop-types'

import useTranslate from '../../../../Utils/Hooks/useTranslate'
import RadioGroup from '../../../Formik/RadioGroup'
import FormikText from '../../../Formik/Text'
import Text from '../../../Form/Text'

const CustomName = ({ values, selectedAttribute }) => {
  const translate = useTranslate('editAttributePanel')
  const translateAttributes = useTranslate('attributes')
  const nameOptions = [
    {
      value: 'false',
      label: 'Default',
      testId: 'default-name-toggle'
    },
    {
      value: 'true',
      label: 'Custom',
      testId: 'custom-name-toggle'
    }
  ]
  return (
    <>
      <RadioGroup
        name="isCustomName"
        options={nameOptions}
        title={translate('labels.name')}
        tiled
        hasBottomMargin={false}
        testid="custom-name-options"
      />
      {values.isCustomName !== 'false'
        ? (<FormikText name="name" placeholder={translateAttributes(selectedAttribute.attributeId)} data-testid="custom-name-input" />)
        : (<Text placeholder={translateAttributes(selectedAttribute.attributeId)} disabled />)}
    </>
  )
}

CustomName.propTypes = {
  values: PropTypes.object.isRequired,
  selectedAttribute: PropTypes.object.isRequired
}

export default CustomName
