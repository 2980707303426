const ReportingEnabled = (values, { name }) => {
  const {
    attributeId, isCustomDescription, reporting, description, ...rest
  } = values

  const isCustomName = rest.isCustomName === 'true'

  const config = {
    ...rest,
    isCustomName,
    ...!isCustomName && { name },
    description: isCustomDescription ? description : '',
    reporting: {
      isEnabled: reporting.isEnabled,
      value: reporting.isEnabled || (!reporting.isEnabled && reporting.value) ? reporting.value : 0
    }
  }
  return config
}

export default ReportingEnabled
