import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Icon from '@mdi/react'
import { useHistory } from 'react-router-dom'
import {
  mdiHexagonMultipleOutline, mdiPackageDown, mdiPlusCircleOutline, mdiMinusCircleOutline
} from '@mdi/js'

import useTranslate from '../../../Utils/Hooks/useTranslate'
import useTableCheckboxState from '../../../Utils/Hooks/useTableCheckboxState'

import Breadcrumbs from '../../Breadcrumbs'
import Button from '../../Button'
import ButtonGroup from '../../ButtonGroup'
import PageHeader from '../../PageHeader'
import PageInfo from '../../PageInfo'
import PageCaption from '../../PageCaption'
import Panel from '../../Panel'
import PanelContext from '../../../State/Panel/Context'
import ModalContext from '../../../State/Modal/Context'
import UserContent from '../../../State/User/Context'
import OrganizationsTable from './OrganizationsTable'
import PageMetaData from '../../PageMetaData'

const Organizations = ({ setState, setOrgSelectState }) => {
  const { isBulkActionDisabled, setSelectedCheckboxes } = useTableCheckboxState()
  const { actions: { changeView } } = useContext(PanelContext)
  const { actions: { changeModalView } } = useContext(ModalContext)
  const [status, setStatus] = useState('active')
  const history = useHistory()
  const { selectors: { getUser } } = useContext(UserContent)
  const translate = useTranslate('organizationsPage')
  const translateMetaData = useTranslate('pageMetaData')
  setState('Content/Overview.html')
  setOrgSelectState('jobs')

  const openPanel = () => {
    changeView('organizations/create')
  }

  const openDeleteModal = () => {
    changeModalView('organizations/delete', true)
  }

  const openModal = () => {
    if (status === 'inactive' || status === 'archived') {
      changeModalView('organizations/reactivate', true)
    } else {
      changeModalView('organizations/archive', true)
    }
  }

  useEffect(() => {
    if (!getUser().admin) {
      history.push('/organization')
    }
  }, [])

  return (
    <>
      <PageMetaData pageMetaData={{ title: `${translateMetaData('organizationsTitle')}`, description: 'TODO' }} />
      <Breadcrumbs />
      <PageHeader>
        <PageInfo>
          <Icon path={mdiHexagonMultipleOutline} size={1} />
          <PageCaption>
            <h1>{translate('heading')}</h1>
            <h4>
              <span>{translate('description')}</span>
            </h4>
          </PageCaption>
        </PageInfo>
        <ButtonGroup>
          <Button onClick={openModal} disabled={isBulkActionDisabled} inverted><Icon path={mdiPackageDown} size={1} />
            {status === 'active' ? translate('archiveOrganizationButton') : translate('reactivateOrganizationButton')}
          </Button>
          {status === 'active'
            && <Button onClick={openPanel}><Icon path={mdiPlusCircleOutline} size={1} />{translate('createOrganizationButton')}</Button>
          }
          {status === 'archived'
            && <Button onClick={openDeleteModal} disabled={isBulkActionDisabled} inverted><Icon path={mdiMinusCircleOutline} size={1} />{translate('deleteOrganizationButton')}</Button>
          }
        </ButtonGroup>
        <Panel />
      </PageHeader>
      <OrganizationsTable
        setSelectedCheckboxes={setSelectedCheckboxes}
        setStatus={setStatus}
        status={status}
      />
    </>
  )
}

Organizations.propTypes = {
  setState: PropTypes.func.isRequired,
  setOrgSelectState: PropTypes.func
}

Organizations.defaultProps = {
  setOrgSelectState: () => {}
}

export default Organizations
