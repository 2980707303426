const EditDevice = async (values, device, createZone) => {
  const { displayName, zoneName, ...rest } = device
  const { jobsiteId, deviceId } = rest
  const {
    mqtt: {
      isEnabled, sensorReportType, sensorReportRate, fixedSampleRate, broker
    }
  } = values
  let editZoneId = values.zone.id
  if (values.zone.isNewZone === 'true') {
    const zoneData = {
      jobsiteId,
      devices: [deviceId],
      name: values.zone.zoneName
    }
    editZoneId = await createZone(zoneData)
  }
  const baseMqttSettings = {
    isEnabled,
    sensorReportType,
    sensorReportRate,
    ...sensorReportType === 'fixed'
      ? { fixedSampleRate }
      : {}
  }
  const customBrokerValues = {
    addressType: broker && broker.addressType === 'brokerIp' ? 'ip' : 'url',
    port: broker && broker.port,
    ...broker && broker.addressType === 'brokerIp'
      ? { ip: broker && broker.brokerIp }
      : { url: broker && broker.brokerUrl }
  }
  const mqttBrokerValues = {
    ...baseMqttSettings,
    broker: {
      brokerType: broker && broker.brokerType,
      ...broker && broker.brokerType === 'custom'
        ? customBrokerValues
        : {}
    }
  }

  const firmware = {
    isEnabled: values.firmware.isEnabled,
    ...values.firmware.isEnabled && {
      location: values.firmware.location,
      version: values.firmware.version
    }
  }

  const configValues = {
    config: {
      ...rest.config,
      ...isEnabled
        ? { mqtt: mqttBrokerValues }
        : { mqtt: { isEnabled: false } },
      bacnet: values.bacnet,
      network: values.network,
      firmware
    }
  }
  return {
    ...rest, ...configValues, zoneId: editZoneId, name: values.device.deviceName
  }
}

export default EditDevice
