import React, { useState, useContext } from 'react'
import { mdiAlertOutline } from '@mdi/js'
import Icon from '@mdi/react'

import useTranslate from '../../../Utils/Hooks/useTranslate'
import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'
import ModalContext from '../../../State/Modal/Context'
import DeviceContext from '../../../State/Devices/Context'
import NotificationsContext from '../../../State/Notifications/Context'

import Ribbon from '../../Ribbon'
import Button from '../../Button'
import Checkbox from '../../Form/Checkbox'
import ModalContent from '../../Modal/Components/ModalContent'
import ModalFooter from '../../Modal/Components/ModalFooter'

import {
  ConfirmationHeader, ConfirmationLabel, CheckboxGroupContainer, CheckboxContainer, ConfirmationFooter, WarningText
} from './Styles'

const DevicesDelete = () => {
  const translate = useTranslate('deleteDevicesModal')
  const { actions: { closeModal, setSubmitted } } = useContext(ModalContext)
  const { actions: { deleteDevices }, selectors: { getSelectedDeviceList } } = useContext(DeviceContext)
  const { actions: { addNotification } } = useContext(NotificationsContext)
  const [execute, , isSubmitting, result, hasError] = useAsyncCallback(deleteDevices)
  const [isSensorHubsSelected, setSensorHubsSelected] = useState(false)
  const [isSensorDataSelected, setSensorDataSelected] = useState(false)
  useErrorNotifier(hasError, result)

  const handleSensorHubsClick = () => setSensorHubsSelected(!isSensorHubsSelected)
  const handleSensorData = () => setSensorDataSelected(!isSensorDataSelected)
  const handleSubmit = async () => {
    const selectedDevices = getSelectedDeviceList()
    await execute(selectedDevices)
    setSubmitted()
    closeModal()
    addNotification(translate('notifications.success'), 'success', 2)
  }
  const handleCancel = () => {
    closeModal()
  }
  const allAgreed = isSensorDataSelected && isSensorHubsSelected
  return (
    <>
      <Ribbon type="warning">
        <Icon path={mdiAlertOutline} size={1} /> <WarningText>{translate('labels.warning')}</WarningText>
      </Ribbon>
      <ModalContent hasBottomPadding={false}>
        <ConfirmationHeader>{translate('confirmationHeader')}</ConfirmationHeader>
        <ConfirmationLabel>{translate('labels.confirmationLabel')}</ConfirmationLabel>
        <CheckboxGroupContainer>
          <CheckboxContainer>
            <Checkbox defaultChecked={isSensorHubsSelected} label={translate('labels.sensorHubs')} disabled={isSubmitting} onChange={handleSensorHubsClick} index={0} />
          </CheckboxContainer>
          <CheckboxContainer>
            <Checkbox defaultChecked={isSensorDataSelected} label={translate('labels.sensorData')} disabled={isSubmitting} onChange={handleSensorData} index={1} />
          </CheckboxContainer>
        </CheckboxGroupContainer>
        <ConfirmationFooter>{translate('confirmationFooter')}</ConfirmationFooter>
      </ModalContent>
      <ModalFooter>
        <Button
          destructive
          data-testid="delete-device-modal-delete-button"
          onClick={handleSubmit}
          disabled={!allAgreed}
          isLoading={isSubmitting}
        >
          {translate('labels.delete')}
        </Button>
        <Button
          inverted
          data-testid="delete-device-modal-cancel-button"
          onClick={handleCancel}
          disabled={isSubmitting}
        >
          {translate('labels.cancel')}
        </Button>
      </ModalFooter>
    </>
  )
}

export default DevicesDelete
