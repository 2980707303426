import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const useErrorRouter = (list, key, routeId, isLoading) => {
  const history = useHistory()
  const hasAccess = Array.isArray(list) && list.find(item => item[key] === routeId)

  useEffect(() => {
    if (routeId && !isLoading && !hasAccess) {
      history.push({
        pathname: '/error',
        state: { isPermissionError: true }
      })
    }
  }, [isLoading]) // eslint-disable-line
}


export default useErrorRouter
