import React from 'react'
import Skeleton from 'react-loading-skeleton'

import {
  TableContainer, TableHeader, TableHeaderCell, TableBody, TableBodyRow, TableBodyCell
} from '../Styles'

const NUMBER_OF_ROWS = 4

const values = [
  {
    key: 1,
    cellWidth: 40,
    circle: true
  },
  {
    key: 2,
    cellWidth: 248,
    flex: true
  },
  {
    key: 3,
    cellWidth: 144
  },
  {
    key: 4,
    cellWidth: 144
  },
  {
    key: 5,
    cellWidth: 66
  }
]

const rows = Array.from(Array(NUMBER_OF_ROWS).keys())

const TableSkeletonLoader = () => (
  <TableContainer>
    <TableHeader>
      <tr>
        {values.map((skeleton) => {
          const {
            cellWidth, circle, flex, key
          } = skeleton
          return (
            <TableHeaderCell cellWidth={cellWidth} flex={flex} key={key}>
              <Skeleton height={circle && (cellWidth - 16)} width={cellWidth - 16} circle={circle} />
            </TableHeaderCell>
          )
        })}
      </tr>
    </TableHeader>
    <TableBody>
      {rows.map(row => (
        <TableBodyRow key={row}>
          {values.map((skeleton) => {
            const {
              cellWidth, circle, flex, key
            } = skeleton
            return (
              <TableBodyCell cellWidth={cellWidth} flex={flex} key={key}>
                <Skeleton height={circle && (cellWidth - 16)} width={cellWidth - 16} circle={circle} />
              </TableBodyCell>
            )
          })}
        </TableBodyRow>
      ))}
    </TableBody>
  </TableContainer>
)

export default TableSkeletonLoader
