import { useContext, useLayoutEffect } from 'react'
import TableContext from '../../State/Table/Context'

const useTableCheckboxState = () => {
  const { actions: { setSelectedCheckboxes }, selectors: { getSelectedCheckboxes } } = useContext(TableContext)
  const selectedCheckboxes = getSelectedCheckboxes()
  const isBulkActionDisabled = selectedCheckboxes.length === 0

  useLayoutEffect(() => {
    setSelectedCheckboxes([])
  }, []) // eslint-disable-line

  return { isBulkActionDisabled, selectedCheckboxes, setSelectedCheckboxes }
}

export default useTableCheckboxState
