import styled from 'styled-components'

export const Wrapper = styled.div({
  flex: 1
})

export const InputContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid',
  borderColor: theme.colors.n05,
  padding: '0.5rem 1rem',
  ':focus-within': {
    boxShadow: `0 1px 0px 0px ${theme.colors.b01}`,
    borderColor: theme.colors.b01
  },
  '& > svg': {
    marginRight: '1rem',
    color: theme.colors.n08
  }
}))

export const FilterPill = styled.div(({ theme }) => ({
  margin: '0 .5rem 0 0',
  borderRadius: '4px',
  color: theme.colors.n08,
  backgroundColor: theme.colors.n05,
  fontSize: '14px',
  letterSpacing: '0.3px',
  label: {
    padding: '0.5rem',
    '& > button': {
      border: 'none',
      backgroundColor: 'transparent',
      svg: {
        marginRight: '-0.5rem',
        verticalAlign: '-4px',
        fill: theme.colors.n08
      }
    }
  }
}))

export const Suggestions = styled.div(({ theme, active }) => ({
  padding: 8,
  borderRadius: 2,
  boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
  backgroundColor: 'white',
  display: active ? 'block' : 'none',
  '& > button': {
    marginBottom: '0.5rem',
    padding: '0 0.5rem',
    border: 'none',
    borderRadius: '4px',
    fontSize: '14px',
    lineHeight: '24px',
    color: theme.colors.n08,
    backgroundColor: theme.colors.n05,
    ':last-of-type': {
      marginBottom: 0
    }
  }
}))

export const FilterButton = styled.button(({ theme }) => ({
  marginLeft: 'auto',
  fontSize: '14px',
  border: 'none',
  color: theme.colors.b01,
  cursor: 'pointer',
  svg: {
    marginRight: '0.25rem',
    verticalAlign: '-3px',
    fill: theme.colors.b01
  }
}))

export const TextInput = styled.input(({ theme }) => ({
  flex: '1',
  border: 'none',
  appearance: 'none',
  background: 'none',
  fontSize: '1rem',
  width: '100%',
  '::placeholder': {
    letterSpacing: '0.5px',
    color: theme.colors.n05
  },
  ':focus': {
    outline: 'none'
  }
}))

export const ClearButton = styled.span(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2px',
  borderRadius: '2px',
  cursor: 'pointer',
  svg: {
    verticalAlign: '-4px',
    color: theme.colors.n08
  },
  ':hover': {
    backgroundColor: theme.colors.n02
  },
  ':focus': {
    outline: 'none',
    backgroundColor: theme.colors.n03
  }
}))
