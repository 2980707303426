import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Checkbox from './Checkbox'
import { Heading } from './Styles'
import Error from '../../Form/Error'

const CheckboxBoolean = ({
  value,
  label,
  heading,
  name,
  testIdRoot,
  ...rest
}) => {
  const [error, setError] = useState()
  return (
    <>
      {heading && <Heading data-testid="checkbox-group-heading">{heading}</Heading>}
      <Checkbox
        label={label}
        value={value}
        name={name}
        id={name}
        testIdRoot={testIdRoot}
        setError={setError}
        {...rest}
      />
      {error && <Error testId="text-error" error={error} />}
    </>
  )
}
CheckboxBoolean.propTypes = {
  value: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  heading: PropTypes.string,
  testIdRoot: PropTypes.string
}

CheckboxBoolean.defaultProps = {
  label: '',
  heading: undefined,
  testIdRoot: ''
}

export default CheckboxBoolean
