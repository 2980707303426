import { object } from 'yup'
import { EmailField, MinimumJobsites } from '../Helpers'
import useTranslateWithLanguage from '../../Hooks/useTranslateWithLanguage'

const EditUserSchema = (language) => {
  const translate = useTranslateWithLanguage('formValidation', language)

  return object().shape({
    email: EmailField().required(translate('emailIsRequired')),
    jobsites: MinimumJobsites('role', 'installer', translate('minimumJobsites'))
  })
}

export default EditUserSchema
