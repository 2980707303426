import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link as ReactRouterLink, useHistory, useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

import Icon from '@mdi/react'
import {
  mdiDomain, mdiPackageDown, mdiPlusCircleOutline, mdiArrowLeft, mdiAccountMultipleOutline, mdiPublish, mdiDeleteOutline
} from '@mdi/js'

import useTranslate from '../../../Utils/Hooks/useTranslate'
import useTableCheckboxState from '../../../Utils/Hooks/useTableCheckboxState'
import useErrorRouter from '../../../Utils/Hooks/useErrorRouter'
import useFeatureFlag from '../../../Utils/Hooks/useFeatureFlag'

import JobsiteEditButton from './Components/JobsiteEditButton'
import Breadcrumbs from '../../Breadcrumbs'
import Link from '../../Link'
import Button from '../../Button'
import ButtonGroup from '../../ButtonGroup'
import PageHeader from '../../PageHeader'
import PageInfo from '../../PageInfo'
import PageCaption from '../../PageCaption'
import Panel from '../../Panel'
import PanelContext from '../../../State/Panel/Context'
import JobsContext from '../../../State/Jobs/Context'
import DevicesContext from '../../../State/Devices/Context'
import FirmwareContext from '../../../State/Firmware/Context'
import ModalContext from '../../../State/Modal/Context'
import DevicesTable from './DevicesTable'
import PageMetaData from '../../PageMetaData'

const Devices = ({ setState, setOrgSelectState }) => {
  const { isBulkActionDisabled, selectedCheckboxes, setSelectedCheckboxes } = useTableCheckboxState()
  const { actions: { changeView, resetView }, selectors: { dataIsStale } } = useContext(PanelContext)
  const { actions: { fetchJobsites }, selectors: { getJobsite, getJobsites, isLoading } } = useContext(JobsContext)
  const { actions: { setSelectedDeviceList } } = useContext(DevicesContext)
  const { actions: { fetchFirmware } } = useContext(FirmwareContext)
  const { actions: { changeModalView }, selectors: { dataIsStale: modalDataIsStale } } = useContext(ModalContext)
  const [status, setStatus] = useState('active')
  const { organizationId, jobsiteId } = useParams()
  const history = useHistory()
  const translate = useTranslate('devicesPage')
  const translateMetaData = useTranslate('pageMetaData')
  const jobsite = getJobsite(jobsiteId) || {}
  const {
    name, address, installers, namingConvention, status: jobsiteStatus
  } = jobsite

  const isDeleteHubsEnabled = useFeatureFlag('deleteHubsEnabled')
  const archiveString = status === 'active' ? 'archiveSensorHubButton' : 'unarchiveSensorHubButton'
  const isJobsiteArchived = jobsiteStatus === 'inactive'
  const isEditDisabled = isBulkActionDisabled || isJobsiteArchived

  useErrorRouter(getJobsites(), 'jobsiteId', jobsiteId, isLoading())

  useEffect(() => {
    fetchJobsites(organizationId)
    fetchFirmware()
  }, [dataIsStale(), modalDataIsStale()]) // eslint-disable-line

  setState('Content/Managing_Jobs.html')
  setOrgSelectState('jobs')

  const renderNumberOfInstallers = () => {
    if (installers) {
      return installers.length === 1 ? `${installers.length} ${translate('descriptionInstaller')}`
        : `${installers.length} ${translate('descriptionInstallers')}`
    }
    return `0 ${translate('descriptionInstallers')}`
  }

  const openPanel = () => {
    changeView('device/create')
  }

  const openUpdateFirmwarePanel = () => {
    setSelectedDeviceList(selectedCheckboxes)
    changeView('device/update-firmware')
  }

  const navigateToJobs = (e) => {
    e.preventDefault()
    resetView()
    history.push(`/organization/${organizationId}/jobs`)
  }

  const handleArchiveClick = () => {
    if (status === 'active') {
      setSelectedDeviceList(selectedCheckboxes)
      changeModalView('devices/deactivate')
    } else {
      setSelectedDeviceList(selectedCheckboxes)
      changeModalView('devices/activate')
      // toggleDeviceStatus(selectedCheckboxes)
      // setStatus('active')
      // addNotification(translate('notifications.success'), 'success', 2)
    }
  }

  const handleDeleteClick = () => {
    setSelectedDeviceList(selectedCheckboxes)
    changeModalView('devices/delete')
  }

  return (
    <>
      <PageMetaData pageMetaData={{ title: `${translateMetaData('jobsitesTitle')} | ${name}`, description: 'TODO' }} />
      <Breadcrumbs>
        <a href={`/organization/${organizationId}/jobs`} type="button" onClick={navigateToJobs} data-testid="devices-jobs-link">
          <Icon path={mdiArrowLeft} size={1} />{translate('breadcrumbs.jobs')}
        </a>
      </Breadcrumbs>
      <PageHeader>
        <PageInfo>
          <Icon path={mdiDomain} size={1} />
          <PageCaption>
            <h1>{name && !isLoading() ? `${translate('heading')} (${name})` : <Skeleton width={200} />}<JobsiteEditButton jobsiteId={jobsiteId} /></h1>
            <h4>
              <span>{address && !isLoading() ? address.displayAddress : <Skeleton width={190} />}</span>
              <span>
                <Icon path={mdiAccountMultipleOutline} size={0.75} />
                {
                  isLoading() ? <Skeleton width={85} /> : renderNumberOfInstallers()
                }
                &nbsp;
                (
                <Link
                  to={{
                    pathname: `/organization/${organizationId}/team`,
                    search: `?jobsiteId=${jobsiteId}`,
                    state: { fromDevices: true }
                  }}
                  ReactRouterComponent={ReactRouterLink}
                  data-testid="devices-installers-link"
                >
                  {translate('descriptionManage')}
                </Link>
                )
              </span>
            </h4>
          </PageCaption>
        </PageInfo>
        <ButtonGroup data-testid="devices-button-group">
          <Button
            onClick={handleArchiveClick}
            inverted
            data-testid="devices-archive-button"
            disabled={isEditDisabled}
          >
            <Icon path={mdiPackageDown} size={1} />{translate(archiveString)}
          </Button>
          {status === 'active' && (
            <>
              {isDeleteHubsEnabled && (
                <Button
                  inverted
                  destructive
                  disabled={isEditDisabled}
                  data-testid="devices-delete-button"
                  onClick={handleDeleteClick}
                >
                  <Icon path={mdiDeleteOutline} size={1} />{translate('deleteHubsButton')}
                </Button>
              )}
              <Button
                onClick={openUpdateFirmwarePanel}
                inverted
                data-testid="devices-update-firmware-button"
                disabled={isEditDisabled}
              >
                <Icon path={mdiPublish} size={1} />{translate('updateFirmwareButton')}
              </Button>
              <Button
                onClick={openPanel}
                data-testid="devices-create-hub"
                disabled={isJobsiteArchived}
              >
                <Icon path={mdiPlusCircleOutline} size={1} />{translate('createSensorHubButton')}
              </Button>
            </>
          )}
        </ButtonGroup>
        <Panel />
      </PageHeader>
      <DevicesTable
        namingConvention={namingConvention}
        setSelectedCheckboxes={setSelectedCheckboxes}
        status={status}
        setStatus={setStatus}
      />
    </>
  )
}

Devices.propTypes = {
  setState: PropTypes.func.isRequired,
  setOrgSelectState: PropTypes.func
}

Devices.defaultProps = {
  setOrgSelectState: () => { }
}

export default Devices
