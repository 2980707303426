import useValidationSchema from '../../Hooks/useValidationSchema'
import useConfigTransform from '../../Hooks/useConfigTransform'
import CoVSoundComponent from './Components/CoVSoundComponent'

const CoVSoundField = () => {
  const { getCurrentSchema } = useValidationSchema()
  const { getCurrentTransform } = useConfigTransform()

  return {
    validationSchema: getCurrentSchema('attributes/covSound'),
    configTransform: getCurrentTransform('attributes/reportingEnabled'),
    Component: () => null,
    CoVComponent: CoVSoundComponent
  }
}

export default CoVSoundField
