const EditJobsite = (values) => {
  const {
    mqtt: {
      isEnabled, sensorReportType, sensorReportRate, fixedSampleRate, broker
    }
  } = values
  const address = `${values.address.streetAddress} ${values.address.city} ${values.address.country}`

  const baseMqttSettings = {
    isEnabled,
    sensorReportType,
    sensorReportRate,
    ...sensorReportType === 'fixed'
      ? { fixedSampleRate }
      : {}
  }

  const customBrokerValues = {
    addressType: broker && broker.addressType === 'brokerIp' ? 'ip' : 'url',
    port: broker && broker.port,
    ...broker && broker.addressType === 'brokerIp'
      ? { ip: broker && broker.brokerIp }
      : { url: broker && broker.brokerUrl }
  }
  const mqttBrokerValues = {
    ...baseMqttSettings,
    broker: {
      brokerType: broker && broker.brokerType,
      ...broker && broker.brokerType === 'custom'
        ? customBrokerValues
        : {}
    }
  }

  const jobsiteConfig = {
    name: values.name.trim(),
    address: {
      streetAddress: values.address.streetAddress.trim(),
      city: values.address.city.trim(),
      country: values.address.country.trim(),
      displayAddress: address.trim()
    },
    namingConvention: values.namingConvention
  }

  const baseConfig = {
    blePin: `${values.blePin}`,
    bacnet: values.bacnet,
    network: values.network,
    firmware: {
      isEnabled: values.firmware.isEnabled,
      ...values.firmware.isEnabled && {
        location: values.firmware.location,
        version: values.firmware.version
      }
    }
  }

  const config = Object.assign(
    {},
    baseConfig,
    values.mqtt.isEnabled
      ? { mqtt: mqttBrokerValues }
      : { mqtt: { isEnabled: false } }
  )

  return Object.assign({}, jobsiteConfig, { config })
}

export default EditJobsite
