import { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import UserContext from '../../../../State/User/Context'


const MagicLinkRouter = ({ children }) => {
  const { actions: { setReferrer }, selectors: { isLoggedIn } } = useContext(UserContext)
  const location = useLocation()

  useEffect(() => {
    if (!isLoggedIn()) {
      setReferrer(location)
    }
  }, []) // eslint-disable-line

  return children
}

MagicLinkRouter.propTypes = {
  children: PropTypes.node.isRequired
}

export default MagicLinkRouter
