import React from 'react'
import PropTypes from 'prop-types'

import { TableCell } from '../../../../Table'

const OrgAdmin = ({ row }) => {
  const { original: { email } } = row

  return (
    <TableCell>{email}</TableCell>
  )
}
OrgAdmin.propTypes = {
  row: PropTypes.object.isRequired
}
export default OrgAdmin
