import React from 'react'
import PropTypes from 'prop-types'

import useTranslate from '../../../../Utils/Hooks/useTranslate'

import FieldSet from '../../../Form/Fieldset'
import Text from '../../../Formik/Text'

const Address = ({ submitCount }) => {
  const translate = useTranslate('jobPanel')
  return (
    <FieldSet>
      <Text label={translate('nameInputLabel')} name="name" placeholder={translate('nameInputPlaceholder')} data-testid="jobsite-name" submitCount={submitCount} />
      <Text label={translate('addressInputLabel')} name="address.streetAddress" placeholder={translate('addressInputPlaceholder')} data-testid="jobsite-street-address" submitCount={submitCount} />
      <Text label={translate('cityInputLabel')} name="address.city" placeholder={translate('cityInputPlaceholder')} data-testid="jobsite-city" submitCount={submitCount} />
      <Text label={translate('countryInputLabel')} name="address.country" placeholder={translate('countryInputPlaceholder')} data-testid="jobsite-country" submitCount={submitCount} />
    </FieldSet>
  )
}

Address.propTypes = {
  submitCount: PropTypes.number.isRequired
}

export default Address
