import React, {
  useEffect, useRef, useContext, useState
} from 'react'
import { createPortal } from 'react-dom'
import Transition from 'react-transition-group/Transition'
import PropTypes from 'prop-types'

import PanelHeader from './Components/PanelHeader'
import PanelErrorBoundary from './Components/PanelErrorBoundary'
import PanelViewFactory from '../PanelViews'
import PanelContext from '../../State/Panel/Context'
import ModalContext from '../../State/Modal/Context'
import Modal from '../Modal'

import { PanelWrapper, PanelBackdrop, PanelContent } from './Styles'

const ANIMATION_DURATION = 240

const Panel = ({ onClose, unmountOnExit, ...rest }) => {
  const panelRoot = document.getElementById('panel-root')
  const el = useRef(document.createElement('div'))
  const modalRef = useRef(document.getElementById('modal-root'))
  const refWrapper = useRef(null)
  const [hasError, setHasError] = useState(false)

  const { selectors: { isOpenSelector, currentViewSelector }, actions: { closePanel, resetView } } = useContext(PanelContext)
  const { actions: { changeModalView } } = useContext(ModalContext)
  const { getCurrentView } = PanelViewFactory()
  const [isDirty, setIsDirty] = useState(false)

  const isOpen = isOpenSelector()
  const target = el.current
  const handleErrorReset = () => {
    if (hasError) {
      setHasError(false)
      resetView()
    }
  }

  // Component mounting
  useEffect(() => {
    panelRoot.appendChild(target)
    return () => target.remove()
  }, []) // eslint-disable-line

  // Click outside handler
  const handleClickOutside = (event) => {
    if (isOpen && refWrapper.current && !refWrapper.current.contains(event.target) && !modalRef.current.contains(event.target)) {
      onClose()
      if (isDirty) {
        changeModalView('cancel-confirmation/request')
      } else {
        closePanel()
        resetView()
      }
      handleErrorReset()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  })

  // Esc key handling
  const handleEscPress = (event) => {
    if (isOpen && (event.key === 'Escape' || event.keyCode === 27)) {
      onClose()
      if (isDirty) {
        changeModalView('cancel-confirmation/request')
      } else {
        closePanel()
        resetView()
      }
      handleErrorReset()
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', handleEscPress)
    return () => document.removeEventListener('keyup', handleEscPress)
  })

  const currentViewType = currentViewSelector()
  const { view: CurrentView, header } = getCurrentView(currentViewType)
  return createPortal((
    <Transition
      appear
      unmountOnExit={unmountOnExit}
      timeout={ANIMATION_DURATION}
      in={isOpen}
    >
      {state => (
        <>
          <PanelBackdrop data-state={state} />
          <PanelWrapper
            ref={refWrapper}
            aria-modal="true"
            role="dialog"
            data-state={state}
            data-testid="panel"
          >
            <PanelHeader header={header} isOpen={isOpen} onClose={onClose} hasError={hasError} setHasError={setHasError} />
            <PanelContent>
              <PanelErrorBoundary hasError={hasError} setHasError={setHasError}>
                <CurrentView setIsDirty={setIsDirty} {...rest} />
              </PanelErrorBoundary>
            </PanelContent>
            <div id="panelFooter" />
          </PanelWrapper>
          <Modal />
        </>
      )}
    </Transition>
  ), el.current)
}

Panel.propTypes = {
  onClose: PropTypes.func,
  unmountOnExit: PropTypes.bool
}

Panel.defaultProps = {
  onClose: () => null,
  unmountOnExit: false
}

export default Panel
