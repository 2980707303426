import React from 'react'
import ReactDOM from 'react-dom'
import * as Firebase from 'firebase/app'
import StackdriverErrorReporter from 'stackdriver-errors-js'

import config from './Config'
import App from './Components/App'

Firebase.initializeApp(config.firebase)

const errorHandler = new StackdriverErrorReporter()
errorHandler.start({
  key: config.firebase.stackDriverApiKey,
  projectId: config.firebase.projectId
})

ReactDOM.render(<App />, document.getElementById('root'))
