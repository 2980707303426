import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { parse } from 'query-string'
import { Route, Redirect, useLocation } from 'react-router-dom'

import useTranslate from '../../../../Utils/Hooks/useTranslate'

import UserContext from '../../../../State/User/Context'
import Spinner from '../../../Spinner'


const PublicRoute = ({
  component: Component, ...rest
}) => {
  const { selectors: { isLoggedIn, isInitialized } } = useContext(UserContext)
  const location = useLocation()
  const { next } = parse(location.search)
  const redirect = next || '/organization'
  const translate = useTranslate('navigator')

  if (!isInitialized()) {
    return <Spinner message={translate('loading')} />
  }

  return (
    <Route
      {...rest}
      render={props => (!isLoggedIn()
        ? <Component {...props} />
        : <Redirect to={{ pathname: redirect, state: { referrer: location.state } }} />)}
    />
  )
}

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired
}

export default PublicRoute
