import { string } from 'yup'
import toTitleCase from '../../toTitleCase'
import OrganizationClient from '../../../State/Organization/Client'

async function testUniqueCompanyName() {
  try {
    const { name } = this.parent
    return OrganizationClient.verifyUniqueCompanyName(toTitleCase(name))
  } catch (e) {
    return null
  }
}

const UniqueCompanyName = ({
  requiredMessage, unqiueCompanyNameMessage
}) => string()
  .required(requiredMessage)
  .test(
    'name',
    unqiueCompanyNameMessage || 'The company name ${value} has already been registered', //eslint-disable-line
    testUniqueCompanyName
  )

export default UniqueCompanyName
