import React from 'react'
import PropTypes from 'prop-types'

import useTranslate from '../../../../Utils/Hooks/useTranslate'

import FieldSet from '../../../Form/Fieldset'
import Text from '../../../Formik/Text'

const Organization = ({ submitCount }) => {
  const translate = useTranslate('organizationPanel')
  return (
    <FieldSet>
      <Text label={translate('nameInputLabel')} name="name" placeholder={translate('nameInputPlaceholder')} data-testid="organization-name" submitCount={submitCount} />
      {/*
        // commenting this out until requirements are made more clear accountId: CD-409
        <Text label={translate('accountId')} name="accountId" placeholder={translate('accountIdPlaceholder')} data-testid="organization-account-id" submitCount={submitCount} />
      */}
      <Text label={translate('emailInputLabel')} name="email" placeholder={translate('emailInputPlaceholder')} data-testid="organization-email" submitCount={submitCount} />
      <Text label={translate('cityInputLabel')} name="city" placeholder={translate('cityInputPlaceholder')} data-testid="organization-city" submitCount={submitCount} />
      <Text label={translate('provinceOrStateInputLabel')} name="provinceOrState" placeholder={translate('provinceOrStateInputPlaceholder')} data-testid="organization-provinceOrState" submitCount={submitCount} />
      <Text label={translate('countryInputLabel')} name="country" placeholder={translate('countryInputPlaceholder')} data-testid="organization-country" submitCount={submitCount} />
    </FieldSet>
  )
}

Organization.propTypes = {
  submitCount: PropTypes.number.isRequired
}

export default Organization
