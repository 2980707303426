import React, { useContext } from 'react'
import PropTypes from 'prop-types'


import ExternalLink from '../ExternalLink'
import UserContext from '../../State/User/Context'

import useTranslateWithLanguage from '../../Utils/Hooks/useTranslateWithLanguage'

import {
  FooterContainer, FooterItems, SelectWrapper, StyledSelect
} from './Styles'

const options = [
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Español' },
  { value: 'de', label: 'Deutsch' },
  { value: 'fr', label: 'Français' }
]

const Footer = ({ isCentered }) => {
  const { actions: { setLanguagePreference }, selectors: { getLanguagePreference } } = useContext(UserContext)
  const language = getLanguagePreference() || 'en'
  const translate = useTranslateWithLanguage('footer', language)

  const onChange = (event) => {
    setLanguagePreference(event.target.value)
  }
  return (
    <FooterContainer isCentered={isCentered} data-testid="footer">
      <FooterItems>
        <SelectWrapper currentSelectionLabel={`${translate('languageSelectorLabel')} (${options.find(option => option.value === language).label})`}>
          <StyledSelect value={language} onChange={onChange} data-testid="language-selector">
            {options.map((currentOption, index) => (
              <option value={currentOption.value} key={currentOption.value} data-testid={`select-option-${index}`}>
                {currentOption.label}
              </option>
            ))}
          </StyledSelect>
        </SelectWrapper>
        <ExternalLink href="https://helpdesk.deltacontrols.com/">
          {translate('contactUs')}
        </ExternalLink>
      </FooterItems>
    </FooterContainer>
  )
}

Footer.propTypes = {
  isCentered: PropTypes.bool
}

Footer.defaultProps = {
  isCentered: false
}

export default Footer
