const getSelectors = state => ({
  getSelectedCheckboxes() {
    return state.selectedCheckboxes
  },
  getState() {
    return state
  }
})

export default getSelectors
