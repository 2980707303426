import React, { useContext, useState } from 'react'

import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useTranslate from '../../../Utils/Hooks/useTranslate'

import UserContext from '../../../State/User/Context'
import NotificationContext from '../../../State/Notifications/Context'
import OrganizationContext from '../../../State/Organization/Context'
import ModalContext from '../../../State/Modal/Context'
import ModalContent from '../../Modal/Components/ModalContent'
import ModalFooter from '../../Modal/Components/ModalFooter'
import Button from '../../Button'
import SubmitButton from '../../Modal/Components/SubmitButton'
import PanelContext from '../../../State/Panel/Context'

const RemoveInviteLink = () => {
  const { actions: { removeInviteEmail }, selectors: { hasError } } = useContext(UserContext)
  const [execute] = useAsyncCallback(removeInviteEmail)
  const { actions: { closePanel, setSubmitted } } = useContext(PanelContext)
  const { selectors: { getSelectedUser } } = useContext(OrganizationContext)
  const { actions: { closeModal } } = useContext(ModalContext)
  const { actions: { addNotification } } = useContext(NotificationContext)
  const selectedUser = getSelectedUser()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const translate = useTranslate('removeInvite')

  const { inviteId, email } = selectedUser

  const labels = {
    submittingLabel: translate('removingInviteButton'),
    buttonLabel: translate('removeInviteButton')
  }

  const handleClick = async () => {
    setIsSubmitting(true)
    await execute(inviteId)
    if (!hasError()) {
      setIsSubmitting(false)
      addNotification(translate('successNotification'), 'success', 2)
      closeModal()
      setSubmitted()
      closePanel()
    } else {
      addNotification(translate('error'), 'error', 2)
    }
  }

  return (
    <>
      <ModalContent>
        {translate('descriptionFirstPart')} <strong>{email}</strong> {translate('descriptionSecondPart')}
      </ModalContent>
      <ModalFooter>
        <Button onClick={closeModal} inverted data-testid="remove-invite-link-cancel">{translate('cancelButton')}</Button>
        <SubmitButton handleSubmit={handleClick} labels={labels} isSubmitting={isSubmitting} data-testid="remove-link-submit" testIdRoot="remove-invite-link" />
      </ModalFooter>
    </>
  )
}

export default RemoveInviteLink
