import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import { Link as ReactRouterLink, useParams } from 'react-router-dom'

import Link from '../../../../Link'
import { TableCell } from '../../../../Table'

import JobsContext from '../../../../../State/Jobs/Context'

const DeviceName = ({ row }) => {
  const { deviceId, displayName, status } = row.original || {}
  const { organizationId, jobsiteId } = useParams()
  const { actions: { fetchJobsites }, selectors: { getJobsite } } = useContext(JobsContext)
  const hubName = document.getElementById(`hub-name-${deviceId}`)
  const hubNameCell = document.getElementById(`hub-name-cell-${deviceId}`)
  const isTextOverflowed = (el, cell) => el.offsetWidth > cell.scrollWidth
  const isHubNameOverflowed = hubName !== null && hubNameCell !== null && isTextOverflowed(hubName, hubNameCell)
  const jobsite = getJobsite(jobsiteId) || {}
  const { status: jobsiteStatus } = jobsite
  const hubIsEditable = status === 'active' && jobsiteStatus === 'active'

  useEffect(() => {
    fetchJobsites(organizationId)
  }, []) // eslint-disable-line

  if (row.canExpand) {
    return <>{row.subRows[0].original.zoneName}</>
  }

  return (
    <TableCell id={`hub-name-cell-${deviceId}`}>
      {isHubNameOverflowed ? (
        <Tooltip placement="top" title={displayName} arrow data-testid="tooltip">
          <div>
            {hubIsEditable
              ? (
                <Link
                  to={`/organization/${organizationId}/jobs/${jobsiteId}/devices/${deviceId}`}
                  ReactRouterComponent={ReactRouterLink}
                  data-testid={`devices-device-name-link-${deviceId}`}
                  id={`hub-name-${deviceId}`}
                >
                  {displayName}
                </Link>
              )
              : <>{displayName}</>
            }
          </div>
        </Tooltip>
      ) : (
        <div>
          {hubIsEditable
            ? (
              <Link
                to={`/organization/${organizationId}/jobs/${jobsiteId}/devices/${deviceId}`}
                ReactRouterComponent={ReactRouterLink}
                data-testid={`devices-device-name-link-${deviceId}`}
                id={`hub-name-${deviceId}`}
              >
                {displayName}
              </Link>
            )
            : <>{displayName}</>
          }
        </div>
      )}
    </TableCell>
  )
}

DeviceName.propTypes = {
  row: PropTypes.object.isRequired
}

export default DeviceName
