import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import useLocalStorage from '../../Utils/Hooks/useLocalStorage'
import UserContext from '../../State/User/Context'

import { orgSelectorContentStyles } from './Styles'

const OrganizationSelector = () => {
  const { selectors: { getUID, getOrganizations } } = useContext(UserContext)
  const { organizationId } = useParams()
  const [defaultOrg] = useLocalStorage(getUID(), null)
  const currentOrgId = organizationId || defaultOrg
  const currentOrg = getOrganizations().find(org => org.id === currentOrgId)

  return (
    <div style={orgSelectorContentStyles}>{currentOrg.name}</div>
  )
}

export default OrganizationSelector
