import React from 'react'
import PropTypes from 'prop-types'

import useTranslate from '../../../../../Utils/Hooks/useTranslate'

import { TableCell } from '../../../../Table'
import formatTime from '../../../../../Utils/formatTime'

const LastNetworkSync = ({ row }) => {
  const { lastSeenAt, config: { mqtt: { isEnabled, broker } } } = row.original
  const translate = useTranslate('devicesPage')
  const translateTable = useTranslate('tableFiltering')

  const getSyncValue = () => {
    if (!isEnabled) {
      return (<i>{`MQTT(${translate('cellFormatting.disabled')})`}</i>)
    }

    if (broker && broker.brokerType !== 'default') {
      return (<i>{`MQTT(${translate('cellFormatting.selfHosted')})`}</i>)
    }

    return lastSeenAt
      ? formatTime(lastSeenAt, translateTable('dateFormattingToday'), translateTable('dateFormattingYesterday'))
      : (<i>{translate('cellFormatting.notSynced')}</i>)
  }

  return (
    <TableCell>
      {getSyncValue()}
    </TableCell>
  )
}

LastNetworkSync.propTypes = {
  row: PropTypes.object.isRequired
}

export default LastNetworkSync
