import { useContext, useEffect } from 'react'

import useErrorHandler from './useErrorHandler'

import NotificationsContext from '../../State/Notifications/Context'

const useErrorNotifier = (hasError, firebaseError, type = 'error', duration = null) => {
  const { actions: { addNotification } } = useContext(NotificationsContext)
  const errorMessage = useErrorHandler(firebaseError)

  useEffect(() => {
    if (errorMessage) {
      addNotification(errorMessage, type, duration)
    }
  }, [errorMessage]) // eslint-disable-line
}

export default useErrorNotifier
