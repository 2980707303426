import React from 'react'
import PropTypes from 'prop-types'

import { ModalContentContainer } from './Styles'

const ModalContent = ({ hasBottomPadding, children }) => (
  <ModalContentContainer hasBottomPadding={hasBottomPadding} data-testid="modal-content-container">
    {children}
  </ModalContentContainer>
)

ModalContent.propTypes = {
  hasBottomPadding: PropTypes.bool,
  children: PropTypes.node.isRequired
}

ModalContent.defaultProps = {
  hasBottomPadding: true
}

export default ModalContent
