import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { useParams } from 'react-router-dom'
import { mdiInformationOutline } from '@mdi/js'

import useTranslate from '../../../Utils/Hooks/useTranslate'
import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useIOType from '../../../Utils/Hooks/useIOType'
import useValidationSchema from '../../../Utils/Hooks/useValidationSchema'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'
import useConfigTransform from '../../../Utils/Hooks/useConfigTransform'

import PanelContext from '../../../State/Panel/Context'
import TemplateContext from '../../../State/Templates/Context'

import FieldSet from '../../Form/Fieldset'
import Select from '../../Formik/Select'
import Text from '../../Formik/Text'

import PanelFooter from '../Components/PanelFooter'
import PanelRibbon from '../Components/PanelRibbon'
import EnableInConfig from '../Components/EnableInConfig'
import CustomName from '../Components/CustomName'
import ReadOnlyAttributeFields from '../Components/ReadOnlyAttributeFields'
import Description from '../Components/Description'
import EnableCoV from '../Components/EnableCoV/EnableCoV'

const ReadOnlyFields = ({ values }) => {
  const translate = useTranslate('editAttributePanel')
  const { value } = values
  const readOnlyFields = [
    {
      header: translate('labels.type'),
      value: translate('inputOutputType'),
      testId: 'type-value'
    },
    {
      header: translate('labels.bacnetLabel'),
      value: `${useIOType(value)}1`,
      testId: 'bacnet-object'
    }
  ]
  return (
    <ReadOnlyAttributeFields fields={readOnlyFields} />
  )
}

ReadOnlyFields.propTypes = {
  values: PropTypes.object.isRequired
}

const EditAttributeIO = ({ setIsDirty }) => {
  const { actions: { closePanel, setSubmitted } } = useContext(PanelContext)
  const { actions: { updateAttribute }, selectors: { getSelectedAttribute } } = useContext(TemplateContext)
  const { templateId } = useParams()
  const { getCurrentTransform } = useConfigTransform()
  const configTransform = getCurrentTransform('attributes/editIO')
  const { getCurrentSchema } = useValidationSchema()
  const selectedAttribute = getSelectedAttribute()
  const [execute, , , result, hasError] = useAsyncCallback(updateAttribute)
  useErrorNotifier(hasError, result)
  const initialValues = {
    ...selectedAttribute,
    isCustomName: selectedAttribute.isCustomName.toString(),
    isCustomDescription: selectedAttribute.description.length > 0,
    reporting: {
      ...selectedAttribute.reporting,
      value: selectedAttribute.reporting.value ? selectedAttribute.reporting.value : 0
    }
  }
  const translate = useTranslate('editAttributePanel')
  const labels = {
    buttonLabel: translate('saveButton'),
    submittingButton: translate('submittingButton'),
    cancelLabel: translate('cancelButton')
  }

  const onSubmit = async (values, { resetForm }) => {
    const config = await configTransform(values, selectedAttribute)
    await execute(templateId, selectedAttribute.attributeId, config)
    setSubmitted()
    closePanel()
    resetForm()
  }

  const channelTypeOptions = [
    {
      value: 'analogInput',
      label: translate('labels.analogInput')
    },
    {
      value: 'analogOutput',
      label: translate('labels.analogOutput')
    },
    {
      value: 'binaryInput',
      label: translate('labels.binaryInput')
    },
    {
      value: 'binaryOutput',
      label: translate('labels.binaryOutput')
    }
  ]

  const channelValues = [
    {
      value: '0-10 VDC',
      label: '0-10 VDC'
    },
    {
      value: '0-5 VDC',
      label: '0-5 VDC'
    },
    {
      value: '10K Resistor',
      label: '10K Resistor'
    }
  ]

  const isOutput = values => values.value.channelType.includes('Output') //eslint-disable-line

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={getCurrentSchema('attributes/editIO')}
        enableReinitialize
      >
        {({
          values, handleSubmit, isSubmitting, errors, isValid, dirty, submitCount
        }) => (
          <Form onChange={setIsDirty(dirty)}>
            <PanelRibbon errors={errors} submitCount={submitCount} />
            <EnableInConfig />
            <FieldSet>
              <CustomName values={values} selectedAttribute={selectedAttribute} />
              <Select
                id="channel-type-select"
                name="value.channelType"
                options={channelTypeOptions}
                testId="channel-type-select"
                label={translate('labels.channelType')}
              />
              <Select
                name="value.channelValue"
                options={channelValues}
                id="channel-value-select"
                testId="channel-value-select"
                label={translate('labels.channelValue')}
                disabled={isOutput(values)}
              />
            </FieldSet>
            {!isOutput(values) && (
              <EnableCoV values={values}>
                <Text
                  name="reporting.value"
                  label={translate('labels.covAcceptable')}
                  id="reportingValue"
                  accessoryText={`V ${translate('labels.covLastKnown')}`}
                  prefixText="+/-"
                  type="number"
                  min="0"
                  max="100"
                  data-testid="cov-value-input"
                  textHelper={translate('labels.covRange')}
                  hasTooltip
                  tooltipIcon={mdiInformationOutline}
                  tooltipContent={translate('labels.covRangeTooltip')}
                />
              </EnableCoV>
            )}
            <Description values={values} />
            <ReadOnlyFields values={values} />
            <PanelFooter
              handleReset={closePanel}
              handleSubmit={handleSubmit}
              errors={errors}
              labels={labels}
              isSubmitting={isSubmitting}
              isValid={isValid}
              testIdRoot="edit-attribute"
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

EditAttributeIO.propTypes = {
  setIsDirty: PropTypes.func.isRequired
}

export default EditAttributeIO
