import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Checkbox from './Checkbox'
import { Heading } from './Styles'
import Error from '../../Form/Error'

const CheckboxGroup = ({
  options,
  heading,
  name,
  testIdRoot,
  ...rest
}) => {
  const [error, setError] = useState()
  return (
    <>
      {heading && <Heading data-testid="checkbox-group-heading">{heading}</Heading>}
      {options.map((option, index) => (
        <Checkbox
          key={option.value}
          label={option.label}
          value={option.value}
          name={name}
          id={option.value + index}
          testIdRoot={testIdRoot}
          index={index}
          setError={setError}
          {...rest}
        />
      ))}
      {error && <Error testId="text-error" error={error} />}
    </>
  )
}
CheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  heading: PropTypes.string,
  testIdRoot: PropTypes.string
}

CheckboxGroup.defaultProps = {
  heading: undefined,
  testIdRoot: ''
}

export default CheckboxGroup
