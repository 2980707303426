import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'

import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useTranslate from '../../../Utils/Hooks/useTranslate'
import useValidationSchema from '../../../Utils/Hooks/useValidationSchema'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'
import useConfigTransform from '../../../Utils/Hooks/useConfigTransform'

import PanelContext from '../../../State/Panel/Context'
import OrganizationsContext from '../../../State/Organization/Context'
import NotificationContext from '../../../State/Notifications/Context'
import PanelFooter from '../Components/PanelFooter'
import Organization from '../Components/Organization'
// import Subscription from '../Components/Subscription'
import PanelRibbon from '../Components/PanelRibbon'

const CreateOrganization = ({ setIsDirty }) => {
  const { actions: { closePanel, setSubmitted } } = useContext(PanelContext)
  const { actions: { createOrganization, createInvite } } = useContext(OrganizationsContext)
  const { actions: { addNotification } } = useContext(NotificationContext)
  const [, , , result, hasError] = useAsyncCallback(createOrganization)
  const { getCurrentTransform } = useConfigTransform()
  const configTransform = getCurrentTransform('organization/create')
  const { getCurrentSchema } = useValidationSchema()
  const translate = useTranslate('organizationPanel')
  useErrorNotifier(hasError, result)

  const initialValues = {
    name: '',
    accountId: '',
    email: '',
    city: '',
    provinceOrState: '',
    country: '',
    status: 'active'
  }

  const labels = {
    buttonLabel: translate('createButton'),
    cancelLabel: translate('cancelButton')
  }

  const onSubmit = async (values, { resetForm }) => {
    const formData = configTransform(values)
    const res = await createOrganization(formData)
    await createInvite(res)
    addNotification(translate('inviteEmailSent'), 'success', 2)
    setSubmitted()
    closePanel()
    resetForm()
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getCurrentSchema('organizations/create')}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit, errors, isSubmitting, isValid, dirty, submitCount
      }) => (
        <Form onChange={setIsDirty(dirty)}>
          <PanelRibbon errors={errors} submitCount={submitCount} />
          <Organization submitCount={submitCount} />
          {/*
            Subscription commented out until requirements are made clear: CD-409
            <Subscription values={values} setFieldValue={setFieldValue} />
          */}
          <PanelFooter
            handleReset={closePanel}
            handleSubmit={handleSubmit}
            errors={errors}
            labels={labels}
            isSubmitting={isSubmitting}
            isValid={isValid}
            testIdRoot="create-organization"
            submitCount={submitCount}
          />
        </Form>
      )}
    </Formik>
  )
}

CreateOrganization.propTypes = {
  setIsDirty: PropTypes.func.isRequired
}

export default CreateOrganization
