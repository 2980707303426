import Styled from 'styled-components'

export const LabelContainer = Styled.div({
  display: 'flex',
  alignItems: 'center'
})

export const Label = Styled.label({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '0 0 1.5rem',
  cursor: 'pointer'
})

export const LabelText = Styled.span({
  fontWeight: '500'
})

export const Checkbox = Styled.input(() => ({
  position: 'relative',
  appearance: 'none'
}))

export const Toggler = Styled.div(({ theme }) => ({
  position: 'relative',
  width: '37px',
  height: '21px',
  transition: '.3s',
  '&:focus': {
    outline: 'none'
  },
  [`${Checkbox}:checked + &`]: {
    '&::before': {
      content: '""',
      background: theme.colors.n08,
      backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' color=\'white\'%3E%3Crect x=\'8\' y=\'3.5\' width=\'2\' height=\'8\' fill=\'currentColor\' /%3E%3C/svg%3E")',
      border: `1px solid ${theme.colors.n08}`
    },
    '&:focus::before': {
      backgroundColor: theme.colors.n09
    },
    '&:hover::before': {
      backgroundColor: theme.colors.n09
    },
    '&[disabled]::before': {
      background: theme.colors.n02,
      borderColor: theme.colors.n04,
      color: theme.colors.n05,
      backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' color=\'%23BCCFDC\'%3E%3Crect x=\'8\' y=\'3.5\' width=\'2\' height=\'8\' fill=\'currentColor\' /%3E%3C/svg%3E")',
      cursor: 'not-allowed'
    },
    '&:focus::after': {
      borderColor: theme.colors.n09
    },
    '&:hover::after': {
      borderColor: theme.colors.n09
    },
    '&[disabled]::after': {
      borderColor: theme.colors.n05,
      cursor: 'not-allowed'
    },
    '&::after': {
      borderColor: theme.colors.n07,
      transform: 'translate3d(16px,0,0)'
    }
  },
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    background: '#EDF1F5',
    border: `1px solid ${theme.colors.n05}`,
    height: '16px',
    width: '37px',
    borderRadius: '99rem',
    transition: '.3s'
  },
  '&:focus::before': {
    borderColor: theme.colors.n06,
    background: theme.colors.n03
  },
  '&:hover::before': {
    borderColor: theme.colors.n06,
    background: theme.colors.n03
  },
  '&[disabled]::before': {
    borderColor: theme.colors.n04,
    background: theme.colors.n02,
    cursor: 'not-allowed'
  },
  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: '-2px',
    left: '0px',
    background: 'white',
    height: '21px',
    width: '21px',
    borderRadius: '99rem',
    transition: '.3s',
    border: `1px solid ${theme.colors.n08}`,
    boxShadow: `-1px 1px 2px 0 rgba(0,0,0,0.16), 1px 0 2px 0 ${theme.colors.n05}`
  },
  '&:focus::after': {
    borderColor: theme.colors.n09
  },
  '&:hover::after': {
    borderColor: theme.colors.n09
  },
  '&[disabled]::after': {
    background: theme.colors.n01,
    borderColor: theme.colors.n05,
    cursor: 'not-allowed'
  }
}))
