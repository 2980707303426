import styled from 'styled-components'

export const FieldHeader = styled.h3({
  fontSize: '0.875rem',
  fontWeight: '500',
  marginBottom: '.5rem'
})

export const Field = styled.p({
  fontWeight: '300'
})

export const FieldContainer = styled.div({
  marginBottom: '2rem'
})
