import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link as ReactRouterLink, useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import LZString from 'lz-string'

import useTranslate from '../../../Utils/Hooks/useTranslate'
import useErrorRouter from '../../../Utils/Hooks/useErrorRouter'
import useLocalStorage from '../../../Utils/Hooks/useLocalStorage'

import JobsContext from '../../../State/Jobs/Context'
import DevicesContext from '../../../State/Devices/Context'
import PanelContext from '../../../State/Panel/Context'
import FirmwareContext from '../../../State/Firmware/Context'

import Breadcrumbs from '../../Breadcrumbs'
import PageInfo from '../../PageInfo'
import PageHeader from '../../PageHeader'
import PageCaption from '../../PageCaption'
import Panel from '../../Panel'
import Link from '../../Link'
import MacAddress from './Components/MacAddress'
import DeviceEditButton from './Components/DeviceEditButton'
import DeviceAttributesTable from './DeviceAttributesTable'
import hubIcon from '../../../Images/hub-icon.svg'
import PageMetaData from '../../PageMetaData'

const DeviceAttributes = ({ setState, setOrgSelectState }) => {
  // DeviceId is the database Id
  const { deviceId, jobsiteId, organizationId } = useParams()
  const [devicesList] = useLocalStorage('device_list', null)
  const { actions: { fetchJobsites }, selectors: { getJobsite } } = useContext(JobsContext)
  const { selectors: { getSelectedDevice, isLoading }, actions: { fetchDevice } } = useContext(DevicesContext)
  const { actions: { fetchFirmware } } = useContext(FirmwareContext)
  const { selectors: { dataIsStale } } = useContext(PanelContext)
  const translate = useTranslate('attributesPage')
  const translateMetaData = useTranslate('pageMetaData')
  const jobsite = getJobsite(jobsiteId) || {}
  const device = getSelectedDevice() || {}
  const { name: jobsiteName } = jobsite
  const { displayName: hubName, system } = device
  const decompressedDevicesList = devicesList && LZString.decompress(devicesList)
  const parsedDevicesList = decompressedDevicesList && JSON.parse(decompressedDevicesList)
  useErrorRouter(parsedDevicesList, 'deviceId', deviceId, isLoading())
  setState('Content/Managing_Jobs.html')
  setOrgSelectState('jobs')

  useEffect(() => {
    fetchJobsites(organizationId)
    fetchDevice(deviceId)
    fetchFirmware()
  }, [dataIsStale()]) // eslint-disable-line
  const isLoadingState = isLoading()
  return (
    <>
      <PageMetaData pageMetaData={{ title: `${translateMetaData('devicesTitle')} | ${hubName}`, description: 'TODO' }} />
      <Breadcrumbs>
        <Link to={`/organization/${organizationId}/jobs`} ReactRouterComponent={ReactRouterLink} data-testid="jobsite-link">
          {translate('breadcrumbs.jobs')}
        </Link>
        <Link to={`/organization/${organizationId}/jobs/${jobsiteId}`} ReactRouterComponent={ReactRouterLink} data-testid="device-link">
          {jobsiteName || <Skeleton width={200} />}
        </Link>
      </Breadcrumbs>
      <PageHeader>
        <PageInfo>
          <img src={hubIcon} alt="hub icon" />
          <PageCaption>
            <h1>{!isLoadingState && hubName ? `${translate('heading')} (${hubName})` : <Skeleton width={200} />}<DeviceEditButton deviceId={deviceId} device={device} /></h1>
            <h4>
              <MacAddress isLoading={isLoading()} macAddress={system && system.macAddress} />
            </h4>
          </PageCaption>
        </PageInfo>
        <Panel />
      </PageHeader>
      <DeviceAttributesTable device={device} isLoading={isLoading()} />
    </>
  )
}

DeviceAttributes.propTypes = {
  setState: PropTypes.func.isRequired,
  setOrgSelectState: PropTypes.func
}

DeviceAttributes.defaultProps = {
  setOrgSelectState: () => {}
}

export default DeviceAttributes
