import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { useParams } from 'react-router-dom'

import useAsync from '../../../Utils/Hooks/useAsync'
import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useTranslate from '../../../Utils/Hooks/useTranslate'

import OrganizationContext from '../../../State/Organization/Context'
import UserContext from '../../../State/User/Context'
import PanelContext from '../../../State/Panel/Context'
import useValidationSchema from '../../../Utils/Hooks/useValidationSchema'
import ModalContext from '../../../State/Modal/Context'
import { createRadioButtonLabel } from '../utils'
import PanelFooter from '../Components/PanelFooter/PanelFooter'
import ClosePanelFooter from '../../Panel/Components/PanelFooter'
import PanelRibbon from '../Components/PanelRibbon'
import RadioGroup from '../../Formik/RadioGroup'
import Select from '../../Formik/Select'
import CheckboxGroup from '../../Formik/CheckboxGroup'
import CheckboxBoolean from '../../Formik/CheckboxBoolean'
import Fieldset from '../../Form/Fieldset'
import formatTime from '../../../Utils/formatTime'
import Button from '../../Button'

import {
  RadioLabelContainer, RadioLabelBody, RadioLableHeader, Unit, UnitHeader, UnitContainer, ResendLink, InviteContainer, RemoveInviteLink, JobAssignmentContainer, SuperAdminContainer
} from './Styles'


const EditUser = ({ setIsDirty }) => {
  const { actions: { closePanel, setSubmitted } } = useContext(PanelContext)
  const { selectors: { getSelectedUser, hasError }, actions: { updateUser, fetchJobsites, updateInvite } } = useContext(OrganizationContext)
  const { selectors: { getUID, getUser }, actions: { updateAdmin, fetchUserAdmin, fetchDeltaControlsUser } } = useContext(UserContext)
  const { actions: { changeModalView } } = useContext(ModalContext)
  const selectedUser = getSelectedUser()
  const currentUserId = getUID()
  const { admin } = getUser()
  const { organizationId } = useParams()
  const [, , jobsites] = useAsync(fetchJobsites, organizationId)
  const [execute, , , , updateUserHasError] = useAsyncCallback(updateUser)
  const [executeUpdateAdmin, , , , updateAdminHasError] = useAsyncCallback(updateAdmin)
  const { getCurrentSchema } = useValidationSchema()
  const translate = useTranslate('userPanel')
  const translateTable = useTranslate('tableFiltering')
  const mappedJobsites = jobsites ? jobsites.map(current => ({ value: current.jobsiteId, label: current.name })) : []
  const createMultilineLabel = createRadioButtonLabel(RadioLabelContainer, RadioLableHeader, RadioLabelBody)

  const {
    email, language, role, userId, createdAt, inviteId, jobsites: userJobsites = [], isPrimaryAdmin
  } = selectedUser

  const [, , isUserAdmin] = useAsync(fetchUserAdmin, userId)
  const [, , isUserDC] = useAsync(fetchDeltaControlsUser, userId)

  const initalValues = {
    language,
    email,
    role,
    jobsites: userJobsites
  }

  const labels = {
    buttonLabel: translate('saveButton'),
    submittingLabel: translate('submittingButton'),
    cancelLabel: translate('cancelButton')
  }

  const languageOptions = [
    { label: 'English', value: 'en' },
    { label: 'Français', value: 'fr' },
    { label: 'Deutsch', value: 'de' },
    { label: 'Español', value: 'es' }
  ]

  const Roles = [
    {
      label: createMultilineLabel(translate('roleOption.administrator'), translate('roleOption.administratorDescription')),
      value: 'admin'
    },
    {
      label: createMultilineLabel(translate('roleOption.installer'), translate('roleOption.installerDescription')),
      value: 'installer'
    }
  ]

  const handleLanguageChange = (event, setFieldValue) => {
    setFieldValue(event.target.id, event.target.value)
    event.preventDefault()
  }

  const onSubmit = async (values) => {
    const { language: lang, ...rest } = values
    if (!inviteId) {
      await execute(organizationId, userId, rest)
      await executeUpdateAdmin(userId, rest)
    } else {
      await updateInvite(inviteId, values)
    }
    if (!hasError() && !updateUserHasError && !updateAdminHasError) {
      setSubmitted()
      closePanel()
    }
  }

  const openResendInviteModal = () => {
    changeModalView('resend-invite-link/request')
  }

  const openRemoveInviteModal = () => {
    changeModalView('remove-invite-link/request')
  }

  const showPendingInvite = !userId && inviteId

  const dateJoined = showPendingInvite
    ? translate('pendingInvite')
    : formatTime(createdAt, translateTable('dateFormattingToday'), translateTable('dateFormattingYesterday'))

  const displayReadonlyRole = role === 'admin' && isPrimaryAdmin
  const isCurrentUser = userId === currentUserId

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initalValues}
        validationSchema={getCurrentSchema('users/edit')}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting, handleSubmit, setFieldValue, errors, values, isValid, dirty, submitCount
        }) => {
          const handleRoleChange = () => {
            const superAdminCheckbox = document.getElementById('superAdmin')
            const installerCheckbox = document.getElementById('installer1')

            if (superAdminCheckbox) {
              if (installerCheckbox.checked) {
                superAdminCheckbox.checked = false
                setFieldValue('admin', false)
              } else {
                superAdminCheckbox.checked = isUserAdmin
                setFieldValue('admin', isUserAdmin)
              }
            }
          }

          return (
            <Form onChange={setIsDirty(dirty)}>
              <PanelRibbon errors={errors} submitCount={submitCount} />
              <Fieldset>
                <UnitContainer>
                  <UnitHeader>{translate('dateJoinedLabel')}</UnitHeader>
                  {showPendingInvite ? (
                    <InviteContainer>
                      <Unit>{dateJoined}</Unit>
                      <ResendLink onClick={openResendInviteModal} role="button" data-testid="resend-link">{translate('resendInviteButton')}</ResendLink>
                      <RemoveInviteLink onClick={openRemoveInviteModal} role="button" data-testid="remove-invite-link">{translate('removeInviteButton')}</RemoveInviteLink>
                    </InviteContainer>
                  ) : (
                    <Unit>{dateJoined}</Unit>
                  )}
                </UnitContainer>
                <UnitContainer>
                  <UnitHeader>{translate('emailInputLabel')}</UnitHeader>
                  <Unit>{email}</Unit>
                </UnitContainer>
              </Fieldset>
              {admin && isUserDC && (
                <Fieldset>
                  <SuperAdminContainer>
                    <CheckboxBoolean
                      data-testid="admin"
                      defaultChecked={isUserAdmin}
                      disabled={isCurrentUser || values.role === 'installer'}
                      id="superAdmin"
                      label={createMultilineLabel(translate('superAdminLabel'), translate('superAdminDescription'))}
                      name="admin"
                    />
                  </SuperAdminContainer>
                </Fieldset>
              )}
              {inviteId && (
                <Fieldset>
                  <Select
                    testId="language"
                    id="language"
                    name="language"
                    label={translate('languageInputLabel')}
                    options={languageOptions}
                    value={values.language}
                    onChange={event => handleLanguageChange(event, setFieldValue)}
                  />
                </Fieldset>
              )}
              {!isCurrentUser && (
              <Fieldset
                heading={!displayReadonlyRole ? translate('roleSection.heading') : undefined}
                hasBorder={false}
              >
                {displayReadonlyRole ? (
                  <UnitContainer>
                    <UnitHeader>{translate('roleSection.heading')}</UnitHeader>
                    <Unit>{translate('roleOption.administrator')}</Unit>
                  </UnitContainer>
                ) : (
                  <>
                    <RadioGroup
                      options={Roles}
                      name="role"
                      alignToTop
                      data-testid="roles"
                      onClick={handleRoleChange}
                    />
                    {values.role === 'installer' && (
                      <JobAssignmentContainer>
                        <CheckboxGroup
                          heading={translate('assignedJobsInputLabel')}
                          options={mappedJobsites}
                          name="jobsites"
                          data-testid="jobsites"
                        />
                      </JobAssignmentContainer>
                    )}
                  </>
                )}
              </Fieldset>
              )}
              {isCurrentUser ? (
                <ClosePanelFooter>
                  <Button onClick={closePanel} inverted data-testid="view-template-close-button">{translate('closeButton')}</Button>
                </ClosePanelFooter>
              ) : (
                <PanelFooter
                  handleReset={closePanel}
                  handleSubmit={handleSubmit}
                  errors={errors}
                  labels={labels}
                  isSubmitting={isSubmitting}
                  isValid={isValid}
                  testIdRoot="edit-user"
                  submitCount={submitCount}
                />
              )}
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

EditUser.propTypes = {
  setIsDirty: PropTypes.func.isRequired
}

export default EditUser
