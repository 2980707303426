import { number } from 'yup'

const ConditionalUDPRequirement = (conditionalFieldKey, conditionalFieldValue, requiredMessage) => number().when(conditionalFieldKey, {
  is: conditionalFieldValue,
  then: number().required(requiredMessage).test(
    'udpNumber',
    'UDP Number must be between 47808–47823 or 49152–65535',
    value => (value >= 47808 && value <= 47823) || (value >= 49152 && value <= 65535)
  ),
  otherwise: number().notRequired()
})

export default ConditionalUDPRequirement
