import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip as MaterialTooltip } from '@material-ui/core'

import { StyledIcon, tooltipStyles } from './Styles'

const Tooltip = ({
  content, icon, size, placement
}) => {
  const classes = tooltipStyles()
  const popperProps = {
    disablePortal: true,
    modifiers: {
      preventOverflow: { enabled: false },
      hide: { enabled: true }
    }
  }
  const tooltipClasses = {
    tooltip: classes.tooltip,
    arrow: classes.arrow,
    tooltipPlacementTop: classes.tooltipMarginVertical,
    tooltipPlacementBottom: classes.tooltipMarginVertical,
    tooltipPlacementLeft: classes.tooltipMarginHorizontal,
    tooltipPlacementRight: classes.tooltipMarginHorizontal
  }
  return (
    <MaterialTooltip
      placement={placement}
      title={content}
      arrow
      data-testid="tooltip"
      PopperProps={popperProps}
      classes={tooltipClasses}
    >
      <StyledIcon path={icon} size={size} />
    </MaterialTooltip>
  )
}

Tooltip.propTypes = {
  content: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  size: PropTypes.number.isRequired,
  placement: PropTypes.string
}

Tooltip.defaultProps = {
  placement: 'top'
}

export default Tooltip
