import { string } from 'yup'

import OrganizationClient from '../../../State/Organization/Client'

async function test(value = '') {
  const { name } = this.parent
  if (!name) return true
  const org = await OrganizationClient.getOrgByName(name)
  if (!org) return true
  const uniqueUserEmails = OrganizationClient.verifyUniqueUsersEmail(value)
  const emailInOrgPromise = OrganizationClient.verifyUniquePermissionsEmailInOrg(org.id, value)
  const [emailExistsInOrg, userEmailsOutsideOfOrg] = await Promise.all([emailInOrgPromise, uniqueUserEmails])
  const primaryEmail = org.email
  const isPrimaryEmail = primaryEmail === value
  const totalEmailCount = emailExistsInOrg.emailCount + userEmailsOutsideOfOrg
  return isPrimaryEmail || emailExistsInOrg.emailCount === 1 || totalEmailCount === 0
}

async function testDisabledEmail(value = '') {
  const { name } = this.parent
  if (!name) return true
  const org = await OrganizationClient.getOrgByName(name)
  if (!org) return true
  const { data } = await OrganizationClient.verifyUniquePermissionsEmailInOrg(org.id, value)
  const emails = data.map(user => user.data())
  return emails.every(user => user && user.status === 'active')
}

const EditOrganizationEmail = ({
  requiredMessage, emailMessage, disabledEmailMessage, uniqueEmailMessage
}) => string()
  .required(requiredMessage)
  .email(emailMessage || 'Please enter a valid email')
  .test(
    'email',
    disabledEmailMessage || 'The email ${value} is disabled', //eslint-disable-line
    testDisabledEmail
  )
  .test(
    'email',
    uniqueEmailMessage || 'The email ${value} has already been registered and does not exist in this organization', //eslint-disable-line
    test
  )

export default EditOrganizationEmail
