const initialState = {
  isOpen: false,
  isStale: false,
  isConfirmed: false,
  currentView: undefined
}

const types = {
  OPEN_PANEL: 'OPEN_PANEL',
  CLOSE_PANEL: 'CLOSE_PANEL',
  CHANGE_VIEW: 'CHANGE_VIEW',
  SET_SUBMITTED: 'SET_SUBMITTED',
  SET_CONFIRMATION: 'SET_CONFIRMATION'
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case (types.OPEN_PANEL):
      return {
        ...state,
        isOpen: true
      }
    case (types.CLOSE_PANEL):
      return {
        ...state,
        isOpen: false,
        isStale: false,
        isConfirmed: false
      }
    case (types.CHANGE_VIEW):
      return {
        ...state,
        currentView: action.view,
        isStale: false
      }
    case (types.SET_SUBMITTED):
      return {
        ...state,
        isStale: true
      }
    case (types.SET_CONFIRMATION):
      return {
        ...state,
        isConfirmed: true
      }
    default:
      return state
  }
}

export {
  reducer,
  types,
  initialState
}
