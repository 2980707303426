import React from 'react'
import PropTypes from 'prop-types'

import { TableCell } from '../../../../Table'

const NetworkSettings = ({ row }) => {
  const { ipAddress, type } = row.original.config.network || {}

  if (!type) {
    return <TableCell align="right"><i>Undefined</i></TableCell>
  }

  return (
    <TableCell align="right">
      {type === 'dhcp' ? type.toUpperCase() : ipAddress}
    </TableCell>
  )
}

NetworkSettings.propTypes = {
  row: PropTypes.object.isRequired
}

export default NetworkSettings
