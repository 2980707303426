import useValidationSchema from '../../Hooks/useValidationSchema'
import useConfigTransform from '../../Hooks/useConfigTransform'
import PercentageComponent from './Components/PercentageComponent'

const PercentageField = () => {
  const { getCurrentSchema } = useValidationSchema()
  const { getCurrentTransform } = useConfigTransform()

  return {
    validationSchema: getCurrentSchema('attributes/percentageField'),
    configTransform: getCurrentTransform('attributes/readOnly'),
    Component: PercentageComponent,
    CoVComponent: () => null
  }
}

export default PercentageField
