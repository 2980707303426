import React from 'react'
import PropTypes from 'prop-types'

import { MaterialCheckbox as Checkbox } from '../Styles'

const RowCheckbox = ({ row, disabled }) => {
  const { onChange, checked } = row.getToggleRowSelectedProps()

  return (
    <Checkbox
      data-testid="table-row-checkbox"
      onChange={onChange}
      checked={checked}
      color="primary"
      inputProps={{
        'aria-label': 'primary checkbox'
      }}
      disabled={disabled}
    />
  )
}
RowCheckbox.propTypes = {
  row: PropTypes.object.isRequired,
  disabled: PropTypes.bool
}

RowCheckbox.defaultProps = {
  disabled: false
}

export default RowCheckbox
