import useValidationSchema from '../../Hooks/useValidationSchema'
import useConfigTransform from '../../Hooks/useConfigTransform'
import NullComponent from './Components/NullComponent'

const PercentageField = () => {
  const { getCurrentSchema } = useValidationSchema()
  const { getCurrentTransform } = useConfigTransform()

  return {
    validationSchema: getCurrentSchema('attributes/readOnly'),
    configTransform: getCurrentTransform('attributes/nullField'),
    Component: NullComponent,
    CoVComponent: () => null
  }
}

export default PercentageField
