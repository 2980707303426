import styled from 'styled-components'

export const Header = styled.div(({ theme, type }) => ({
  marginBottom: '2rem',
  lineHeight: '1.5rem',
  letterSpacing: '0.3px',
  color: theme.colors.n08,
  h1: {
    marginBottom: '1rem',
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: '2rem',
    letterSpacing: '0.5px',
    color: theme.colors.n09,
    svg: {
      height: '1.5rem',
      width: '1.5rem',
      verticalAlign: '-3px',
      marginRight: '1rem',
      fill: type === 'success' ? theme.colors.s02 : theme.colors.s05
    }
  },
  h2: {
    marginBottom: '0.5rem',
    padding: '0 1rem',
    fontWeight: 300,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    letterSpacing: '0.5px'
  },
  span: {
    fontSize: '0.875rem'
  },
  [`@media(min-width: ${theme.breakpoints.xs.maxWidth})`]: {
    h2: {
      padding: 0,
      fontSize: '2rem',
      lineHeight: '2.5rem'
    },
    span: {
      fontSize: '1rem'
    }
  }
}))

export const BadgeContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginTop: '1rem',
  'a:first-of-type': {
    marginBottom: '1rem'
  },
  [`@media(min-width: ${theme.breakpoints.xs.maxWidth})`]: {
    'a:first-of-type': {
      marginRight: '1rem'
    }
  }
}))

export const FooterContainer = styled.div({
  marginTop: '2rem',
  width: '100%'
})

export const FooterHeader = styled.div(({ theme }) => ({
  fontFamily: theme.fonts.roboto,
  fontSize: '0.75rem',
  lineHeight: '1.125rem',
  letterSpacing: '0.2px',
  color: theme.colors.n08,
  fontWeight: 400
}))

export const FooterText = styled.div({
  display: 'flex',
  marginTop: '.5rem',
  justifyContent: 'space-between',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
})

export const UserInfoContainer = styled.div(({ theme }) => ({
  fontSize: '0.75rem',
  width: '85%',
  lineHeight: '1.125rem',
  color: theme.colors.n08,
  letterSpacing: '0.2px',
  marginRight: '1rem',
  fontWeight: 500,
  whiteSpace: 'nowrap',
  overflow: 'hidden'
}))

export const UserEmail = styled.span({
  whiteSpace: 'nowrap',
  overflow: 'hidden'
})

export const SignOutButton = styled.div(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: '1.125rem',
  color: theme.colors.s05,
  letterSpacing: '0.2px',
  cursor: 'pointer',
  fontWeight: 500
}))
