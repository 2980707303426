import getSorter from './getSorters'

const sortAttributesName = (rowA, rowB) => {
  const a = rowA.original.name.toLowerCase()
  const b = rowB.original.name.toLowerCase()

  return getSorter('rowName', a, b)
}

export default sortAttributesName
