import * as firebase from 'firebase/app'
import 'firebase/firestore'

const client = {
  fetchZones(jobsiteId) {
    return firebase.firestore().collection('zones').where('jobsiteId', '==', jobsiteId).get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.reduce((accumulator, current) => ({
          ...accumulator,
          [current.id]: { name: current.data().name }
        }), {})
        return data
      })
  },
  fetchZone(zoneId) {
    return firebase.firestore().collection('zones').doc(zoneId).get()
      .then(doc => doc.data())
  },
  updateZoneName(zoneId, formData) {
    return firebase.firestore().collection('zones').doc(zoneId).update(formData)
  }
}

export default client
