import React from 'react'
import PropTypes from 'prop-types'

import o3Logo from '../../Images/entelicloud-deploy.svg'
import { LogoContainer } from './Styles'

const Logo = ({ logo, alt, size }) => (
  <LogoContainer size={size}>
    <img src={logo} alt={alt} data-testid="logo" />
  </LogoContainer>
)

Logo.propTypes = {
  logo: PropTypes.string,
  size: PropTypes.number,
  alt: PropTypes.string
}

Logo.defaultProps = {
  logo: o3Logo,
  size: 300,
  alt: 'enteliCLOUD Deploy Logo'
}

export default Logo
