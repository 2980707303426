import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  Redirect, Route, Switch
} from 'react-router-dom'

import Dashboard from '../Dashboard'
import useLocalStorage from '../../../Utils/Hooks/useLocalStorage'
import UserContext from '../../../State/User/Context'


const Organization = ({ match }) => {
  const { actions: { hydrateUserData }, selectors: { getOrganizations, getUID } } = useContext(UserContext)
  const [org, setOrg] = useLocalStorage(getUID(), null)
  const orgPermissions = getOrganizations()
  const isInactive = orgPermissions.every(permission => permission.status === 'inactive')
  const adminOrgs = orgPermissions.filter(permission => permission.role === 'admin')
  const isAdmin = adminOrgs.length > 0
  const hasAccess = adminOrgs.find(item => item.id === org)
  const preferredOrg = hasAccess && org

  useEffect(() => {
    hydrateUserData({ uid: getUID() })
  }, [])

  useEffect(() => {
    if ((!org && adminOrgs && isAdmin) || !hasAccess) {
      setOrg(adminOrgs[0] && adminOrgs[0].id)
    }
  }, [JSON.stringify(getOrganizations())]) // eslint-disable-line

  if (isInactive) {
    return (
      <Redirect
        to={{
          pathname: '/error',
          state: { isPermissionError: true, shouldSignOut: true, isInactive }
        }}
      />
    )
  }

  if (!isAdmin) {
    return <Redirect to="/download" />
  }

  return (
    <>
      <Switch>
        <Route path="/organization/:organizationId" component={Dashboard} />
        <Redirect exact from="/organization" to={{ pathname: `${match.url}/${preferredOrg || adminOrgs[0].id}/jobs` }} />
      </Switch>
    </>
  )
}

Organization.propTypes = {
  match: PropTypes.object.isRequired
}

export default Organization
