import React, { useContext } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import useLocalStorage from '../../../Utils/Hooks/useLocalStorage'
import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useTranslate from '../../../Utils/Hooks/useTranslate'

import UserContext from '../../../State/User/Context'
import ModalContext from '../../../State/Modal/Context'
import ModalContent from '../../Modal/Components/ModalContent'
import ModalFooter from '../../Modal/Components/ModalFooter'
import Button from '../../Button'
import Text from '../../Formik/Text'
import Ribbon from '../../Ribbon'

const ForgotPassword = () => {
  const { actions: { sendSignInEmail }, selectors: { getLanguagePreference } } = useContext(UserContext)
  const { actions: { changeModalView, closeModal } } = useContext(ModalContext)
  const [execute, , isLoading, result, hasError] = useAsyncCallback(sendSignInEmail)
  const [, setEmailInput] = useLocalStorage('@login_email', null)
  const translate = useTranslate('forgotPassword')

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid Email').required('Please enter your email')
  })

  const renderSubmitButton = (label, handleSubmit, errors) => {
    if (isLoading) {
      return <Button type="submit" disabled isLoading data-testid="forgot-password-signin">{translate('submitting')}</Button>
    }

    if (errors && Object.keys(errors).length !== 0) {
      return <Button type="submit" disabled data-testid="forgot-password-signin">{label}</Button>
    }

    return <Button type="submit" onClick={handleSubmit} data-testid="forgot-password-signin">{label}</Button>
  }

  const onSubmit = async (values) => {
    const { email } = values
    setEmailInput(email)
    execute(email, getLanguagePreference() || 'en')
  }

  const onKeyDown = (event, values, errors) => {
    const emailHasErrors = errors && Object.keys(errors).length !== 0
    if (emailHasErrors && (event.charCode || event.keyCode) === 13) {
      event.preventDefault()
      onSubmit(values)
    }
  }

  if (result && !isLoading) {
    changeModalView('forgot-password/success')
  }

  return (
    <Formik
      initialValues={{ email: '' }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, errors, values }) => (
        <Form onKeyDown={event => onKeyDown(event, values, errors)}>
          {hasError && <Ribbon type="error">{translate('requestFailed')}</Ribbon>}
          <ModalContent>
            <p>{translate('description')}</p>
            <Text id="forgot-password" label={translate('emailInputLabel')} placeholder={translate('emailInputPlaceholder')} name="email" type="email" autoComplete="email" data-testid="forgot-password-email" textHelper={translate('textHelper')} errorPadding={false} />
          </ModalContent>
          <ModalFooter>
            <Button onClick={closeModal} inverted data-testid="forgot-password-cancel">{translate('cancelButton')}</Button>
            {renderSubmitButton(translate('sendLinkButton'), handleSubmit, errors)}
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

export default ForgotPassword
