import React from 'react'
import PropTypes from 'prop-types'

import TextArea from '../../../../Components/Formik/TextArea'

const NullComponent = ({ translate }) => (
  <>
    <TextArea
      name="value"
      label={translate('labels.value')}
      data-testid="ir-text-area"
    />
  </>
)

NullComponent.propTypes = {
  translate: PropTypes.func.isRequired
}

export default NullComponent
