import styled from 'styled-components'

export const FilterWrapperContainer = styled.div(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  margin: '1.5rem 0',
  justifyContent: 'space-between',
  width: '100%',
  '& > div': {
    maxWidth: '100%',
    minWidth: '100%',
    marginBottom: 8,
    [`@media(min-width: ${theme.breakpoints.lg.minWidth})`]: {
      marginBottom: 0,
      marginRight: 8,
      minWidth: 0
    }
  },
  '& > div > button > div': {
    width: '100%',
    [`@media(min-width: ${theme.breakpoints.lg.minWidth})`]: {
      width: 211
    }
  },
  '& > div:last-child': {
    marginRight: 0
  },
  [`@media(min-width: ${theme.breakpoints.lg.minWidth})`]: {
    flexDirection: 'row'
  }
}))
