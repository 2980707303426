export default {
  colors: {
    b01: '#005FBE',
    b02: '#66CCFF',
    s01: '#BCCED6',
    s02: '#10965C',
    s03: '#EAC83B',
    s04: '#F47B24',
    s05: '#CD3220',
    n01: '#FFFFFF',
    n02: '#F4F7FA',
    n03: '#EBF0F5',
    n04: '#D9E3EB',
    n05: '#BCCFDC',
    n06: '#8BA5B6',
    n07: '#617C8F',
    n08: '#394F5F',
    n09: '#182229'
  },
  fonts: {
    roboto: '"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
  },
  breakpoints: {
    xs: { maxWidth: '413px' },
    sm: { maxWidth: '767px', minWidth: '414px' },
    md: { maxWidth: '1023px', minWidth: '768px' },
    lg: { maxWidth: '1439px', minWidth: '1024px' },
    xl: { minWidth: '1440px' }
  }
}
