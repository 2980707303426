import styled from 'styled-components'

export const ButtonGroup = styled.div({
  display: 'flex',
  marginTop: '32px',
  '& > button, & > a': {
    '&:first-of-type': {
      marginRight: '16px'
    }
  }
})

export const SectionHeader = styled.h3(({ theme }) => ({
  padding: '0.5rem',
  margin: '0.5rem 0',
  fontSize: '1.125rem',
  lineHeight: '1.5rem',
  letterSpacing: '0.4px',
  color: theme.colors.n09,
  borderBottom: '1px solid',
  borderColor: theme.colors.n04
}))

export const SectionWrapper = styled.div({
  marginTop: '1rem',
  marginBottom: '3rem'
})

export const SectionRow = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 740,
  padding: '0.625rem 0.5rem',
  '& > *': {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    letterSpacing: '0.2px'
  },
  '& > h4': {
    flex: 1,
    fontWeight: 500,
    color: theme.colors.n09
  },
  '& > span': {
    flex: 2,
    color: theme.colors.n07
  },
  '& > button': {
    flex: 1,
    border: 'none',
    textAlign: 'left',
    fontFamily: theme.fonts.roboto,
    fontWeight: 500,
    color: theme.colors.b01,
    backgroundColor: 'white',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none'
    },
    '&:disabled': {
      cursor: 'default'
    }
  }
}))
