import React from 'react'
import PropTypes from 'prop-types'

import { TableCell } from '../../../../Table'
import { Value as ValueDisplay } from './Styles'

const Value = ({ row }) => {
  const { original: { isReadOnly, displayValue, value } } = row
  return (
    <TableCell align="right">
      <ValueDisplay isReadOnly={isReadOnly || value === null}>{displayValue}</ValueDisplay>
    </TableCell>
  )
}

Value.propTypes = {
  row: PropTypes.object.isRequired
}

export default Value
