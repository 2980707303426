import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Close from '@material-ui/icons/Close'
import Heading from '../../../Heading'
import PanelContext from '../../../../State/Panel/Context'

import { StyledHeaderContainer, CloseLink } from './Styles'

const PanelHeader = ({ header, onClose, setHasError }) => {
  const { actions: { closePanel, resetView } } = useContext(PanelContext)

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      closePanel()
    }
  }

  const handleClick = () => {
    onClose()
    closePanel()
    setHasError(false)
    resetView()
  }

  return (
    <StyledHeaderContainer data-testid="panel-header">
      <Heading tag="h2" level={3}>{header}</Heading>
      <CloseLink data-testid="panelCloseButton" onClick={handleClick} onKeyPress={handleEnterPress} tabIndex="0"><Close /></CloseLink> {/* eslint-disable-line */}
    </StyledHeaderContainer>
  )
}

PanelHeader.propTypes = {
  header: PropTypes.string.isRequired,
  setHasError: PropTypes.func,
  onClose: PropTypes.func
}

PanelHeader.defaultProps = {
  setHasError: () => null,
  onClose: () => null
}

export default PanelHeader
