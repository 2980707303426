const getSelectors = state => ({
  getState() {
    return state
  },
  isLoading() {
    return state.loading
  },
  getErrorCode() {
    return state.error.code
  },
  hasError() {
    return state.error.status
  },
  getDevices() {
    return state.data ? state.data : []
  },
  getDevice(id) {
    return state.data.find(device => device.id === id)
  },
  getSearchResults(query) {
    if (!state.data) {
      return []
    }
    const normalizedQuery = query.toLowerCase()
    return state.data.filter(item => (
      item.name.toLowerCase().indexOf(normalizedQuery) > -1
      || item.address.toLowerCase().indexOf(normalizedQuery) > -1
    ))
  },
  getSelectedDevice() {
    return state.selectedDevice
  },
  getSelectedDeviceList() {
    return state.selectedDeviceList
  },
  getFilters() {
    return state.filters
  }
})

export default getSelectors
