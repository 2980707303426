const OffSelectAttribute = (values, { name }) => {
  const {
    attributeId, isCustomDescription, description, value, ...rest
  } = values

  const isCustomName = rest.isCustomName === 'true'

  const config = {
    ...rest,
    isCustomName,
    ...!isCustomName && { name },
    description: isCustomDescription ? description : '',
    value: Number.parseInt(value, 10)
  }
  return config
}

export default OffSelectAttribute
