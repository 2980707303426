import React, { useState, useContext } from 'react'
import { mdiAlertOutline } from '@mdi/js'


import useTranslate from '../../../Utils/Hooks/useTranslate'
import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'
import ModalContext from '../../../State/Modal/Context'
import TemplatesContext from '../../../State/Templates/Context'
import NotificationsContext from '../../../State/Notifications/Context'

import Button from '../../Button'
import ModalContent from '../../Modal/Components/ModalContent'
import ModalFooter from '../../Modal/Components/ModalFooter'

import ConfirmationHeader from '../Components/ConfirmationHeader'
import ModalRibbon from '../Components/ModalRibbon'
import ConfirmationCheckboxes from '../Components/ConfirmationCheckboxes'
import ConfirmationFooter from '../Components/ConfirmationFooter'

const TemplatesDelete = () => {
  const translate = useTranslate('deleteTemplatesModal')
  const { actions: { closeModal, setSubmitted } } = useContext(ModalContext)
  const { actions: { deleteTemplates }, selectors: { getSelectedTemplatesList } } = useContext(TemplatesContext)
  const { actions: { addNotification } } = useContext(NotificationsContext)
  const [execute, , isSubmitting, result, hasError] = useAsyncCallback(deleteTemplates)
  const [isTemplatesSelected, setTemplatesSelected] = useState(false)
  const [isAttributesSelected, setAttributesSelected] = useState(false)
  useErrorNotifier(hasError, result)

  const handleTemplatesClick = () => setTemplatesSelected(!isTemplatesSelected)
  const handleAttributesClick = () => setAttributesSelected(!isAttributesSelected)
  const handleSubmit = async () => {
    const selectedTemplates = getSelectedTemplatesList()
    await execute(selectedTemplates)
    setSubmitted()
    closeModal()
    addNotification(translate('notifications.success'), 'success', 2)
  }
  const handleCancel = () => {
    closeModal()
  }
  const checkboxes = [
    {
      label: translate('labels.templates'),
      checked: isTemplatesSelected,
      disabled: isSubmitting,
      onChange: handleTemplatesClick,
      testId: 'confirmation-templates'
    },
    {
      label: translate('labels.attributes'),
      checked: isAttributesSelected,
      disabled: isSubmitting,
      onChange: handleAttributesClick,
      testId: 'confirmation-attributes'
    }
  ]
  const allAgreed = checkboxes.every(({ checked }) => checked)
  return (
    <>
      <ModalRibbon icon={mdiAlertOutline} type="warning" textContent={translate('labels.warning')} />
      <ModalContent hasBottomPadding={false}>
        <ConfirmationHeader header={translate('confirmationHeader')} />
        <ConfirmationCheckboxes confirmationLabel={translate('labels.confirmationLabel')} checkboxes={checkboxes} />
        <ConfirmationFooter footer={translate('confirmationFooter')} />
      </ModalContent>
      <ModalFooter>
        <Button
          destructive
          data-testid="delete-template-modal-delete-button"
          onClick={handleSubmit}
          disabled={!allAgreed}
          isLoading={isSubmitting}
        >
          {translate('labels.delete')}
        </Button>
        <Button
          inverted
          data-testid="delete-template-modal-cancel-button"
          onClick={handleCancel}
          disabled={isSubmitting}
        >
          {translate('labels.cancel')}
        </Button>
      </ModalFooter>
    </>
  )
}

export default TemplatesDelete
