import Styled from 'styled-components'
import Icon from '@mdi/react'
import { makeStyles } from '@material-ui/core/styles'

export const StyledIcon = Styled(Icon)(({ theme }) => ({
  color: theme.colors.n06,
  verticalAlign: 'middle',
  marginLeft: '.25rem',
  cursor: 'pointer',
  '&:hover': {
    color: 'black'
  }
}))

export const tooltipStyles = makeStyles(() => ({
  tooltip: {
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '0.2px',
    lineHeight: '20px',
    maxWidth: '320px',
    minWidth: '48px',
    borderRadius: '2px',
    backgroundColor: '#182229',
    padding: '.625rem 1rem'
  },
  arrow: {
    color: '#182229',
    fontSize: '8px'
  },
  tooltipMarginVertical: {
    margin: '10px 0'
  },
  tooltipMarginHorizontal: {
    margin: '0 10px'
  }
}))
