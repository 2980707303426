import React from 'react'
import PropTypes from 'prop-types'

const ExternalLink = ({
  href, children, Component, testId
}) => {
  const RenderComponent = Component || 'a'
  return (
    <RenderComponent href={href} target="_blank" rel="noopener noreferrer" data-testid={testId}>
      {children}
    </RenderComponent>
  )
}


ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  Component: PropTypes.object,
  testId: PropTypes.string
}

ExternalLink.defaultProps = {
  Component: undefined,
  testId: 'external-link'
}

export default ExternalLink
