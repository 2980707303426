import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { mdiPencilOutline, mdiFileDocumentBoxOutline } from '@mdi/js'

import Icon from '@mdi/react'
import PanelContext from '../../../../../State/Panel/Context'
import OrgContext from '../../../../../State/Organization/Context'
import UserContext from '../../../../../State/User/Context'

const EditButton = ({ row }) => {
  const rowIndex = row.index + 1
  const { actions: { changeView } } = useContext(PanelContext)
  const { actions: { setSelectedUser } } = useContext(OrgContext)
  const { selectors: { getUID } } = useContext(UserContext)

  const handleClick = () => {
    const view = row.original.status !== 'inactive' && !row.original.isPrimary && getUID() !== row.original.userId ? 'users/edit' : 'users/view'
    setSelectedUser(row.original)
    changeView(view)
  }

  const icon = row.original.status !== 'inactive' && !row.original.isPrimary && getUID() !== row.original.userId ? mdiPencilOutline : mdiFileDocumentBoxOutline

  return (
    <button type="button" onClick={handleClick} data-testid={`users-edit-${rowIndex}`}>
      <Icon path={icon} size={1} />
    </button>
  )
}

EditButton.propTypes = {
  row: PropTypes.object.isRequired
}

export default EditButton
