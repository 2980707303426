import { useContext } from 'react'

import UserContext from '../../State/User/Context'
import useLanguage from './useLanguage'

import CreateAccountSchema from '../ValidationSchemas/CreateAccount'
import PasswordChangeSchema from '../ValidationSchemas/PasswordChange'
import InviteUserSchema from '../ValidationSchemas/InviteUser'
import CreateJobsiteSchema from '../ValidationSchemas/CreateJobsite'
import CreateOrganizationSchema from '../ValidationSchemas/CreateOrganization'
import EditOrganizationSchema from '../ValidationSchemas/EditOrganization'
import EditUserSchema from '../ValidationSchemas/EditUser'
import EditProfileSchema from '../ValidationSchemas/EditProfile'
import CreateDeviceSchema from '../ValidationSchemas/CreateDevice'
import EditDeviceSchema from '../ValidationSchemas/EditDevice'
import EditZoneSchema from '../ValidationSchemas/EditZone'
import EditTemplatesSchema from '../ValidationSchemas/EditTemplates'
import EditAttributeIO from '../ValidationSchemas/EditAttributeIO'
import AttributesReadOnly from '../ValidationSchemas/AttributesReadOnly'
import AttributesPercentageField from '../ValidationSchemas/AttributesPercentageField'
import AttributesTimeField from '../ValidationSchemas/AttributesTimeField'
import AttributesOffNumberField from '../ValidationSchemas/AttributesOffNumberField'
import AttributesCoVTemperatureField from '../ValidationSchemas/AttributesCoVTemperatureField'
import AttributesCoVPercentageField from '../ValidationSchemas/AttributesCoVPercentageField'
import AttributesCoVLightField from '../ValidationSchemas/AttributesCoVLightField'
import AttributesCoVSoundField from '../ValidationSchemas/AttributesCoVSoundField'
import AttributesCoVColorField from '../ValidationSchemas/AttributesCoVColorField'
import AttributesProgressiveField from '../ValidationSchemas/AttributesProgressiveField'

const useValidationSchema = () => {
  const { selectors: { getLanguagePreference } } = useContext(UserContext)
  const language = useLanguage(getLanguagePreference)

  const ValidationSchemas = {}

  ValidationSchemas['account/create'] = CreateAccountSchema
  ValidationSchemas['password/change'] = PasswordChangeSchema
  ValidationSchemas['users/invite'] = InviteUserSchema
  ValidationSchemas['users/edit'] = EditUserSchema
  ValidationSchemas['profile/edit'] = EditProfileSchema
  ValidationSchemas['jobsites/create'] = CreateJobsiteSchema
  ValidationSchemas['organizations/create'] = CreateOrganizationSchema
  ValidationSchemas['organizations/edit'] = EditOrganizationSchema
  ValidationSchemas['devices/create'] = CreateDeviceSchema
  ValidationSchemas['devices/edit'] = EditDeviceSchema
  ValidationSchemas['zones/edit'] = EditZoneSchema
  ValidationSchemas['templates/edit'] = EditTemplatesSchema
  ValidationSchemas['attributes/editIO'] = EditAttributeIO
  ValidationSchemas['attributes/readOnly'] = AttributesReadOnly
  ValidationSchemas['attributes/percentageField'] = AttributesPercentageField
  ValidationSchemas['attributes/timeField'] = AttributesTimeField
  ValidationSchemas['attributes/offNumberField'] = AttributesOffNumberField
  ValidationSchemas['attributes/covTemperature'] = AttributesCoVTemperatureField
  ValidationSchemas['attributes/covPercentage'] = AttributesCoVPercentageField
  ValidationSchemas['attributes/covLight'] = AttributesCoVLightField
  ValidationSchemas['attributes/covSound'] = AttributesCoVSoundField
  ValidationSchemas['attributes/covColor'] = AttributesCoVColorField
  ValidationSchemas['attributes/progressive'] = AttributesProgressiveField

  const getCurrentSchema = type => ValidationSchemas[type](language)
  return {
    getCurrentSchema
  }
}

export default useValidationSchema
