import React from 'react'
import { ThemeProvider } from 'styled-components'
import { HelmetProvider } from 'react-helmet-async'
import NotificationsProvider from '../../State/Notifications/Provider'
import UserProvider from '../../State/User/Provider'
import PanelProvider from '../../State/Panel/Provider'
import ModalProvider from '../../State/Modal/Provider'
import OrgProvider from '../../State/Organization/Provider'
import JobsProvider from '../../State/Jobs/Provider'
import DevicesProvider from '../../State/Devices/Provider'
import ZonesProvider from '../../State/Zones/Provider'
import TemplatesProvider from '../../State/Templates/Provider'
import FirmwareProvider from '../../State/Firmware/Provider'
import TableProvider from '../../State/Table/Provider'
import Toaster from '../Toaster'
import Navigator from '../Navigator'
import Theme from '../../Theme'

const { Theme: DefaultTheme, GlobalStyles } = Theme

const App = () => (
  <HelmetProvider>
    <ThemeProvider theme={DefaultTheme}>
      <NotificationsProvider>
        <UserProvider>
          <PanelProvider>
            <ModalProvider>
              <OrgProvider>
                <JobsProvider>
                  <DevicesProvider>
                    <ZonesProvider>
                      <TemplatesProvider>
                        <FirmwareProvider>
                          <TableProvider>
                            <GlobalStyles />
                            <Navigator />
                            <Toaster />
                          </TableProvider>
                        </FirmwareProvider>
                      </TemplatesProvider>
                    </ZonesProvider>
                  </DevicesProvider>
                </JobsProvider>
              </OrgProvider>
            </ModalProvider>
          </PanelProvider>
        </UserProvider>
      </NotificationsProvider>
    </ThemeProvider>
  </HelmetProvider>
)

export default App
