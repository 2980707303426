import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@mdi/react'

import Ribbon from '../../../Ribbon'
import { WarningText } from './Styles'

const ModalRibbon = ({ icon, type, textContent }) => (
  <>
    <Ribbon type={type}>
      <Icon path={icon} size={1} /> <WarningText>{textContent}</WarningText>
    </Ribbon>
  </>
)

ModalRibbon.propTypes = {
  icon: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  textContent: PropTypes.string.isRequired
}

export default ModalRibbon
