import React from 'react'
import { useField } from 'formik'

import Select from '../../Form/Select'

const Component = (props) => {
  const [field, meta] = useField(props)
  const hasError = meta.touched && meta.error
  return (
    <>
      <Select
        {...field}
        {...props}
        {...(hasError ? { error: meta.error } : {})}
      />
    </>
  )
}

export default Component
