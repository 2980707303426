const getSelectors = state => ({
  isOpenSelector() {
    return state.isOpen
  },
  dataIsStale() {
    return state.isStale
  },
  currentViewSelector() {
    return state.currentView
  },
  isConfirmed() {
    return state.isConfirmed
  },
  getState() {
    return state
  }
})

export default getSelectors
