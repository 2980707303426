import Styled from 'styled-components'

export const Container = Styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '1.5rem'
})

export const FieldContainer = Styled.div({
  display: 'flex',
  alignItems: 'center'
})

export const Delimiter = Styled.span({
  margin: '0 .5rem'
})

export const Label = Styled.label(({ theme }) => ({
  display: 'block',
  color: theme.colors.n09,
  fontSize: '.875rem',
  fontWeight: 500,
  letterSpacing: '0.2px'
}))

export const Input = Styled.input(({ theme }) => ({
  margin: '0 0 .5rem',
  padding: '0 .5rem',
  height: '40px',
  width: '45px',
  border: 'none',
  borderBottom: `1px solid ${theme.colors.n08}`,
  background: 'none',
  color: theme.colors.n08,
  fontWeight: 400,
  ':focus': {
    outline: 'none',
    borderBottom: `2px solid ${theme.colors.b01}`
  },
  ':disabled': {
    color: theme.colors.n06,
    borderBottomColor: theme.colors.n06,
    cursor: 'not-allowed'
  },
  '::placeholder': {
    color: theme.colors.n05
  },
  [`&::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button`
  ]: {
    '-webkit-appearance': 'none',
    margin: '0'
  },
  '&[type=number]': {
    '-moz-appearance': 'textfield'
  }
}))
