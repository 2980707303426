import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

import Icon from '@mdi/react'
import { mdiLaunch } from '@mdi/js'

import useLocalStorage from '../../Utils/Hooks/useLocalStorage'
import useTranslate from '../../Utils/Hooks/useTranslate'

import UserContext from '../../State/User/Context'
import Dropdown from '../Dropdown'
import ExternalLink from '../ExternalLink'
import UserDropdownButton from './UserDropdownButton'

import { SubListItem, UserDropdownInfoContainer } from './Styles'

const UserDropdown = () => {
  const [shouldClose, setShouldClose] = useState(false)
  const { actions: { logout, hydrateUserData }, selectors: { getUser, getUID } } = useContext(UserContext)
  const [defaultOrg] = useLocalStorage(getUID(), null)
  const { organizationId } = useParams()
  const translate = useTranslate('userDropdown')

  const {
    firstName, lastName, email, userId
  } = getUser()

  useEffect(() => {
    hydrateUserData({ uid: userId })
  }, [])

  return (
    <Dropdown
      ButtonComponent={UserDropdownButton}
      shouldClose={shouldClose}
      contentStyles={{ right: 0, width: 256 }}
      data-testid="user-dropdown"
    >
      <UserDropdownInfoContainer>
        <div data-testid="user-dropdown-name">{firstName && lastName ? `${firstName} ${lastName}` : <Skeleton width={200} />}</div>
        <SubListItem data-testid="user-dropdown-email">{email || <Skeleton width={200} />}</SubListItem>
      </UserDropdownInfoContainer>
      <hr />
      <Link to="/my-account" data-testid="user-dropdown-myaccount">{translate('myAccount')}</Link>
      <Link
        to={`/organization/${organizationId || defaultOrg}/organization-settings`}
        onClick={() => setShouldClose(true)}
        data-testid="user-dropdown-organization"
      >
        {translate('organization')}
      </Link>
      <hr />
      <ExternalLink href="https://deltacontrols.com/privacy-policy/" data-testid="user-dropdown-privacy">
        <span>{translate('privacyStatement')}</span>
        <Icon path={mdiLaunch} size={1} />
      </ExternalLink>
      <ExternalLink href="https://deltacontrols.com/terms-of-use-and-conditions/" data-testid="user-dropdown-terms">
        <span>{translate('termsOfService')}</span>
        <Icon path={mdiLaunch} size={1} />
      </ExternalLink>
      <hr />
      <button type="button" onClick={logout} data-testid="user-dropdown-signout">{translate('signOut')}</button>
    </Dropdown>
  )
}

export default UserDropdown
