import styled from 'styled-components'

export const RadioLabelContainer = styled.div({
  paddingBottom: '1.5rem'
})

export const RadioLableHeader = styled.h4(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: '400',
  marginBottom: '0.5rem',
  color: theme.colors.n08
}))

export const RadioLabelBody = styled.p(({ theme }) => ({
  fontSize: '.75rem',
  fontWeight: '400',
  color: theme.colors.n07
}))


export const UnitHeader = styled.h3({
  fontSize: '0.875rem',
  fontWeight: '500',
  marginBottom: '.5rem'
})

export const Unit = styled.p({
  flexGrow: 1,
  fontWeight: '300',
  fontSize: '0.875rem'
})

export const UnitContainer = styled.div({
  marginBottom: '2rem'
})

export const ResendLink = styled.a(({ theme }) => ({
  color: theme.colors.b01,
  textDecoration: 'none',
  fontSize: '0.875rem',
  cursor: 'pointer',
  marginRight: '0.75rem'
}))

export const RemoveInviteLink = styled.a(({ theme }) => ({
  color: theme.colors.s05,
  textDecoration: 'none',
  fontSize: '0.875rem',
  cursor: 'pointer'
}))

export const InviteContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between'
})

export const JobAssignmentContainer = styled.div({
  paddingBottom: '1.5rem'
})

export const SuperAdminContainer = styled.div({
  span: {
    '&:last-of-type': {
      display: 'inline-block',
      verticalAlign: 'top'
    }
  }
})
