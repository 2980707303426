import { types } from './Reducer'

const getActions = (state, dispatch) => {
  const openPanel = () => {
    dispatch({ type: types.OPEN_PANEL })
  }

  const closePanel = () => {
    dispatch({ type: types.CLOSE_PANEL })
  }

  const changeView = (viewName) => {
    dispatch({ type: types.CHANGE_VIEW, view: viewName })
    dispatch({ type: types.OPEN_PANEL })
  }

  const resetView = () => {
    dispatch({ type: types.CHANGE_VIEW, view: 'blank' })
  }

  const setSubmitted = () => {
    dispatch({ type: types.SET_SUBMITTED })
  }

  const setConfirmation = () => {
    dispatch({ type: types.SET_CONFIRMATION })
  }

  return {
    openPanel,
    closePanel,
    changeView,
    resetView,
    setSubmitted,
    setConfirmation
  }
}

export default getActions
