
const useFlatObjectKeys = (source) => {
  const isObject = val => typeof val === 'object' && !Array.isArray(val)

  const addDelimiter = (a, b) => (a ? `${a}.${b}` : b)

  const paths = (obj = {}, head = '') => Object.entries(obj)
    .reduce((product, [key, value]) => {
      const fullPath = addDelimiter(head, key)
      return isObject(value)
        ? product.concat(paths(value, fullPath))
        : product.concat(fullPath)
    }, [])

  return paths(source)
}

export default useFlatObjectKeys
