import React, { useEffect, useState, useContext } from 'react'
import { mdiAlertOutline } from '@mdi/js'


import useTranslate from '../../../Utils/Hooks/useTranslate'
import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'
import ModalContext from '../../../State/Modal/Context'
import JobsContext from '../../../State/Jobs/Context'
import NotificationsContext from '../../../State/Notifications/Context'

import Button from '../../Button'
import ModalContent from '../../Modal/Components/ModalContent'
import ModalFooter from '../../Modal/Components/ModalFooter'

import ConfirmationHeader from '../Components/ConfirmationHeader'
import ModalRibbon from '../Components/ModalRibbon'
import ConfirmationCheckboxes from '../Components/ConfirmationCheckboxes'
import ConfirmationFooter from '../Components/ConfirmationFooter'

const JobsDelete = () => {
  const translate = useTranslate('deleteJobModal')
  const { actions: { closeModal, setSubmitted } } = useContext(ModalContext)
  const { actions: { deleteJobsites }, selectors: { getSelectedJobsitesList } } = useContext(JobsContext)
  const { actions: { addNotification } } = useContext(NotificationsContext)
  const [execute, , isSubmitting, result, hasError] = useAsyncCallback(deleteJobsites)
  const [isJobSiteSelected, setJobSiteSelected] = useState(false)
  const [isSensorHubsSelected, setSensorHubsSelected] = useState(false)
  const [isSensorDataSelected, setSensorDataSelected] = useState(false)
  const selectedJobsites = getSelectedJobsitesList()
  useErrorNotifier(hasError, result)

  const handleJobSiteClick = () => setJobSiteSelected(!isJobSiteSelected)
  const handleSensorHubClick = () => setSensorHubsSelected(!isSensorHubsSelected)
  const handleSensorDataClick = () => setSensorDataSelected(!isSensorDataSelected)
  const handleSubmit = async () => {
    await execute(selectedJobsites)
    setSubmitted()
    closeModal()
  }

  useEffect(() => {
    if (hasError === false) {
      addNotification(translate('notifications.success'), 'success', 2)
    }
  }, [hasError]) // eslint-disable-line

  const handleCancel = () => {
    closeModal()
  }

  const checkboxes = [
    {
      label: translate('labels.jobSite'),
      checked: isJobSiteSelected,
      disabled: isSubmitting,
      onChange: handleJobSiteClick,
      testId: 'confirmation-job-site'
    },
    {
      label: translate('labels.sensorHubs'),
      checked: isSensorHubsSelected,
      disabled: isSubmitting,
      onChange: handleSensorHubClick,
      testId: 'confirmation-sensor-hubs'
    },
    {
      label: translate('labels.sensorData'),
      checked: isSensorDataSelected,
      disabled: isSubmitting,
      onChange: handleSensorDataClick,
      testId: 'confirmation-sensor-data'
    }
  ]
  const allAgreed = checkboxes.every(({ checked }) => checked)
  return (
    <>
      <ModalRibbon icon={mdiAlertOutline} type="warning" textContent={translate('labels.warning')} />
      <ModalContent hasBottomPadding={false}>
        <ConfirmationHeader header={translate('confirmationHeader')} />
        <ConfirmationCheckboxes confirmationLabel={translate('labels.confirmationLabel')} checkboxes={checkboxes} />
        <ConfirmationFooter footer={translate('confirmationFooter')} />
      </ModalContent>
      <ModalFooter>
        <Button
          destructive
          data-testid="delete-job-modal-delete-button"
          onClick={handleSubmit}
          disabled={!allAgreed}
          isLoading={isSubmitting}
        >
          {translate('labels.delete')}
        </Button>
        <Button
          inverted
          data-testid="delete-job-modal-cancel-button"
          onClick={handleCancel}
          disabled={isSubmitting}
        >
          {translate('labels.cancel')}
        </Button>
      </ModalFooter>
    </>
  )
}

export default JobsDelete
