import React from 'react'
import PropTypes from 'prop-types'
import { BreadcrumbsContainer, Separator } from './Styles'

const Breadcrumbs = ({ children }) => {
  if (!Array.isArray(children)) {
    return <BreadcrumbsContainer>{children}</BreadcrumbsContainer>
  }

  return (
    <BreadcrumbsContainer data-testid="breadcrumb-container">
      {children.reduce((aggregate, current, currentIndex) => (
        currentIndex >= 1
          ? <>{aggregate} <Separator> / </Separator> {current}</>
          : <>{current}</>
      ), '')}
    </BreadcrumbsContainer>
  )
}

Breadcrumbs.propTypes = {
  children: PropTypes.node
}

Breadcrumbs.defaultProps = {
  children: <></>
}

export default Breadcrumbs
