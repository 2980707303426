import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { mdiInformationOutline } from '@mdi/js'
import { useParams } from 'react-router-dom'

import useTranslate from '../../../Utils/Hooks/useTranslate'
import useValidationSchema from '../../../Utils/Hooks/useValidationSchema'
import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'

import Ribbon from '../../Ribbon'
import ZonesContext from '../../../State/Zones/Context'
import PanelContext from '../../../State/Panel/Context'
import Text from '../../Formik/Text'
import Fieldset from '../../Form/Fieldset'
import PanelFooter from '../Components/PanelFooter'
import PanelRibbon from '../Components/PanelRibbon'

const EditZone = ({ setIsDirty }) => {
  const { actions: { updateZoneName }, selectors: { getSelectedZone } } = useContext(ZonesContext)
  const { actions: { setSubmitted, closePanel } } = useContext(PanelContext)
  const [execute, , , result, hasError] = useAsyncCallback(updateZoneName)
  const { jobsiteId } = useParams()
  const zone = getSelectedZone()
  const { zoneId, zoneName } = zone.subRows[0].original
  const { getCurrentSchema } = useValidationSchema()
  const validationSchema = getCurrentSchema('zones/edit')
  const translate = useTranslate('zonePanel')
  useErrorNotifier(hasError, result)

  const initialValues = {
    name: zoneName,
    jobsiteId
  }

  const buttonLabels = {
    buttonLabel: translate('saveButton'),
    cancelLabel: translate('cancelButton'),
    submittingLabel: translate('submittingButton')
  }

  const onSubmit = async (values) => {
    await execute(zoneId, { name: values.name })
    setSubmitted()
    closePanel()
  }
  return (
    <>
      <Ribbon type="warning">{`${zone.subRows.length} ${translate('hubsConnectedToZone')}`}</Ribbon>
      <Formik
        initialValues={initialValues}
        validateOnMount={false}
        enableReinitialize
        onSubmit={onSubmit}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {({
          handleSubmit, isSubmitting, isValid, errors, dirty, submitCount
        }) => (
          <Form onChange={setIsDirty(dirty)}>
            <PanelRibbon errors={errors} submitCount={submitCount} />
            <Fieldset hasBorder={false}>
              <Text
                name="name"
                data-testid="edit-zone-name"
                label={translate('zoneNameInputLabel')}
                hasTooltip
                tooltipIcon={mdiInformationOutline}
                tooltipContent={translate('zoneNameInputTooltip')}
                submitCount={submitCount}
              />
            </Fieldset>
            <PanelFooter
              handleSubmit={handleSubmit}
              handleReset={closePanel}
              dirty={dirty}
              errors={errors}
              isSubmitting={isSubmitting}
              isValid={isValid}
              labels={buttonLabels}
              testIdRoot="edit-zone"
              submitCount={submitCount}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

EditZone.propTypes = {
  setIsDirty: PropTypes.func.isRequired
}

export default EditZone
