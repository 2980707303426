import styled from 'styled-components'

export const PageHeaderContainer = styled.div(({ theme }) => ({
  alignItems: 'left',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 24,
  justifyContent: 'space-between',
  width: '100%',
  '& > div > img:first-child': {
    marginLeft: '1rem'
  },
  [`@media(min-width: ${theme.breakpoints.xl.minWidth})`]: {
    alignItems: 'center',
    flexDirection: 'row'
  }
}))
