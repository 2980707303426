import { useContext } from 'react'
import { number } from 'yup'
import { useParams } from 'react-router-dom'
import DevicesClient from '../../../State/Devices/Client'
import DeviceContext from '../../../State/Devices/Context'

const UniqueDeviceNumber = (messages) => {
  const { jobsiteId } = useParams()
  const { selectors: { getSelectedDevice } } = useContext(DeviceContext)
  const device = getSelectedDevice() || { config: { bacnet: { deviceNumber: null } } }

  async function test(value) {
    const { config: { bacnet: { deviceNumber: savedDeviceNumber } } } = device
    const deviceNumberCount = await DevicesClient.verifyUniqueDeviceNumber(savedDeviceNumber, jobsiteId, value)
    return deviceNumberCount === 0
  }

  const {
    requiredMessage,
    rangeMessage,
    uniqueMessage
  } = messages

  return number()
    .required(requiredMessage)
    .min(1, rangeMessage)
    .max(4194304, rangeMessage)
    .test(
      'deviceNumber',
      uniqueMessage,
      test
    )
}

export default UniqueDeviceNumber
