import React from 'react'
import PropTypes from 'prop-types'

import { TableCell } from '../../../../Table'


const Email = ({ row }) => {
  const { email } = row.original || {}

  return (
    <TableCell data-testid="users-email">{email}</TableCell>
  )
}

Email.propTypes = {
  row: PropTypes.object.isRequired
}

export default Email
