import { string } from 'yup'
import OrganizationClient from '../../../State/Organization/Client'

async function testUniqueAccountId() {
  try {
    const { accountId } = this.parent
    const accountIdCount = await OrganizationClient.verifyUniqueAccountId(accountId, 'edit')
    return accountIdCount === 0
  } catch (e) {
    return null
  }
}

const UniqueAccountId = ({
  requiredMessage, uniqueAccountIdMessage
}) => string()
  .required(requiredMessage)
  .test(
    'accountId',
    uniqueAccountIdMessage || 'The account ID ${value} has already been registered', //eslint-disable-line
    testUniqueAccountId
  )

export default UniqueAccountId
