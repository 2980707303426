import React, { useContext } from 'react'

import OrganizationContext from '../../../State/Organization/Context'
import PanelContext from '../../../State/Panel/Context'

import useTranslate from '../../../Utils/Hooks/useTranslate'

import ReadOnlyFields from '../Components/ReadOnlyFields'
import PanelFooter from '../../Panel/Components/PanelFooter'
import Fieldset from '../../Form/Fieldset'
import Button from '../../Button'

const ViewUser = () => {
  const { selectors: { getSelectedUser } } = useContext(OrganizationContext)
  const { actions: { closePanel } } = useContext(PanelContext)
  const {
    userId, createdAt, updatedAt, ...rest
  } = getSelectedUser()
  const translate = useTranslate('viewUserPanel')
  const getAssignedJobsites = (values) => {
    if (values.role === 'admin') {
      return translate('labels.allJobsites')
    }
    if (values.jobsites && values.jobsites.length > 0) {
      return translate('labels.reactivateToView')
    }
    return translate('labels.noJobsites')
  }
  const getKeyValue = (key, values) => {
    if (key === 'jobsites') {
      return getAssignedJobsites(values)
    }

    if (key === 'role') {
      if (values.isPrimary) {
        return translate('labels.role.primaryAdmin')
      }

      if (values.role === 'installer') {
        return translate('labels.role.installer').replace(/^\w/, char => char.toUpperCase())
      }

      if (values.role === 'admin') {
        return translate('labels.role.admin').replace(/^\w/, char => char.toUpperCase())
      }
    }

    if (key === 'status') {
      return values[key].replace(/^\w/, char => char.toUpperCase())
    }

    return values[key]
  }
  const generateUserField = key => ({
    header: translate(`labels.${key}`),
    value: getKeyValue(key, rest),
    testId: `read-only-${key}`
  })
  const fields = [
    generateUserField('firstName'),
    generateUserField('lastName'),
    generateUserField('email'),
    generateUserField('role'),
    generateUserField('jobsites')
  ]

  return (
    <>
      <Fieldset hasBorder={false}>
        <ReadOnlyFields fields={fields} />
        <PanelFooter>
          <Button onClick={closePanel} inverted data-testid="view-template-close-button">{translate('closeButton')}</Button>
        </PanelFooter>
      </Fieldset>
    </>
  )
}

export default ViewUser
