import styled from 'styled-components'

export const Status = styled.span(({ isEnabled, theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  color: isEnabled ? theme.colors.s02 : 'inherit',
  svg: {
    marginRight: '0.5rem',
    fill: isEnabled ? theme.colors.s02 : 'inherit'
  }
}))
