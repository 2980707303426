import styled from 'styled-components'

export const MessageContainer = styled.div(({ theme, isError }) => ({
  fontSize: '.875rem',
  display: 'flex',
  padding: '1.5px 0',
  '& > span > p': {
    color: isError ? `${theme.colors.s05} !important` : `${theme.colors.n08} !important`,
    marginLeft: '.5rem',
    marginBottom: '0 !important',
    lineHeight: '20px !important',
    lineSpacing: '0.2px !important'
  },
  '& > span > svg': {
    color: isError ? theme.colors.s05 : theme.colors.b01,
    height: '1.25rem !important',
    width: '1.25rem !important'
  }
}))

export const Heading = styled.h4(({ theme }) => ({
  fontSize: '.875rem',
  marginBottom: '1rem',
  fontWeight: '500',
  color: theme.colors.n09
}))
