import React from 'react'
import PropTypes from 'prop-types'

import { TableCell } from '../../../../Table'

const DeviceNumber = ({ row }) => {
  const { bacnet } = row.original.config
  const { deviceNumber } = bacnet || {}

  return (
    <TableCell align="right" data-testid="devices-deviceNumber">
      {(!deviceNumber || deviceNumber === '') ? <i>Undefined</i> : deviceNumber}
    </TableCell>
  )
}

DeviceNumber.propTypes = {
  row: PropTypes.object.isRequired
}

export default DeviceNumber
