import React from 'react'
import PropTypes from 'prop-types'

import backgroundImage from '../../Images/delta-building.jpg'
import { BackgroundImageContainer } from './Styles'

const BackgroundImage = ({ src, alt }) => (
  <BackgroundImageContainer data-testid="background-image-container">
    <img src={src} alt={alt} data-testid="background-image" />
  </BackgroundImageContainer>
)

BackgroundImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string
}

BackgroundImage.defaultProps = {
  src: backgroundImage,
  alt: 'Delta Controls\' Office'
}

export default BackgroundImage
