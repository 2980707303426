
import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { Redirect, useHistory } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useValidationSchema from '../../../Utils/Hooks/useValidationSchema'
import useTranslate from '../../../Utils/Hooks/useTranslate'

import UserContext from '../../../State/User/Context'
import Spinner from '../../Spinner'
import Logo from '../../Logo'
import BackgroundImage from '../../BackgroundImage'
import PageBoundary from '../../PageBoundary'
import ContentWrapper from '../../ContentWrapper'
import PublicForm from '../../PublicForm'
import Footer from '../../Footer'
import Text from '../../Formik/Text'
import Checkbox from '../../Form/Checkbox'
import Button from '../../Button'
import ExternalLink from '../../ExternalLink'
import PageMetaData from '../../PageMetaData'

import { Header, FooterText, ScrollableWrapper } from './Styles'

const CreatePassword = () => {
  const history = useHistory()
  const { getCurrentSchema } = useValidationSchema()
  const {
    actions: { createUser, processInvite, fetchUserPermissions }, selectors: {
      getInviteData, getLanguagePreference, isLoading, getUser
    }
  } = useContext(UserContext)
  const isOrgPermissionsPopulated = getUser() && getUser().permissions && getUser().permissions.length !== 0
  const [createUserAuth, , createUserAuthLoading, createUserAuthResult, createUserAuthError] = useAsyncCallback(createUser)
  const [acceptInvite, acceptInviteInit, acceptInviteLoading, acceptInviteResult, acceptInviteError] = useAsyncCallback(processInvite)
  const translate = useTranslate('accountActivation')
  const translateError = useTranslate('errorHandling')
  const invite = getInviteData()
  const translateMetaData = useTranslate('pageMetaData')

  const [isCheckboxChecked, setCheckboxChecked] = useState(false)
  const handleCheckboxClick = () => setCheckboxChecked(!isCheckboxChecked)

  useEffect(() => {
    async function fetchUser(uid) { await fetchUserPermissions(uid) }
    if (acceptInviteInit && acceptInviteResult && !acceptInviteError) {
      fetchUser(createUserAuthResult)
    }
  }, [createUserAuthResult, acceptInviteInit, acceptInviteResult, acceptInviteError, fetchUserPermissions])

  useEffect(() => {
    if (isOrgPermissionsPopulated) {
      history.push('/')
    }
  }, [isOrgPermissionsPopulated, history])

  if (!invite) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { error: translateError('invalidInvite') }
        }}
      />
    )
  }

  const initialValues = {
    email: invite.email || '',
    password: '',
    language: getLanguagePreference() || invite.language || (window && window.navigator.language.slice(0, 2)) || 'en'
  }


  if (createUserAuthLoading || acceptInviteLoading || isLoading()) {
    return <Spinner message={translate('formSubmitting')} />
  }

  return (
    <>
      <PageMetaData pageMetaData={{ title: `${translateMetaData('verifyAccountTitle')}`, description: 'TODO' }} />
      <PageBoundary>
        <ContentWrapper>
          <ScrollableWrapper>
            <PublicForm>
              <Logo size={300} />
              <Header>
                <h1>{translate('heading')}</h1>
                <span>{translate('description')}</span>
              </Header>
              <Formik
                initialValues={initialValues}
                validateOnMount={false}
                validationSchema={getCurrentSchema('account/create')}
                enableReinitialize
                onSubmit={(async (values) => {
                  await createUserAuth(values)
                  await acceptInvite(invite.inviteId, values)
                })}
              >{({ isValid, isSubmitting }) => (
                <Form>
                  {createUserAuthError && <strong>{translate('errorOcurred')}: {createUserAuthResult.message}</strong>}
                  {acceptInviteError && <strong>{translate('errorOcurred')}: {acceptInviteError.message}</strong>}
                  <Text name="firstName" label={translate('firstNameInputLabel')} placeholder={translate('firstNameInputPlaceholder')} autoComplete="given-name" data-testid="create-password-first-name" />
                  <Text name="lastName" label={translate('lastNameInputLabel')} placeholder={translate('lastNameInputPlaceholder')} autoComplete="family-name" data-testid="create-password-last-name" />
                  <Text type="email" name="email" label={translate('emailInputLabel')} placeholder={translate('emailInputPlaceholder')} autoComplete="email" data-testid="create-password-email" disabled />
                  <Text name="password" label={translate('passwordInputLabel')} placeholder={translate('passwordInputPlaceholder')} autoComplete="new-password" isPassword data-testid="create-password-password" textHelper={translate('passwordDescription')} />
                  <Text name="confirmPassword" label={translate('confirmPasswordInputLabel')} placeholder={translate('confirmPasswordInputPlaceholder')} autoComplete="new-password" isPassword data-testid="create-password" textHelper={translate('confirmPasswordDescription')} />
                  <FooterText>
                    <Checkbox
                      checked={isCheckboxChecked}
                      label={(
                        <ReactMarkdown
                          renderers={{
                            link: ExternalLink
                          }}
                        >
                          {translate('termsAndConditions')}
                        </ReactMarkdown>
                      )}
                      disabled={isSubmitting}
                      onChange={handleCheckboxClick}
                      testId="confirmation-job-site"
                      index={0}
                      inputProps={{
                        'aria-label': 'primary checkbox'
                      }}
                    />
                  </FooterText>
                  <Button type="submit" disabled={!isValid || isSubmitting || isCheckboxChecked === false} data-testid="create-password-activate">{translate('createAccountButton')}</Button>
                </Form>
              )}
              </Formik>
            </PublicForm>
            <Footer />
          </ScrollableWrapper>
        </ContentWrapper>
        <BackgroundImage />
      </PageBoundary>
    </>
  )
}

export default CreatePassword
