import Styled from 'styled-components'

export const Container = Styled.div(({ hidden, isDelimited }) => ({
  display: hidden ? 'none' : 'block',
  margin: !isDelimited ? '0 0 1.5rem' : '0'
}))

export const Input = Styled.input(({ theme, hasError, hasPrefix }) => ({
  margin: '0 0 .5rem',
  padding: hasPrefix ? '0.3rem 9.5rem 0 2rem' : '0 .5rem',
  height: '40px',
  width: '100%',
  border: 'none',
  borderBottom: `1px solid ${theme.colors.n08}`,
  background: 'none',
  color: theme.colors.n08,
  fontWeight: 400,
  ':focus': {
    outline: 'none'
  },
  ':disabled': {
    color: theme.colors.n06,
    borderBottomColor: theme.colors.n06,
    cursor: 'not-allowed'
  },
  '::placeholder': {
    color: theme.colors.n05
  },
  ...(hasError ? { borderBottomColor: theme.colors.s05 } : {}),
  [`&::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button`
  ]: {
    '-webkit-appearance': 'none',
    margin: '0'
  },
  '&[type=number]': {
    '-moz-appearance': 'textfield'
  }
}))

export const Label = Styled.label(({ theme }) => ({
  display: 'block',
  color: theme.colors.n09,
  fontSize: '.875rem',
  fontWeight: 500,
  letterSpacing: '0.2px'
}))


export const Field = Styled.div(() => ({
  display: 'flex',
  position: 'relative'
}))

export const VisibilityButton = Styled.button(({ theme, error }) => ({
  border: 'none',
  borderBottom: '1px solid',
  borderColor: error ? theme.colors.s05 : theme.colors.n08,
  height: '40px',
  width: '45px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  cursor: 'pointer',
  svg: {
    width: '1.25rem',
    color: theme.colors.n08
  }
}))

export const AccessoryTextContainer = Styled.div(({ theme }) => ({
  border: 'none',
  color: theme.colors.n06,
  position: 'absolute',
  right: '0',
  top: '25%'
}))

export const PrefixTextContainer = Styled.div(({ theme }) => ({
  border: 'none',
  color: theme.colors.n06,
  position: 'absolute',
  left: '0',
  top: '25%'
}))


export const HelperText = Styled.h4({
  fontSize: '.875rem',
  padding: '0.5rem 0',
  lineHeight: '16px',
  margin: '0'
})
