const initialState = {
  loading: false,
  error: {
    status: false,
    code: null
  },
  data: null
}


const types = {
  FETCH_FIRMWARE_PENDING: 'FETCH_FIRMWARE_PENDING',
  FETCH_FIRMWARE_SUCCESS: 'FETCH_FIRMWARE_SUCCESS',
  FETCH_FIRMWARE_FAILURE: 'FETCH_FIRMWARE_FAILURE'
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case (types.FETCH_FIRMWARE_PENDING):
      return {
        ...state,
        loading: true
      }
    case (types.FETCH_FIRMWARE_SUCCESS):
      return {
        ...state,
        loading: false,
        data: action.payload
      }
    case (types.FETCH_FIRMWARE_FAILURE):
      return {
        ...state,
        loading: false,
        error: {
          status: true,
          code: action.payload.message
        }
      }
    default:
      return state
  }
}

export {
  reducer,
  initialState,
  types
}
