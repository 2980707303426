import React, { useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { mdiAlertCircleOutline } from '@mdi/js'
import { Icon } from '@mdi/react'
import { ThemeContext } from 'styled-components'

import useTranslate from '../../../../Utils/Hooks/useTranslate'

import UserContext from '../../../../State/User/Context'
import PageMetaData from '../../../PageMetaData'
import Ribbon from '../../../Ribbon'

import {
  PageContainer, ServerErrorHeader, HttpCode, ReturnLink, SignOutButton
} from './Styles'

const ErrorPage = () => {
  const { actions: { logout } } = useContext(UserContext)
  const theme = useContext(ThemeContext)
  const history = useHistory()
  const { state } = useLocation()
  const { isPermissionError, shouldSignOut, isInactive } = state || {}
  const translate = useTranslate('ErrorPage')
  const translateMetaData = useTranslate('pageMetaData')

  const handleSignOut = async () => {
    await logout()
    history.push(isInactive ? '/' : '/create-password')
  }
  return (
    <>
      <PageMetaData pageMetaData={{ title: `${translateMetaData('pageNotFoundTitle')}` }} />
      {isPermissionError && <Ribbon type="error" isFixed isCentered>{translate('accessDenied.ribbon')}</Ribbon>}
      <PageContainer>
        <Icon path={mdiAlertCircleOutline} size={1.25} color={theme.colors.n07} />
        <ServerErrorHeader>{translate(isPermissionError ? 'accessDenied.header' : 'serverError.header')}</ServerErrorHeader>
        <HttpCode>{translate(isPermissionError ? 'accessDenied.httpCode' : 'serverError.httpCode')}</HttpCode>
        {shouldSignOut
          ? <SignOutButton id="signout" onClick={handleSignOut} data-testid="signout-button">{translate('signOutLink')}</SignOutButton>
          : <ReturnLink to="/">{translate('returnLink')}</ReturnLink>}
      </PageContainer>
    </>
  )
}

export default ErrorPage
