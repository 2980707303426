import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@mdi/react'

import { StyledButtonIcon } from './Styles'

const ButtonIcon = ({
  path, disabled, type, styles, inverted, ...rest
}) => (
  <StyledButtonIcon disabled={disabled} type={type} inverted={inverted} styles={styles} data-testid={`${path}-icon`} {...rest}>
    <Icon path={path} />
  </StyledButtonIcon>
)

ButtonIcon.propTypes = {
  path: PropTypes.string.isRequired,
  type: PropTypes.string,
  styles: PropTypes.object,
  inverted: PropTypes.bool,
  disabled: PropTypes.bool
}

ButtonIcon.defaultProps = {
  type: 'button',
  styles: {},
  inverted: false,
  disabled: false
}

export default ButtonIcon
