import React from 'react'
import PropTypes from 'prop-types'

import Select from '../../../../Components/Formik/Select'

const OffSelectComponent = ({ translate }) => {
  const firstOption = {
    value: 1,
    label: `1 (${translate('labels.offValue')})`
  }
  let options = [firstOption]
  for (let i = 2; i <= 7; i += 1) {
    options = options.concat({
      value: i,
      label: i
    })
  }
  return (
    <>
      <Select
        label={translate('labels.value')}
        name="value"
        options={options}
        testId="select-value"
        data-testid="number-select"
      />
    </>
  )
}

OffSelectComponent.propTypes = {
  translate: PropTypes.func.isRequired
}

export default OffSelectComponent
