import { object, number, boolean } from 'yup'
import {
  RequiredField,
  UniqueZoneName,
  ConditionalRequirement,
  ConditionalNumberRequirement,
  ConditionalObjectRequirement,
  NetworkConditionalRequirementIP,
  NetworkConditionalIP,
  MQTTConditionalRequirementIP,
  UrlConditionalRequirement,
  ConditionalUDPRequirement,
  UniqueDeviceNumber,
  BLEPin
} from '../Helpers'
import useTranslateWithLanguage from '../../Hooks/useTranslateWithLanguage'

const CreateDeviceSchema = (language) => {
  const translate = useTranslateWithLanguage('formValidation', language)

  const fixedSampleRateSchema = number()
    .required(translate('fixedSampleRateIsRequired'))
    .min(5, translate('fixedSampleRateMin'))
    .max(1440, translate('fixedSampleRateMax'))

  const customBrokerPortSchema = number()
    .required(translate('portIsRequired'))
    .min(1, translate('portGreaterThanZero'))
    .max(65535, translate('portLessThanMax'))

  const customBrokerEnabledSchema = object().shape({
    brokerIp: MQTTConditionalRequirementIP(translate('ipOrUrlIsRequired'), translate('ipAddressIsNotValid')),
    brokerUrl: UrlConditionalRequirement(translate('urlIsRequired'), translate('urlIsNotValid')),
    port: ConditionalNumberRequirement('brokerType', 'custom', customBrokerPortSchema)
  })

  const deviceIdMessages = {
    requiredMessage: translate('bacNetDeviceIdIsRequired'),
    uniqueMessage: translate('bacNetDeviceIdIsUnique'),
    rangeMessage: translate('bacNetDeviceIdIsInRange')
  }

  return object().shape({
    device: object().shape({
      deviceName: RequiredField(translate('fieldIsRequired'))
    }),
    zone: object().shape({
      zoneName: UniqueZoneName('isNewZone', 'true', translate('fieldIsRequired')),
      id: ConditionalRequirement('isNewZone', 'false', translate('fieldIsRequired'))
    }),
    network: object().shape({
      ipAddress: NetworkConditionalRequirementIP('type', 'static', translate('ipAddressIsRequired'), translate('ipAddressIsNotValid')),
      subnetMask: NetworkConditionalRequirementIP('type', 'static', translate('subnetMaskIsRequired'), translate('subnetMaskIsNotValid')),
      gatewayIp: NetworkConditionalRequirementIP('type', 'static', translate('gatewayIpIsRequired'), translate('gatewayIpIsNotValid')),
      dnsIp: NetworkConditionalRequirementIP('type', 'static', translate('dnsIpIsRequired'), translate('dnsIpIsNotValid')),
      dnsIp2: NetworkConditionalIP('type', 'static', '', translate('dnsIpIsNotValid'))
    }),
    bacnet: object().shape({
      udpNumber: ConditionalUDPRequirement('protocol', 'ip', translate('udpPortIsRequired')),
      deviceNumber: UniqueDeviceNumber(deviceIdMessages)
    }),
    mqtt: object().shape({
      sensorReportRate: number().required(translate('sensorReportRateIsRequired')).min(5, translate('sensorReportRateMin')).max(1440, translate('sensorReportRateMax')),
      fixedSampleRate: ConditionalNumberRequirement('sensorReportType', 'fixed', fixedSampleRateSchema),
      isEnabled: boolean(),
      broker: ConditionalObjectRequirement('isEnabled', true, customBrokerEnabledSchema)
    }),
    blePin: BLEPin(translate('blePinIsRequired'), translate('blePinLength'), translate('bleDefaultPin'))
  })
}

export default CreateDeviceSchema
