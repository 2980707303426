import styled, { keyframes } from 'styled-components'

const circleAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const lineAnimation = keyframes`
  0% {
    stroke-dashoffset: 0;
    /* transform: rotate(0); */
    stroke-dasharray: 1px, 452px;
  }
  
  50% {
    stroke-dashoffset: -33px;
    /* transform: rotate(45deg); */
    stroke-dasharray: 226px, 452px;
  }
  
  100% {
    stroke-dashoffset: -281px;
    /* transform: rotate(360deg); */
    stroke-dasharray: 226px, 452px;
  }
`

export const SpinnerWrapper = styled.svg`
  box-sizing: border-box;
  width: 150px;
`

export const InnerCircleWrapper = styled.g`
  transform-origin: 50% 50%;
  animation: 2s linear infinite ${circleAnimation};
`

export const InnerCircle = styled.circle`
  stroke-dashoffset: 0;
  stroke-dasharray: 181px, 452px;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke-linecap: round;
  transform-origin: 50% 50%;
  animation: 1.4s ease-in-out infinite ${lineAnimation};
  stroke: ${props => props.theme.colors.n02};
  fill: transparent;
`

export const OuterCircle = styled.circle`
  stroke-width: 8;
  stroke: ${props => props.theme.colors.n06};
  fill: transparent;
`

export const Three = styled.g`
  transform: translate(11px, 25px);
  fill: ${props => props.theme.colors.n06};
`

export const Container = styled.div(({ theme }) => ({
  position: 'fixed',
  textAlign: 'center',
  background: theme.colors.n02,
  height: '100vh',
  width: '100vw',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  '& > div .MuiCircularProgress-colorPrimary': {
    color: theme.colors.n06
  }
}))

export const Message = styled.h2(({ theme }) => ({
  fontSize: '.7rem',
  fontWeight: 500,
  textTransform: 'uppercase',
  letterSpacing: 1,
  margin: '1.5rem',
  color: theme.colors.n06
}))
