import ZonesClient from './Client'
import { types } from './Reducer'

const getActions = (state, dispatch) => {
  const fetchZones = async (jobsiteId) => {
    try {
      dispatch({ type: types.FETCH_ZONES_PENDING })
      const response = await ZonesClient.fetchZones(jobsiteId)
      dispatch({ type: types.FETCH_ZONES_SUCCESS, payload: response })
    } catch (error) {
      dispatch({ type: types.FETCH_ZONES_FAILURE, payload: error })
    }
  }
  const updateZoneName = (zoneId, formData) => ZonesClient.updateZoneName(zoneId, formData)
  const setSelectedZone = zone => dispatch({ type: types.SET_SELECTED_ZONE, payload: zone })
  return {
    fetchZones,
    updateZoneName,
    setSelectedZone
  }
}

export default getActions
