import { object } from 'yup'
import useTranslateWithLanguage from '../../Hooks/useTranslateWithLanguage'
import { RequiredField } from '../Helpers'

const EditTemplates = (language) => {
  const translate = useTranslateWithLanguage('templatePanel', language)

  return object().shape({
    name: RequiredField(translate('nameInputValidation')),
    description: RequiredField(translate('descriptionInputValidation'))
  })
}

export default EditTemplates
