import useValidationSchema from '../../Hooks/useValidationSchema'
import useConfigTransform from '../../Hooks/useConfigTransform'
import BooleanComponent from './Components/BooleanComponent'

const BooleanField = () => {
  const { getCurrentSchema } = useValidationSchema()
  const { getCurrentTransform } = useConfigTransform()
  const nullField = () => null

  return {
    validationSchema: getCurrentSchema('attributes/readOnly'),
    configTransform: getCurrentTransform('attributes/booleanField'),
    Component: BooleanComponent,
    CoVComponent: nullField
  }
}

export default BooleanField
