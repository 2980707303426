const getSelectors = state => ({
  isLoading() {
    return state.loading
  },
  getErrorCode() {
    return state.error.code
  },
  hasError() {
    return state.error.status
  },
  getUsers() {
    return state.data ? state.data.users : null
  },
  getOrganizations() {
    return state.organizations ? state.organizations : []
  },
  getOrganization(id) {
    return state.data && state.data.find(org => org.organizationId === id)
  },
  getCurrentId() {
    return state.currentOrg ? state.currentOrg : null
  },
  getState() {
    return state
  },
  getSelectedUser() {
    return state.selectedUser
  },
  getSelectedOrganization() {
    return state.selectedOrganization
  }
})

export default getSelectors
