import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import Icon from '@mdi/react'
import {
  mdiAccountMultipleOutline, mdiPackageDown, mdiAccountPlusOutline
} from '@mdi/js'

import useTranslate from '../../../Utils/Hooks/useTranslate'
import useTableCheckboxState from '../../../Utils/Hooks/useTableCheckboxState'

import Breadcrumbs from '../../Breadcrumbs'
import Button from '../../Button'
import ButtonGroup from '../../ButtonGroup'
import PageHeader from '../../PageHeader'
import PageInfo from '../../PageInfo'
import PageCaption from '../../PageCaption'
import Panel from '../../Panel'
import PanelContext from '../../../State/Panel/Context'
import ModalContext from '../../../State/Modal/Context'
import UserContext from '../../../State/User/Context'
import TeamTable from './TeamTable'
import PageMetaData from '../../PageMetaData'

const Team = ({ setState, setOrgSelectState }) => {
  const { isBulkActionDisabled } = useTableCheckboxState()
  const { actions: { changeView } } = useContext(PanelContext)
  const { actions: { changeModalView } } = useContext(ModalContext)
  const { selectors: { getOrganization } } = useContext(UserContext)
  const [status, setStatus] = useState('active')
  const { organizationId } = useParams()
  const organization = getOrganization(organizationId)
  const translate = useTranslate('usersPage')
  const translateMetaData = useTranslate('pageMetaData')


  setState('Content/Managing_Users.html')
  setOrgSelectState('team')

  const openPanel = () => {
    changeView('users/invite')
  }

  const openModal = () => {
    if (status === 'inactive') {
      changeModalView('users/reactivate', true)
    } else {
      changeModalView('users/archive', true)
    }
  }

  return (
    <>
      <PageMetaData pageMetaData={{ title: `${translateMetaData('usersTitle')}`, description: 'TODO' }} />
      <Breadcrumbs />
      <PageHeader>
        <PageInfo>
          <Icon path={mdiAccountMultipleOutline} size={1} />
          <PageCaption>
            <h1>{translate('heading')}</h1>
            <h4>
              <span>{translate('description')}</span>
            </h4>
          </PageCaption>
        </PageInfo>
        <ButtonGroup>
          <Button onClick={openModal} inverted data-testid="users-archive-button" disabled={isBulkActionDisabled}><Icon path={mdiPackageDown} size={1} />
            {status === 'active' ? translate('archiveUserButton') : translate('reactivateUserButton')}
          </Button>
          {status === 'active'
            && <Button onClick={openPanel} data-testid="users-invite-button"><Icon path={mdiAccountPlusOutline} size={1} />{translate('inviteUserButton')}</Button>
          }
        </ButtonGroup>
        <Panel />
      </PageHeader>
      <TeamTable
        organization={organization}
        status={status}
        setStatus={setStatus}
        isBulkActionDisabled={isBulkActionDisabled}
      />
    </>
  )
}

Team.propTypes = {
  setState: PropTypes.func.isRequired,
  setOrgSelectState: PropTypes.func
}

Team.defaultProps = {
  setOrgSelectState: () => {}
}

export default Team
