import styled from 'styled-components'

const getInverted = inverted => (trueValue, falseValue) => (inverted ? trueValue : falseValue)

const getButtonDefaultStyle = (theme, inverted, destructive) => {
  const isInverted = getInverted(inverted)

  if (destructive) {
    return {
      backgroundColor: isInverted(theme.colors.n01, theme.colors.s05),
      color: isInverted(theme.colors.s05, theme.colors.n01),
      borderColor: isInverted(theme.colors.n05, theme.colors.s05),
      svg: {
        fill: isInverted(theme.colors.s05, theme.colors.n01)
      }
    }
  }

  return {
    backgroundColor: isInverted(theme.colors.n01, theme.colors.b01),
    color: isInverted(theme.colors.b01, theme.colors.n01),
    borderColor: isInverted(theme.colors.n05, theme.colors.b01),
    svg: {
      fill: isInverted(theme.colors.b01, theme.colors.n01)
    }
  }
}

const getButtonHoverStyle = (theme, inverted, destructive) => {
  const isInverted = getInverted(inverted)

  if (destructive && inverted) {
    return {
      backgroundColor: theme.colors.n02,
      color: theme.colors.s05,
      borderColor: theme.colors.s05,
      svg: {
        fill: theme.colors.s05
      }
    }
  }

  return {
    backgroundColor: isInverted(theme.colors.n02, theme.colors.n08),
    color: isInverted(theme.colors.n09, theme.colors.n01),
    borderColor: isInverted(theme.colors.n07, theme.colors.n08),
    svg: {
      fill: isInverted(theme.colors.n09, theme.colors.n01)
    }
  }
}

const getButtonSizeStyle = (size) => {
  if (size === 'small') {
    return {
      height: '24px',
      fontSize: '.75rem',
      lineHeight: '1rem',
      letterSpacing: '0.2px',
      padding: '0 .5rem 0 0.25rem'
    }
  }

  if (size === 'medium') {
    return {
      height: '32px',
      fontSize: '.875rem',
      lineHeight: '1rem',
      letterSpacing: '0.3px',
      padding: '.25rem .75rem'
    }
  }

  return {
    height: '40px',
    boxSizing: 'border-box',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.4px',
    padding: '0 1rem'
  }
}

const getDisabledStyle = (theme, inverted, isLoading) => {
  const isInverted = getInverted(inverted)

  if (isLoading) {
    return {
      borderColor: isInverted(theme.colors.n07, theme.colors.n08),
      backgroundColor: isInverted(theme.colors.n01, theme.colors.n08),
      color: isInverted(theme.colors.n09, theme.colors.n01),
      svg: {
        fill: isInverted(theme.colors.n09, theme.colors.n01)
      },
      '&:hover': {
        borderColor: isInverted(theme.colors.n07, theme.colors.n08),
        backgroundColor: isInverted(theme.colors.n01, theme.colors.n08),
        color: isInverted(theme.colors.n09, theme.colors.n01),
        svg: {
          fill: isInverted(theme.colors.n09, theme.colors.n01)
        }
      }
    }
  }

  return {
    borderColor: isInverted(theme.colors.n04, theme.colors.n02),
    backgroundColor: isInverted(theme.colors.n01, theme.colors.n02),
    color: theme.colors.n06,
    svg: {
      fill: theme.colors.n05
    },
    '&:hover': {
      borderColor: isInverted(theme.colors.n04, theme.colors.n02),
      backgroundColor: isInverted(theme.colors.n01, theme.colors.n02),
      color: theme.colors.n06,
      svg: {
        fill: theme.colors.n05
      }
    }
  }
}

export const StyledButton = styled.button(({
  fullWidth, size, theme, inverted, destructive, isLoading
}) => {
  const isInverted = getInverted(inverted)
  return {
    ...getButtonSizeStyle(size),
    fontFamily: theme.fonts.roboto,
    textAlign: 'center',
    border: '1px solid',
    borderRadius: '2px',
    fontWeight: 500,
    cursor: 'pointer',
    display: fullWidth ? 'block' : 'inline-block',
    width: fullWidth ? '100%' : 'auto',
    ...getButtonDefaultStyle(theme, inverted, destructive),
    '& > svg': {
      verticalAlign: '-7px',
      marginLeft: '-3px',
      marginRight: '8px'
    },
    '& .MuiCircularProgress-root': {
      height: '1rem !important',
      width: '1rem !important',
      verticalAlign: '-2px',
      marginRight: '0.5rem',
      color: isInverted(theme.colors.n09, theme.colors.n01)
    },
    '&:disabled': {
      cursor: 'not-allowed',
      ...getDisabledStyle(theme, inverted, isLoading)
    },
    '&:hover': {
      ...getButtonHoverStyle(theme, inverted, destructive)
    },
    '&:focus': {
      backgroundColor: isInverted(theme.colors.n03, theme.colors.n09),
      color: isInverted(theme.colors.n09, theme.colors.n01),
      borderColor: theme.colors.n09,
      svg: {
        fill: isInverted(theme.colors.n09, theme.colors.n01)
      }
    }
  }
})
