const ProgressiveField = (values, { name }) => {
  const {
    attributeId, isCustomDescription, description, value, ...rest
  } = values

  const isCustomName = rest.isCustomName === 'true'

  const config = {
    ...rest,
    isCustomName,
    ...!isCustomName && { name },
    description: isCustomDescription ? description : '',
    value: {
      isCustomValue: value.isCustomValue === 'custom',
      customValue: value.customValue
    }
  }

  return config
}

export default ProgressiveField
