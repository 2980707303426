import { object, number } from 'yup'
import useTranslateWithLanguage from '../../Hooks/useTranslateWithLanguage'
import { CustomName, ConditionalNumberRequirement } from '../Helpers'

const AttributesProgressiveField = (language) => {
  const translate = useTranslateWithLanguage('formValidation', language)

  return object().shape({
    name: CustomName(translate),
    value: object().shape({
      customValue: ConditionalNumberRequirement('isCustomValue', 'custom', number().required(translate('internalAcousticOccuancyRequired')).min(0, translate('covMin')).max(65536, translate('covMax')))
    })
  })
}

export default AttributesProgressiveField
