import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { mdiInformationOutline } from '@mdi/js'

import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useAsync from '../../../Utils/Hooks/useAsync'
import useTranslate from '../../../Utils/Hooks/useTranslate'
import useValidationSchema from '../../../Utils/Hooks/useValidationSchema'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'
import useConfigTransform from '../../../Utils/Hooks/useConfigTransform'

import Fieldset from '../../Form/Fieldset'
import Select from '../../Formik/Select'
import Text from '../../Formik/Text'
import PanelContext from '../../../State/Panel/Context'
import JobsiteContext from '../../../State/Jobs/Context'

import DevicesContext from '../../../State/Devices/Context'
import TemplateContext from '../../../State/Templates/Context'
import FirmwareContext from '../../../State/Firmware/Context'
import MQTTSettings from '../Components/MQTTSettings'
import NetworkSettings from '../Components/NetworkSettings'
import BACnetSettings from '../Components/BACnetSettings'
import ZoneSelection from '../Components/ZoneSelection'
import PanelFooter from '../Components/PanelFooter'
import PanelRibbon from '../Components/PanelRibbon'
import Firmware from '../Components/Firmware'


const CreateHub = ({ setIsDirty }) => {
  const { actions: { closePanel, setSubmitted } } = useContext(PanelContext)
  const { actions: { createDevice } } = useContext(DevicesContext)
  const { actions: { fetchJobsiteZones, fetchJobsiteTemplates }, selectors: { getJobsite } } = useContext(JobsiteContext)
  const { selectors: { getFirmwares } } = useContext(FirmwareContext)
  const { actions: { fetchTemplateData } } = useContext(TemplateContext)
  const { jobsiteId, organizationId } = useParams()
  const translate = useTranslate('devicePanel')
  const translateConfig = useTranslate('configForm')
  const { getCurrentSchema } = useValidationSchema()

  const jobsite = getJobsite(jobsiteId)
  const {
    config: {
      network, bacnet, blePin, mqtt: {
        isEnabled, sensorReportType, fixedSampleRate, sensorReportRate, broker
      }
    }
  } = jobsite

  const {
    brokerType, addressType, ipAddress, url, port
  } = broker || {}

  const [execute, , , result, hasError] = useAsyncCallback(createDevice)
  const [,, zones] = useAsync(fetchJobsiteZones, jobsiteId)
  const mappedZones = zones ? Object.keys(zones).map(zoneId => ({ value: zoneId, label: zones[zoneId].name })) : []
  const [,, templates] = useAsync(fetchJobsiteTemplates, organizationId)
  const mappedTemplates = templates ? templates.map(template => ({ value: template.templateId, label: template.name })) : []
  const firmwares = getFirmwares()
  useErrorNotifier(hasError, result)

  const initialValues = {
    templateId: mappedTemplates.length > 0 ? mappedTemplates[0].value : '',
    device: {
      deviceName: ''
    },
    zone: {
      isNewZone: 'true',
      zoneName: '',
      jobsiteId,
      id: mappedZones.length > 0 ? mappedZones[0].value : ''
    },
    network: {
      type: network ? network.type : 'dhcp',
      ipAddress: network ? network.ipAddress : '',
      subnetMask: network ? network.subnetMask : '',
      gatewayIp: network ? network.gatewayIp : '',
      dnsIp: network ? network.dnsIp : '',
      dnsIp2: network.dnsIp2 ? network.dnsIp2 : ''
    },
    bacnet: {
      protocol: bacnet ? bacnet.protocol : 'ethernet',
      deviceNumber: bacnet ? bacnet.deviceNumber : 1,
      ...bacnet.protocol === 'ip' ? {
        udpNumber: bacnet.udpNumber
      } : {}
    },
    mqtt: {
      isEnabled,
      sensorReportType: sensorReportType || 'changeOfValue',
      fixedSampleRate: fixedSampleRate || 5,
      sensorReportRate: sensorReportRate || 60,
      broker: broker
        ? {
          brokerType: brokerType || 'default',
          ...brokerType === 'custom' ? {
            addressType: addressType === 'ip' ? 'brokerIp' : 'brokerUrl',
            port,
            ...addressType === 'ip' ? { brokerIp: ipAddress } : { brokerUrl: url }
          } : {}
        }
        : {
          brokerType: 'default'
        }
    },
    blePin,
    firmware: {
      isEnabled: false,
      version: null,
      location: null
    }
  }

  const { getCurrentTransform } = useConfigTransform()
  const configTransform = getCurrentTransform('devices/create')

  const onInput = (e) => {
    e.target.value = e.target.value.slice(0, 6)
  }

  const onSubmit = async (values) => {
    const templateData = await fetchTemplateData(values.templateId)
    const config = configTransform(values, jobsite, templateData, jobsiteId)
    const notificationMessages = {
      operationFailure: translate('notifications.fail')
    }
    await execute(config, notificationMessages)
    setSubmitted()
    closePanel()
  }

  const labels = {
    cancelLabel: translate('cancelButton'),
    submittingLabel: translate('submittingButton'),
    buttonLabel: translate('createButton')
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getCurrentSchema('devices/create')}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({
        setFieldValue, values, handleSubmit, isSubmitting, errors, isValid, dirty, submitCount
      }) => (
        <Form onChange={setIsDirty(dirty)}>
          <PanelRibbon errors={errors} submitCount={submitCount} />
          <Fieldset>
            <Select
              id="template-dropdown"
              name="templateId"
              label={translate('templateHeading')}
              testId="template"
              options={mappedTemplates}
              onChange={(e) => {
                const { target: { value } } = e
                setFieldValue('templateId', value)
              }}
            />
          </Fieldset>
          <ZoneSelection values={values} setFieldValue={setFieldValue} zones={mappedZones} isCreateDevice submitCount={submitCount} />
          <NetworkSettings values={values} submitCount={submitCount} />
          <BACnetSettings values={values} submitCount={submitCount} />
          <MQTTSettings values={values} setFieldValue={setFieldValue} submitCount={submitCount} />
          <Firmware values={values} firmwares={firmwares} setFieldValue={setFieldValue} />
          <Fieldset heading={translateConfig('securitySettings.heading')}>
            <Text
              label={translateConfig('securitySettings.bluetoothPinInputLabel')}
              name="blePin"
              pattern="[0-9]*"
              data-testid="ble-pin"
              hasTooltip
              tooltipContent={translateConfig('securitySettings.bluetoothPinTooltip')}
              tooltipIcon={mdiInformationOutline}
              textHelper={translateConfig('securitySettings.bluetoothPinTextHelper')}
              submitCount={submitCount}
              onInput={onInput}
            />
          </Fieldset>
          <PanelFooter
            handleReset={closePanel}
            handleSubmit={handleSubmit}
            errors={errors}
            labels={labels}
            isSubmitting={isSubmitting}
            isValid={isValid}
            testIdRoot="create-hub"
            submitCount={submitCount}
          />
        </Form>
      )}
    </Formik>
  )
}

CreateHub.propTypes = {
  setIsDirty: PropTypes.func.isRequired
}

export default CreateHub
