import useValuesArrays from './useValuesArrays'
import ReadOnlyField from '../AttributeFormProperties/ReadOnlyField'
import PercentageField from '../AttributeFormProperties/PercentageField'
import TimeField from '../AttributeFormProperties/TimeField'
import OffNumberField from '../AttributeFormProperties/OffNumberField'
import BooleanField from '../AttributeFormProperties/BooleanField'
import offSelectField from '../AttributeFormProperties/OffSelectField'
import NullField from '../AttributeFormProperties/NullField'
import CoVTemperatureField from '../AttributeFormProperties/CoVTemperatureField'
import CoVPercentageField from '../AttributeFormProperties/CoVPercentageField'
import CoVLightField from '../AttributeFormProperties/CoVLightField'
import CoVSoundField from '../AttributeFormProperties/CoVSoundField'
import CoVColorField from '../AttributeFormProperties/CoVColorField'
import ProgressiveField from '../AttributeFormProperties/ProgressiveField'

const ValuesArrays = useValuesArrays()
const AttributeFormProperties = {}

const populateAttributeFormProperties = (fields, fieldType) => fields.map((field) => { // eslint-disable-line
  AttributeFormProperties[field] = fieldType
})

AttributeFormProperties.readOnly = ReadOnlyField

const useAttributesFormProperties = (translate) => {
  populateAttributeFormProperties(ValuesArrays.percentageValues, PercentageField)
  populateAttributeFormProperties(ValuesArrays.timeValues, TimeField)
  populateAttributeFormProperties(ValuesArrays.offValues, OffNumberField)
  populateAttributeFormProperties(ValuesArrays.offSelectValues, offSelectField)
  populateAttributeFormProperties(ValuesArrays.booleanValues, BooleanField)
  populateAttributeFormProperties(ValuesArrays.nullValues, NullField)
  populateAttributeFormProperties(ValuesArrays.readOnlyTemperatureSensors, CoVTemperatureField)
  populateAttributeFormProperties(ValuesArrays.readOnlyPercentageSensors, CoVPercentageField)
  populateAttributeFormProperties(ValuesArrays.readOnlyLightSensors, CoVLightField)
  populateAttributeFormProperties(ValuesArrays.readOnlySoundSensors, CoVSoundField)
  populateAttributeFormProperties(ValuesArrays.readOnlyColorSensors, CoVColorField)
  populateAttributeFormProperties(ValuesArrays.customValues, ProgressiveField)

  const getCurrentAttribute = (type) => {
    const attribute = Object.keys(AttributeFormProperties).includes(type) ? type : 'readOnly'
    return AttributeFormProperties[attribute](translate)
  }
  return {
    getCurrentAttribute
  }
}

export default useAttributesFormProperties
