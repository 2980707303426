import React, {
  Fragment, useState, useEffect, useRef
} from 'react'
import PropTypes from 'prop-types'

import Icon from '@mdi/react'
import {
  mdiFilterOutline, mdiFilterVariant, mdiClose, mdiCloseCircle
} from '@mdi/js'

import {
  InputContainer,
  Suggestions,
  Wrapper,
  FilterButton,
  TextInput,
  FilterPill,
  ClearButton
} from './Styles'

const Search = ({
  filters, onFilter, onSearch, placeholderText, searchTestId
}) => {
  const [selectedFilters, setFilters] = useState({})
  const [searchTerm, setSearchTerm] = useState('')
  const [showSuggestions, toggleSuggestions] = useState(false)
  const node = useRef()
  const inputRef = useRef()
  const filtersNotEmpty = Object.keys(filters).length !== 0 || filters.constructor !== Object

  const handleClick = (e) => {
    if (!node.current.contains(e.target)) {
      toggleSuggestions(false)
    }
  }

  const updateSearchTerm = (e) => {
    const { value } = e.target
    toggleSuggestions(true)
    setSearchTerm(value)
    onSearch(value)
  }

  const resetSearchField = () => {
    setSearchTerm('')
    onSearch('')
  }

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      resetSearchField()
    }
  }

  const applyFilterValue = key => (e) => {
    selectedFilters[key] = e.target.value
    const value = { ...selectedFilters }
    setFilters(value)
    onFilter(value)
  }

  const applyFilter = key => () => {
    [selectedFilters[key]] = filters[key]
    const value = { ...selectedFilters }
    setFilters(value)
    onFilter(value)
  }

  const removeFilter = key => () => {
    delete selectedFilters[key]
    const value = { ...selectedFilters }
    setFilters(value)
    onFilter(value)
  }

  const getMatchingFilters = () => Object.keys(filters).filter(filter => filter.indexOf(searchTerm) > -1)

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  return (
    <Wrapper ref={node}>
      <InputContainer onClick={() => inputRef.current.focus()} data-testid="filters">
        <Icon path={mdiFilterOutline} size={1} />
        {Object.keys(selectedFilters).map(key => (
          <FilterPill onClick={e => e.stopPropagation()} key={key}>
            <label htmlFor={key}>
              {key}:&nbsp;
              <select onChange={applyFilterValue(key)} name={key}>
                {filters[key].map(value => <option key={value}>{value}</option>)}
              </select>
              <button type="button" onClick={removeFilter(key)}><Icon path={mdiClose} size={0.7} /></button>
            </label>
          </FilterPill>
        ))}
        <TextInput ref={inputRef} role="search" type="text" value={searchTerm} onChange={updateSearchTerm} placeholder={placeholderText} data-testid={`${searchTestId}-search-field`} />
        {
          filtersNotEmpty
          && <FilterButton onClick={() => toggleSuggestions(true)}><Icon path={mdiFilterVariant} size={0.7} /> Filters</FilterButton>
        }
        {searchTerm && searchTerm !== ''
          && (
            <ClearButton
              role="button"
              tabIndex="0"
              onKeyPress={handleEnterPress}
              onClick={resetSearchField}
              data-testid="search-clear-button"
            >
              <Icon path={mdiCloseCircle} size={0.75} />
            </ClearButton>
          )
        }
      </InputContainer>
      {filtersNotEmpty && (
        <Suggestions active={showSuggestions}>
          {getMatchingFilters().length < 1 && (
            <div>There are no filters matching the search term</div>
          )}
          {getMatchingFilters().map(filter => (
            <Fragment key={filter}>
              <button type="submit" onClick={applyFilter(filter)}>{filter in selectedFilters ? `✅ ${filter}` : filter }</button>
              <br />
            </Fragment>
          ))}
        </Suggestions>
      )}
    </Wrapper>
  )
}

Search.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onFilter: PropTypes.func,
  filters: PropTypes.object,
  placeholderText: PropTypes.string.isRequired,
  searchTestId: PropTypes.string
}

Search.defaultProps = {
  filters: {},
  onFilter: () => null,
  searchTestId: undefined
}

export default Search
