import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import Icon from '@mdi/react'
import {
  mdiFileDocumentBoxOutline, mdiArrowLeft
} from '@mdi/js'

import useTranslate from '../../../Utils/Hooks/useTranslate'
import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'

import TemplatesContext from '../../../State/Templates/Context'
import PanelContext from '../../../State/Panel/Context'

import Breadcrumbs from '../../Breadcrumbs'
import PageInfo from '../../PageInfo'
import PageHeader from '../../PageHeader'
import PageCaption from '../../PageCaption'
import Panel from '../../Panel'
import AttributesTable from './AttributesTable'
import PageMetaData from '../../PageMetaData'

const Attributes = ({ setState, setOrgSelectState }) => {
  const { templateId, organizationId } = useParams()
  const history = useHistory()
  const { actions: { fetchTemplateData, setSelectedTemplate } } = useContext(TemplatesContext)
  const { actions: { resetView } } = useContext(PanelContext)
  const [execute, , , template] = useAsyncCallback(fetchTemplateData)
  const translate = useTranslate('templateAttributesPage')
  const translateMetaData = useTranslate('pageMetaData')
  const {
    name
  } = template || {}
  setState('Content/Managing_Templates.html')
  setOrgSelectState('templates')
  useEffect(() => {
    execute(templateId)
  }, []) // eslint-disable-line

  useEffect(() => {
    setSelectedTemplate(template)
  }, [template]) // eslint-disable-line
  const navigateToJobs = (e) => {
    e.preventDefault()
    resetView()
    history.push(`/organization/${organizationId}/templates`)
  }


  return (
    <>
      <PageMetaData pageMetaData={{ title: `${translateMetaData('templatesTitle')} | ${name}`, description: 'TODO' }} />
      <Breadcrumbs>
        <a href={`/organization/${organizationId}/templates`} type="button" onClick={navigateToJobs} data-testid="template-templates-link">
          <Icon path={mdiArrowLeft} size={1} />{translate('breadcrumbs.templates')}
        </a>
      </Breadcrumbs>
      <PageHeader>
        <PageInfo>
          <Icon path={mdiFileDocumentBoxOutline} size={1} />
          <PageCaption>
            <h1>{name ? `${translate('heading')} (${name})` : <Skeleton width={200} />}</h1>
            <h4>
              <span>{translate('description')}</span>
            </h4>
          </PageCaption>
        </PageInfo>
        <Panel />
      </PageHeader>
      <AttributesTable />
    </>
  )
}

Attributes.propTypes = {
  setState: PropTypes.func.isRequired,
  setOrgSelectState: PropTypes.func
}

Attributes.defaultProps = {
  setOrgSelectState: () => {}
}

export default Attributes
