import React from 'react'
import PropTypes from 'prop-types'

import { TableCell } from '../../../../Table'

const Value = ({ row }) => {
  const { original: { value, isReadOnly, displayValue } } = row
  return (
    <TableCell align="right">
      {isReadOnly || value === null
        ? <i>{displayValue}</i>
        : <>{displayValue}</>
      }
    </TableCell>
  )
}

Value.propTypes = {
  row: PropTypes.object.isRequired
}

export default Value
