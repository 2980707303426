import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@mdi/react'
import { mdiCheckCircle } from '@mdi/js'

import { Status } from '../../Styles'
import { TableCell } from '../../../../Table'
import useTranslate from '../../../../../Utils/Hooks/useTranslate'


const EnabledStatus = ({ row }) => {
  const { original: { isEnabled } } = row
  const translate = useTranslate('templateAttributesPage')
  return (
    <TableCell>
      <Status isEnabled={isEnabled} data-testid="devices-device-status">
        {isEnabled
          ? <><Icon path={mdiCheckCircle} size={0.7} />{translate('cellFormattingEnabled')}</>
          : translate('cellFormattingDisabled')
        }
      </Status>
    </TableCell>
  )
}

EnabledStatus.propTypes = {
  row: PropTypes.object.isRequired
}

export default EnabledStatus
