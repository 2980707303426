import React from 'react'

import useTranslate from '../../../Utils/Hooks/useTranslate'

import InviteUser from '../InviteUser'
import EditUser from '../EditUser'
import EditProfile from '../EditProfile'
import CreateHub from '../CreateHub'
import EditHub from '../EditHub'
import CreateOrganization from '../CreateOrganization'
import CreateJobSite from '../CreateJobSite'
import EditJobSite from '../EditJobSite/EditJobSite'
import ViewJobSite from '../ViewJobSite'
import EditOrganization from '../EditOrganization/EditOrganization'
import EditLanguage from '../EditLanguage/EditLanguage'
import ChangePassword from '../ChangePassword'
import EditZone from '../EditZone'
import EditTemplate from '../EditTemplate'
import ViewHub from '../ViewHub'
import UpdateFirmware from '../UpdateFirmware'
import ViewTemplate from '../ViewTemplate'
import ViewAttribute from '../ViewAttribute'
import EditAttributeIO from '../EditAttributeIO'
import EditAttribute from '../EditAttribute'
import ViewUser from '../ViewUser'
import ViewOrganization from '../ViewOrganization'

const PanelViewFactory = () => {
  const translate = useTranslate('panelHeader')
  const PanelViews = {}
  PanelViews.blank = {
    view: () => (<></>),
    header: ''
  }
  PanelViews['users/invite'] = {
    view: InviteUser,
    header: translate('inviteUser')
  }
  PanelViews['users/edit'] = {
    view: EditUser,
    header: translate('editUser')
  }
  PanelViews['profile/edit'] = {
    view: EditProfile,
    header: translate('editName')
  }
  PanelViews['password/change'] = {
    view: ChangePassword,
    header: translate('changePassword')
  }
  PanelViews['preferences/edit'] = {
    view: EditLanguage,
    header: translate('editLanguage')
  }
  PanelViews['device/create'] = {
    view: CreateHub,
    header: translate('createSensorHub')
  }
  PanelViews['device/edit'] = {
    view: EditHub,
    header: translate('editSensorHub')
  }
  PanelViews['device/view'] = {
    view: ViewHub,
    header: translate('viewSensorHub')
  }
  PanelViews['device/update-firmware'] = {
    view: UpdateFirmware,
    header: translate('updateFirmware')
  }
  PanelViews['organizations/create'] = {
    view: CreateOrganization,
    header: translate('createOrganization')
  }
  PanelViews['organizations/edit'] = {
    view: EditOrganization,
    header: translate('editOrganization')
  }
  PanelViews['organizations/view'] = {
    view: ViewOrganization,
    header: translate('viewOrganization')
  }
  PanelViews['jobs/create'] = {
    view: CreateJobSite,
    header: translate('createJob')
  }
  PanelViews['jobs/edit'] = {
    view: EditJobSite,
    header: translate('editJob')
  }
  PanelViews['jobs/view'] = {
    view: ViewJobSite,
    header: translate('viewJob')
  }
  PanelViews['zones/edit'] = {
    view: EditZone,
    header: translate('editZone')
  }
  PanelViews['templates/view'] = {
    view: ViewTemplate,
    header: translate('viewTemplate')
  }
  PanelViews['templates/edit'] = {
    view: EditTemplate,
    header: translate('editTemplate')
  }
  PanelViews['attributes/view'] = {
    view: ViewAttribute,
    header: translate('viewTemplate')
  }
  PanelViews['attributes/editIO'] = {
    view: EditAttributeIO,
    header: translate('editAttribute')
  }
  PanelViews['attributes/edit'] = {
    view: EditAttribute,
    header: translate('editAttribute')
  }
  PanelViews['users/view'] = {
    view: ViewUser,
    header: translate('viewUser')
  }

  const getCurrentView = (type) => {
    const panelView = Object.keys(PanelViews).includes(type) ? type : 'blank'
    return PanelViews[panelView]
  }

  return {
    getCurrentView
  }
}

export default PanelViewFactory
