import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { mdiPencilOutline } from '@mdi/js'

import PanelContext from '../../../../../State/Panel/Context'
import DeviceContext from '../../../../../State/Devices/Context'
import ButtonIcon from '../../../../ButtonIcon'

const DeviceEditButton = ({ deviceId, device }) => {
  const { actions: { changeView } } = useContext(PanelContext)
  const { actions: { setSelectedDeviceId } } = useContext(DeviceContext)
  const isConfigured = device.phase === 'configured' || device.phase === 'issue'
  const handleClick = () => {
    setSelectedDeviceId(deviceId)
    changeView(isConfigured ? 'device/view' : 'device/edit')
  }
  return <ButtonIcon onClick={handleClick} path={mdiPencilOutline} inverted data-testid="device-attributess-edit-device-button" />
}

DeviceEditButton.propTypes = {
  deviceId: PropTypes.string.isRequired,
  device: PropTypes.object.isRequired
}

export default DeviceEditButton
