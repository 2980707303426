import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@mdi/react'
import {
  mdiChevronLeft, mdiChevronRight, mdiPageFirst, mdiPageLast
} from '@mdi/js'
import { PaginationButton, StyledPagination } from './Styles'

const Pagination = ({
  canPreviousPage, canNextPage, pageOptions, pageIndex, gotoPage, nextPage, previousPage, pageCount
}) => (
  <StyledPagination>
    <div>
      <PaginationButton
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
        inverted
        data-testid="first-page-button"
      >
        <Icon path={mdiPageFirst} size={1} />
      </PaginationButton>
      <PaginationButton
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
        inverted
        data-testid="previous-page-button"
      >
        <Icon path={mdiChevronLeft} size={1} />
      </PaginationButton>
      <PaginationButton
        onClick={() => nextPage()}
        disabled={!canNextPage}
        inverted
        data-testid="next-page-button"
      >
        <Icon path={mdiChevronRight} size={1} />
      </PaginationButton>
      <PaginationButton
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
        inverted
        data-testid="last-page-button"
      >
        <Icon path={mdiPageLast} size={1} />
      </PaginationButton>
      <span>
        Page
        <strong>
          {pageIndex + 1} of {pageOptions.length}
        </strong>
      </span>
    </div>
  </StyledPagination>
)

Pagination.propTypes = {
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  pageOptions: PropTypes.array.isRequired,
  pageIndex: PropTypes.number.isRequired,
  gotoPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired
}

export default Pagination
