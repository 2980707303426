import { object, ref } from 'yup'
import {
  RequiredField,
  EmailField,
  ConfirmPasswordField,
  PasswordField
} from '../Helpers'
import useTranslateWithLanguage from '../../Hooks/useTranslateWithLanguage'

const CreateAccountSchema = (language) => {
  const translate = useTranslateWithLanguage('formValidation', language)
  return object().shape({
    firstName: RequiredField(translate('firstNameIsRequired')),
    lastName: RequiredField(translate('lastNameIsRequired')),
    email: EmailField().required(translate('emailIsRequired')),
    password: PasswordField(translate('passwordMustHaveEightCharacters')).required(translate('passwordIsRequired')),
    confirmPassword: ConfirmPasswordField([ref('password')], translate('passwordsMustMatch')).required(translate('passwordIsRequired'))
  })
}

export default CreateAccountSchema
