import React from 'react'
import { mdiInformationOutline } from '@mdi/js'

import useTranslate from '../../../../Utils/Hooks/useTranslate'

import FieldSet from '../../../Form/Fieldset'
import Toggle from '../../../Formik/Toggle'

const EnableInConfig = () => {
  const translate = useTranslate('editAttributePanel')
  return (
    <FieldSet>
      <Toggle
        name="isEnabled"
        label={translate('enableInConfig')}
        hasTooltip
        tooltipContent={translate('enableInConfigTooltip')}
        tooltipIcon={mdiInformationOutline}
        toggleTestid="toggle-enable-in-config"
      />
    </FieldSet>
  )
}

export default EnableInConfig
