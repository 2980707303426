import styled from 'styled-components'

export const BackgroundImageContainer = styled.div(({ theme }) => ({
  display: 'none',
  [`@media(min-width: ${theme.breakpoints.lg.minWidth})`]: {
    display: 'block',
    flex: 0.55,
    position: 'relative',
    width: 'calc(100vw - 36rem)',
    height: '100%',
    overflow: 'hidden',
    img: {
      position: 'absolute',
      right: 0,
      maxWidth: 'none',
      maxHeight: '100%'
    }
  },
  [`@media(min-width: ${theme.breakpoints.xl.minWidth})`]: {
    flex: 0.60
  }
}))
