import styled from 'styled-components'

export const DropdownContainer = styled.div(({ styles }) => ({
  ...styles,
  position: 'relative'
}))

export const DropdownButton = styled.button({
  padding: 0,
  width: '100%',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  '&:focus': {
    outline: 'none'
  }
})

export const DropdownContents = styled.div(({ theme, styles }) => ({
  ...styles,
  position: 'absolute',
  padding: 8,
  borderRadius: 2,
  backgroundColor: 'white',
  boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  zIndex: 10,
  '& > *': {
    padding: '8px',
    fontSize: '1rem',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textDecoration: 'none',
    color: theme.colors.n09,
    cursor: 'pointer',
    svg: {
      color: theme.colors.n09
    }
  },
  '& > a, & > button': {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.colors.n02
    }
  },
  '& > button': {
    border: 'none'
  },
  '& > hr': {
    margin: '8px',
    border: 0,
    height: 1,
    padding: 0,
    cursor: 'auto',
    background: theme.colors.n05
  }
}))
