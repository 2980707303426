import React from 'react'
import PropTypes from 'prop-types'
import { mdiHelpCircleOutline } from '@mdi/js'
import Icon from '@mdi/react'
import Config from '../../Config'
import { StyledLink } from './Styles'
import ExternalLink from '../ExternalLink'

const SupportLink = ({ endpoint }) => (
  <ExternalLink href={`${Config.support.domain}/${endpoint}`} testId="support-link-button" Component={StyledLink}>
    <Icon path={mdiHelpCircleOutline} size={1} />
  </ExternalLink>
)


SupportLink.propTypes = {
  endpoint: PropTypes.string.isRequired
}

export default SupportLink
