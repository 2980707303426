import React, {
  useState,
  useEffect,
  useContext,
  useMemo
} from 'react'
import PropTypes from 'prop-types'
import LZString from 'lz-string'
import { useParams } from 'react-router-dom'

import useSearch from '../../../Utils/Hooks/useSearch'
import useTranslate from '../../../Utils/Hooks/useTranslate'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'
import useSorter from '../../../Utils/Hooks/useSorter'
import useLocalStorage from '../../../Utils/Hooks/useLocalStorage'

import DevicesContext from '../../../State/Devices/Context'
import PanelContext from '../../../State/Panel/Context'
import ModalContext from '../../../State/Modal/Context'
import FilterWrapper from '../../FilterWrapper'
import { Tab, TabGroup } from '../../TabGroup'
import Search from '../../Search'
import Table from '../../Table'
import Select from '../../Select'
import {
  DeviceName, NetworkSettings, DeviceNumber, FirmwareVersion, DeviceStatus, LastNetworkSync, EditButton
} from './Components'
import Toggle from '../../Form/Toggle'
import { ToggleWrapper } from './Styles'

const searchFields = ['displayName']

const DevicesTable = ({
  namingConvention, setSelectedCheckboxes, status, setStatus
}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [phase, setPhase] = useState('all')
  const {
    selectors: {
      getDevices, isLoading, hasError, getErrorCode
    },
    actions: { fetchDevices, setFilters }
  } = useContext(DevicesContext)
  const { actions: { changeView, resetView }, selectors: { dataIsStale } } = useContext(PanelContext)
  const { selectors: { dataIsStale: modalDataIsStale } } = useContext(ModalContext)
  const { jobsiteId } = useParams()
  const [, setDevicesList] = useLocalStorage('device_list', null)
  const activeDevices = getDevices() && getDevices().filter(device => device.status === 'active')
  const archivedDevices = getDevices() && getDevices().filter(device => device.status === 'inactive')
  const results = useSearch(status === 'inactive' ? archivedDevices : activeDevices, searchQuery, searchFields)
  const [isExpanded, setIsExpanded] = useState(false)
  const isFiltered = !!searchQuery || phase !== 'all'
  const translate = useTranslate('devicesPage')
  const translateTable = useTranslate('tableFiltering')
  const sortHubs = useSorter('hubs')
  useErrorNotifier(hasError(), getErrorCode())

  const phaseOptions = [
    {
      value: 'all',
      label: translateTable('statusAll')
    },
    {
      value: 'configured',
      label: translateTable('statusConfigured')
    },
    {
      value: 'notConfigured',
      label: translateTable('statusNotConfigured')
    }
  ]

  useEffect(() => {
    fetchDevices(jobsiteId, { phase }, namingConvention)
    setFilters({ phase })
    if (dataIsStale() || modalDataIsStale()) {
      resetView()
    }
  }, [phase, dataIsStale(), modalDataIsStale()]) // eslint-disable-line

  useEffect(() => {
    if (getDevices().length !== 0) {
      const devicesList = JSON.stringify(getDevices())
      const compressedDevicesList = LZString.compress(devicesList)
      setDevicesList(compressedDevicesList)
    }
  }, [isLoading()]) // eslint-disable-line

  const onSearch = (value) => {
    setSearchQuery(value)
  }

  const handleCreateClick = () => {
    changeView('device/create')
  }

  const handleArchiveTabClick = () => {
    setStatus('inactive')
    setIsExpanded(false)
    document.getElementById('showZones').checked = false
  }

  const emptyList = {
    filteredMessage: translate('noResults'),
    unfilteredMessage: translate('emptyListMessage'),
    emptyArchive: translate('emptyArchive'),
    ctaLabel: translate('createSensorHubButton'),
    ctaFunc: handleCreateClick
  }

  /* eslint-disable react/prop-types */
  const columns = useMemo(
    () => [
      {
        id: 'zoneId',
        Header: translate('tableHubNameColumn'),
        accessor: 'zoneName',
        Cell: DeviceName,
        sortType: sortHubs,
        width: 184,
        flex: true
      },
      {
        Header: translate('tableNetworkColumn'),
        Cell: NetworkSettings,
        accessor: row => (row.config && row.config.network.type !== 'dhcp' ? row.config.network.ipAddress : 0),
        Aggregated: () => null,
        align: 'right',
        width: 120
      },
      {
        Header: translate('tableDeviceIdColumn'),
        Cell: DeviceNumber,
        accessor: 'config.bacnet.deviceNumber',
        Aggregated: () => null,
        align: 'right',
        width: 120
      },
      {
        Header: translate('tableFirmwareColumn'),
        Cell: FirmwareVersion,
        Aggregated: () => null,
        accessor: row => (row.config && row.config.firmware.isEnabled ? row.config.firmware.version : 0),
        align: 'left',
        width: 168
      },
      {
        Header: translate('tableStatusColumn'),
        accessor: 'phase',
        Cell: DeviceStatus,
        Aggregated: () => null,
        width: 144
      },
      {
        Header: translate('tableLastNetworkSyncColumn'),
        accessor: row => (row.lastSeenAt ? row.lastSeenAt.seconds : 0),
        Aggregated: () => null,
        Cell: LastNetworkSync,
        width: 168
      },
      {
        Header: translate('tableDetailsColumn'),
        id: 'edit',
        Cell: EditButton,
        width: 80
      }
    ], [isExpanded] // eslint-disable-line
    /* eslint-enable react/prop-types */
  )

  return (
    <>
      <TabGroup>
        <Tab active={status === 'active'} type="button" onClick={() => setStatus('active')} data-testid="devices-active-tab">{translateTable('active')}</Tab>
        <Tab active={status === 'inactive'} type="button" onClick={handleArchiveTabClick} data-testid="devices-archive-tab">{translateTable('archived')}</Tab>
      </TabGroup>
      <FilterWrapper>
        <Select name={translateTable('status')} options={phaseOptions} onChange={item => setPhase(item.value)} data-testid="device-status-filter" optionsTestIDRoot="device-status" />
        <Search searchFields={searchFields} onSearch={onSearch} placeholderText={translateTable('filterByName')} />
        <ToggleWrapper>
          <Toggle name="showZones" label={translate('showZonesToggleLabel')} onChange={() => setIsExpanded(!isExpanded)} data-testid="show-zones-toggle" disabled={status === 'inactive' && true} />
        </ToggleWrapper>
      </FilterWrapper>
      <Table
        isLoading={isLoading()}
        columns={columns}
        data={results}
        isExpanded={isExpanded}
        isFiltered={isFiltered}
        emptyList={emptyList}
        status={status}
        setSelectedCheckboxes={setSelectedCheckboxes}
        initialSortBy="zoneId"
        idField="deviceId"
        tableCanBeGrouped
      />
    </>
  )
}

DevicesTable.propTypes = {
  namingConvention: PropTypes.string,
  setSelectedCheckboxes: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
}

DevicesTable.defaultProps = {
  namingConvention: 'prefix'
}

export default DevicesTable
