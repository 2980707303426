import { types } from './Reducer'

const getActions = (state, dispatch) => {
  const setSelectedCheckboxes = (selectedCheckboxes) => {
    dispatch({ type: types.SET_SELECTED_CHECKBOXES, payload: selectedCheckboxes })
  }

  return {
    setSelectedCheckboxes
  }
}

export default getActions
