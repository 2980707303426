const useValuesArrays = () => ({
  nullValues: ['programmableIRCode1', 'programmableIRCode2', 'programmableIRCode3', 'programmableIRCode4', 'programmableIRCode5', 'programmableIRCode6', 'programmableIRCode7', 'programmableIRCode8', 'programmableIRCode9', 'programmableIRCode10', 'programmableIRCode11', 'programmableIRCode12'],
  offSelectValues: ['bleMaxTransmitPower'],
  offValues: ['irRepeats', 'lightRingPatternRepeats', 'soundRepeats'],
  percentageValues: ['motionSensitivity', 'lightRingBrightness', 'soundVolume', 'occupancySensitivity', 'lightRingRedComponent', 'lightRingBlueComponent', 'lightRingGreenComponent'],
  timeValues: ['occupancyAcousticBaselineUpdatePeriod', 'occupancyAcousticRetriggerPeriod', 'occupancyInactivityPeriod', 'internalOccupancyLatchTime'],
  booleanValues: ['enableBle', 'activatePostStatusMode', 'customLightRingColors'],
  ioValues: ['ioChannel1', 'ioChannel2'],
  customValues: ['internalAcousticOccupancyThreshold'],
  readOnlyPercentageSensors: ['occupantHumidity', 'internalHumidity'],
  readOnlyTemperatureSensors: ['occupantTemperature', 'irTemperature', 'internalTemperature'],
  readOnlyColorSensors: ['colorTemperature'],
  readOnlyLightSensors: ['lightLevel'],
  readOnlySoundSensors: ['soundLevel']
})

export default useValuesArrays
