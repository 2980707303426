import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const PageContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  padding: '1rem .5rem'
})

export const NotFoundHeader = styled('h2')(({ theme }) => ({
  fontSize: '1.5rem',
  margin: '1.5rem 0 .5rem',
  lineHeight: '2rem',
  letterSpacing: '0.5px',
  fontWeight: '400',
  color: theme.colors.n07,
  textAlign: 'center'
}))

export const HttpCode = styled('p')(({ theme }) => ({
  color: theme.colors.n06,
  textTransform: 'uppercase',
  fontSize: '.875rem',
  lineHeight: '1.275rem',
  lettterSpacing: '0.2px',
  marginBottom: '1.5rem',
  textAlign: 'center'
}))

export const ReturnLink = styled(Link)(({ theme }) => ({
  color: theme.colors.b01,
  fontSize: '.875rem',
  lineHeight: '1.275rem',
  lettterSpacing: '0.2px',
  textDecoration: 'none',
  fontWeight: '500',
  textAlign: 'center'
}))
