import { object, number } from 'yup'
import useTranslateWithLanguage from '../../Hooks/useTranslateWithLanguage'

import { CustomName } from '../Helpers'

const AttributesTimeField = (language) => {
  const translate = useTranslateWithLanguage('formValidation', language)
  return object().shape({
    name: CustomName(translate),
    value: number().required(translate('timeRequired')).min(0, translate('timeMin')).max(65536, translate('timeMax'))
  })
}

export default AttributesTimeField
