import { string } from 'yup'
import RequiredField from './RequiredField'

const ConditionalRequirement = (conditionalFieldKey, conditionalFieldValue, requiredMessage) => string().when(conditionalFieldKey, {
  is: conditionalFieldValue,
  then: RequiredField(requiredMessage),
  otherwise: string().notRequired()
})

export default ConditionalRequirement
