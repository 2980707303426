const initialState = {
  loading: true,
  error: {
    status: false,
    code: null
  },
  data: null,
  selectedJobsiteId: null,
  selectedJobsitesList: null
}

const types = {
  FETCH_JOBSITES_PENDING: 'FETCH_JOBSITES_PENDING',
  FETCH_JOBSITES_SUCCESS: 'FETCH_JOBSITES_SUCCESS',
  FETCH_JOBSITES_FAILURE: 'FETCH_JOBSITES_FAILURE',
  SET_SELECTED_JOBSITE_ID: 'SET_SELECTED_JOBSITE_ID',
  SET_SELECTED_JOBSITES_LIST: 'SET_SELECTED_JOBSITES_LIST',
  UPDATE_JOBSITE_PENDING: 'UPDATE_JOBSITE_PENDING',
  UPDATE_JOBSITE_SUCCESS: 'UPDATE_JOBSITE_SUCCESS',
  UPDATE_JOBSITE_FAILURE: 'UPDATE_JOBSITE_FAILURE'
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case (types.FETCH_JOBSITES_PENDING):
    case (types.UPDATE_JOBSITE_PENDING):
      return {
        ...state,
        loading: true
      }
    case (types.FETCH_JOBSITES_SUCCESS):
    case (types.UPDATE_JOBSITES_SUCCESS):
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: {
          status: false,
          code: null
        }
      }
    case (types.FETCH_JOBSITES_FAILURE):
    case (types.UPDATE_JOBSITES_FAILURE):
      return {
        ...state,
        loading: false,
        data: null,
        error: {
          status: true,
          code: action.payload
        }
      }
    case (types.SET_SELECTED_JOBSITE_ID):
      return {
        ...state,
        selectedJobsiteId: action.id
      }
    case types.SET_SELECTED_JOBSITES_LIST:
      return {
        ...state,
        selectedJobsitesList: action.payload
      }
    default:
      return state
  }
}

export {
  reducer,
  types,
  initialState
}
