import React, { useContext } from 'react'

import useTranslate from '../../../Utils/Hooks/useTranslate'

import ModalContext from '../../../State/Modal/Context'
import PanelContext from '../../../State/Panel/Context'
import ModalContent from '../../Modal/Components/ModalContent'
import ModalFooter from '../../Modal/Components/ModalFooter'
import OrganizationContext from '../../../State/Organization/Context'
import Button from '../../Button'
import Ribbon from '../../Ribbon'

const ResendInviteLinkSent = () => {
  const { actions: { closeModal } } = useContext(ModalContext)
  const { actions: { closePanel } } = useContext(PanelContext)
  const { selectors: { getSelectedUser } } = useContext(OrganizationContext)
  const selectedUser = getSelectedUser()
  const translate = useTranslate('resendInviteSent')

  const { email } = selectedUser

  const handleClick = () => {
    closeModal()
    closePanel()
  }

  return (
    <>
      <Ribbon type="success">{translate('successRibbon')}</Ribbon>
      <ModalContent>
        <p>
          {translate('description')} <strong>{email}</strong>.
        </p>
      </ModalContent>
      <ModalFooter>
        <Button onClick={handleClick} inverted data-testid="resend-invite-button-close">{translate('closeButton')}</Button>
      </ModalFooter>
    </>
  )
}

export default ResendInviteLinkSent
