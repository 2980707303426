import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import useOnClickOutside from '../../Utils/Hooks/useOnClickOutside'
import { DropdownContainer, DropdownButton, DropdownContents } from './Styles'

export const clickOutsideHandler = (buttonRef, setModalOpen) => (event) => {
  if (!buttonRef.current.contains(event.target)) {
    setModalOpen(false)
  }
}

const Dropdown = ({
  ButtonComponent, name, selectedOption, containerStyles, updateParent, shouldClose, hasOnlyOneOrg, contentStyles, children, ...rest
}) => {
  const modalRef = useRef(null)
  const buttonRef = useRef(null)
  const [isModalOpen, setModalOpen] = useState(false)
  useOnClickOutside(modalRef, clickOutsideHandler(buttonRef, setModalOpen))

  useEffect(() => {
    if (shouldClose) {
      setModalOpen(false)
    }
  }, [shouldClose])

  const handleClick = () => {
    setModalOpen(!isModalOpen)
    updateParent(false)
  }

  return (
    <DropdownContainer styles={containerStyles} data-testid="dropdown-container">
      <DropdownButton
        type="button"
        role="listbox"
        tabIndex="0"
        ref={buttonRef}
        onClick={handleClick}
        disabled={hasOnlyOneOrg}
        data-testid="dropdown-button-container"
        {...rest}
      >
        <ButtonComponent isOpen={isModalOpen} name={name} selectedOption={selectedOption} data-testid="dropdown-button" />
      </DropdownButton>
      {isModalOpen && (
        <DropdownContents ref={modalRef} styles={contentStyles} data-testid="dropdown-contents">
          {children}
        </DropdownContents>
      )}
    </DropdownContainer>
  )
}

Dropdown.propTypes = {
  ButtonComponent: PropTypes.func.isRequired,
  name: PropTypes.string,
  selectedOption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  updateParent: PropTypes.func,
  shouldClose: PropTypes.bool,
  containerStyles: PropTypes.object,
  contentStyles: PropTypes.object,
  children: PropTypes.node,
  hasOnlyOneOrg: PropTypes.bool
}

Dropdown.defaultProps = {
  name: '',
  selectedOption: '',
  containerStyles: {},
  contentStyles: undefined,
  updateParent: () => null,
  shouldClose: false,
  children: undefined,
  hasOnlyOneOrg: false
}

export default Dropdown
