import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useTranslate from '../../../Utils/Hooks/useTranslate'

import OrganizationContext from '../../../State/Organization/Context'
import UserContext from '../../../State/User/Context'
import ModalContext from '../../../State/Modal/Context'
import TableContext from '../../../State/Table/Context'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'

import ModalContent from '../../Modal/Components/ModalContent'
import ModalFooter from '../../Modal/Components/ModalFooter'
import SubmitButton from '../../Modal/Components/SubmitButton'
import Button from '../../Button'

const UsersArchive = () => {
  const { organizationId } = useParams()
  const { actions: { deactivateUsers, clearCheckedUsers } } = useContext(OrganizationContext)
  const { selectors: { getSelectedCheckboxes } } = useContext(TableContext)
  const { actions: { updateStatus } } = useContext(UserContext)
  const { actions: { closeModal, setSubmitted } } = useContext(ModalContext)
  const checkedUsers = getSelectedCheckboxes()
  const shouldActivate = checkedUsers.some(user => user.status === 'inactive')
  const translate = useTranslate(!shouldActivate ? 'deactivateUsersModal' : 'reactivateUsersModal')
  const [execute, , isLoading, result, hasError] = useAsyncCallback(deactivateUsers)
  const [executeUpdateStatus, , isStatusLoading, statusResult, hasStatusError] = useAsyncCallback(updateStatus)
  useErrorNotifier(hasError, result)
  useErrorNotifier(hasStatusError, statusResult)
  const labels = {
    submittingLabel: translate('labels.submitting'),
    buttonLabel: translate('labels.submit')
  }

  const notificationMessages = {
    operationSuccess: translate('notifications.success'),
    operationFailure: translate('notifications.fail')
  }

  const handleClick = async () => {
    await execute(organizationId, checkedUsers, notificationMessages)
    await executeUpdateStatus(organizationId, checkedUsers)
    closeModal()
    setSubmitted()
    clearCheckedUsers()
  }

  return (
    <>
      <ModalContent>
        <p>{translate('copy')}</p>
        {!shouldActivate && <ReactMarkdown>{translate('invitesCopy')}</ReactMarkdown>}
      </ModalContent>
      <ModalFooter>
        <Button onClick={closeModal} inverted data-testid="remove-invite-link-cancel">{translate('labels.cancel')}</Button>
        <SubmitButton handleSubmit={handleClick} labels={labels} isSubmitting={isLoading || isStatusLoading} testIdRoot="remove-invite-link" />
      </ModalFooter>
    </>
  )
}

export default UsersArchive
