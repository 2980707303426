import React, { useContext, useEffect } from 'react'
import { useField } from 'formik'
import PropTypes from 'prop-types'

import PanelContext from '../../../State/Panel/Context'
import ModalContext from '../../../State/Modal/Context'
import ToggleInput from '../../Form/Toggle'

const Toggle = ({ confirmModal, toggleTestid, ...props }) => {
  const { selectors: { isConfirmed } } = useContext(PanelContext)
  const { actions: { changeModalView } } = useContext(ModalContext)
  const [field, meta, helpers] = useField({ ...props, type: 'checkbox' })
  const hasError = meta.touched && meta.error
  const shouldConfirm = confirmModal && meta.value && !isConfirmed()

  useEffect(() => {
    if (confirmModal && isConfirmed()) {
      helpers.setValue(!meta.value)
    }
  }, [isConfirmed()]) //eslint-disable-line

  return (
    <ToggleInput
      onChange={shouldConfirm ? () => changeModalView(confirmModal) : field.onChange}
      checked={meta.value}
      toggleTestId={toggleTestid}
      {...props}
      {...(hasError ? { error: meta.error } : {})}
    />
  )
}

Toggle.propTypes = {
  toggleTestid: PropTypes.string,
  confirmModal: PropTypes.string
}

Toggle.defaultProps = {
  toggleTestid: null,
  confirmModal: null
}

export default Toggle
