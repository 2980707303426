import React from 'react'
import PropTypes from 'prop-types'

import useTranslate from '../../../../Utils/Hooks/useTranslate'

import Fieldset from '../../../Form/Fieldset'
import Text from '../../../Formik/Text'

const BLEPin = ({ submitCount }) => {
  const translate = useTranslate('configForm')
  const onInput = (e) => {
    e.target.value = e.target.value.slice(0, 6)
  }
  return (
    <Fieldset heading={translate('securitySettings.heading')}>
      <Text
        label={translate('securitySettings.bluetoothPinInputLabel')}
        name="blePin"
        placeholder={translate('securitySettings.bluetoothPinInputPlaceholder')}
        pattern="[0-9]*"
        data-testid="jobsite-ble-pin"
        submitCount={submitCount}
        textHelper={translate('securitySettings.bluetoothPinTextHelper')}
        onInput={onInput}
      />
    </Fieldset>
  )
}

BLEPin.propTypes = {
  submitCount: PropTypes.number.isRequired
}

export default BLEPin
