import Styled from 'styled-components'

export const Container = Styled.div(() => ({
  margin: '0 0 1.5rem'
}))

export const StyledSelect = Styled.select(({ theme }) => ({
  margin: '0 0 .5rem',
  padding: '0 .5rem',
  height: '40px',
  width: '100%',
  border: 'none',
  color: theme.colors.n08,
  fontWeight: '400',
  borderRadius: '0',
  backgroundColor: 'transparent',
  borderBottom: `1px solid ${theme.colors.n08}`,
  ':focus': {
    outline: 'none'
  },
  ':disabled': {
    color: theme.colors.n06,
    borderBottomColor: theme.colors.n06,
    backgroundColor: theme.colors.n04,
    cursor: 'not-allowed'
  },
  '::placeholder': {
    color: theme.colors.n05
  },
  ':-moz-focusring': {
    color: 'transparent',
    textShadow: '0 0 0 #000'
  }
}))

export const Label = Styled.label(({ theme }) => ({
  display: 'block',
  color: theme.colors.n09,
  fontSize: '.875rem',
  fontWeight: 500,
  letterSpacing: '0.2px'
}))
