import React, { useState } from 'react'
import PropTypes from 'prop-types'

import FieldSet from '../../Form/Fieldset'
import Toggle from '../Toggle'
import LinkToggle from '../../Form/LinkToggle'

import { ExpandControlContainer, ChildrenContainer } from './Styles'

const ExpandGroup = ({
  type, heading, children, expandCondition, confirmModal, toggleValue, toggleName, hasBorder, testId, toggleTestid, ...rest
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleIsExpanded = () => setIsExpanded(!isExpanded)
  const isToggle = type === 'toggle'
  return (
    <FieldSet hasBorder={hasBorder} isExpandGroup isToggle={isToggle}>
      <ExpandControlContainer isToggle={isToggle} isExpandGroup>
        {isToggle ? (
          <Toggle
            label={heading}
            value={toggleValue}
            name={toggleName}
            confirmModal={confirmModal}
            toggleTestid={toggleTestid}
            {...rest}
          />
        ) : (
          <LinkToggle
            label={heading}
            isExpanded={isExpanded}
            onClick={toggleIsExpanded}
            testId={testId}
            {...rest}
          />
        )}
      </ExpandControlContainer>
      {(expandCondition || isExpanded) && (
        <ChildrenContainer isToggle={isToggle}>
          {children}
        </ChildrenContainer>
      )}
    </FieldSet>
  )
}

ExpandGroup.propTypes = {
  type: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array
  ]).isRequired,
  expandCondition: PropTypes.bool,
  confirmModal: PropTypes.string,
  toggleValue: PropTypes.string,
  toggleName: PropTypes.string,
  hasBorder: PropTypes.bool,
  testId: PropTypes.string,
  toggleTestid: PropTypes.string
}

ExpandGroup.defaultProps = {
  toggleValue: '',
  toggleName: '',
  hasBorder: true,
  expandCondition: false,
  confirmModal: null,
  testId: null,
  toggleTestid: null
}

export default ExpandGroup
