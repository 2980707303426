import { object, number } from 'yup'
import useTranslateWithLanguage from '../../Hooks/useTranslateWithLanguage'

import { CustomName, Reporting } from '../Helpers'

const AttributesTemperatureField = (language) => {
  const translate = useTranslateWithLanguage('formValidation', language)
  const validation = () => number().required(translate('covRequired')).min(0, translate('covMin')).max(10, translate('covMax'))
  return object().shape({
    name: CustomName(translate),
    reporting: Reporting(translate, validation)
  })
}

export default AttributesTemperatureField
