import React from 'react'
import PropTypes from 'prop-types'

import { ButtonContainer } from './Styles'

const ButtonGroup = ({ children, ...rest }) => (
  <ButtonContainer data-testid="button-container" {...rest}>
    {children}
  </ButtonContainer>
)

ButtonGroup.propTypes = {
  children: PropTypes.node.isRequired
}

export default ButtonGroup
