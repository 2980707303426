import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'

import useTranslate from '../../../../../Utils/Hooks/useTranslate'

const MacAddress = ({ macAddress, isLoading }) => {
  const translate = useTranslate('attributesPage')
  if (isLoading) {
    return <Skeleton width={200} />
  }
  if (macAddress === '') {
    return <span><i>{translate('missingMacAddress')}</i></span>
  }

  return <span>{macAddress}</span>
}

MacAddress.propTypes = {
  macAddress: PropTypes.string,
  isLoading: PropTypes.bool
}

MacAddress.defaultProps = {
  macAddress: null,
  isLoading: false
}

export default MacAddress
