import getSorter from './getSorters'

const sortEmail = (rowA, rowB) => {
  const a = rowA.original.email.toLowerCase()
  const b = rowB.original.email.toLowerCase()

  return getSorter('email', a, b)
}

export default sortEmail
