import styled from 'styled-components'

export const WarningText = styled.span({
  marginLeft: '1rem'
})

export const ConfirmationLabel = styled.strong({
  fontSize: '.875rem',
  fontWeight: '500'
})

export const ConfirmationHeader = styled.p({
  marginBottom: '1.5rem !important'
})


export const CheckboxGroupContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  padding: '1rem 0 1.5rem'
})

export const CheckboxContainer = styled.div({
  padding: '.5rem 0 0'
})

export const ConfirmationFooter = styled.p({
  marginBottom: '1.5rem !important'
})
