import React from 'react'
import PropTypes from 'prop-types'
import useTranslate from '../../../../../Utils/Hooks/useTranslate'

const AttributeName = ({ row, template }) => {
  const translate = useTranslate('attributes')
  const translateAttributesPage = useTranslate('attributesPage')
  const {
    original: {
      attributeId,
      name,
      isCustomName,
      value
    }
  } = row
  let displayName = name
  if (!isCustomName) {
    displayName = translate(attributeId)
  }
  const { namingConvention: { isEnabled, value: namingConventionValue } } = template
  displayName = value && value.channelType ? `${displayName} (${translateAttributesPage(`channelType.${value.channelType}`)})` : displayName

  if (!isEnabled) {
    return (<p>{displayName}</p>)
  }
  return <>{namingConventionValue === 'prefix' ? (<p>{`%hubname% - ${displayName}`}</p>) : (<p>{`${displayName} - %hubname%`}</p>)}</>
}

AttributeName.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      attributeId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      isCustomName: PropTypes.bool.isRequired,
      value: PropTypes.object
    }).isRequired
  }).isRequired,
  template: PropTypes.shape({
    namingConvention: PropTypes.shape({
      isEnabled: PropTypes.bool,
      value: PropTypes.string
    })
  })
}

AttributeName.defaultProps = {
  template: {
    namingConvention: {
      isEnabled: false,
      value: undefined
    }
  }
}

export default AttributeName
