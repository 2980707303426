
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import Config from '../../Config'

const userClient = {
  getIdToken() {
    return firebase.auth().currentUser.getIdToken()
  },
  async fetchFirmware() {
    const url = `${Config.firmware.host}/list?key=${Config.firmware.apiKey}`

    const token = await this.getIdToken()
    return fetch(url, {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(data => data.json())
  }
}

export default userClient
