import JobsiteClient from './Client'
import { types } from './Reducer'

const getActions = (state, dispatch) => {
  const fetchJobsites = async (org, filters = {}) => {
    try {
      dispatch({ type: types.FETCH_JOBSITES_PENDING })
      const response = await JobsiteClient.getJobsitesData(org, filters)
      dispatch({ type: types.FETCH_JOBSITES_SUCCESS, payload: response })
    } catch (error) {
      dispatch({ type: types.FETCH_JOBSITES_FAILURE, payload: error })
    }
  }
  const fetchJobsite = jobsiteId => JobsiteClient.fetchJobsite(jobsiteId)
  const createJobsite = formData => JobsiteClient.createJobsite(formData)
  const setSelectedJobsiteId = id => dispatch({ type: types.SET_SELECTED_JOBSITE_ID, id })
  const setSelectedJobsitesList = selectedJobsitesList => dispatch({ type: types.SET_SELECTED_JOBSITES_LIST, payload: selectedJobsitesList })
  const updateJobsite = async (jobsiteId, formData) => {
    try {
      dispatch({ type: types.UPDATE_JOBSITE_PENDING })
      const response = await JobsiteClient.updateJobSite(jobsiteId, formData)
      dispatch({ type: types.UPDATE_JOBSITE_SUCCESS, payload: response })
    } catch (error) {
      dispatch({ type: types.UPDATE_JOBSITE_FAILURE, payload: error })
    }
  }
  const fetchJobsiteZones = jobsiteId => JobsiteClient.fetchJobsiteZones(jobsiteId)
  const fetchJobsiteTemplates = organizationId => JobsiteClient.fetchJobsiteTemplates(organizationId)
  const toggleJobsiteStatus = jobsites => JobsiteClient.toggleJobsiteStatus(jobsites)
  const deleteJobsites = jobsites => JobsiteClient.deleteJobsites(jobsites)
  return {
    fetchJobsites,
    fetchJobsite,
    createJobsite,
    setSelectedJobsiteId,
    setSelectedJobsitesList,
    fetchJobsiteZones,
    updateJobsite,
    fetchJobsiteTemplates,
    toggleJobsiteStatus,
    deleteJobsites
  }
}


export default getActions
