import React from 'react'
import PropTypes from 'prop-types'

import Text from '../../../../Components/Formik/Text'

const TimeField = ({ translate }) => (
  <>
    <Text
      name="value"
      textHelper={translate('labels.timeHelper')}
      label={translate('labels.value')}
      accessoryText={translate('labels.timeAccessory')}
      type="number"
      min="0"
      max="65536"
      data-testid="time-input"
    />
  </>
)

TimeField.propTypes = {
  translate: PropTypes.func.isRequired
}

export default TimeField
