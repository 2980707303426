import styled from 'styled-components'

export const PageContainer = styled.div({
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'row',
  overflowY: 'auto'
})

const SideNavigationStyles = styled.nav(({ theme }) => ({
  minWidth: 216,
  maxWidth: 216,
  minHeight: '100vh',
  backgroundColor: theme.colors.n03
}))

export const SideNavigation = styled(SideNavigationStyles).attrs({
  'aria-label': 'Main Navigation'
})``

export const LogoContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 120,
  '& > div': {
    marginBottom: 0
  }
})

export const BodyContainer = styled.main(({ theme }) => ({
  width: '100%',
  padding: '0 32px',
  [`@media(min-width: ${theme.breakpoints.md.minWidth})`]: {
    width: 'calc(100% - 216px)'
  }
}))

export const NavMenu = styled.ul({
  margin: 0,
  padding: 0,
  listStyleType: 'none'
})

export const NavMenuItem = styled.li(({ theme }) => ({
  fontSize: '1rem',
  lineHeight: '24px',
  letterSpacing: '0.2px',
  color: theme.colors.n08,
  'a, button': {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    padding: '16px 0',
    paddingLeft: '24px',
    textDecoration: 'none',
    color: theme.colors.n08,
    svg: {
      marginRight: 28,
      fill: theme.colors.n08
    },
    '&: hover': {
      backgroundColor: theme.colors.n04
    }
  },
  '.active': {
    fontWeight: 500,
    color: theme.colors.b01,
    backgroundColor: 'white',
    svg: {
      fill: theme.colors.b01
    },
    '&: hover': {
      cursor: 'default',
      backgroundColor: 'white'
    }
  }
}))

export const SignOutButton = styled.button(({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  fontFamily: theme.fonts.roboto,
  border: 'none',
  backgroundColor: theme.colors.n03,
  cursor: 'pointer'
}))


export const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'baseline',
  float: 'right'
})
