import { object, ref } from 'yup'
import {
  ConfirmPasswordField,
  PasswordField,
  ConditionalRequirement
} from '../Helpers'
import useTranslateWithLanguage from '../../Hooks/useTranslateWithLanguage'

const PasswordChangeSchema = (language) => {
  const translate = useTranslateWithLanguage('formValidation', language)

  return object().shape({
    currentPassword: ConditionalRequirement('isTokenFresh', false, translate('currentPasswordIsRequired')),
    newPassword: PasswordField(translate('passwordMustHaveEightCharacters')).required(translate('passwordIsRequired')),
    confirmPassword: ConfirmPasswordField([ref('newPassword')], translate('passwordsMustMatch')).required(translate('passwordIsRequired'))
  })
}

export default PasswordChangeSchema
