import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { mdiInformationOutline } from '@mdi/js'

import useTranslate from '../../../../Utils/Hooks/useTranslate'

import ExpandGroup from '../../../Formik/ExpandGroup'
import Select from '../../../Formik/Select'
import Fieldset from '../../../Form/Fieldset'

const Firmware = ({
  values, firmwares, setFieldValue, isEditPanel, status
}) => {
  const translate = useTranslate('configForm')
  const firmwareSort = firmwares ? firmwares.sort((a, b) => b.path.localeCompare(a.path, undefined, { numeric: true, sensitivity: 'base' })) : []

  const firmwareOptions = firmwareSort.map(
    (firmware, index) => ({ value: firmware.path, label: index === 0 ? `${firmware.name} (${translate('firmware.latest')})` : firmware.name })
  )

  const firmwareHasValue = values.firmware && values.firmware.version && values.firmware.location
  const isFirmwareEnabled = values.firmware && values.firmware.isEnabled

  useEffect(() => {
    if (!firmwareHasValue && firmwares && firmwares[0]) {
      setFieldValue('firmware.version', firmwares[0].name)
      setFieldValue('firmware.location', firmwares[0].path)
    }
  }, [values.firmware.isEnabled]) // eslint-disable-line

  return isEditPanel && values.firmware.isEnabled && status === 'configured' ? (
    <Fieldset heading={translate('firmware.firmwareSettingsHeading')}>
      <Select
        id="select-firmware"
        label={translate('firmware.targetVersionLabel')}
        options={firmwareOptions}
        name="firmware.location"
        onChange={(e) => {
          const { target: { selectedIndex, value, options } } = e
          setFieldValue('firmware.version', options[selectedIndex].text)
          setFieldValue('firmware.location', value)
        }}
        testId="firmware-settings"
      />
    </Fieldset>
  ) : (
    <ExpandGroup
      type="toggle"
      heading={translate('firmware.heading')}
      expandCondition={values.firmware.isEnabled}
      toggleName="firmware.isEnabled"
      hasTooltip
      tooltipContent={translate('firmware.tooltip')}
      tooltipIcon={mdiInformationOutline}
      data-testid="firmware-toggle"
      toggleTestid="firmware-toggle-div"
    >
      <Select
        id="select-firmware"
        label={translate('firmware.targetVersionLabel')}
        options={firmwareOptions}
        name="firmware.location"
        value={isFirmwareEnabled ? values.firmware.location : undefined}
        onChange={(e) => {
          const { target: { selectedIndex, value, options } } = e
          setFieldValue('firmware.version', options[selectedIndex].text)
          setFieldValue('firmware.location', value)
        }}
        testId="update-firmware"
      />
    </ExpandGroup>
  )
}

Firmware.propTypes = {
  values: PropTypes.object.isRequired,
  firmwares: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isEditPanel: PropTypes.bool,
  status: PropTypes.string
}

Firmware.defaultProps = {
  isEditPanel: false,
  status: undefined
}

export default Firmware
