import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { mdiPencilOutline } from '@mdi/js'

import PanelContext from '../../../../../State/Panel/Context'
import JobContext from '../../../../../State/Jobs/Context'
import ButtonIcon from '../../../../ButtonIcon'

const JobsiteEditButton = ({ jobsiteId }) => {
  const { actions: { changeView } } = useContext(PanelContext)
  const { actions: { setSelectedJobsiteId } } = useContext(JobContext)
  const handleClick = () => {
    setSelectedJobsiteId(jobsiteId)
    changeView('jobs/edit')
  }
  return <ButtonIcon onClick={handleClick} path={mdiPencilOutline} inverted data-testid="devices-edit-jobsite-button" />
}

JobsiteEditButton.propTypes = {
  jobsiteId: PropTypes.string.isRequired
}

export default JobsiteEditButton
