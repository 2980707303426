import React, { useContext } from 'react'

import useTranslate from '../../../Utils/Hooks/useTranslate'
import PanelContext from '../../../State/Panel/Context'
import DeviceContext from '../../../State/Devices/Context'

import PanelFooter from '../../Panel/Components/PanelFooter'
import Button from '../../Button'
import Fieldset from '../../Form/Fieldset'
import ReadOnlyFields from '../Components/ReadOnlyFields'

const ViewHub = () => {
  const { actions: { closePanel } } = useContext(PanelContext)
  const { selectors: { getSelectedDevice } } = useContext(DeviceContext)
  const translate = useTranslate('devicePanel')
  const translateConfig = useTranslate('configForm')

  const device = getSelectedDevice()
  const {
    zoneName,
    name,
    system: {
      macAddress,
      firmwareVersion
    },
    config: {
      network, bacnet, blePin
    }
  } = device

  const nameFields = [
    {
      header: translate('zoneNameInputLabel'),
      value: zoneName,
      testId: 'view-zone-name'
    },
    {
      header: translate('hubLabelInputLabel'),
      value: name,
      testId: 'view-sensor-hub-name'
    }
  ]

  const networkTypeField = [
    {
      header: translateConfig('networkSettings.typeInputLabel'),
      value: network.type === 'dhcp' ? translateConfig('networkSettings.typeInputOption.DHCP') : translateConfig('networkSettings.typeInputOption.static'),
      testId: 'view-network-type'
    },
    ...network.type === 'static' ? [
      {
        header: translateConfig('networkSettings.static.ipAddressInputLabel'),
        value: network.ipAddress,
        testId: 'view-network-ip-address'
      },
      {
        header: translateConfig('networkSettings.static.subnetMaskInputLabel'),
        value: network.subnetMask,
        testId: 'view-network-subnet-mask'
      },
      {
        header: translateConfig('networkSettings.static.gatewayIpInputLabel'),
        value: network.gatewayIp,
        testId: 'view-network-gateway-ip'
      },
      {
        header: translateConfig('networkSettings.static.dnsIpInputLabel'),
        value: network.dnsIp,
        testId: 'view-network-dns-ip'
      }
    ] : []
  ]

  const bacnetSettingsFields = [
    {
      header: translateConfig('bacNetSettings.protocolTypeInputLabel'),
      value: bacnet.protocol === 'ip' ? translateConfig('bacNetSettings.protocolTypeInputOption.ip') : translateConfig('bacNetSettings.protocolTypeInputOption.ethernet'),
      testId: 'view-bacnet-protocol'
    },
    {
      header: translateConfig('bacNetSettings.deviceIdInputLabel'),
      value: bacnet.deviceNumber,
      testId: 'view-bacnet-device-Number'
    },
    ...bacnet.protocol === 'ip' ? [
      {
        header: translateConfig('bacNetSettings.udpNumberInputLabel'),
        value: bacnet.udpNumber,
        testId: 'view-bacnet-udp-number'
      }
    ] : []
  ]

  const getFirmwareVersion = (firmware) => {
    if (!device.config.mqtt.isEnabled) {
      return translateConfig('firmware.unavailable.mqttDisabled')
    }

    if (device.config.mqtt.broker.brokerType === 'custom') {
      return translateConfig('firmware.unavailable.mqttCustom')
    }

    return firmware || translateConfig('firmware.pendingNetworkSync')
  }

  const otherFields = [
    {
      header: translateConfig('securitySettings.bleMacAddressLabel'),
      value: macAddress,
      testId: 'ble-mac-address'
    },
    {
      header: translateConfig('securitySettings.bluetoothPinInputLabel'),
      value: blePin,
      testId: 'ble-pin'
    },
    {
      header: translateConfig('firmware.deviceFirmwareLabel'),
      value: getFirmwareVersion(firmwareVersion),
      testId: 'firmware-version'
    }
  ]

  return (
    <>
      <Fieldset>
        <ReadOnlyFields fields={nameFields} />
      </Fieldset>
      <Fieldset heading={translateConfig('networkSettings.heading')}>
        <ReadOnlyFields fields={networkTypeField} />
      </Fieldset>
      <Fieldset heading={translateConfig('bacNetSettings.heading')}>
        <ReadOnlyFields fields={bacnetSettingsFields} />
      </Fieldset>
      <Fieldset>
        <ReadOnlyFields fields={otherFields} />
      </Fieldset>
      <PanelFooter>
        <Button onClick={closePanel} inverted data-testid="view-template-close-button">{translate('closeButton')}</Button>
      </PanelFooter>
    </>
  )
}

export default ViewHub
