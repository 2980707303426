import React from 'react'
import PropTypes from 'prop-types'
import useTranslate from '../../../../Utils/Hooks/useTranslate'
import usePanelRibbonErrors from '../../../../Utils/Hooks/usePanelRibbonErrors'

import Ribbon from '../../../Ribbon'

const PanelRibbon = ({ errors, submitCount }) => {
  const ribbonErrors = usePanelRibbonErrors(errors)
  const translate = useTranslate('devicePanel')

  return (
    <>
      {(ribbonErrors.length > 0 && submitCount > 0) && (
        <Ribbon type="error" isSticky>{`${ribbonErrors.length} ${translate('errorNotification')}`}</Ribbon>
      )}
    </>
  )
}

PanelRibbon.propTypes = {
  errors: PropTypes.object.isRequired,
  submitCount: PropTypes.number.isRequired
}

export default PanelRibbon
