import React, { useContext, useEffect } from 'react'
import {
  Field, Form, Formik
} from 'formik'
import * as Yup from 'yup'
import { useLocation } from 'react-router-dom'

import UserClient from '../../../State/User/Client'
import UserContext from '../../../State/User/Context'
import ModalContext from '../../../State/Modal/Context'

import useLocalStorage from '../../../Utils/Hooks/useLocalStorage'
import useTranslate from '../../../Utils/Hooks/useTranslate'
import useErrorHandler from '../../../Utils/Hooks/useErrorHandler'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'

import Spinner from '../../Spinner'
import PageBoundary from '../../PageBoundary'
import Logo from '../../Logo'
import BackgroundImage from '../../BackgroundImage'
import ContentWrapper from '../../ContentWrapper'
import PublicForm from '../../PublicForm'
import Footer from '../../Footer'
import Modal from '../../Modal'
import Text from '../../Formik/Text'
import Button from '../../Button'
import ButtonGroup from '../../ButtonGroup'
import PageMetaData from '../../PageMetaData'

import { Header, HelpMessage, Error } from './Styles'


const SignIn = () => {
  const { actions, selectors } = useContext(UserContext)
  const { login, signInWithEmailLink } = actions
  const {
    getReferrer,
    getErrorCode,
    hasError,
    isLoading
  } = selectors

  const { actions: { changeModalView } } = useContext(ModalContext)
  const stringifiedReferrer = JSON.stringify(getReferrer())
  const [requestEmail] = useLocalStorage('@login_email', null)
  const { state } = useLocation()
  const { error } = state || {}
  const translate = useTranslate('signIn')
  const translateValidation = useTranslate('formValidation')
  const errorCode = useErrorHandler(getErrorCode())
  useErrorNotifier(error, error)

  useEffect(() => {
    if (getReferrer()) {
      const link = `${window.location.origin}${getReferrer().pathname}${getReferrer().search}`
      if (UserClient.isSignInLink(link)) {
        signInWithEmailLink(requestEmail, link)
      }
    }
  }, [stringifiedReferrer]) // eslint-disable-line

  const Email = ({ field, submitCount }) => ( // eslint-disable-line
    <Text
      type="email"
      field={field}
      name="email"
      label={translate('emailInputLabel')}
      placeholder={translate('emailInputPlaceholder')}
      autoComplete="username"
      submitCount={submitCount}
    />
  )
  const Password = ({ field, submitCount }) => ( // eslint-disable-line
    <Text field={field} name="password" label={translate('passwordInputLabel')} placeholder={translate('passwordInputPlaceholder')} autoComplete="current-password" isPassword submitCount={submitCount} />
  )

  const ValidationSchema = Yup.object().shape({
    email: Yup.string().email(translateValidation('emailIsNotValid')).required(translateValidation('emailIsRequired')),
    password: Yup.string().required(translateValidation('passwordIsRequired'))
  })

  const onSubmit = (values) => {
    const { email, password } = values
    login(email, password)
  }

  const openModal = () => {
    changeModalView('forgot-password/request')
  }

  if (isLoading()) {
    return <Spinner message={translate('authenticating')} />
  }

  return (
    <>
      <PageMetaData pageMetaData={{ description: 'TODO' }} />
      <PageBoundary>
        <ContentWrapper>
          <PublicForm>
            <Logo size={300} />
            <Header>
              <span>
                {translate('description')}
              </span>
            </Header>
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={ValidationSchema}
              onSubmit={onSubmit}
            >{({ isValid, submitCount }) => (
              <Form>
                {hasError() && (
                <Error>{errorCode}</Error>
                )}
                <Field name="email" component={Email} submitCount={submitCount} />
                <Field name="password" component={Password} submitCount={submitCount} />
                <ButtonGroup>
                  <Button type="submit" disabled={!isValid && submitCount > 0}>{translate('signInButton')}</Button>
                  <Button type="button" onClick={openModal} inverted>{translate('forgotPasswordButton')}</Button>
                </ButtonGroup>
              </Form>
            )}
            </Formik>
            <HelpMessage>{translate('needHelp')}</HelpMessage>
          </PublicForm>
          <Footer />
        </ContentWrapper>
        <BackgroundImage />
        <Modal />
      </PageBoundary>
    </>
  )
}

export default SignIn
