import styled from 'styled-components'

export const TabGroupContainer = styled.div(({ theme }) => ({
  borderBottom: '2px solid',
  borderColor: theme.colors.n04
}))

export const Tab = styled.button(({ theme, active }) => ({
  padding: '8px 16px',
  lineHeight: '24px',
  letterSpacing: '0.3px',
  fontFamily: theme.fonts.roboto,
  fontSize: '1rem',
  fontWeight: active ? 500 : 400,
  background: 'none',
  border: 'none',
  boxShadow: `0 2px 0 ${active ? theme.colors.b01 : theme.colors.n04}`,
  cursor: 'pointer',
  '&:focus': {
    outline: 'none'
  }
}))
