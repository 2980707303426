import styled from 'styled-components'

export const UserDropdownButtonContainer = styled.div(({ theme }) => ({
  display: 'flex',
  marginTop: 8,
  padding: '8px 0',
  '& > svg': {
    color: theme.colors.n05
  },
  '& > svg:first-child': {
    marginRight: 8,
    color: theme.colors.n08
  }
}))

export const SubListItem = styled.span(({ theme }) => ({
  fontSize: 14,
  color: theme.colors.n07
}))

export const UserDropdownInfoContainer = styled.div(() => ({
  cursor: 'default',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
}))
