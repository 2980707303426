import React, { useContext } from 'react'
import { NavLink as Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import PanelContext from '../../State/Panel/Context'

const NavLink = ({ children, ...rest }) => {
  const { actions: { resetView } } = useContext(PanelContext)

  return (
    <>
      <Link
        {...rest}
        onClick={resetView}
      >
        {children}
      </Link>
    </>
  )
}

NavLink.propTypes = {
  children: PropTypes.node.isRequired
}

export default NavLink
