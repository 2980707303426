import React from 'react'
import PropTypes from 'prop-types'
import { CellWrapper } from '../Styles'

const TableCell = ({ align, children, ...rest }) => (
  <CellWrapper align={align} data-testid="table-cell" {...rest}>
    {children}
  </CellWrapper>
)

TableCell.propTypes = {
  align: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.node
}

TableCell.defaultProps = {
  align: 'left',
  children: null
}

export default TableCell
