import React from 'react'
import PropTypes from 'prop-types'
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons'
import {
  RadioInput,
  Container,
  Label,
  LabelText,
  Checked,
  UnChecked
} from './Styled'

const Radio = ({
  name,
  id,
  label,
  value,
  alignToTop,
  hasNoZones,
  isExistingCheckbox,
  testId,
  ...rest
}) => (
  <Container>
    <Label htmlFor={id} alignToTop={alignToTop} hasNoZones={hasNoZones} data-testid={testId}>
      <LabelText data-testid="radio-label" hasNoZones={hasNoZones} isExistingCheckbox={isExistingCheckbox}>{label}</LabelText>
      <RadioInput id={id} type="radio" name={name} value={value} disabled={hasNoZones} {...rest} />
      <Checked><RadioButtonChecked /></Checked>
      <UnChecked hasNoZones={hasNoZones} isExistingCheckbox={isExistingCheckbox}><RadioButtonUnchecked /></UnChecked>
    </Label>
  </Container>
)

Radio.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  id: PropTypes.string,
  alignToTop: PropTypes.bool,
  hasNoZones: PropTypes.bool,
  isExistingCheckbox: PropTypes.bool,
  testId: PropTypes.string
}

Radio.defaultProps = {
  alignToTop: false,
  hasNoZones: false,
  isExistingCheckbox: false,
  name: undefined,
  value: undefined,
  label: undefined,
  id: undefined,
  testId: undefined
}

export default Radio
