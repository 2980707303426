import React, {
  useState,
  useEffect,
  useContext,
  useMemo
} from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { parse } from 'query-string'
import PropTypes from 'prop-types'

import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useSearch from '../../../Utils/Hooks/useSearch'
import useTranslate from '../../../Utils/Hooks/useTranslate'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'
import useSorter from '../../../Utils/Hooks/useSorter'

import OrgContext from '../../../State/Organization/Context'
import JobsContext from '../../../State/Jobs/Context'
import PanelContext from '../../../State/Panel/Context'
import UserContext from '../../../State/User/Context'
import ModalContext from '../../../State/Modal/Context'
import FilterWrapper from '../../FilterWrapper'
import { Tab, TabGroup } from '../../TabGroup'
import Search from '../../Search'
import Table from '../../Table'
import HeaderCheckbox from '../../Table/Components/HeaderCheckbox'
import RowCheckbox from '../../Table/Components/RowCheckbox'
import {
  Name, Email, Role, JobCount, DateJoined, EditButton
} from './Components'
import JobSelect from './Components/JobSelect'
import RoleSelect from './Components/RoleSelect'

const searchFields = ['firstName', 'lastName', 'email']

const TeamTable = ({ status, setStatus }) => {
  const {
    actions: { fetchUsers, clearCheckedUsers }, selectors: { hasError, getErrorCode }
  } = useContext(OrgContext)
  const { selectors: { getJobsites, isLoading: jobListIsLoading }, actions: { fetchJobsites } } = useContext(JobsContext)
  const { actions: { changeView }, selectors: { dataIsStale } } = useContext(PanelContext)
  const { selectors: { getUser } } = useContext(UserContext)
  const { selectors: { dataIsStale: isModalDataStale } } = useContext(ModalContext)
  const { organizationId } = useParams()
  const location = useLocation()
  const history = useHistory()
  const { jobsiteId } = parse(location.search)
  const isFromDevices = location.state && location.state.fromDevices
  const translate = useTranslate('usersPage')
  const translateTable = useTranslate('tableFiltering')
  const [searchQuery, setSearchQuery] = useState('')
  const [jobsOptions, setJobsOptions] = useState([{ value: 'all', label: translate('allRole') }])
  const [selectedRole, setSelectedRole] = useState('all')
  const [selectedJob, setSelectedJob] = useState('all')
  const [disabledRows, setDisabledRows] = useState([])
  const [execute, , isLoading, userList] = useAsyncCallback(fetchUsers)
  const activeUsers = userList && userList.filter(user => user.status === 'active' || user.inviteId)
  const archivedUsers = userList && userList.filter(user => user.status === 'inactive')
  const results = useSearch(status === 'inactive' ? archivedUsers : activeUsers, searchQuery, searchFields)
  const isFiltered = !!searchQuery || selectedRole !== 'all' || selectedJob !== 'all'
  const sortEmail = useSorter('email')
  const sortFirstname = useSorter('firstName')
  const sortRole = useSorter('role')
  useErrorNotifier(hasError(), getErrorCode())

  const roleOptions = [
    {
      value: 'all',
      label: translate('allRole')
    },
    {
      value: 'installer',
      label: translate('installerRole')
    },
    {
      value: 'admin',
      label: translate('administratorRole')
    }
  ]

  useEffect(() => {
    if (jobsiteId && isFromDevices) {
      history.push(`/organization/${organizationId}/team`)
      setSelectedJob(jobsiteId)
      setSelectedRole('installer')
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    fetchJobsites(organizationId, {})
  }, []) // eslint-disable-line

  useEffect(() => {
    execute(organizationId, { selectedRole, selectedJob })
  }, [selectedRole, selectedJob, dataIsStale()]) // eslint-disable-line

  useEffect(() => {
    if (isModalDataStale()) {
      execute(organizationId, { selectedRole, selectedJob })
    }
  }, [isModalDataStale()]) // eslint-disable-line

  useEffect(() => {
    if (getJobsites().length > 0 && !jobListIsLoading()) {
      setJobsOptions(getJobsites().reduce((accumulator, current) => {
        accumulator.push({ value: current.jobsiteId, label: current.name })
        return accumulator
      }, [{ value: 'all', label: translate('allRole') }]))
    }
  }, [jobListIsLoading()]) // eslint-disable-line

  useEffect(() => {
    if (userList && !isLoading) {
      const currentUserRow = results.findIndex(entry => (entry.userId && entry.userId === getUser().userId))
      setDisabledRows(currentUserRow > -1 ? [currentUserRow] : [])
    }
  }, [isLoading]) // eslint-disable-line

  const onSearch = (value) => {
    setSearchQuery(value)
  }

  const isDisabled = (original) => {
    const currentUser = getUser()
    return original.userId === currentUser.userId
  }

  const tableColumns = [
    {
      id: 'selection',
      groupByBoundary: true,
      Header: ({ ...rest }) => <HeaderCheckbox disabledRows={disabledRows} {...rest} />, // eslint-disable-line
      Cell: ({ row, ...rest }) => <RowCheckbox row={row} disabled={isDisabled(row.original)} {...rest} />, // eslint-disable-line
      width: 56
    },
    {
      Header: translate('tableEmailColumn'),
      accessor: 'email',
      Cell: Email,
      width: 320,
      flex: true,
      sortType: sortEmail
    },
    {
      Header: translate('tableNameColumn'),
      accessor: 'firstName',
      Cell: Name,
      width: 248,
      flex: true,
      sortType: sortFirstname
    },
    {
      Header: translate('tableRoleColumn'),
      Cell: Role,
      accessor: 'role',
      width: 180,
      sortType: sortRole
    },
    {
      ...status === 'active' && {
        Header: translate('tableJobsColumn'),
        Cell: JobCount,
        align: 'right',
        width: 120
      }
    },
    {
      Header: translate('tableDateJoinedColumn'),
      Cell: DateJoined,
      accessor: row => (row.userId ? row.createdAt.seconds : 'a'), //eslint-disable-line
      width: 168
    },
    {
      id: 'edit',
      Header: translate('tableDetailsColumn'),
      Cell: EditButton,
      width: 80
    }
  ]
  const columnFilter = col => col.Header
  const columns = useMemo(
    () => tableColumns.filter(columnFilter),
    [status, disabledRows] // eslint-disable-line
  )

  const handleCreateClick = () => {
    changeView('users/invite')
  }

  const handleTabClick = (tab) => {
    setStatus(tab)
    clearCheckedUsers()
  }

  const emptyList = {
    filteredMessage: translate('noResults'),
    unfilteredMessage: translate('emptyListMessage'),
    emptyArchive: translate('emptyArchive'),
    ctaLabel: translate('inviteUserButton'),
    ctaFunc: handleCreateClick
  }

  return (
    <>
      <TabGroup>
        <Tab active={status === 'active'} type="button" onClick={() => handleTabClick('active')} data-testid="users-active-tab">{translate('activeTab')}</Tab>
        <Tab active={status === 'inactive'} type="button" onClick={() => handleTabClick('inactive')} data-testid="users-archive-tab">{translate('inactiveTab')}</Tab>
      </TabGroup>
      <FilterWrapper>
        <RoleSelect
          name={translate('filteringRoleLabel')}
          options={roleOptions}
          onChange={item => setSelectedRole(item.value)}
          isFromDevices={isFromDevices}
        />
        <JobSelect
          name={translate('filteringJobsLabel')}
          options={jobsOptions}
          onChange={item => setSelectedJob(item.value)}
          isLoading={jobListIsLoading()}
          jobsiteId={selectedJob}
        />
        <Search searchFields={searchFields} onSearch={onSearch} placeholderText={translateTable('filterByNameOrEmail')} searchTestId="users" />
      </FilterWrapper>
      <Table
        isLoading={isLoading}
        columns={columns}
        data={results}
        isFiltered={isFiltered}
        emptyList={emptyList}
        status={status}
        initialSortBy="email"
      />
    </>
  )
}

TeamTable.propTypes = {
  status: PropTypes.string,
  setStatus: PropTypes.func
}

TeamTable.defaultProps = {
  status: undefined,
  setStatus: () => null
}

export default TeamTable
