import React, { useEffect, useRef, useContext } from 'react'
import { createPortal } from 'react-dom'
import Transition from 'react-transition-group/Transition'

import ModalHeader from './Components/ModalHeader'
import ModalContext from '../../State/Modal/Context'
import ModalViewFactory from '../ModalViews'

import { ModalWrapper, ModalBackdrop } from './Styles'

const ANIMATION_DURATION = 240

const Modal = (props) => {
  const modalRoot = document.getElementById('modal-root')
  const el = useRef(document.createElement('div'))
  const refWrapper = useRef(null)

  const { actions, selectors } = useContext(ModalContext)
  const { closeModal } = actions
  const { isOpenSelector, currentViewSelector, isClickOutsideDisabled } = selectors
  const { getCurrentView } = ModalViewFactory()

  const isOpen = isOpenSelector()
  const target = el.current

  // Component mounting
  useEffect(() => {
    modalRoot.appendChild(target)
    return () => target.remove()
  }, []) // eslint-disable-line

  // Click outside handler
  const handleClickOutside = (event) => {
    if (isOpen && refWrapper.current && !refWrapper.current.contains(event.target) && !isClickOutsideDisabled()) {
      closeModal()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  })

  // Esc key handling
  const handleEscPress = (event) => {
    if (isOpen && (event.key === 'Escape' || event.keyCode === 27)) {
      closeModal()
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', handleEscPress)
    return () => document.removeEventListener('keyup', handleEscPress)
  })

  const currentViewType = currentViewSelector()
  const { view: CurrentView, header } = getCurrentView(currentViewType)
  return createPortal((
    <Transition
      appear
      unmountOnExit
      timeout={ANIMATION_DURATION}
      in={isOpen}
    >
      {state => (
        <>
          <ModalBackdrop data-state={state} />
          <ModalWrapper
            ref={refWrapper}
            aria-modal="true"
            role="dialog"
            data-state={state}
            data-testid="modal"
          >
            <ModalHeader header={header} isOpen={isOpen} />
            <CurrentView {...props} />
            <div id="modalFooter" /> { /* React portal */}
          </ModalWrapper>
        </>
      )}
    </Transition>
  ), el.current)
}

export default Modal
