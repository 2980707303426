import getSorter from './getSorters'

const sortFirstname = (rowA, rowB) => {
  const a = rowA.original && rowA.original.firstName ? rowA.original.firstName.toLowerCase() : 'a'
  const b = rowB.original && rowB.original.firstName ? rowB.original.firstName.toLowerCase() : 'a'

  return getSorter('firstName', a, b)
}

export default sortFirstname
