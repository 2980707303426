import React from 'react'
import PropTypes from 'prop-types'

import useTranslate from '../../../../Utils/Hooks/useTranslate'

import { TableCell } from '../../../Table'
import formatTime from '../../../../Utils/formatTime'

const DateJoined = ({ row }) => {
  const { createdAt, userId, inviteId } = row.original
  const translate = useTranslate('usersPage')
  const translateTable = useTranslate('tableFiltering')

  return (
    <TableCell data-testid="users-date-joined">
      {userId && !inviteId
        ? formatTime(createdAt, translateTable('dateFormattingToday'), translateTable('dateFormattingYesterday'))
        : <i>{translate('cellFormattingPendingInvite')}</i>
      }
    </TableCell>
  )
}

DateJoined.propTypes = {
  row: PropTypes.object.isRequired
}

export default DateJoined
