import React, { useContext } from 'react'

import useTranslate from '../../../Utils/Hooks/useTranslate'

import ModalContext from '../../../State/Modal/Context'
import PanelContext from '../../../State/Panel/Context'
import ModalContent from '../../Modal/Components/ModalContent'
import ModalFooter from '../../Modal/Components/ModalFooter'
import Button from '../../Button'

const MQTTOptOut = () => {
  const { actions: { closeModal } } = useContext(ModalContext)
  const { actions: { setConfirmation } } = useContext(PanelContext)
  const translate = useTranslate('MqttOptOut')

  const handleCancel = () => {
    closeModal()
  }

  const handleConfirm = () => {
    setConfirmation()
    closeModal()
  }

  return (
    <>
      <ModalContent>
        <p>
          {translate('description')}
        </p>
      </ModalContent>
      <ModalFooter>
        <Button onClick={handleConfirm} destructive data-testid="cancel-modal-confirm-button">{translate('disableButton')}</Button>
        <Button onClick={handleCancel} inverted data-testid="cancel-modal-deny-button">{translate('cancelButton')}</Button>
      </ModalFooter>
    </>
  )
}

export default MQTTOptOut
