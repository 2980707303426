import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Icon from '@mdi/react'
import {
  mdiFileDocumentBoxOutline, mdiPackageDown, mdiContentDuplicate, mdiDeleteOutline
} from '@mdi/js'

import useTranslate from '../../../Utils/Hooks/useTranslate'
import useTableCheckboxState from '../../../Utils/Hooks/useTableCheckboxState'
import useFeatureFlag from '../../../Utils/Hooks/useFeatureFlag'

import ModalContext from '../../../State/Modal/Context'
import TemplatesContext from '../../../State/Templates/Context'

import Breadcrumbs from '../../Breadcrumbs'
import Button from '../../Button'
import ButtonGroup from '../../ButtonGroup'
import PageHeader from '../../PageHeader'
import PageInfo from '../../PageInfo'
import PageCaption from '../../PageCaption'
import TemplatesTable from './TemplatesTable'
import Panel from '../../Panel'
import PageMetaData from '../../PageMetaData'

const Templates = ({ setState, setOrgSelectState }) => {
  const { actions: { changeModalView } } = useContext(ModalContext)
  const { actions: { setSelectedTemplate, setSelectedTemplatesList }, selectors: { getTemplate } } = useContext(TemplatesContext)
  const isArchivingEnabled = useFeatureFlag('archivingEnabled')
  const translate = useTranslate('templatesPage')
  const translateMetaData = useTranslate('pageMetaData')
  const { isBulkActionDisabled, selectedCheckboxes, setSelectedCheckboxes } = useTableCheckboxState()
  const isDuplicationDisabled = selectedCheckboxes.length !== 1
  const isArchivingButtonDisabled = isBulkActionDisabled || selectedCheckboxes.some(template => template.isDefaultTemplate)
  const openModal = () => {
    changeModalView('templates/duplicate')
  }

  setState('Content/Managing_Templates.html')
  setOrgSelectState('templates')

  const handleDuplicateButton = () => {
    const [selectedTemplate] = selectedCheckboxes
    setSelectedTemplate(getTemplate(selectedTemplate.templateId))
    changeModalView('templates/duplicate')
  }
  const handleDeleteButton = () => {
    setSelectedTemplatesList(selectedCheckboxes)
    changeModalView('templates/delete')
  }

  return (
    <>
      <PageMetaData pageMetaData={{ title: `${translateMetaData('templatesTitle')}`, description: 'TODO' }} />
      <Breadcrumbs />
      <PageHeader>
        <PageInfo>
          <Icon path={mdiFileDocumentBoxOutline} size={1} />
          <PageCaption>
            <h1>{translate('heading')}</h1>
            <h4>
              <span>{translate('description')}</span>
            </h4>
          </PageCaption>
        </PageInfo>
        <ButtonGroup data-testid="templates-button-group">
          {
            isArchivingEnabled && (
              <Button onClick={openModal} inverted data-testid="templates-archive-button" disabled={isArchivingButtonDisabled}>
                <Icon path={mdiPackageDown} size={1} />{translate('archiveTemplateButton')}
              </Button>
            )
          }
          <Button onClick={handleDeleteButton} destructive inverted data-testid="templates-delete-button" disabled={isArchivingButtonDisabled}>
            <Icon path={mdiDeleteOutline} size={1} />{translate('deleteTemplateButton')}
          </Button>
          <Button onClick={handleDuplicateButton} inverted data-testid="templates-duplicate-button" disabled={isDuplicationDisabled}>
            <Icon path={mdiContentDuplicate} size={1} />{translate('duplicateTemplateButton')}
          </Button>
        </ButtonGroup>
        <Panel />
      </PageHeader>
      <TemplatesTable selectedCheckboxes={selectedCheckboxes} setSelectedCheckboxes={setSelectedCheckboxes} />
    </>
  )
}

Templates.propTypes = {
  setState: PropTypes.func.isRequired,
  setOrgSelectState: PropTypes.func.isRequired
}

export default Templates
