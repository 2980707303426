export const orgSelectorContentStyles = ({
  top: '80%',
  left: '75%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  minHeight: 57,
  padding: '1rem',
  letterSpacing: '0.3px',
  cursor: 'default',
  color: 'white',
  backgroundColor: '#394F5F',
  '& > span': {
    flex: 1,
    textAlign: 'left',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  '& > svg': {
    marginTop: 1,
    fill: 'white'
  }
})
