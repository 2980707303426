import React from 'react'
import PropTypes from 'prop-types'

import { PageHeaderContainer } from './Styles'

const PageHeader = ({ children }) => (
  <PageHeaderContainer data-testid="page-header-container">
    {children}
  </PageHeaderContainer>
)

PageHeader.propTypes = {
  children: PropTypes.node.isRequired
}

export default PageHeader
