import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import Icon from '@mdi/react'
import { mdiPencilOutline, mdiFileDocumentBoxOutline } from '@mdi/js'

import PanelContext from '../../../../../State/Panel/Context'
import TemplateContext from '../../../../../State/Templates/Context'

const Details = ({ row, isConfiguredDevice }) => {
  const { actions: { changeView } } = useContext(PanelContext)
  const { actions: { setSelectedAttribute } } = useContext(TemplateContext)

  const handleClick = () => {
    setSelectedAttribute(row.original)
    const panelView = isConfiguredDevice ? 'attributes/view' : 'attributes/edit'
    changeView(panelView)
  }

  return (
    <button
      type="button"
      title="Edit attribute"
      data-testid={`attribute-edit-button-${row.original.bacnetId}`}
      onClick={handleClick}
    >
      <Icon path={isConfiguredDevice ? mdiFileDocumentBoxOutline : mdiPencilOutline} size={1} />
    </button>
  )
}

Details.propTypes = {
  row: PropTypes.object.isRequired,
  isConfiguredDevice: PropTypes.bool.isRequired
}

export default Details
