import * as firebase from 'firebase/app'
import 'firebase/firestore'
import toTitleCase from '../../Utils/toTitleCase'

import Config from '../../Config'

const orgClient = {
  getIdToken() {
    return firebase.auth().currentUser.getIdToken()
  },
  getOrgByName(name) {
    if (!name) return {}
    return firebase.firestore().collection('/organizations')
      .where('name', '==', name)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.docs.length === 0) {
          return null
        }
        return querySnapshot.docs[0].data()
      })
  },
  getOrgData(orgId) {
    return firebase.firestore().doc(`/organizations/${orgId}`).get()
  },
  getOrganizations(filters) {
    return firebase.firestore().collection('organizations')
      .get()
      .then(({ docs }) => {
        const data = docs.reduce((accumulator, current) => {
          if ('status' in filters) {
            if (current.data().status === filters.status) {
              accumulator.push({ id: current.id, ...current.data() })
            }
          } else {
            accumulator.push({ id: current.id, ...current.data() })
          }
          return accumulator
        }, [])
        return data
      })
      .catch(() => [])
  },
  fetchPrimaryAdmin(orgId) {
    return firebase.firestore()
      .collection(`/organizations/${orgId}/permissions`)
      .where('isPrimary', '==', true)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.docs.length === 0) {
          return null
        }
        const { firstName, lastName, email } = querySnapshot.docs[0].data()
        return { firstName, lastName, email }
      })
  },
  removePrimaryAdmin(orgId) {
    return firebase.firestore()
      .collection(`/organizations/${orgId}/permissions`)
      .where('isPrimary', '==', true)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((snapshot) => {
          snapshot.ref.update({
            isPrimary: false
          })
        })
      })
  },
  setPrimaryAdmin(orgId, userRef) {
    return firebase.firestore().collection(`organizations/${orgId}/permissions`).doc(userRef).update({ isPrimary: true })
  },
  getOrgListDetails(orgList, filters) {
    const query = orgList.reduce((accumulator, currentOrg) => {
      const { id } = currentOrg
      const orgStatusData = firebase.firestore().doc(`/organizations/${id}`).get().then((doc) => {
        if ('status' in filters) {
          if (doc.data().status === filters.status) {
            return doc
          }
          return false
        }
        return false
      })
      if (orgStatusData) {
        accumulator.push(orgStatusData)
      }
      return accumulator
    }, [])
    return Promise.all(query)
  },
  fetchOrgAdmins(orgId, isOrgInvites) {
    let query = firebase.firestore().collection('invitations').where('organizationId', '==', orgId).where('role', '==', 'admin')
    if (!isOrgInvites) {
      query = firebase.firestore().collection(`/organizations/${orgId}/permissions`).where('role', '==', 'admin')
    }
    return query.get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.reduce((accumulator, current) => {
          accumulator.push({ userId: current.id, ...current.data() })
          return accumulator
        }, [])
        return Promise.resolve(data)
      })
  },
  async fetchOrgPermissions(orgId, filters) {
    const orgAdmins = await this.fetchOrgAdmins(orgId, false)
    const isAdminRole = 'selectedRole' in filters && filters.selectedRole !== 'installer'
    const isFilteredJob = 'selectedJob' in filters && filters.selectedJob !== 'all'
    let query = firebase.firestore()
      .collection(`/organizations/${orgId}/permissions`)

    if ('status' in filters) {
      query = query.where('status', '==', filters.status)
    }

    if ('selectedRole' in filters && filters.selectedRole !== 'all') {
      query = query.where('role', '==', filters.selectedRole)
    }

    if (isFilteredJob) {
      query = query.where('jobsites', 'array-contains-any', [filters.selectedJob])
    }

    return query.get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.reduce((accumulator, current) => {
          accumulator.push({ userId: current.id, ...current.data() })
          return accumulator
        }, [])
        if (isAdminRole && isFilteredJob) {
          const newData = data.concat(orgAdmins.filter(admin => (admin.status === filters.status && data.findIndex(user => user.userId === admin.userId) === -1)))
            .sort((a, b) => ((a.userId > b.userId) ? 1 : -1))
          return Promise.resolve(newData)
        }
        return Promise.resolve(data)
      })
  },
  async fetchOrgInvites(orgId, filters) {
    const orgAdmins = await this.fetchOrgAdmins(orgId, true)
    const isAdminRole = 'selectedRole' in filters && filters.selectedRole !== 'installer'
    const isFilteredJob = 'selectedJob' in filters && filters.selectedJob !== 'all'
    let query = firebase.firestore()
      .collection('invitations')
      .where('organizationId', '==', orgId)

    if ('selectedRole' in filters && filters.selectedRole !== 'all') {
      query = query.where('role', '==', filters.selectedRole)
    }

    if (isFilteredJob) {
      query = query.where('jobsites', 'array-contains-any', [filters.selectedJob])
    }

    if ('status' in filters && filters.status === 'inactive') {
      return []
    }

    return query.get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.reduce((accumulator, current) => {
          accumulator.push({ inviteId: current.id, ...current.data() })
          return accumulator
        }, [])
        if (isAdminRole && isFilteredJob) {
          const newData = data.concat(orgAdmins.filter(admin => (data.findIndex(user => user.inviteId === admin.userId) === -1)))
            .sort((a, b) => ((a.userId > b.userId) ? 1 : -1))
          return Promise.resolve(newData)
        }
        return Promise.resolve(data)
      })
  },
  async createInvite(formData) {
    const url = `${Config.cloud.api.host}/invite`
    const token = await this.getIdToken()
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      cache: 'no-cache',
      credentials: 'omit',
      body: JSON.stringify(formData)
    })
  },
  async createOrganization(formData) {
    const ref = firebase.firestore().collection('organizations').doc()
    const orgName = toTitleCase(formData.name)
    ref.set({
      ...formData, id: ref.id, name: orgName, createdAt: firebase.firestore.Timestamp.fromDate(new Date())
    })

    const inviteData = {
      jobsites: [],
      name: orgName,
      email: formData.email,
      language: 'en',
      role: 'admin',
      organizationId: ref.id
    }

    return { inviteData }
  },
  async updateOrganization(organizationId, formData) {
    await firebase.firestore().collection('organizations').doc(organizationId).update({})
    return firebase.firestore().collection('organizations').doc(organizationId).set(formData, { merge: true })
  },
  updateUser(orgId, userId, formData) {
    return firebase.firestore().collection(`/organizations/${orgId}/permissions`).doc(userId).set(formData, { merge: true })
  },
  verifyUniqueCompanyName(companyName) {
    const query = firebase.firestore()
      .collection('organizations')
      .where('name', '==', companyName)
    return query.get().then((querySnapshot) => {
      const data = querySnapshot.docs
      if (data.length === 0) {
        return true
      }
      return querySnapshot.docs.forEach(doc => doc.data().name === companyName)
    })
  },
  verifyUniqueAccountId(accountId) {
    const query = firebase.firestore()
      .collection('organizations')
      .where('accountId', '==', accountId)
    const accountIdCount = query.get().then(querySnapshot => querySnapshot.docs.length)
    return accountIdCount
  },
  async verifyUniqueUsersEmail(email) {
    const { host } = Config.cloud.api
    if (email && email.length !== 0) {
      const url = `${host}/email/verify/${email}`
      const res = await fetch(url, {
        method: 'GET',
        cache: 'no-cache'
      })
        .then(data => data.json())
        .then(jsonData => (jsonData && jsonData.data ? jsonData.data.exists : null))
      return res ? 1 : 0
    }
    return 0
  },
  verifyUniquePermissionsEmailInOrg(organizationId, email) {
    const query = firebase.firestore()
      .collection(`/organizations/${organizationId}/permissions`)
      .where('email', '==', email)
    const emailCount = query.get().then(querySnapshot => ({ emailCount: querySnapshot.docs.length, data: querySnapshot.docs }))
    return emailCount
  },
  fetchJobsites(organizationId) {
    const query = firebase.firestore()
      .collection('jobsites')
      .where('organizationId', '==', organizationId)
    return query.get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.reduce((accumulator, current) => {
          const jobsite = current.data()
          accumulator.push({ jobsiteId: current.id, name: jobsite.name })
          return accumulator
        }, [])
        return data
      })
  },
  updateInvite(inviteId, userData) {
    return firebase.firestore().doc(`invitations/${inviteId}`).update(userData)
  },
  deactivateUsers(organizationId, checkedUsers) {
    const batch = firebase.firestore().batch()
    const updateUser = (userObj) => {
      const orgUserRef = firebase.firestore().doc(`/organizations/${organizationId}/permissions/${userObj.userId}`)
      batch.update(orgUserRef, { status: userObj.status === 'active' ? 'inactive' : 'active' })
      const userRef = firebase.firestore().doc(`/users/${userObj.userId}`)
      batch.update(userRef, { status: userObj.status === 'active' ? 'inactive' : 'active' })
    }
    const deleteInvite = (inviteObj) => {
      const inviteRef = firebase.firestore().doc(`/invitations/${inviteObj.inviteId}`)
      batch.delete(inviteRef)
    }
    checkedUsers.forEach(user => (user.userId ? updateUser(user) : deleteInvite(user)))
    return batch.commit()
  },
  updateOrganizations(organizations) {
    const batch = firebase.firestore().batch()
    const updateOrganization = (org) => {
      const orgRef = firebase.firestore().doc(`/organizations/${org.id}`)
      batch.update(orgRef, { status: org.status === 'active' ? 'archived' : 'active' })
    }
    organizations.forEach(org => updateOrganization(org))
    return batch.commit()
  },
  deleteOrganizations(organizations) {
    const batch = firebase.firestore().batch()
    const deleteOrganization = (org) => {
      const orgRef = firebase.firestore().doc(`/organizations/${org.id}`)
      batch.delete(orgRef)
    }
    organizations.forEach(org => deleteOrganization(org))
    return batch.commit()
  }
}

export default orgClient
