import useTranslate from './useTranslate'
import useValuesArrays from './useValuesArrays'

const useUnitsOfMeasure = () => {
  const ValuesArrays = useValuesArrays()
  const translate = useTranslate('templateAttributesPage')

  return (attributeId, value) => {
    const includesAttribute = values => values.includes(attributeId)
    switch (true) {
      case includesAttribute(ValuesArrays.nullValues):
        return value === null ? translate('nullValue') : value
      case includesAttribute(ValuesArrays.offValues) || includesAttribute(ValuesArrays.offSelectValues):
        return value === 1 ? `${value} (${translate('offLabel')})` : value
      case includesAttribute(ValuesArrays.percentageValues):
        return `${value}%`
      case includesAttribute(ValuesArrays.timeValues):
        return `${value} ${translate('secondsLabel')}`
      case includesAttribute(ValuesArrays.booleanValues):
        return value ? translate('onValue') : translate('offValue')
      case includesAttribute(ValuesArrays.ioValues):
        return value.channelValue
      case includesAttribute(ValuesArrays.customValues):
        return value.isCustomValue ? value.customValue : translate('hubDefault')
      default:
        return translate('readOnly')
    }
  }
}

export default useUnitsOfMeasure
