import useValidationSchema from '../../Hooks/useValidationSchema'
import useConfigTransform from '../../Hooks/useConfigTransform'
import CoVPercentageComponent from './Components/CoVPercentageComponent'

const CoVPercentageField = () => {
  const { getCurrentSchema } = useValidationSchema()
  const { getCurrentTransform } = useConfigTransform()

  return {
    validationSchema: getCurrentSchema('attributes/covPercentage'),
    configTransform: getCurrentTransform('attributes/reportingEnabled'),
    Component: () => null,
    CoVComponent: CoVPercentageComponent
  }
}

export default CoVPercentageField
