import { useEffect, useState } from 'react'
import useUnitsOfMeasure from './useUnitsOfMeasure'

const useSearch = (data, query, searchFields, isAttributeSearch = false) => {
  const [results, setResults] = useState([])
  const normalizedQuery = query.toLowerCase()
  const stringifiedData = JSON.stringify(data)
  const getAttibuteValue = useUnitsOfMeasure()

  const injectDisplayValue = ({ value, attributeId, ...rest }) => ({
    ...rest, value, attributeId, displayValue: getAttibuteValue(attributeId, value)
  })

  useEffect(() => {
    if (data) {
      let filtered = data.filter(item => (
        searchFields.findIndex((field) => {
          // deep object lookup
          const term = field.split('.').reduce((prev, curr) => prev && prev[curr], item)
          return term && term.toLowerCase().indexOf(normalizedQuery) > -1
        }) > -1
      ))
      if (isAttributeSearch) {
        filtered = filtered.map(injectDisplayValue)
      }
      setResults(filtered)
    }
  }, [stringifiedData, query, searchFields]) // eslint-disable-line

  return results
}

export default useSearch
