import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import Icon from '@mdi/react'
import { mdiPencilOutline, mdiFileDocumentBoxOutline } from '@mdi/js'

import PanelContext from '../../../../../State/Panel/Context'
import OrganizationContext from '../../../../../State/Organization/Context'

const EditButton = ({ row }) => {
  const { actions: { changeView } } = useContext(PanelContext)
  const rowIndex = row.index + 1
  const { actions: { setSelectedOrganization, getOrgByName } } = useContext(OrganizationContext)
  const { original: { name } } = row
  const isArchived = row.original.status === 'archived'

  const handleClick = async () => {
    const view = isArchived ? 'organizations/view' : 'organizations/edit'
    const org = await getOrgByName(name)
    setSelectedOrganization(org)
    changeView(view)
  }

  const icon = isArchived ? mdiFileDocumentBoxOutline : mdiPencilOutline

  return (
    <button type="button" onClick={handleClick} data-testid={`organization-edit-button-${rowIndex}`}>
      <Icon path={icon} size={1} />
    </button>
  )
}

EditButton.propTypes = {
  row: PropTypes.object.isRequired
}

export default EditButton
