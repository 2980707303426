import { types } from './Reducer'
import Client from './Client'

const getActions = (state, dispatch) => {
  const fetchFirmware = async () => {
    try {
      dispatch({ type: types.FETCH_FIRMWARE_PENDING })
      const response = await Client.fetchFirmware()
      dispatch({ type: types.FETCH_FIRMWARE_SUCCESS, payload: response })
    } catch (e) {
      dispatch({ type: types.FETCH_FIRMWARE_FAILURE, payload: e })
    }
  }

  return {
    fetchFirmware
  }
}

export default getActions
