import styled from 'styled-components'

export const BreadcrumbsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: '0 16px',
  height: 56,
  fontSize: '1rem',
  lineHeight: '24px',
  letterSpacing: '0.4px',
  '& > a, & > button': {
    color: theme.colors.n09,
    backgroundColor: 'inherit',
    border: 'none',
    textDecoration: 'underline',
    cursor: 'pointer',
    svg: {
      verticalAlign: '-6px',
      marginRight: 8,
      color: theme.colors.n08
    }
  }
}))

export const Separator = styled.span(({ theme }) => ({
  margin: '0.5rem',
  color: theme.colors.n05
}))
