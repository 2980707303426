import React from 'react'
import PropTypes from 'prop-types'
import { mdiInformationOutline } from '@mdi/js'

import Text from '../../../../Components/Formik/Text'

const CoVColorComponent = ({ translate, ...rest }) => (
  <>
    <Text
      name="reporting.value"
      label={translate('labels.covAcceptable')}
      accessoryText={`K° ${translate('labels.covLastKnown')}`}
      prefixText="+/-"
      textHelper={translate('labels.covColorHelper')}
      hasTooltip
      tooltipIcon={mdiInformationOutline}
      tooltipContent={translate('labels.covRangeTooltip')}
      type="number"
      min="0"
      max="100"
      data-testid="cov-color-input"
      {...rest}
    />
  </>
)

CoVColorComponent.propTypes = {
  translate: PropTypes.func.isRequired
}

export default CoVColorComponent
