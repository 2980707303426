import React from 'react'
import PropTypes from 'prop-types'

import FieldSet from '../../../Form/Fieldset'
import ReadOnlyFields from '../ReadOnlyFields'

const ReadOnlyAttributeFields = ({ fields }) => (
  <FieldSet hasBorder={false}>
    <ReadOnlyFields fields={fields} />
  </FieldSet>
)

ReadOnlyAttributeFields.propTypes = {
  fields: PropTypes.array.isRequired
}

export default ReadOnlyAttributeFields
