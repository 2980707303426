import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

import Icon from '@mdi/react'
import { mdiHexagonMultipleOutline } from '@mdi/js'

import useAsync from '../../../Utils/Hooks/useAsync'
import useTranslate from '../../../Utils/Hooks/useTranslate'
import useFeatureFlag from '../../../Utils/Hooks/useFeatureFlag'

import OrganizationContext from '../../../State/Organization/Context'
import Breadcrumbs from '../../Breadcrumbs'
import PageHeader from '../../PageHeader'
import PageCaption from '../../PageCaption'
import { SectionHeader, SectionWrapper, SectionRow } from './Styles'
import PageMetaData from '../../PageMetaData'

const ORG_NAME_SKELETON = 100
const ADMIN_NAME_SKELETON = 100
const EMAIL_SKELETON = 145

const OrganizationSettings = ({ setState, setOrgSelectState }) => {
  const { organizationId } = useParams()
  const { actions: { fetchOrganizationInfo } } = useContext(OrganizationContext)
  const [, isLoading, organization] = useAsync(fetchOrganizationInfo, organizationId)
  const {
    name: organizationName, primaryAdmin
  } = organization || {}
  const translate = useTranslate('myOrganizationPage')
  const translateMetaData = useTranslate('pageMetaData')
  const isOrgMgmtEnabled = useFeatureFlag('orgMgmtEnabled')
  setState('Content/Overview.html')
  setOrgSelectState('organization-settings')

  const renderAdmin = (property) => {
    if (isLoading) {
      return <Skeleton width={property === 'name' ? ADMIN_NAME_SKELETON : EMAIL_SKELETON} />
    }
    if (primaryAdmin) {
      return (property === 'name')
        ? `${primaryAdmin.firstName} ${primaryAdmin.lastName}`
        : primaryAdmin.email
    }
    return <i>{translate('generalSection.adminNotSet')}</i>
  }

  return (
    <>
      <PageMetaData pageMetaData={{ title: `${translateMetaData('organizationSettingsTitle')}`, description: 'TODO' }} />
      <Breadcrumbs />
      <PageHeader>
        <Icon path={mdiHexagonMultipleOutline} size={1} />
        <PageCaption>
          <h1>{translate('heading')}</h1>
        </PageCaption>
      </PageHeader>
      <SectionHeader>{translate('generalSection.heading')}</SectionHeader>
      <SectionWrapper>
        <SectionRow>
          <h4>{translate('generalSection.organizationName')}</h4>
          <span>{organizationName || <Skeleton width={ORG_NAME_SKELETON} />}</span>
        </SectionRow>
        {isOrgMgmtEnabled && (
          <>
            <SectionRow>
              <h4>{translate('generalSection.administrator')}</h4>
              <span>{renderAdmin('name')}</span>
            </SectionRow>
            <SectionRow>
              <h4>{translate('generalSection.adminEmail')}</h4>
              <span>{renderAdmin('email')}</span>
            </SectionRow>
          </>
        )}
      </SectionWrapper>
    </>
  )
}

OrganizationSettings.propTypes = {
  setState: PropTypes.func,
  setOrgSelectState: PropTypes.func
}

OrganizationSettings.defaultProps = {
  setState: () => null,
  setOrgSelectState: () => null
}

export default OrganizationSettings
