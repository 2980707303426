import styled from 'styled-components'

export const SelectorButtonContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 16px',
  width: 211,
  letterSpacing: '0.3px',
  color: theme.colors.n09,
  borderBottom: '1px solid',
  borderColor: theme.colors.n05,
  '& > span': {
    flex: 1,
    textAlign: 'left',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  '& > svg': {
    color: theme.colors.n09
  }
}))

export const SubListItem = styled.span(({ theme }) => ({
  fontSize: 14,
  color: theme.colors.n07
}))

export const contentStyles = ({
  top: '100%',
  left: 0,
  width: 211,
  zIndex: 10,
  button: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
})
