import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { parse } from 'query-string'
import { Route, Redirect, useLocation } from 'react-router-dom'

import useFetchCallback from '../../../../Utils/Hooks/useFetchCallback'
import useTranslate from '../../../../Utils/Hooks/useTranslate'

import UserContext from '../../../../State/User/Context'
import Spinner from '../../../Spinner'


const InviteRoute = ({
  component: Component, ...rest
}) => {
  const {
    selectors: {
      isLoggedIn, isInitialized, getInviteData, isLoading: loginPending
    }, actions: { fetchInvite, setInviteData }
  } = useContext(UserContext)
  const location = useLocation()
  const { inviteId } = parse(location.search)
  const [execute, , isLoading, result] = useFetchCallback(fetchInvite)
  const { data: invite } = result || {}
  const translate = useTranslate('accountActivation')
  const translateNavigator = useTranslate('navigator')
  const isValid = invite && Object.keys(invite).length > 0
  const appIsLoading = !isInitialized() || (!isLoggedIn() && loginPending() && getInviteData())

  useEffect(() => {
    const retrieveInvite = async (invId) => {
      await execute(invId)
    }
    if (!invite && inviteId) {
      retrieveInvite(inviteId)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (isValid) {
      setInviteData({ ...invite, inviteId })
    }
  }, [JSON.stringify(invite)]) // eslint-disable-line

  if (appIsLoading) {
    return <Spinner message={translateNavigator('loading')} />
  }

  if (isLoading) {
    return <Spinner message={translate('loadingInvite')} />
  }

  if (invite && Object.keys(invite).length === 0) {
    return <Redirect to={{ pathname: '/', state: { error: { code: 'invites/invite-not-found' } } }} />
  }

  return (
    <Route
      {...rest}
      render={props => (isLoggedIn()
        ? <Component {...props} />
        : (
          <Redirect to={{
            pathname: invite && invite.isExistingUser ? '/' : '/create-password',
            search: invite && invite.isExistingUser && '?next=/invite'
          }}
          />
        )
      )}
    />
  )
}

InviteRoute.propTypes = {
  component: PropTypes.elementType.isRequired
}

export default InviteRoute
