import React from 'react'
import PropTypes from 'prop-types'

import useTranslate from '../../../../../Utils/Hooks/useTranslate'

const FirmwareVersion = ({ row }) => {
  const { original } = row
  const {
    config: {
      firmware: {
        isEnabled,
        version
      }
    }
  } = original
  const translate = useTranslate('devicesPage')
  const trimFilename = () => version.replace('.tar.gz', '')
  return (
    <>
      {isEnabled ? trimFilename(version) : <i>{translate('cellFormatting.factoryDefault')}</i>}
    </>
  )
}

FirmwareVersion.propTypes = {
  row: PropTypes.object.isRequired
}

export default FirmwareVersion
