import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@mdi/react'
import { mdiInformationOutline, mdiAlertCircleOutline } from '@mdi/js'

import { MessageContainer, Heading } from './Styles'

const InfoMessage = ({ isError, content, heading }) => (
  <>
    <Heading>{heading}</Heading>
    <MessageContainer isError={isError}>
      {isError ? (
        <span><Icon path={mdiAlertCircleOutline} size={1} /></span>
      ) : (
        <span><Icon path={mdiInformationOutline} size={1} /></span>
      )}
      <span><p>{content}</p></span>
    </MessageContainer>
  </>
)

InfoMessage.propTypes = {
  isError: PropTypes.bool,
  content: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired
}

InfoMessage.defaultProps = {
  isError: false
}

export default InfoMessage
