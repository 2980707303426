import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Close from '@material-ui/icons/Close'
import Heading from '../../../Heading'
import ModalContext from '../../../../State/Modal/Context'

import { StyledHeaderContainer, CloseLink } from './Styles'

const ModalHeader = ({ header }) => {
  const { actions } = useContext(ModalContext)
  const { closeModal } = actions

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      closeModal()
    }
  }
  return (
    <StyledHeaderContainer data-testid="modal-header">
      <Heading tag="h2" level={3}>{header}</Heading>
      <CloseLink data-testid="modalCloseButton" onClick={closeModal} onKeyPress={handleEnterPress} tabIndex="0" data-testid="modal-close"><Close /></CloseLink> {/* eslint-disable-line */}
    </StyledHeaderContainer>
  )
}

ModalHeader.propTypes = {
  header: PropTypes.string.isRequired
}

export default ModalHeader
