import styled from 'styled-components'

export const PageCaptionContainer = styled.div(({ theme }) => ({
  flex: 1,
  padding: '0 1rem',
  h1: {
    display: 'inline-flex',
    verticalAlign: 'center',
    button: {
      marginLeft: '0.5rem'
    }
  },
  h4: {
    '& > span': {
      marginRight: '1rem',
      svg: {
        fill: theme.colors.n07,
        marginRight: 5,
        verticalAlign: '-4px',
        fontSize: 15
      }
    }
  },
  a: {
    color: 'inherit',
    '&:hover': {
      color: theme.colors.n06
    }
  }
}))
