import React from 'react'
import PropTypes from 'prop-types'
import { mdiInformationOutline } from '@mdi/js'

import useTranslate from '../../../../Utils/Hooks/useTranslate'

import Text from '../../../Formik/Text'
import RadioGroup from '../../../Formik/RadioGroup'
import Fieldset from '../../../Form/Fieldset'

const BACnetSettings = ({ values, submitCount }) => {
  const translate = useTranslate('configForm')
  return (
    <Fieldset heading={translate('bacNetSettings.heading')}>
      <RadioGroup
        name="bacnet.protocol"
        title={translate('bacNetSettings.protocolTypeInputLabel')}
        tiled
        options={[
          {
            value: 'ethernet',
            label: translate('bacNetSettings.protocolTypeInputOption.ethernet')
          },
          {
            value: 'ip',
            label: translate('bacNetSettings.protocolTypeInputOption.ip')
          }
        ]}
        testid="bacnet-protocol-type"
      />
      <Text
        label={translate('bacNetSettings.deviceIdInputLabel')}
        name="bacnet.deviceNumber"
        placeholder={translate('bacNetSettings.deviceIdInputPlaceholder')}
        type="number"
        hasTooltip
        tooltipContent={translate('bacNetSettings.deviceIdInputTooltip')}
        tooltipIcon={mdiInformationOutline}
        min="1"
        max="4194302"
        data-testid="bacnet-device-number"
        textHelper={translate('bacNetSettings.deviceIdInputTextHelper')}
        submitCount={submitCount}
      />
      {
        values.bacnet.protocol === 'ip' && (
          <Text
            label={translate('bacNetSettings.udpNumberInputLabel')}
            name="bacnet.udpNumber"
            placeholder={translate('bacNetSettings.udpNumberInputPlaceholder')}
            type="number"
            tooltipContent={translate('bacNetSettings.udpNumberInputTooltip')}
            tooltipIcon={mdiInformationOutline}
            hasTooltip
            min="0"
            data-testid="bacnet-udp-number"
            textHelper={translate('bacNetSettings.udpNumberInputTextHelper')}
            submitCount={submitCount}
          />
        )
      }
    </Fieldset>
  )
}

BACnetSettings.propTypes = {
  values: PropTypes.object.isRequired,
  submitCount: PropTypes.number.isRequired
}

export default BACnetSettings
