const initialState = {
  loading: false,
  error: {
    status: false,
    code: null
  },
  data: null,
  currentOrg: null,
  inviteData: null,
  selectedUser: null,
  checkedUsers: [],
  selectedOrganizationName: null,
  organizations: [],
  checkedOrganizations: []
}

const types = {
  FETCH_ORG_PENDING: 'FETCH_ORG_PENDING',
  FETCH_ORG_SUCCESS: 'FETCH_ORG_SUCCESS',
  FETCH_ORG_FAILURE: 'FETCH_ORG_FAILURE',
  FETCH_ORG_LIST_DETAILS_PENDING: 'FETCH_ORG_LIST_DETAILS_PENDING',
  FETCH_ORG_LIST_DETAILS_SUCCESS: 'FETCH_ORG_LIST_DETAILS_SUCCESS',
  FETCH_ORG_LIST_DETAILS_FAILURE: 'FETCH_ORG_LIST_DETAILS_FAILURE',
  CREATE_INVITE_PENDING: 'CREATE_INVITE_PENDING',
  CREATE_INVITE_SUCCESS: 'CREATE_INVITE_SUCCESS',
  CREATE_INVITE_FAILURE: 'CREATE_INVITE_FAILURE',
  SET_SELECTED_USER: 'SET_SELECTED_USER',
  SET_SELECTED_ORG: 'SET_SELECTED_ORG',
  UPDATE_USER_PENDING: 'UPDATE_USER_PENDING',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
  SET_SELECTED_USERS: 'SET_SELECTED_USERS',
  REMOVE_CHECKED_USERS: 'REMOVE_CHECKED_USERS',
  CLEAR_CHECKED_USERS: 'CLEAR_CHECKED_USERS',
  CLEAR_CHECKED_ORGANIZATIONS: 'CLEAR_CHECKED_ORGANIZATIONS',
  SET_SELECTED_ORGANIZATION: 'SET_SELECTED_ORGANIZATION',
  SET_SELECTED_ORGANIZATION_NAME: 'SET_SELECTED_ORGANIZATION_NAME',
  GET_ORGS_PENDING: 'GET_ORGS_PENDING',
  GET_ORGS_SUCCESS: 'GET_ORGS_SUCCESS',
  GET_ORGS_FAILURE: 'GET_ORGS_FAILURE'
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case (types.FETCH_ORG_PENDING):
    case (types.FETCH_ORG_LIST_DETAILS_PENDING):
    case (types.CREATE_INVITE_PENDING):
    case (types.UPDATE_USER_PENDING):
      return {
        ...state,
        data: [],
        loading: true
      }
    case (types.FETCH_ORG_FAILURE):
    case (types.FETCH_ORG_LIST_DETAILS_FAILURE):
    case (types.CREATE_INVITE_FAILURE):
    case (types.UPDATE_USER_FAILURE):
      return {
        ...state,
        loading: false,
        error: {
          status: true,
          code: action.payload
        }
      }
    case (types.GET_ORGS_PENDING):
      return {
        ...state,
        loading: true,
        organizations: []
      }
    case (types.GET_ORGS_SUCCESS):
    case (types.GET_ORGS_FAILURE):
      return {
        ...state,
        loading: false,
        organizations: action.payload
      }
    case (types.FETCH_ORG_SUCCESS):
      return {
        ...state,
        loading: false,
        data: action.payload,
        currentOrg: action.payload.id,
        error: {
          status: false,
          code: null
        }
      }
    case (types.FETCH_ORG_LIST_DETAILS_SUCCESS):
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: {
          status: false,
          code: null
        }
      }
    case (types.CREATE_INVITE_SUCCESS):
      return {
        ...state,
        loading: false,
        inviteData: action.payload
      }
    case (types.SET_SELECTED_USER):
      return {
        ...state,
        selectedUser: action.payload
      }
    case (types.SET_SELECTED_ORG):
      return {
        ...state,
        currentOrg: action.payload
      }
    case (types.UPDATE_USER_SUCCESS):
      return {
        ...state,
        loading: false
      }
    case (types.SET_SELECTED_USERS):
      return {
        ...state,
        checkedUsers: action.payload
      }
    case (types.REMOVE_CHECKED_USERS):
      return {
        ...state,
        checkedUsers: action.payload
      }
    case (types.CLEAR_CHECKED_USERS):
      return {
        ...state,
        checkedUsers: []
      }
    case (types.SET_SELECTED_ORGANIZATIONS):
      return {
        ...state,
        checkedOrganizations: action.payload
      }
    case (types.REMOVE_CHECKED_ORGANIZATIONS):
      return {
        ...state,
        checkedOrganizations: action.payload
      }
    case (types.CLEAR_CHECKED_ORGANIZATIONS):
      return {
        ...state,
        checkedOrganizations: []
      }
    case (types.SET_SELECTED_ORGANIZATION):
      return {
        ...state,
        selectedOrganization: action.payload
      }
    case (types.SET_SELECTED_ORGANIZATION_NAME):
      return {
        ...state,
        selectedOrganizationName: action.name
      }
    case (types.UPDATE_ORGANIZATION_PENDING):
      return {
        ...state,
        loading: true
      }
    case (types.UPDATE_ORGANIZATION_SUCCESS):
    case (types.UPDATE_ORGANIZATION_FAILURE):
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}

export {
  reducer,
  types,
  initialState
}
