import { types } from './Reducer'

const getActions = (state, dispatch) => {
  const openModal = () => {
    dispatch({ type: types.OPEN_MODAL })
  }

  const closeModal = () => {
    dispatch({ type: types.CLOSE_MODAL })
  }

  const changeModalView = (viewName, isClickOutsideDisabled = false) => {
    dispatch({ type: types.CHANGE_VIEW, view: viewName, isClickOutsideDisabled })
    dispatch({ type: types.OPEN_MODAL })
  }

  const setSubmitted = () => {
    dispatch({ type: types.SET_SUBMITTED })
  }

  return {
    openModal,
    closeModal,
    changeModalView,
    setSubmitted
  }
}

export default getActions
