import styled, { keyframes, css } from 'styled-components'

const panelOpenAnimation = keyframes`
  from {
    transform: translateX(416px);
  }
  to {
    transform: translateX(0);
  }
`

const panelCloseAnimation = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(416px);
  }
`

const backdropOpenAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.75;
  }
`

const backdropCloseAnimation = keyframes`
  from {
    opacity: 0.75;
  }
  to {
    opacity: 0;
  }
`

export const PanelWrapper = styled.div(
  ({ theme }) => ({
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    borderLeft: `2px solid ${theme.colors.n04}`,
    top: '0',
    right: '0',
    height: '100vh',
    width: '100%',
    transform: 'translateX(100%)',
    // transform: isOpen ? 'translateX(0)' : 'translateX(416px)',
    // transition: 'all .5s ease-in-out',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    zIndex: '10',
    [`@media(min-width: ${theme.breakpoints.md.minWidth})`]: {
      transform: 'translateX(416px)',
      width: '416px'
    }
  }),
  css`
    &[data-state="entering"] {
      animation: ${panelOpenAnimation} 240ms cubic-bezier(0.0, 0.0, 0.2, 1) both;
    }
    &[data-state="entered"] {
      animation: ${panelOpenAnimation} 240ms cubic-bezier(0.0, 0.0, 0.2, 1) both;
    }
    &[data-state="exiting"] {
      animation: ${panelCloseAnimation} 240ms cubic-bezier(0.4, 0.0, 1, 1) both;
    }
  `
)


export const PanelBackdrop = styled.div({
  position: 'fixed',
  left: 216,
  top: '0',
  right: '0',
  bottom: '0',
  opacity: 0.70,
  backgroundColor: 'white',
  zIndex: 10
},
css`
    &[data-state="entering"] {
      animation: ${backdropOpenAnimation} 240ms cubic-bezier(0.175, 0.885, 0.320, 1.175) both;
    }
    &[data-state="entered"] {
      animation: 240ms cubic-bezier(0.175, 0.885, 0.320, 1.175) both;
    }
    &[data-state="exiting"] {
      animation: ${backdropCloseAnimation} 240ms cubic-bezier(0.0, 0.0, 0.2, 1) both;
    }
    &[data-state="exited"] {
      display: none;
    }
  `)

export const PanelContent = styled.div({
  maxHeight: '100%',
  overflowY: 'auto',
  flex: 1,
  zIndex: '-1'
})
