import Styled from 'styled-components'

const getFontStyle = (level) => {
  if (level === 2) {
    return {
      fontSize: '1rem',
      fontWeight: 500
    }
  }
  if (level === 3) {
    return {
      fontSize: '1.125rem',
      fontWeight: 400,
      letterSpacing: '0.3px'
    }
  }
  if (level === 4) {
    return {
      fontSize: '.875rem',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.5px',
      height: '24px'
    }
  }
  return {
    fontSize: '.875rem',
    fontWeight: 500
  }
}

export const Header = Styled.div(({ theme, level, isOnlyHeading }) => ({
  ...getFontStyle(level),
  color: isOnlyHeading ? theme.colors.n07 : theme.colors.n09
}))
