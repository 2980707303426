import React, { useContext } from 'react'
import { createPortal } from 'react-dom'

import NotificationsContext from '../../State/Notifications/Context'

import { ToasterContainer } from './Styles'
import Toast from './Toast'

const Toaster = () => {
  const toasterRoot = document.getElementById('toaster-root')
  const { actions: { dismissNotification }, selectors: { getAllToasts } } = useContext(NotificationsContext)

  return createPortal((
    <>
      <ToasterContainer data-testid="toaster">
        {getAllToasts().reverse().map(({
          id, text, category, timeout
        }, index) => {
          const onClose = () => dismissNotification(id)
          return (
            <Toast key={id} category={category} timeout={timeout} onClose={onClose} zIndex={index}>
              {text}
            </Toast>
          )
        })}
      </ToasterContainer>
    </>
  ), toasterRoot)
}

export default Toaster
