import React from 'react'
import PropTypes from 'prop-types'
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined'
import { Tooltip } from '@material-ui/core'

import { TableCell } from '../../../../Table'

const Description = ({ row, isDefaultTemplate }) => {
  const { original: { description }, index } = row

  return (
    <TableCell>
      { !isDefaultTemplate && description && (
        <Tooltip placement="top" title={description} arrow data-testid="tooltip">
          <ChatOutlinedIcon data-testid={`description-icon-${index}`} />
        </Tooltip>
      )}
    </TableCell>
  )
}

Description.propTypes = {
  row: PropTypes.object.isRequired,
  isDefaultTemplate: PropTypes.bool.isRequired
}

export default Description
