import styled from 'styled-components'

export const LogoContainer = styled.div({
  textAlign: 'center',
  marginBottom: '2.5rem'
})

export const Header = styled.div(({ theme }) => ({
  marginBottom: '2rem',
  lineHeight: '1.5rem',
  letterSpacing: '0.3px',
  color: theme.colors.n08,
  h1: {
    marginBottom: '1rem',
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: '2rem',
    letterSpacing: '0.5px',
    color: theme.colors.n09
  }
}))

export const FooterText = styled.span(({ theme }) => ({
  display: 'inline-block',
  marginTop: '0',
  marginBottom: '2rem',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  letterSpacing: '0.2px',
  color: theme.colors.n08,
  p: {
    display: 'inline'
  },
  span: {
    marginLeft: '0'
  }
}))

export const BgImageContainer = styled.div({
  position: 'relative',
  width: 'calc(100vw - 36rem)',
  height: '100%',
  overflow: 'hidden',
  img: {
    position: 'absolute',
    right: 0,
    maxWidth: 'none',
    maxHeight: '100%'
  }
})

export const ScrollableWrapper = styled.div({
  overflowY: 'scroll'
})
