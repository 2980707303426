import styled from 'styled-components'

export const ContentWrapperContainer = styled.div(({ theme, isCentered }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  [`@media(min-width: ${theme.breakpoints.lg.minWidth})`]: {
    flex: isCentered ? 1 : 0.45
  },
  [`@media(min-width: ${theme.breakpoints.xl.minWidth})`]: {
    flex: isCentered ? 1 : 0.4
  }
}))
