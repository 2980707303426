const CreateOrganization = (values) => {
  const organizationConfig = {
    name: values.name,
    accountId: values.accountId,
    email: values.email,
    city: values.city,
    country: values.country,
    provinceOrState: values.provinceOrState,
    status: values.status
  }

  return organizationConfig
}

export default CreateOrganization
