import React from 'react'
import PropTypes from 'prop-types'

import { PageCaptionContainer } from './Styles'

const PageCaption = ({ children }) => (
  <PageCaptionContainer>
    {children}
  </PageCaptionContainer>
)

PageCaption.propTypes = {
  children: PropTypes.node.isRequired
}

export default PageCaption
