import { object, number } from 'yup'


const Reporting = (translate, validation) => object().shape({
  value: number().when('isEnabled', {
    is: true,
    then: validation(),
    otherwise: number().notRequired()
  })
})


export default Reporting
