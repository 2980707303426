import React, {
  Fragment, memo, useState, useCallback
} from 'react'
import PropTypes from 'prop-types'
import {
  Container, Delimiter, Label, FieldContainer
} from './Styles'
import SingleInput from './SingleInput'
import Error from '../Error'

const DelimitedComponent = ({
  regions,
  label,
  autoFocus,
  handleChange,
  value,
  delimiter,
  name,
  error,
  delimitedTestId,
  setIsDelimitedFilled,
  setFieldsAreBlurred,
  setTouched
}) => {
  const [activeInput, setActiveInput] = useState(0)
  const [delimitedValues, setDelimitedValues] = useState(value === '' ? Array(regions).fill('') : value.split(delimiter))

  const handleDelimitedChange = useCallback(
    (delimited) => {
      const delimitedValue = delimited.join('.')
      handleChange(delimitedValue)
      if (!delimitedValue.includes('')) {
        setIsDelimitedFilled(true)
      }
    },
    [handleChange] // eslint-disable-line
  )

  const onChangeAtFocus = useCallback(
    (str) => {
      const updatedDelimitedValues = [...delimitedValues]
      updatedDelimitedValues[activeInput] = str || ''
      setDelimitedValues(updatedDelimitedValues)
      handleDelimitedChange(updatedDelimitedValues)
    },
    [activeInput, handleDelimitedChange, delimitedValues]
  )

  const focusInput = useCallback(
    (inputIndex) => {
      const selectedIndex = Math.max(Math.min(regions - 1, inputIndex), 0)
      setActiveInput(selectedIndex)
    },
    [regions] // eslint-disable-line
  )

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1)
  }, [activeInput, focusInput])

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1)
  }, [activeInput, focusInput])

  const handleOnFocus = useCallback(
    index => () => {
      focusInput(index)
    },
    [focusInput]
  )

  const handleOnChange = useCallback(
    (e) => {
      const val = e.currentTarget.value
      if (!val) {
        e.preventDefault()
        return
      }
      onChangeAtFocus(val)
    },
    [onChangeAtFocus, focusNextInput] // eslint-disable-line
  )

  const onBlur = useCallback(() => {
    setTimeout(() => {
      if (!Array(regions).fill('').map((val, index) => `region-${label} ${index + 1}`).includes(document.activeElement.id)) {
        setActiveInput(-1)
        setTouched(true)
        setFieldsAreBlurred(true)
      }
    }, 10)
  }, []) // eslint-disable-line

  const handleOnKeyDown = useCallback(
    (e) => {
      switch (e.key) {
        case 'Backspace':
        case 'Delete': {
          if (!delimitedValues[activeInput]) {
            focusPrevInput()
          } else if (delimitedValues[activeInput].length === 1) {
            e.preventDefault()
            onChangeAtFocus('')
          }
          break
        }
        case '.': {
          e.preventDefault()
          focusNextInput()
          break
        }
        case ' ': {
          e.preventDefault()
          break
        }
        default:
          break
      }
    },
    [activeInput, onChangeAtFocus, focusNextInput, focusPrevInput, delimitedValues]
  )

  const handleOnPaste = useCallback(
    (e) => {
      e.preventDefault()
      const pastedData = e.clipboardData
        .getData('text/plain')
        .trim()
        .split(delimiter)
        .slice(0, regions - activeInput)
      if (pastedData) {
        let nextFocusIndex = 0
        const updatedDelimitedValues = [...delimitedValues]
        updatedDelimitedValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = pastedData.shift() || val
            if (changedValue) {
              updatedDelimitedValues[index] = changedValue
              nextFocusIndex = index
            }
          }
        })
        setDelimitedValues(updatedDelimitedValues)
        setActiveInput(Math.min(nextFocusIndex + 1, regions - 1))
        handleDelimitedChange(updatedDelimitedValues)
      }
    },
    [activeInput, regions, delimitedValues, handleDelimitedChange] // eslint-disable-line
  )

  const onInput = (e) => {
    e.target.value = e.target.value.slice(0, 3)
  }

  return (
    <>
      {label && <Label htmlFor={name} data-testid="delimited-label">{label}</Label>}
      <Container data-testid={`delimited-${delimitedTestId}-${label.replace(/\s+/g, '-').toLowerCase()}`}>
        <FieldContainer>
          {Array(regions).fill('').map((val, index) => (
            <Fragment key={`region ${index + 1}`}>
              <SingleInput
                id={`region-${label} ${index + 1}`}
                name={`region ${index + 1}`}
                focus={activeInput === index && autoFocus}
                value={delimitedValues && delimitedValues[index]}
                onFocus={handleOnFocus(index)}
                onChange={handleOnChange}
                onKeyDown={handleOnKeyDown}
                onBlur={onBlur}
                onInput={onInput}
                onPaste={handleOnPaste}
                data-testid={`delimited-input-${index}`}
              />
              {index < regions - 1 && <Delimiter>{delimiter}</Delimiter>}
            </Fragment>
          ))}
        </FieldContainer>
        {error && <Error testId="delimited-error" error={error} />}
      </Container>
    </>
  )
}

DelimitedComponent.propTypes = {
  handleChange: PropTypes.func.isRequired,
  delimiter: PropTypes.string,
  value: PropTypes.string,
  regions: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.string,
  delimitedTestId: PropTypes.string,
  setTouched: PropTypes.func,
  autoFocus: PropTypes.bool,
  setIsDelimitedFilled: PropTypes.func,
  setFieldsAreBlurred: PropTypes.func
}

DelimitedComponent.defaultProps = {
  autoFocus: false,
  delimiter: undefined,
  regions: undefined,
  label: undefined,
  name: undefined,
  error: undefined,
  value: '',
  setTouched: () => null,
  setIsDelimitedFilled: () => null,
  setFieldsAreBlurred: () => null,
  delimitedTestId: undefined
}

const Delimited = memo(DelimitedComponent)
export default Delimited
