const getSelectors = state => ({
  getState() {
    return state
  },
  isLoading() {
    return state.loading
  },
  getZones() {
    return state.data || {}
  },
  getSelectedZone() {
    return state.selectedZone
  }
})

export default getSelectors
