import React from 'react'
import PropTypes from 'prop-types'
import { TableCell } from '../../../../Table'

const RightAligned = ({ cell: { value }, row }) => {
  const { devices } = row.original

  return (
    <TableCell align="right">
      {devices ? value : 0}
    </TableCell>
  )
}

RightAligned.propTypes = {
  cell: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired
}

export default RightAligned
