import React from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  Toggler,
  LabelContainer,
  Label,
  LabelText
} from './Styles'
import Tooltip from '../../Tooltip'

const Toggle = ({
  label,
  onChange,
  value,
  name,
  disabled,
  hasTooltip,
  tooltipContent,
  tooltipIcon,
  toggleTestId,
  ...rest
}) => (
  <Label htmlFor={name}>
    <LabelContainer>
      <LabelText data-testid="toggle-label">{label}</LabelText>
      {hasTooltip && (
        <Tooltip content={tooltipContent} icon={tooltipIcon} size={1} data-testid="toggle-tooltip" />
      )}
    </LabelContainer>
    <Checkbox disabled={disabled} id={name} onChange={onChange} name={name} value={value} type="checkbox" {...rest} />
    <Toggler disabled={disabled} tabIndex="0" data-testid={`${toggleTestId}`} />
  </Label>
)

Toggle.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  hasTooltip: PropTypes.bool,
  tooltipIcon: PropTypes.node,
  tooltipContent: PropTypes.string,
  toggleTestId: PropTypes.string,
  disabled: PropTypes.bool
}

Toggle.defaultProps = {
  label: undefined,
  onChange: () => null,
  name: undefined,
  value: false,
  hasTooltip: false,
  tooltipContent: undefined,
  tooltipIcon: undefined,
  toggleTestId: null,
  disabled: false
}

export default Toggle
