import Styled from 'styled-components'


const Title = Styled.div(({ theme }) => ({
  margin: '0 0 1rem',
  color: theme.colors.n09,
  fontSize: '.875rem',
  fontWeight: 500,
  letterSpacing: '0.2px'
}))

const Label = Styled.label(({ alignToTop, hasNoZones }) => ({
  display: 'flex',
  alignItems: alignToTop ? 'flex-start' : 'center',
  order: '4',
  cursor: hasNoZones ? 'not-allowed' : 'pointer'
}))

const Checked = Styled.span({
  margin: '0 0 0 .5rem',
  display: 'none',
  order: '1'
})

const UnChecked = Styled.span(({ hasNoZones, isExistingCheckbox, theme }) => ({
  margin: '0 0 0 .5rem',
  order: '2',
  color: hasNoZones && isExistingCheckbox ? theme.colors.n06 : ''
}))

const RadioInput = Styled.input(
  {
    order: '3',
    appearance: 'none',
    [`:checked ~ ${Checked}`]: {
      display: 'block'
    },
    [`:checked ~ ${UnChecked}`]: {
      display: 'none'
    }
  }
)

const Container = Styled.div({
  margin: '0 0 .5rem',
  position: 'relative'
})

const LabelText = Styled.div(({ hasNoZones, isExistingCheckbox, theme }) => ({
  order: '4',
  color: hasNoZones && isExistingCheckbox ? theme.colors.n06 : ''
}))


const RadioGroupContainer = Styled.div(({ hasBottomMargin }) => ({
  margin: hasBottomMargin ? '0 0 1.5rem' : '0'
}))

const GridContainer = Styled.div(({ tiled }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  flexDirection: tiled ? 'row' : 'column',
  ...(tiled && ({
    '> *': { width: '47.75%' }
  }))
}))

export {
  Container,
  GridContainer,
  RadioGroupContainer,
  Title,
  LabelText,
  Label,
  Checked,
  UnChecked,
  RadioInput
}
