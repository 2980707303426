import { string } from 'yup'
import OrganizationClient from '../../../State/Organization/Client'

async function test(value = '') {
  const { organizationId } = this.parent
  const uniqueUserEmails = OrganizationClient.verifyUniqueUsersEmail(value)
  const permissionsEmails = OrganizationClient.verifyUniquePermissionsEmailInOrg(organizationId, value)
  const [permissions, userEmails] = await Promise.all([permissionsEmails, uniqueUserEmails])
  const emailCount = permissions.emailCount + userEmails
  return emailCount === 0
}

async function testDisabledEmail(value = '') {
  const { organizationId } = this.parent
  const { data } = await OrganizationClient.verifyUniquePermissionsEmailInOrg(organizationId, value)
  const emails = data.map(user => user.data())
  return emails.every(user => user && user.status === 'active')
}

const UniqueEmail = ({
  requiredMessage, emailMessage, disabledEmailMessage, uniqueEmailMessage
}) => string()
  .required(requiredMessage)
  .email(emailMessage || 'Please enter a valid email')
  .test(
    'email',
    disabledEmailMessage || 'The email ${value} is disabled', //eslint-disable-line
    testDisabledEmail
  )
  .test(
    'email',
    uniqueEmailMessage || 'The email ${value} has already been registered', //eslint-disable-line
    test
  )

export default UniqueEmail
