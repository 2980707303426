import { object } from 'yup'
import useTranslateWithLanguage from '../../Hooks/useTranslateWithLanguage'
import { CustomName } from '../Helpers'

const AttributesReadOnly = (language) => {
  const translate = useTranslateWithLanguage('formValidation', language)

  return object().shape({
    name: CustomName(translate)
  })
}

export default AttributesReadOnly
