import getSorter from './getSorters'

const sortRole = (rowA, rowB) => {
  const a = rowA.original.isPrimary ? `'Primary' + ${rowA.original.role.toLowerCase()}` : rowA.original.role.toLowerCase()
  const b = rowB.original.isPrimary ? `'Primary' + ${rowB.original.role.toLowerCase()}` : rowB.original.role.toLowerCase()

  return getSorter('role', a, b)
}

export default sortRole
