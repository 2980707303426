import React from 'react'
import PropTypes from 'prop-types'

import RadioGroup from '../../../../Components/Formik/RadioGroup'

const BooleanComponent = ({ translate }) => {
  const options = [
    {
      value: 'true',
      label: translate('labels.onValue'),
      testId: 'edit-attribute-on-value'
    },
    {
      value: 'false',
      label: translate('labels.offValue'),
      testId: 'edit-attribute-off-value'
    }
  ]
  return (
    <>
      <RadioGroup
        name="value"
        options={options}
        tiled
        title={translate('labels.value')}
      />
    </>
  )
}

BooleanComponent.propTypes = {
  translate: PropTypes.func.isRequired
}

export default BooleanComponent
