import React, {
  useMemo, useContext, useState, useEffect
} from 'react'
import { useParams } from 'react-router-dom'

import useTranslate from '../../../Utils/Hooks/useTranslate'
import useSearch from '../../../Utils/Hooks/useSearch'
import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useSorter from '../../../Utils/Hooks/useSorter'
import useErrorRouter from '../../../Utils/Hooks/useErrorRouter'
import useLocalStorage from '../../../Utils/Hooks/useLocalStorage'

import TemplatesContext from '../../../State/Templates/Context'
import PanelContext from '../../../State/Panel/Context'

import FilterWrapper from '../../FilterWrapper'
import Search from '../../Search'
import Table from '../../Table'
import Select from '../../Select'
import AttributeName from './Components/AttributeName'
import EnabledStatus from './Components/EnabledStatus'
import Value from './Components/Value'
import AttributeType from './Components/AttributeType'
import Description from './Components/Description'
import Details from './Components/Details'

const searchFields = ['name']

const TemplateTable = () => {
  const { templateId } = useParams()
  const { actions: { fetchTemplateData } } = useContext(TemplatesContext)
  const { selectors: { dataIsStale } } = useContext(PanelContext)
  const [execute, , isLoading, template, hasError] = useAsyncCallback(fetchTemplateData)
  const [selectedType, setSelectedType] = useState('all')
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredResults, setFilteredResults] = useState([])
  const isFiltered = !!searchQuery || selectedType !== 'all'
  const translate = useTranslate('templateAttributesPage')
  const translateAttributes = useTranslate('attributesPage')
  const sortAttributeName = useSorter('rowName')
  const [templateList] = useLocalStorage('templates_list', null)
  useErrorRouter(templateList, 'templateId', templateId, isLoading)

  useEffect(() => {
    execute(templateId)
  }, [dataIsStale()]) // eslint-disable-line

  useEffect(() => {
    if (template && template !== null && !hasError) {
      const reducer = (acc, current, currentIndex) => {
        if (current.name === 'Bluetooth Beacon MAC Address') { return acc }
        const combinedAttribute = { attributeId: Object.keys(template.config.sensors)[currentIndex], ...current }
        return acc.concat(combinedAttribute)
      }
      const sorter = (a, b) => {
        if (a.type.toLowerCase() > b.type.toLowerCase()) {
          return 1
        }

        if (a.type.toLowerCase() < b.type.toLowerCase()) {
          return -1
        }

        return 0
      }
      const buildCombinedAttributeData = () => (template.config ? Object.values(template.config.sensors).reduce(reducer, []) : []).sort(sorter)
      const combinedAttributeData = template ? buildCombinedAttributeData() : []
      setFilteredResults(combinedAttributeData.filter(type => selectedType === 'all' || type.type === selectedType))
    }
  }, [template, selectedType]) // eslint-disable-line

  const types = [
    'temperature',
    'humidity',
    'occupancy',
    'environmental',
    'lightRing',
    'sound',
    'bluetooth',
    'irBlaster',
    'inputOutput'
  ]
  const allOption = [
    {
      value: 'all',
      label: translate('allTypeOption')
    }
  ]

  const typeOptions = allOption.concat(...types.map(type => ({ value: type, label: translate(`${type}TypeOption`) })))
  const results = useSearch(filteredResults, searchQuery, searchFields, true)

  const columns = useMemo(
    () => [
      {
        id: 'name',
        isFirstRow: true,
        Header: translate('tableAttributeNameColumn'),
        accessor: 'name',
        Cell: ({ row }) => (<AttributeName row={row} template={template} />), /* eslint-disable-line */
        width: 150,
        sortType: sortAttributeName,
        flex: true
      },
      {
        Header: translate('tableValueColumn'),
        Cell: Value,
        accessor: 'displayValue', // displayValue comes is injected into the attribute object in the `useSearch` hook
        align: 'right',
        width: 150
      },
      {
        Header: translate('tableStatusColumn'),
        Cell: EnabledStatus,
        accessor: 'isEnabled',
        width: 144
      },
      {
        Header: translate('tableTypeColumn'),
        accessor: 'type',
        Cell: AttributeType,
        aggregate: '',
        width: 144
      },
      {
        Header: translate('tableDescriptionColumn'),
        accessor: 'description',
        Cell: ({ row }) => (<Description row={row} isDefaultTemplate={template.isDefaultTemplate} />), /* eslint-disable-line */
        width: 80,
        disableSortBy: true
      },
      {
        Header: translate('tableDetailsColumn'),
        Cell: ({ row }) => (<Details row={row} isDefaultTemplate={template.isDefaultTemplate} />), /* eslint-disable-line */
        width: 80,
        disableSortBy: true
      }
    ], [JSON.stringify(template)] // eslint-disable-line
  )

  const onSearch = (value) => {
    setSearchQuery(value)
  }

  const emptyList = {
    filteredMessage: translate('noResults'),
    unfilteredMessage: translate('emptyListMessage')
  }

  return (
    <>
      <FilterWrapper>
        <Select name={translateAttributes('filteringTypeLabel')} options={typeOptions} onChange={item => setSelectedType(item.value)} data-testid="device-status-filter" optionsTestIDRoot="device-status" />
        <Search searchFields={searchFields} onSearch={onSearch} placeholderText={translate('filterByName')} />
      </FilterWrapper>
      <Table
        isLoading={isLoading}
        columns={columns}
        data={results}
        isFiltered={isFiltered}
        emptyList={emptyList}
        initialSortBy="name"
        tableCanBeGrouped
      />
    </>
  )
}

export default TemplateTable
