import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useField, useFormikContext } from 'formik'
import DelimitedInput from '../../Form/Delimited'

const Delimited = (props) => {
  const [field, meta, helpers] = useField(props)
  const [isDelimitedFilled, setIsDelimitedFilled] = useState(false)
  const [fieldsAreBlurred, setFieldsAreBlurred] = useState(false)
  const { setTouched } = helpers
  const { setFieldValue } = useFormikContext()
  const onChange = name => value => setFieldValue(name, value)
  const { name, submitCount } = props
  const handleChange = onChange(name)
  const delimitedFieldsCheck = (isDelimitedFilled || fieldsAreBlurred) && meta.touched
  const hasError = (delimitedFieldsCheck && meta.error && meta.value !== meta.initialValue) || submitCount > 0

  return (
    <DelimitedInput {...props} {...field} handleChange={handleChange} setIsDelimitedFilled={setIsDelimitedFilled} setFieldsAreBlurred={setFieldsAreBlurred} {...(hasError ? { error: meta.error } : {})} setTouched={setTouched} />
  )
}

Delimited.propTypes = {
  name: PropTypes.string.isRequired,
  submitCount: PropTypes.number
}

Delimited.defaultProps = {
  submitCount: undefined
}

export default Delimited
