import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'

import useTranslate from '../../../Utils/Hooks/useTranslate'

import UserContext from '../../../State/User/Context'
import PanelContext from '../../../State/Panel/Context'
import useValidationSchema from '../../../Utils/Hooks/useValidationSchema'

import Fieldset from '../../Form/Fieldset'
import Text from '../../Formik/Text'
import PanelFooter from '../Components/PanelFooter'
import PanelRibbon from '../Components/PanelRibbon'

const EditProfile = ({ setIsDirty }) => {
  const { actions: { closePanel } } = useContext(PanelContext)
  const { selectors: { getUser, hasError }, actions: { updateProfile, hydrateUserData } } = useContext(UserContext)
  const { userId, firstName, lastName } = getUser()
  const { getCurrentSchema } = useValidationSchema()
  const translate = useTranslate('myAccountPanel')

  const initialValues = {
    firstName,
    lastName
  }

  const labels = {
    buttonLabel: translate('saveButton'),
    submittingLabel: translate('submittingButton'),
    cancelLabel: translate('cancelButton')
  }

  const onSubmit = async (values, actions) => {
    await updateProfile({ firstName: values.firstName, lastName: values.lastName }, translate('editName.nameUpdatedSuccessfully'))
    if (!hasError()) {
      hydrateUserData({ uid: userId })
      actions.setSubmitting(false)
      closePanel()
    }
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={getCurrentSchema('profile/edit')}
        onSubmit={onSubmit}
        onReset={closePanel}
      >
        {({
          errors, isSubmitting, handleSubmit, handleReset, isValid, dirty, submitCount
        }) => (
          <Form onChange={setIsDirty(dirty)}>
            <PanelRibbon errors={errors} submitCount={submitCount} />
            <Fieldset>
              <Text id="firstName" label={translate('editName.firstNameInputLabel')} name="firstName" placeholder={translate('editName.firstNameInputPlaceholder')} data-testid="first-name" submitCount={submitCount} />
              <Text id="lastName" label={translate('editName.lastNameInputLabel')} name="lastName" placeholder={translate('editName.lastNameInputPlaceholder')} data-testid="last-name" submitCount={submitCount} />
            </Fieldset>
            <PanelFooter
              handleReset={handleReset}
              handleSubmit={handleSubmit}
              errors={errors}
              labels={labels}
              isSubmitting={isSubmitting}
              isValid={isValid}
              testIdRoot="edit-profile"
              submitCount={submitCount}
            />
          </Form>
        )}
      </Formik>

    </>
  )
}

EditProfile.propTypes = {
  setIsDirty: PropTypes.func.isRequired
}

export default EditProfile
