const getSelectors = state => ({
  getUserEmail() {
    return state.data.email
  },
  isLoading() {
    return state.loading
  },
  isLoggedIn() {
    return state.isAuthenticated
  },
  isInitialized() {
    return state.initialized
  },
  getErrorCode() {
    return state.error.code
  },
  hasError() {
    return state.error.status
  },
  shouldRedirect() {
    return state.shouldRedirect
  },
  shouldReAuth() {
    return state.expired
  },
  shouldReAuthIsLoading() {
    return state.shouldReAuthIsLoading
  },
  getLanguagePreference() {
    return state.data && state.data.language
  },
  getOrganizations() {
    return state.data.permissions ? state.data.permissions : null
  },
  getOrganization(id) {
    return state.data.permissions.find(org => org.id === id)
  },
  getInviteData() {
    return state.inviteData
  },
  getUID() {
    return state.data ? state.data.userId : ''
  },
  getUser() {
    return state.data ? state.data : null
  },
  getReferrer() {
    return state.referrer
  },
  getAuthMagicLink() {
    return state.isAuthMagicLink
  },
  getState() {
    return state
  },
  isFromSuccessInvite() {
    return state.isFromSuccessInvite
  },
  getInviteOrgId() {
    return state.inviteOrgId
  }
})

export default getSelectors
