import styled from 'styled-components'

export const ModalContentContainer = styled.div(({ theme, hasBottomPadding }) => ({
  padding: '1.5rem 1rem 0rem',
  p: {
    marginBottom: '2rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.3px',
    color: theme.colors.n08
  },
  [`@media (min-width: ${theme.breakpoints.sm.minWidth})`]: {
    padding: hasBottomPadding ? '1.5rem 1rem' : '1.5rem 1rem 0'
  }
}))
