import styled, { keyframes, css } from 'styled-components'

const modalOpenAnimation = keyframes`
  from {
    transform: scale(0.8) translate(-60%, -60%);
    opacity: 0;
  }
  to {
    transform: scale(1) translate(-50%, -50%);
    opacity: 1;
  }
`

const modalCloseAnimation = keyframes`
  from {
    transform: scale(1) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: scale(0.8) translate(-60%, -60%);
    opacity: 0;
  }
`

const backdropOpenAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.75;
  }
`

const backdropCloseAnimation = keyframes`
  from {
    opacity: 0.75;
  }
  to {
    opacity: 0;
  }
`

export const ModalWrapper = styled.div(
  ({ theme }) => ({
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '680px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '2px',
    color: theme.colors.n08,
    backgroundColor: 'white',
    boxSizing: 'border-box',
    zIndex: '10',
    overflow: 'auto',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    width: '90%'
  }),
  css`
    &[data-state="entering"] {
      animation: ${modalOpenAnimation} 240ms cubic-bezier(0.0, 0.0, 0.2, 1) both;
    }
    &[data-state="entered"] {
      animation: ${modalOpenAnimation} 240ms cubic-bezier(0.0, 0.0, 0.2, 1) both;
    }
    &[data-state="exiting"] {
      animation: ${modalCloseAnimation} 240ms cubic-bezier(0.4, 0.0, 1, 1) both;
    }
  `
)

export const ModalBackdrop = styled.div(
  ({ theme }) => ({
    position: 'fixed',
    left: '0',
    top: '0',
    right: '0',
    bottom: '0',
    opacity: 0.75,
    backgroundColor: theme.colors.n04,
    zIndex: 10
  }),
  css`
    &[data-state="entering"] {
      animation: ${backdropOpenAnimation} 240ms cubic-bezier(0.175, 0.885, 0.320, 1.175) both;
    }
    &[data-state="entered"] {
      animation: 240ms cubic-bezier(0.175, 0.885, 0.320, 1.175) both;
    }
    &[data-state="exiting"] {
      animation: ${backdropCloseAnimation} 240ms cubic-bezier(0.0, 0.0, 0.2, 1) both;
    }
  `
)

export const closeButtonStyles = {
  position: 'absolute',
  top: '1rem',
  right: '1rem'
}
