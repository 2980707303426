const useIOType = (value) => {
  if (!value || !value.channelType) {
    return ''
  }
  switch (value.channelType) {
    case 'analogInput':
      return 'AI'
    case 'analogOutput':
      return 'AO'
    case 'binaryInput':
      return 'BI'
    case 'binaryOutput':
      return 'BO'
    default:
      return ''
  }
}

export default useIOType
