import React from 'react'
import PropTypes from 'prop-types'
import { EmptyListMessageContainer } from '../Styles'

const EmptyTableCTA = ({ children, ...rest }) => (
  <EmptyListMessageContainer data-testid="empty-list-cta-link" {...rest}>
    {children}
  </EmptyListMessageContainer>
)

EmptyTableCTA.propTypes = {
  children: PropTypes.node.isRequired
}

export default EmptyTableCTA
