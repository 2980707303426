import useValidationSchema from '../../Hooks/useValidationSchema'
import useConfigTransform from '../../Hooks/useConfigTransform'
import CoVLightComponent from './Components/CoVLightComponent'

const CoVLightField = () => {
  const { getCurrentSchema } = useValidationSchema()
  const { getCurrentTransform } = useConfigTransform()

  return {
    validationSchema: getCurrentSchema('attributes/covLight'),
    configTransform: getCurrentTransform('attributes/reportingEnabled'),
    Component: () => null,
    CoVComponent: CoVLightComponent
  }
}

export default CoVLightField
