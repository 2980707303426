import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Dropdown from '../../../../Dropdown'
import SelectButton from '../../../../Select/SelectButton'

import { contentStyles } from '../../../../Select/Styles'

const RoleSelect = ({
  name, options, onChange, optionsTestIDRoot, isFromDevices, ...rest
}) => {
  const [selectedOption, setSelectedOption] = useState(options[0])
  const [shouldClose, setShouldClose] = useState(false)

  useEffect(() => {
    if (isFromDevices) {
      setSelectedOption(options[options.findIndex(option => option.value === 'installer')])
    } else {
      setSelectedOption(options[0])
    }
  }, []) // eslint-disable-line

  const handleParentState = (input) => {
    setShouldClose(input)
  }

  const handleSelect = (option) => {
    setSelectedOption(option)
    onChange(option)
    setShouldClose(true)
  }

  return (
    <Dropdown
      ButtonComponent={SelectButton}
      name={name}
      selectedOption={selectedOption}
      contentStyles={contentStyles}
      shouldClose={shouldClose}
      updateParent={handleParentState}
      {...rest}
    >
      {options.map(option => (
        <button
          type="button"
          onClick={() => handleSelect(option)}
          key={option.value}
          data-testid={`${optionsTestIDRoot}-${option.value}`}
        >
          {option.label}
        </button>
      ))}
    </Dropdown>
  )
}

RoleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  optionsTestIDRoot: PropTypes.string,
  isFromDevices: PropTypes.bool
}

RoleSelect.defaultProps = {
  optionsTestIDRoot: 'option',
  isFromDevices: false
}

export default RoleSelect
