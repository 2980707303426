import Styled from 'styled-components'

export const Container = Styled.div(({
  theme, hasBorder, isExpandGroup, isToggle, isOnlyHeading
}) => ({
  height: isOnlyHeading ? '55px' : 'auto',
  margin: 0,
  backgroundColor: isOnlyHeading ? theme.colors.n02 : 'white',
  borderBottom: hasBorder ? `1px solid ${theme.colors.n04}` : '0px',
  padding: (!isToggle && isExpandGroup) || isOnlyHeading ? '0' : '1.5rem 1.5rem 0'
}))

export const HeadingContainer = Styled.div(({ isOnlyHeading }) => ({
  padding: isOnlyHeading ? '1rem 1.5rem' : '0 0 1.5rem'
}))
