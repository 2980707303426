import React from 'react'
import PropTypes from 'prop-types'
import { mdiInformationOutline } from '@mdi/js'

import useTranslate from '../../../../Utils/Hooks/useTranslate'
import ExpandGroup from '../../../Formik/ExpandGroup'

const EnableCoV = ({ values, children }) => {
  const translate = useTranslate('editAttributePanel')
  return (
    <>
      <ExpandGroup
        type="toggle"
        heading={translate('labels.covHeader')}
        expandCondition={values.reporting.isEnabled}
        toggleName="reporting.isEnabled"
        hasTooltip
        tooltipContent={translate('labels.covHeaderTooltip')}
        tooltipIcon={mdiInformationOutline}
        toggleTestId="enable-cov-toggle"
      >
        {children}
      </ExpandGroup>
    </>
  )
}

EnableCoV.propTypes = {
  values: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
}

export default EnableCoV
