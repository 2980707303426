import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { useParams } from 'react-router-dom'

import TemplateContext from '../../../State/Templates/Context'
import DeviceContext from '../../../State/Devices/Context'
import PanelContext from '../../../State/Panel/Context'
import useTranslate from '../../../Utils/Hooks/useTranslate'
import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useAttributeFormProperties from '../../../Utils/Hooks/useAttributeFormProperties'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'

import FieldSet from '../../Form/Fieldset'

import EnableInConfig from '../Components/EnableInConfig'
import Description from '../Components/Description'
import ReadOnlyAttributeFields from '../Components/ReadOnlyAttributeFields'
import CustomName from '../Components/CustomName'
import EnableCoV from '../Components/EnableCoV'
import PanelFooter from '../Components/PanelFooter'

const EditAttribute = ({ setIsDirty }) => {
  const { templateId, deviceId } = useParams()
  const { actions: { updateAttribute }, selectors: { getSelectedAttribute, getSelectedTemplate } } = useContext(TemplateContext)
  const { actions: { updateDeviceAttribute }, selectors: { getSelectedDevice } } = useContext(DeviceContext)
  const { actions: { closePanel, setSubmitted } } = useContext(PanelContext)

  const selectedTemplate = getSelectedTemplate()
  const selectedDevice = getSelectedDevice()

  const attribute = {
    entityId: templateId || deviceId,
    selectedAttribute: getSelectedAttribute(),
    unitsOfMeasure: templateId ? selectedTemplate.config.unitsOfMeasure : selectedDevice.config.unitsOfMeasure
  }

  const initialValues = {
    ...attribute.selectedAttribute,
    isCustomName: attribute.selectedAttribute.isCustomName.toString(),
    isCustomDescription: attribute.selectedAttribute.description.length > 0,
    value: typeof attribute.selectedAttribute.value === 'boolean' ? attribute.selectedAttribute.value.toString() : attribute.selectedAttribute.value,
    ...attribute.selectedAttribute.attributeId === 'internalAcousticOccupancyThreshold' && {
      value: {
        isCustomValue: attribute.selectedAttribute.value.isCustomValue ? 'custom' : 'default',
        customValue: attribute.selectedAttribute.value.customValue
      }
    }
  }

  const translate = useTranslate('editAttributePanel')
  const translateAttributeTypes = useTranslate('templateAttributesPage')
  const labels = {
    buttonLabel: translate('saveButton'),
    submittingButton: translate('submittingButton'),
    cancelLabel: translate('cancelButton')
  }

  const { getCurrentAttribute } = useAttributeFormProperties(translate)
  const {
    validationSchema, configTransform, Component: ValueComponent, CoVComponent
  } = getCurrentAttribute(attribute.selectedAttribute.attributeId)
  const [execute, , , result, hasError] = useAsyncCallback(templateId ? updateAttribute : updateDeviceAttribute)
  useErrorNotifier(hasError, result)

  const fields = [
    {
      header: translate('labels.type'),
      value: translateAttributeTypes(`${attribute.selectedAttribute.type}TypeOption`),
      testId: 'attribute-type'
    },
    {
      ...attribute.selectedAttribute.isReadOnly && {
        header: translate('labels.value'),
        value: translate('labels.readOnly'),
        testId: 'attribute-value'
      }
    },
    {
      ...attribute.selectedAttribute.about && {
        header: translate('labels.about'),
        value: attribute.selectedAttribute.about,
        testId: 'attribute-about'
      }
    },
    {
      header: translate('labels.bacnetLabel'),
      value: attribute.selectedAttribute.bacnetId,
      testId: 'attribute-bacnet-id'
    }
  ]

  const onSubmit = async (values, { resetForm }) => {
    const config = await configTransform(values, attribute.selectedAttribute)
    await execute(attribute.entityId, attribute.selectedAttribute.attributeId, config)
    setSubmitted()
    closePanel()
    resetForm()
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          dirty, isValid, isSubmitting, errors, handleSubmit, values, submitCount
        }) => (
          <Form onChange={setIsDirty(dirty)}>
            <EnableInConfig />
            <FieldSet>
              <CustomName values={values} selectedAttribute={attribute.selectedAttribute} />
              <ValueComponent translate={translate} attributeId={attribute.selectedAttribute.attributeId} submitCount={submitCount} values={values} />
            </FieldSet>
            {attribute.selectedAttribute.reporting && (
              <EnableCoV values={values}>
                <CoVComponent
                  translate={translate}
                  selectedAttribute={attribute.selectedAttribute}
                  unitsOfMeasure={attribute.unitsOfMeasure}
                  submitCount={submitCount}
                />
              </EnableCoV>
            )}
            <Description values={values} />
            <ReadOnlyAttributeFields fields={fields} />
            <PanelFooter
              handleReset={closePanel}
              handleSubmit={handleSubmit}
              errors={errors}
              labels={labels}
              isSubmitting={isSubmitting}
              isValid={isValid}
              testIdRoot="edit-attribute"
            />
          </Form>
        )}
      </Formik>
    </>
  )
}
EditAttribute.propTypes = {
  setIsDirty: PropTypes.func.isRequired
}

export default EditAttribute
