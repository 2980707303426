import React, { useContext } from 'react'

import useTranslate from '../../../Utils/Hooks/useTranslate'

import ModalContext from '../../../State/Modal/Context'
import PanelContext from '../../../State/Panel/Context'
import ModalContent from '../../Modal/Components/ModalContent'
import ModalFooter from '../../Modal/Components/ModalFooter'
import Button from '../../Button'

const CancelConfirmation = () => {
  const { actions: { closeModal } } = useContext(ModalContext)
  const { actions: { closePanel, resetView } } = useContext(PanelContext)
  const translate = useTranslate('cancelConfirmation')

  const handleCancelDeny = () => {
    closeModal()
  }

  const handleCancelConfirm = () => {
    closeModal()
    closePanel()
    resetView()
  }

  return (
    <>
      <ModalContent>
        <p>
          {translate('description')}
        </p>
      </ModalContent>
      <ModalFooter>
        <Button onClick={handleCancelDeny} inverted data-testid="cancel-modal-deny-button">{translate('denyButton')}</Button>
        <Button onClick={handleCancelConfirm} data-testid="cancel-modal-confirm-button">{translate('confirmButton')}</Button>
      </ModalFooter>
    </>
  )
}

export default CancelConfirmation
