import Styled from 'styled-components'

export const ForgotPasswordButton = Styled.button(({ theme }) => ({
  fontSize: '1rem',
  lineHeight: '1.5rem',
  letterSpacing: '0.2px',
  fontWeight: 500,
  border: 'none',
  color: theme.colors.b01,
  backgroundColor: 'transparent',
  cursor: 'pointer'
}))

export const ButtonLinkWrapper = Styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '1.5rem'
})
