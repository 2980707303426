import React, { useContext } from 'react'

import { format, fromUnixTime } from 'date-fns'
import useTranslate from '../../../Utils/Hooks/useTranslate'


import PanelContext from '../../../State/Panel/Context'
import OrganizationsContext from '../../../State/Organization/Context'

import ReadOnlyFields from '../Components/ReadOnlyFields'
import PanelFooter from '../../Panel/Components/PanelFooter'
import Fieldset from '../../Form/Fieldset'
import Button from '../../Button'

const ViewOrganization = () => {
  const { actions: { closePanel } } = useContext(PanelContext)
  const { selectors: { getSelectedOrganization } } = useContext(OrganizationsContext)
  const organization = getSelectedOrganization()

  const translate = useTranslate('organizationPanel')

  const getKeyValuePair = (key) => {
    if (key === 'expiresAt') {
      const ts = organization[key] ? organization[key].seconds : null
      return format(fromUnixTime(ts), 'MMM d, yyyy')
    }

    return organization[key]
  }

  const generateOrganizationField = key => ({
    header: translate(`${key}`),
    value: getKeyValuePair(key),
    testId: `read-only-${key}`
  })

  const fields = [
    generateOrganizationField('name'),
    generateOrganizationField('email'),
    generateOrganizationField('city'),
    generateOrganizationField('provinceOrState'),
    generateOrganizationField('country')
  ]
  return (
    <>
      <Fieldset hasBorder={false}>
        <ReadOnlyFields fields={fields} />
        <PanelFooter>
          <Button onClick={closePanel} inverted data-testid="view-template-close-button">{translate('closeButton')}</Button>
        </PanelFooter>
      </Fieldset>
    </>
  )
}

export default ViewOrganization
