import React, { useContext, useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import useFetchCallback from '../../../Utils/Hooks/useFetchCallback'
import useLocalStorage from '../../../Utils/Hooks/useLocalStorage'
import useTranslate from '../../../Utils/Hooks/useTranslate'

import UserContext from '../../../State/User/Context'
import Spinner from '../../Spinner'

const Invite = () => {
  const {
    selectors: {
      getUID, getInviteData, isLoading: orgListIsLoading, getOrganizations
    },
    actions: {
      processInvite, setInviteData, setReferrer, fetchUserPermissions, setSuccessInvite
    }
  } = useContext(UserContext)
  const invite = getInviteData()
  const [execute, isInitialized, isLoading, , hasError] = useFetchCallback(processInvite)
  const [, setOrg] = useLocalStorage(getUID(), null)
  const orgAdded = getOrganizations().some(org => org.id === invite.organizationId)
  const hasAcceptedSuccessfully = invite && isInitialized && !isLoading && !orgListIsLoading() && ((invite.role === 'admin' && orgAdded) || invite.role === 'installer')
  const translate = useTranslate('invites')
  const inviteRetrievalError = !invite || (invite && Object.keys(invite).length === 0)

  useEffect(() => {
    const fetchInviteAndOrgs = async (invId) => {
      await execute(invId)
      await fetchUserPermissions(getUID())
    }
    if (invite) {
      fetchInviteAndOrgs(invite.inviteId)
      if (invite.role === 'admin') {
        setOrg(invite.organizationId)
        setSuccessInvite(invite.organizationId)
      }
      if (invite.role === 'installer') {
        setSuccessInvite(invite.organizationId)
      }
    }
  }, []) // eslint-disable-line

  if (hasAcceptedSuccessfully) {
    setInviteData(null)
    setReferrer(null)
    return <Redirect to="/organization" />
  }

  if (inviteRetrievalError || hasError) {
    return <Redirect to={{ pathname: '/error', state: { isPermissionError: true, shouldSignOut: true } }} />
  }

  return <Spinner message={translate('processingInvite')} />
}

export default Invite
