import styled from 'styled-components'

const getBackgroundColor = (theme, type) => {
  switch (type) {
    case 'success':
      return theme.colors.s02
    case 'warning':
      return theme.colors.s03
    case 'error':
      return theme.colors.s05
    case 'info':
    default:
      return theme.colors.b01
  }
}

export const RibbonContainer = styled.div(({
  theme, isFixed, isCentered, isSticky, type
}) => ({
  position: isFixed ? 'fixed' : 'static',
  justifyContent: isCentered ? 'center' : 'flex-start',
  display: 'flex',
  alignItems: 'center',
  padding: '0 1rem',
  minHeight: '40px',
  width: '100%',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  letterSpacing: '0.2px',
  color: type === 'warning' ? theme.colors.n09 : 'white',
  backgroundColor: getBackgroundColor(theme, type),
  ...isSticky && ({
    position: 'sticky',
    top: '0',
    zIndex: '10'
  })
}))
