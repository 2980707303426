import { object, number } from 'yup'

import useTranslateWithLanguage from '../../Hooks/useTranslateWithLanguage'
import { ConditionalRequirement, Reporting } from '../Helpers'

const EditAttributeIO = (language) => {
  const translate = useTranslateWithLanguage('formValidation', language)
  const validation = () => number().required(translate('ioCov')).min(0, translate('ioCovMin'))
  return object().shape({
    name: ConditionalRequirement('isCustomName', 'true', translate('customName')),
    reporting: Reporting(translate, validation)
  })
}

export default EditAttributeIO
