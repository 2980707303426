import React from 'react'
import { useField } from 'formik'

import TextArea from '../../Form/TextArea'


const Component = (props) => {
  const [field, meta] = useField(props)
  const hasError = meta.touched && meta.error
  return (
    <>
      <TextArea
        {...props}
        {...field}
        {...(hasError ? { error: meta.error } : {})}
      />
    </>
  )
}

export default Component
