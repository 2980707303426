import useValidationSchema from '../../Hooks/useValidationSchema'
import useConfigTransform from '../../Hooks/useConfigTransform'
import OffSelectComponent from './Components/OffSelectComponent'

const NumberField = () => {
  const { getCurrentSchema } = useValidationSchema()
  const { getCurrentTransform } = useConfigTransform()

  return {
    validationSchema: getCurrentSchema('attributes/readOnly'),
    configTransform: getCurrentTransform('attributes/offSelectField'),
    Component: OffSelectComponent,
    CoVComponent: () => null
  }
}

export default NumberField
