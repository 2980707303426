const CreateDevice = (values, jobsite, templateData, jobsiteId) => {
  const { config: { firmware, reporting } } = jobsite
  const {
    mqtt: {
      isEnabled, sensorReportType, sensorReportRate, fixedSampleRate, broker
    }
  } = values

  let firmwareEnabled = values.firmware.isEnabled
  let firmwareLocation = values.firmware.location
  let firmwareVersion = values.firmware.version

  if (firmware !== undefined) {
    firmwareEnabled = values.firmware.isEnabled === true ? values.firmware.isEnabled : firmware.isEnabled
    firmwareLocation = values.firmware.isEnabled === true ? values.firmware.location : firmware.location
    firmwareVersion = values.firmware.isEnabled === true ? values.firmware.version : firmware.version
  }

  const baseConfig = {
    name: values.device.deviceName,
    jobsiteId,
    templateId: values.templateId,
    network: values.network,
    bacnet: values.bacnet,
    reporting,
    unitsOfMeasure: templateData.config.unitsOfMeasure,
    isNewZone: values.zone.isNewZone === 'true',
    blePin: values.blePin.toString(),
    firmware: {
      isEnabled: firmwareEnabled,
      ...firmwareEnabled && {
        location: firmwareLocation,
        version: firmwareVersion
      }
    }
  }
  const baseMqttSettings = {
    isEnabled,
    sensorReportType,
    sensorReportRate,
    ...sensorReportType === 'fixed'
      ? { fixedSampleRate }
      : {}
  }
  const customBrokerValues = {
    addressType: broker && broker.addressType === 'brokerIp' ? 'ip' : 'url',
    port: broker && broker.port,
    ...broker && broker.addressType === 'brokerIp'
      ? { ip: broker && broker.brokerIp }
      : { url: broker && broker.brokerUrl }
  }
  const mqttBrokerValues = {
    ...baseMqttSettings,
    broker: {
      brokerType: broker && broker.brokerType,
      ...broker && broker.brokerType === 'custom'
        ? customBrokerValues
        : {}
    }
  }
  const zoneOptions = values.zone.isNewZone === 'true'
    ? { zoneName: values.zone.zoneName }
    : { zoneId: values.zone.id }
  const config = Object.assign(
    {},
    baseConfig,
    zoneOptions,
    isEnabled
      ? { mqtt: mqttBrokerValues }
      : { mqtt: { isEnabled: false } }
  )
  return config
}

export default CreateDevice
