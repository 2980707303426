import React from 'react'
import PropTypes from 'prop-types'
import { Link as ReactRouterLink, useParams } from 'react-router-dom'

import Link from '../../../../Link'
import { TableCell } from '../../../../Table'

const JobsiteDetailsLink = ({ row }) => {
  const { name, jobsiteId, status } = row.original
  const { organizationId } = useParams()

  return (
    <TableCell>
      {status === 'active'
        ? (
          <Link
            to={`/organization/${organizationId}/jobs/${jobsiteId}`}
            ReactRouterComponent={ReactRouterLink}
            data-testid={`jobs-name-link-${jobsiteId}`}
            title={name}
          >
            {name}
          </Link>
        )
        : <>{name}</>
      }
    </TableCell>
  )
}

JobsiteDetailsLink.propTypes = {
  row: PropTypes.object.isRequired
}

export default JobsiteDetailsLink
