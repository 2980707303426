import OrgClient from './Client'
import JobClient from '../Jobs/Client'
import TemplateClient from '../Templates/Client'
import UserClient from '../User/Client'
import { types } from './Reducer'

const getActions = (state, dispatch, toast) => {
  const getOrg = async (org) => {
    try {
      dispatch({ type: types.FETCH_ORG_PENDING })
      const { id } = org
      const orgRecord = await OrgClient.getOrgData(id)
      const userPermissions = await OrgClient.fetchOrgPermissions(id, {})
      const payload = Object.assign({}, orgRecord.data(), { users: userPermissions })
      dispatch({ type: types.FETCH_ORG_SUCCESS, payload: { id: org.id, ...payload } })
    } catch (error) {
      dispatch({ type: types.FETCH_ORG_FAILURE, payload: error.code })
    }
  }

  const fetchOrganizationInfo = async (orgId) => {
    const orgRecord = OrgClient.getOrgData(orgId)
    const primaryAdminRecord = OrgClient.fetchPrimaryAdmin(orgId)
    const [orgData, primaryAdmin] = await Promise.all([orgRecord, primaryAdminRecord])
    const { accountId, name, email } = orgData.data()
    return {
      accountId, name, primaryAdmin, email
    }
  }

  const getOrgListDetails = async (orgList, filters = {}) => {
    try {
      dispatch({ type: types.FETCH_ORG_LIST_DETAILS_PENDING })
      const orgsRecord = await OrgClient.getOrgListDetails(orgList, filters)
      const payload = orgsRecord.reduce((accumulator, current) => {
        if (current.data()) {
          const {
            name, accountId, subscriptionType, expiresAt, status
          } = current.data()
          accumulator.push({
            name, accountId, subscriptionType, expiresAt, status
          })
        }
        return accumulator
      }, [])
      dispatch({ type: types.FETCH_ORG_LIST_DETAILS_SUCCESS, payload })
    } catch (error) {
      dispatch({ type: types.FETCH_ORG_LIST_DETAILS_FAILURE, payload: error })
    }
  }

  const getOrgByName = async (name) => {
    try {
      const org = await OrgClient.getOrgByName(name)
      return org
    } catch (e) {
      return null
    }
  }

  const createOrganization = async (formData) => {
    try {
      const { inviteData } = await OrgClient.createOrganization(formData)
      return inviteData
    } catch (error) {
      return null
    }
  }

  const getOrgs = async (filters = {}) => {
    try {
      dispatch({ type: types.GET_ORGS_PENDING })
      const payload = await OrgClient.getOrganizations(filters)
      dispatch({ type: types.GET_ORGS_SUCCESS, payload })
    } catch (e) {
      dispatch({ type: types.GET_ORGS_FAILURE, payload: [] })
    }
  }

  const setSelectedOrganization = org => dispatch({ type: types.SET_SELECTED_ORGANIZATION, payload: org })
  const updateOrganization = async (organizationId, formData) => {
    try {
      dispatch({ type: types.UPDATE_ORGANIZATION_PENDING })
      const response = await OrgClient.updateOrganization(organizationId, formData)
      await OrgClient.removePrimaryAdmin(organizationId)
      const isEmailExistsInOrg = await OrgClient.verifyUniquePermissionsEmailInOrg(organizationId, formData.email)
      if (isEmailExistsInOrg.emailCount === 0) {
        await OrgClient.createInvite({
          jobsites: [],
          email: formData.email,
          language: 'en',
          role: 'admin',
          organizationId
        })
      } else {
        await OrgClient.setPrimaryAdmin(organizationId, isEmailExistsInOrg.data[0].ref.id)
      }
      dispatch({ type: types.UPDATE_ORGANIZATION_SUCCESS, payload: response })
    } catch (error) {
      dispatch({ type: types.UPDATE_ORGANIZATION_FAILURE, payload: error })
    }
  }
  const createInvite = async formData => OrgClient.createInvite(formData)

  const setSelectedUser = (userData) => {
    dispatch({ type: types.SET_SELECTED_USER, payload: userData })
  }

  const setSelectedOrg = (orgId) => {
    dispatch({ type: types.SET_SELECTED_ORG, payload: orgId })
  }

  const updateUser = async (orgId, userId, formData) => {
    try {
      dispatch({ type: types.UPDATE_USER_PENDING })
      await OrgClient.updateUser(orgId, userId, formData)
      dispatch({ type: types.UPDATE_USER_SUCCESS })
    } catch (error) {
      dispatch({ type: types.UPDATE_USER_FAILURE, payload: error.code })
    }
  }

  const fetchUsers = async (organizationId, filters = {}) => {
    try {
      const existingUsers = await OrgClient.fetchOrgPermissions(organizationId, filters)
      const invites = await OrgClient.fetchOrgInvites(organizationId, filters)
      const pendingUsers = invites.reduce((accumulator, current) => {
        const {
          inviteId, firstName, lastName, language, role, email, createdAt, jobsites
        } = current
        return accumulator.concat({
          inviteId, firstName, lastName, language, role, email, jobsites, createdAt
        })
      }, [])
      const allUsers = [...existingUsers, ...pendingUsers]
      return allUsers
    } catch (error) {
      return error
    }
  }

  const fetchJobsites = async (orgId) => {
    const jobsites = await OrgClient.fetchJobsites(orgId)
    return jobsites
  }

  const updateInvite = (inviteId, userData) => OrgClient.updateInvite(inviteId, userData)

  const setSelectedUsers = userData => dispatch({ type: types.SET_SELECTED_USERS, payload: userData })

  const removeCheckedUser = (userData) => {
    const filterUsers = checkedUser => (checkedUser.userId && checkedUser.userId !== userData.userId) || (checkedUser.inviteId && checkedUser.inviteId !== userData.inviteId)
    const filteredUsers = state.checkedUsers.filter(filterUsers)
    dispatch({ type: types.REMOVE_CHECKED_USERS, payload: filteredUsers })
  }

  const clearCheckedUsers = () => dispatch({ type: types.CLEAR_CHECKED_USERS })

  const deactivateUsers = async (organizationId, checkedUsers, notificationMessages) => {
    const { operationSuccess, operationFailure } = notificationMessages
    try {
      await OrgClient.deactivateUsers(organizationId, checkedUsers)
      toast.addNotification(operationSuccess, 'success', 4)
    } catch (error) {
      toast.addNotification(operationFailure, 'error', 4)
    }
  }

  const clearCheckedOrganizations = () => dispatch({ type: types.CLEAR_ORGANIZATIONS })

  const updateOrganizations = async (organizations, notificationMessages) => {
    const { operationSuccess, operationFailure } = notificationMessages
    try {
      dispatch({ type: types.UPDATE_ORGANIZATION_PENDING })
      await OrgClient.updateOrganizations(organizations)
      dispatch({ type: types.UPDATE_ORGANIZATION_SUCCESS })
      toast.addNotification(operationSuccess, 'success', 4)
    } catch (error) {
      dispatch({ type: types.UPDATE_ORGANIZATION_FAILURE })
      toast.addNotification(operationFailure, 'error', 4)
    }
  }

  const deleteOrganizations = async (organizations, notificationMessages) => {
    const { operationSuccess, operationFailure } = notificationMessages
    try {
      dispatch({ type: types.UPDATE_ORGANIZATION_PENDING })
      const promises = organizations.map(async (organization) => {
        await UserClient.deleteSingleOrgUsersFromOrg(organization)
        await UserClient.deleteAllOrgInvites(organization)
        await TemplateClient.deleteTemplatesByOrgAsync(organization)
        await JobClient.deleteJobSitesAndRelatedDevicesByOrgAsync(organization)
      })
      await Promise.all(promises)
      await OrgClient.deleteOrganizations(organizations)
      dispatch({ type: types.UPDATE_ORGANIZATION_SUCCESS })
      toast.addNotification(operationSuccess, 'success', 4)
    } catch (error) {
      dispatch({ type: types.UPDATE_ORGANIZATION_FAILURE })
      toast.addNotification(operationFailure, 'error', 4)
    }
  }

  return {
    getOrg,
    getOrgs,
    getOrgListDetails,
    fetchOrganizationInfo,
    createInvite,
    getOrgByName,
    createOrganization,
    setSelectedOrganization,
    updateOrganization,
    setSelectedUser,
    setSelectedOrg,
    updateUser,
    fetchUsers,
    fetchJobsites,
    updateInvite,
    setSelectedUsers,
    removeCheckedUser,
    deactivateUsers,
    updateOrganizations,
    deleteOrganizations,
    clearCheckedUsers,
    clearCheckedOrganizations
  }
}


export default getActions
