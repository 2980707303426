import styled from 'styled-components'

export const PageInfoContainer = styled.div(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  marginBottom: '24px',
  '& > svg:first-child': {
    color: theme.colors.n08,
    marginLeft: '1rem',
    marginRight: '1rem'
  },
  [`@media(min-width: ${theme.breakpoints.xl.minWidth})`]: {
    marginBottom: '0'
  }
}))
