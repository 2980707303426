import React from 'react'
import PropTypes from 'prop-types'
import { MaterialCheckbox as Checkbox } from '../Styles'

const HeaderCheckbox = ({
  getToggleAllPageRowsSelectedProps, toggleRowSelected, isAllRowsSelected, state, disabledRows
}) => {
  const { onChange } = getToggleAllPageRowsSelectedProps()
  // library specific model
  const { selectedRowIds } = state
  const selectedRows = Object.keys(selectedRowIds)

  const handleChange = async (args) => {
    await onChange(args)
    disabledRows.forEach(row => toggleRowSelected(row, false))
  }

  return (
    <>
      <Checkbox
        data-testid="table-header-checkbox"
        onChange={handleChange}
        checked={selectedRows.length > 0}
        color="primary"
        inputProps={{
          'aria-label': 'primary checkbox'
        }}
        indeterminate={selectedRows.length > 0 && !isAllRowsSelected}
      />
    </>
  )
}
HeaderCheckbox.propTypes = {
  getToggleAllPageRowsSelectedProps: PropTypes.func.isRequired,
  toggleRowSelected: PropTypes.func.isRequired,
  isAllRowsSelected: PropTypes.bool.isRequired,
  state: PropTypes.object.isRequired,
  disabledRows: PropTypes.array
}

HeaderCheckbox.defaultProps = {
  disabledRows: []
}

export default HeaderCheckbox
