const getSorter = (type, a, b) => {
  switch (type) {
    case 'email':
    case 'org':
    case 'role':
    case 'rowName':
      return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
    case 'firstName':
      return a.localeCompare(b, undefined, { sensitivity: 'base' })
    default:
      return a.localeCompare(b)
  }
}

export default getSorter
