import React from 'react'
import PropTypes from 'prop-types'
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined'
import { Tooltip } from '@material-ui/core'

import { TableCell } from '../../../../Table'


const Description = ({ row }) => {
  const { original: { description } } = row
  return (
    <TableCell>
      { description && description !== '' && (
        <Tooltip placement="top" title={description} arrow data-testid="tooltip">
          <ChatOutlinedIcon />
        </Tooltip>
      )}
    </TableCell>
  )
}

Description.propTypes = {
  row: PropTypes.object.isRequired
}

export default Description
