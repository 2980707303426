import { object } from 'yup'
import { RequiredField } from '../Helpers'
import useTranslateWithLanguage from '../../Hooks/useTranslateWithLanguage'

const EditProfileSchema = (language) => {
  const translate = useTranslateWithLanguage('formValidation', language)

  return object().shape({
    firstName: RequiredField(translate('firstNameIsRequired')),
    lastName: RequiredField(translate('lastNameIsRequired'))
  })
}

export default EditProfileSchema
