import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import PanelFooter from '../../../Panel/Components/PanelFooter'
import Button from '../../../Button'
import SubmitButton from '../SubmitButton'
import PanelContext from '../../../../State/Panel/Context'

const Component = ({
  labels, handleSubmit, isValid, errors, isSubmitting, testIdRoot, submitCount, isChangePassword, jobsiteCount, isInviteUser, isInstaller
}) => {
  const { actions: { closePanel, resetView } } = useContext(PanelContext)
  const history = useHistory()

  const handleCancel = () => {
    if (isChangePassword) {
      history.push('/my-account')
    }
    closePanel()
    resetView()
  }

  const { cancelLabel } = labels

  return (
    <PanelFooter>
      <Button onClick={handleCancel} inverted data-testid={`${testIdRoot}-cancel-button`}>{cancelLabel}</Button>
      <SubmitButton
        labels={labels}
        handleSubmit={handleSubmit}
        errors={errors}
        isSubmitting={isSubmitting}
        testIdRoot={testIdRoot}
        isValid={isValid}
        submitCount={submitCount}
        jobsiteCount={jobsiteCount}
        isInviteUser={isInviteUser}
        isInstaller={isInstaller}
      />
    </PanelFooter>
  )
}

Component.propTypes = {
  labels: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  testIdRoot: PropTypes.string.isRequired,
  submitCount: PropTypes.number.isRequired,
  isChangePassword: PropTypes.bool,
  isInviteUser: PropTypes.bool,
  jobsiteCount: PropTypes.number,
  isInstaller: PropTypes.bool
}

Component.defaultProps = {
  errors: undefined,
  isChangePassword: false,
  isInviteUser: false,
  jobsiteCount: null,
  isInstaller: false
}

export default Component
