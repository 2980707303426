import React from 'react'
import PropTypes from 'prop-types'

const AttributeName = ({ row, device }) => {
  const {
    original: {
      name
    }
  } = row
  const { namingConvention, displayName } = device

  if (!namingConvention) {
    return <><p>{`${displayName} - ${name}`}</p></>
  }

  if (!namingConvention.isEnabled) {
    return <><p>{name}</p></>
  }

  return <>{namingConvention.value === 'suffix' ? (<p>{`${name} - ${displayName}`}</p>) : (<p>{`${displayName} - ${name}`}</p>)}</>
}

AttributeName.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  device: PropTypes.shape({
    namingConvention: PropTypes.string,
    displayName: PropTypes.string.isRequired
  })
}

AttributeName.defaultProps = {
  device: {
    namingConvention: undefined
  }
}

export default AttributeName
