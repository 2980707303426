import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'

import Organization from '../PageComponents/Organization'
import SignIn from '../PageComponents/SignIn'
import Dashboard from '../PageComponents/Dashboard'
import CreatePassword from '../PageComponents/CreatePassword'
import Invite from '../PageComponents/Invite'
import DownloadApp from '../PageComponents/DownloadApp'
import NotFound from '../PageComponents/NotFound'
import PublicRoute from './Components/PublicRoute'
import PrivateRoute from './Components/PrivateRoute'
import InviteRoute from './Components/InviteRoute'
import MagicLinkRouter from './Components/MagicLinkRouter'
import ErrorPage from './Components/ErrorPage'
import ErrorBoundary from '../ErrorBoundary'

const Navigator = () => (
  <Router>
    <ErrorBoundary>
      <MagicLinkRouter>
        <Switch>
          <PublicRoute exact path="/" component={SignIn} />
          <Route path="/create-password" component={CreatePassword} />
          <InviteRoute path="/invite" component={Invite} />
          <PrivateRoute path="/organization" component={Organization} />
          <PrivateRoute path="/my-account" component={Dashboard} />
          <Route path="/download" component={DownloadApp} />
          <Route path="/error" component={ErrorPage} />
          <Route component={NotFound} />
        </Switch>
      </MagicLinkRouter>
    </ErrorBoundary>
  </Router>
)

export default Navigator
