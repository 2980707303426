/* eslint-disable no-template-curly-in-string */
import { object } from 'yup'
import {
  RequiredField,
  UniqueEmail,
  UniqueCompanyName
  // UniqueAccountId
} from '../Helpers'
import useTranslateWithLanguage from '../../Hooks/useTranslateWithLanguage'

const CreateOrganizationSchema = (language) => {
  const translate = useTranslateWithLanguage('formValidation', language)
  const emailMessages = {
    requiredMessage: translate('emailIsRequired'),
    emailMessage: translate('emailIsNotValid'),
    disabledEmailMessage: translate('organization.disabledEmail'),
    uniqueEmailMessage: translate('organization.uniqueEmail')
  }

  // const accountIdMessages = {
  //   requiredMessage: translate('accountIdIsRequired'),
  //   uniqueAccountIdMessage: translate('organization.uniqueAccountId')
  // }

  const companyNameMessages = {
    requiredMessage: translate('orgNameRequired'),
    unqiueCompanyNameMessage: translate('organization.uniqueOrgName')
  }

  return object().shape({
    name: UniqueCompanyName(companyNameMessages),
    // accountId: UniqueAccountId(accountIdMessages),
    email: UniqueEmail(emailMessages),
    city: RequiredField(translate('cityIsRequired')),
    provinceOrState: RequiredField(translate('provinceOrStateisRequired')),
    country: RequiredField(translate('countryIsRequired'))
  })
}

export default CreateOrganizationSchema
