import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@mdi/react'
import { mdiCheckCircle } from '@mdi/js'

import useTranslate from '../../../../../Utils/Hooks/useTranslate'

import { TableCell } from '../../../../Table'
import { Status } from '../../../../Table/Styles'

const DeviceStatus = ({ row }) => {
  const { phase } = row.original
  const translate = useTranslate('tableFiltering')
  const isConfigured = phase === 'configured' || phase === 'issue'

  return (
    <TableCell>
      <Status isConfigured={isConfigured} data-testid="devices-device-status">
        {isConfigured
          ? <><Icon path={mdiCheckCircle} size={0.7} />{translate('statusConfigured')}</>
          : translate('statusNotConfigured')
        }
      </Status>
    </TableCell>
  )
}

DeviceStatus.propTypes = {
  row: PropTypes.object.isRequired
}

export default DeviceStatus
