import useValidationSchema from '../../Hooks/useValidationSchema'
import useConfigTransform from '../../Hooks/useConfigTransform'

const ReadOnlyField = () => {
  const { getCurrentSchema } = useValidationSchema()
  const { getCurrentTransform } = useConfigTransform()

  return {
    validationSchema: getCurrentSchema('attributes/readOnly'),
    configTransform: getCurrentTransform('attributes/readOnly'),
    Component: () => null,
    CoVComponent: () => null
  }
}

export default ReadOnlyField
