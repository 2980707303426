import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import Context from './Context'
import getActions from './Actions'
import getSelectors from './Selectors'
import { reducer, initialState } from './Reducer'

const ZonesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = getActions(state, dispatch)
  const selectors = getSelectors(state)

  return (
    <Context.Provider value={{
      state,
      actions,
      selectors,
      dispatch
    }}
    >
      {children}
    </Context.Provider>
  )
}

ZonesProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default ZonesProvider
