import Config from '../../Config'

const useFeatureFlags = () => {
  const { featureFlags } = Config
  const getFeatureFlag = featureFlag => featureFlags[featureFlag]
  return {
    getFeatureFlag
  }
}

export default useFeatureFlags
