import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import Checkbox from '../../Form/Checkbox/Checkbox'

const Component = ({
  value, setError, ...rest
}) => {
  const [field, meta] = useField({ value, ...rest })
  const hasError = meta.touched && meta.error
  setError(meta.error)
  return (
    <Checkbox
      {...field}
      {...rest}
      {...(hasError && ({ error: meta.error }))}
      value={value.toString()}
      checked={field.value ? field.value.includes(value.toString()) : false}
    />
  )
}

Component.propTypes = {
  value: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired
}

export default Component
