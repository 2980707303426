import styled from 'styled-components'

export const FooterContainer = styled.footer(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '4rem',
  color: theme.colors.n08,
  fontSize: '0.75rem',
  fontWeight: 500,
  letterSpacing: '0.2px',
  lineHeight: 1,
  borderTop: '1px solid',
  borderColor: theme.colors.n04
}))

export const FooterItems = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '24rem',
  margin: '0 auto',
  '& > *': {
    marginRight: '1rem',
    color: theme.colors.n08,
    textDecoration: 'none',
    ':focus': {
      outline: 'none',
      boxShadow: `0 0 0 1px ${theme.colors.n05}`
    }
  }
}))

export const SelectWrapper = styled.div(({ currentSelectionLabel }) => ({
  position: 'relative',
  '&::after': {
    backgroundColor: 'white',
    top: 4,
    left: 8,
    pointerEvents: 'none',
    content: `"${currentSelectionLabel}"`,
    position: 'absolute'
  }
}))

export const StyledSelect = styled.select(({ theme }) => ({
  position: 'relative',
  padding: '0.25rem 0.5rem 0.25rem 0.5rem',
  width: '136px',
  border: 'none',
  borderRadius: '2px',
  color: theme.colors.n08,
  letterSpacing: '0.2px',
  backgroundColor: 'transparent',
  background: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' style=\'width:24px;height:24px\' transform=\'scale(0.75)\' viewBox=\'0 0 24 24\'%3E%3Cpath fill=\'%23394F5F\' d=\'M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z\' /%3E%3C/svg%3E") no-repeat 99% 50%',
  ':focus': {
    outline: 'none',
    boxShadow: `0 0 0 1px ${theme.colors.n05}`
  },
  ':disabled': {
    color: theme.colors.n06,
    cursor: 'not-allowed'
  },
  '::placeholder': {
    color: theme.colors.n05
  },
  ':-moz-focusring': {
    color: 'transparent',
    textShadow: '0 0 0 #000'
  }
}))
