import React from 'react'
import PropTypes from 'prop-types'

import useWindowSize from '../../Utils/Hooks/useWindowSize'
import { PageContainer } from './Styles'

const PageBoundary = ({ children, isCentered }) => {
  const windowSize = useWindowSize()

  return (
    <PageContainer windowSize={windowSize} isCentered={isCentered}>
      {children}
    </PageContainer>
  )
}

PageBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  isCentered: PropTypes.bool
}

PageBoundary.defaultProps = {
  isCentered: false
}

export default PageBoundary
