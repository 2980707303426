import React from 'react'
import PropTypes from 'prop-types'
import { mdiInformationOutline } from '@mdi/js'

import Text from '../../../../Components/Formik/Text'

const CoVLightComponent = ({ translate, unitsOfMeasure, ...rest }) => {
  const { light } = unitsOfMeasure
  return (
    <>
      <Text
        name="reporting.value"
        label={translate('labels.covAcceptable')}
        accessoryText={`${light} ${translate('labels.covLastKnown')}`}
        prefixText="+/-"
        textHelper={translate('labels.covLightHelper')}
        hasTooltip
        tooltipIcon={mdiInformationOutline}
        tooltipContent={translate('labels.covRangeTooltip')}
        type="number"
        min="0"
        max="100"
        data-testid="cov-light-input"
        {...rest}
      />
    </>
  )
}

CoVLightComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  unitsOfMeasure: PropTypes.object.isRequired
}

export default CoVLightComponent
