const getSelectors = state => ({
  getState() {
    return state
  },
  isLoading() {
    return state.loading
  },
  getFirmwares() {
    return state.data && state.data.files
  },
  getSelectedFirmware() {
    return state.selectedFirmware
  }
})

export default getSelectors
