import React from 'react'
import PropTypes from 'prop-types'
import { Link as ReactRouterLink, useParams } from 'react-router-dom'

import Link from '../../../Link'
import { TableCell } from '../../../Table'

const TemplateName = ({ row }) => {
  const { name, templateId } = row.original
  const { organizationId } = useParams()

  return (
    <TableCell>
      <Link to={`/organization/${organizationId}/templates/${templateId}`} ReactRouterComponent={ReactRouterLink} data-testid="templates-name-link">
        {name}
      </Link>
    </TableCell>
  )
}

TemplateName.propTypes = {
  row: PropTypes.object.isRequired
}

export default TemplateName
