import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import { RadioGroup } from '../../Form/Radio'


const Component = ({
  title, testid, options, ...props
}) => {
  const [field, meta] = useField(props)
  const hasError = meta.touched && meta.error

  return (
    <RadioGroup
      title={title}
      options={options}
      testid={testid}
      {...props}
      {...field}
      {...(hasError ? { error: meta.error } : {})}
    />
  )
}

Component.propTypes = {
  options: PropTypes.array.isRequired,
  title: PropTypes.string,
  testid: PropTypes.string
}

Component.defaultProps = {
  title: null,
  testid: null
}

export default Component
