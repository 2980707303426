import React, {
  useState,
  useEffect,
  useContext,
  useMemo
} from 'react'
import PropTypes from 'prop-types'

import useTranslate from '../../../Utils/Hooks/useTranslate'
import useSearch from '../../../Utils/Hooks/useSearch'
import useSorter from '../../../Utils/Hooks/useSorter'
import OrgContext from '../../../State/Organization/Context'
import PanelContext from '../../../State/Panel/Context'
import ModalContext from '../../../State/Modal/Context'
import UserContent from '../../../State/User/Context'
import FilterWrapper from '../../FilterWrapper'
import { Tab, TabGroup } from '../../TabGroup'
import Search from '../../Search'
import Table from '../../Table'
import HeaderCheckbox from '../../Table/Components/HeaderCheckbox'
import RowCheckbox from '../../Table/Components/RowCheckbox'
import {
  EditButton, OrganizationName, OrgAdmin
} from './Components'

const searchFields = ['name']

const OrganizationsTable = ({ setSelectedCheckboxes, setStatus, status }) => {
  const { selectors: { getUser } } = useContext(UserContent)
  const { actions: { changeView }, selectors: { dataIsStale } } = useContext(PanelContext)
  const { selectors: { dataIsStale: modalDataIsStale } } = useContext(ModalContext)
  const {
    selectors: {
      getOrganizations: getDetailedOrgList, isLoading
    },
    actions: {
      setSelectedOrg,
      getOrgs,
      clearCheckedOrganizations
    }
  } = useContext(OrgContext)
  const translate = useTranslate('organizationsPage')
  const translateTable = useTranslate('tableFiltering')
  const [searchQuery, setSearchQuery] = useState('')
  const activeOrgs = getDetailedOrgList() && getDetailedOrgList().filter(org => org.status === 'active')
  const archivedOrgs = getDetailedOrgList() && getDetailedOrgList().filter(org => org.status === 'archived')
  const results = useSearch(status === 'archived' ? archivedOrgs : activeOrgs, searchQuery, searchFields)
  const isFiltered = !!searchQuery
  const sortOrganization = useSorter('rowName')

  const onSearch = (value) => {
    setSearchQuery(value)
  }

  const columns = useMemo(
    () => [
      {
        id: 'selection',
        groupByBoundary: true,
        Header: HeaderCheckbox,
        Cell: RowCheckbox,
        width: 56
      },
      {
        Header: translate('tableNameColumn'),
        accessor: 'name',
        Cell: ({ row }) => (<OrganizationName row={row} setSelectedOrg={setSelectedOrg} />), /* eslint-disable-line */
        width: 275,
        flex: true,
        sortType: sortOrganization
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ row }) => (<OrgAdmin row={row} />), /* eslint-disable-line */
        width: 205,
        flex: true
      },
      // commenting these columns out until requirements are made more clear: CD-409
      // {
      //   Header: translate('tableAccountIdColumn'),
      //   accessor: 'accountId',
      //   Cell: AccountId,
      //   width: 144,
      //   align: 'right'
      // },
      // {
      //   Header: translate('tableExpiryDateColumn'),
      //   accessor: 'expiresAt',
      //   Cell: ExpiryDate,
      //   width: 168,
      //   align: 'right'
      // },
      {
        Header: translate('tableDetailsColumn'),
        id: 'edit',
        Cell: EditButton,
        width: 80,
        align: 'right'
      }
    ], [] // eslint-disable-line
  )

  useEffect(() => {
    if (getUser().admin) {
      getOrgs()
    }
  }, [dataIsStale(), modalDataIsStale()])

  useEffect(() => {
    if (modalDataIsStale()) {
      getOrgs()
    }
  }, [modalDataIsStale()]) // eslint-disable-line

  const handleCreateClick = () => {
    changeView('organizations/create')
  }

  const handleTabClick = (tab) => {
    setStatus(tab)
    clearCheckedOrganizations()
  }

  const emptyList = {
    filteredMessage: translate('noResults'),
    unfilteredMessage: translate('emptyListMessage'),
    emptyArchive: translate('emptyArchive'),
    ctaLabel: translate('createOrganizationButton'),
    ctaFunc: handleCreateClick
  }

  return (
    <>
      <TabGroup>
        <Tab active={status === 'active'} type="button" onClick={() => handleTabClick('active')} data-testid="org-active-tab">{translateTable('active')}</Tab>
        <Tab active={status === 'archived'} type="button" onClick={() => handleTabClick('archived')} data-testid="org-archive-tab">{translateTable('archived')}</Tab>
      </TabGroup>
      <FilterWrapper>
        <Search searchFields={searchFields} onSearch={onSearch} placeholderText={translateTable('filterByName')} searchTestId="organizations" />
      </FilterWrapper>
      <Table
        isLoading={isLoading()}
        columns={columns}
        data={results}
        isFiltered={isFiltered}
        emptyList={emptyList}
        status={status}
        setSelectedCheckboxes={setSelectedCheckboxes}
        initialSortBy="name"
      />
    </>
  )
}

OrganizationsTable.propTypes = {
  setSelectedCheckboxes: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
}


export default OrganizationsTable
