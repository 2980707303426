import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import {
  TextareaContainer, StyledTextArea, Label
} from './Styles'

const TextArea = ({
  name,
  label,
  textHelper,
  ...rest
}) => {
  const textAreaRef = useRef(null)
  return (
    <TextareaContainer>
      {label && (
      <Label>
        {label}
      </Label>
      )}
      <StyledTextArea name={name} {...rest} data-testid="text-area" ref={textAreaRef} />
      {textHelper && <h4>{textHelper}</h4>}
    </TextareaContainer>
  )
}

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  textHelper: PropTypes.string
}

TextArea.defaultProps = {
  label: undefined,
  textHelper: undefined
}

export default TextArea
