import React from 'react'
import PropTypes from 'prop-types'

import { PublicFormContainer } from './Styles'

const PublicForm = ({ children, isCentered }) => (
  <PublicFormContainer isCentered={isCentered} data-testid="public-form">
    {children}
  </PublicFormContainer>
)

PublicForm.propTypes = {
  children: PropTypes.node.isRequired,
  isCentered: PropTypes.bool
}

PublicForm.defaultProps = {
  isCentered: false
}

export default PublicForm
