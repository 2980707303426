import React from 'react'
import PropTypes from 'prop-types'

import Button from '../../../Button'

const SubmitButton = ({
  labels, handleSubmit, errors, isSubmitting, isValid, testIdRoot, submitCount, jobsiteCount, isInviteUser, isInstaller
}) => {
  const { submittingLabel, buttonLabel } = labels
  const hasJobsites = jobsiteCount === 0 && isInviteUser && isInstaller
  const hasError = errors && Object.keys(errors).length !== 0
  const canSubmit = (((hasError || !isValid) && submitCount > 0) || hasJobsites)

  if (isSubmitting) {
    return <Button type="submit" disabled isLoading data-testid={`${testIdRoot}-submit-button`}>{submittingLabel}</Button>
  }

  if (canSubmit) {
    return <Button type="submit" disabled data-testid={`${testIdRoot}-submit-button`}>{buttonLabel}</Button>
  }

  return <Button type="submit" onClick={handleSubmit} data-testid={`${testIdRoot}-submit-button`}>{buttonLabel}</Button>
}

SubmitButton.propTypes = {
  labels: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  testIdRoot: PropTypes.string.isRequired,
  submitCount: PropTypes.number.isRequired,
  isInviteUser: PropTypes.bool,
  jobsiteCount: PropTypes.number,
  isInstaller: PropTypes.bool
}

SubmitButton.defaultProps = {
  errors: undefined,
  isInviteUser: false,
  jobsiteCount: null,
  isInstaller: false
}

export default SubmitButton
