import React from 'react'
import PropTypes from 'prop-types'

import Text from '../../../../Components/Formik/Text'

const PercentageComponent = ({ translate }) => (
  <>
    <Text
      name="value"
      label={translate('labels.value')}
      accessoryText="%"
      textHelper={translate('labels.percentageHelper')}
      type="number"
      min="0"
      max="100"
      data-testid="percentage-input"
    />
  </>
)

PercentageComponent.propTypes = {
  translate: PropTypes.func.isRequired
}

export default PercentageComponent
