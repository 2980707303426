const initialState = {
  initialized: false,
  loading: false,
  error: {
    status: false,
    code: null
  },
  isAuthenticated: false,
  expired: false,
  referrer: null,
  isAuthMagicLink: null,
  data: null,
  inviteData: null,
  cleanupTasks: []
}

const types = {
  LOGIN_PENDING: 'LOGIN_PENDING',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT_PENDING: 'LOGOUT_PENDING',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  RE_AUTH_PENDING: 'RE_AUTH_PENDING',
  RE_AUTH_SUCCESS: 'RE_AUTH_SUCCESS',
  RE_AUTH_FAILURE: 'RE_AUTH_FAILURE',
  FETCH_PERMISSIONS_PENDING: 'FETCH_PERMISSIONS_PENDING',
  FETCH_PERMISSIONS_SUCCESS: 'FETCH_PERMISSIONS_SUCCESS',
  FETCH_PERMISSIONS_FAILURE: 'FETCH_PERMISSIONS_FAILURE',
  FETCH_INVITE_PENDING: 'FETCH_INVITE_PENDING',
  FETCH_INVITE_SUCCESS: 'FETCH_INVITE_SUCCESS',
  FETCH_INVITE_FAILURE: 'FETCH_INVITE_FAILURE',
  CREATE_USER_PENDING: 'CREATE_USER_PENDING',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
  CHANGE_EMAIL_PENDING: 'CHANGE_EMAIL_PENDING',
  CHANGE_EMAIL_SUCCESS: 'CHANGE_EMAIL_SUCCESS',
  CHANGE_EMAIL_FAILURE: 'CHANGE_EMAIL_FAILURE',
  CHANGE_PASSWORD_PENDING: 'CHANGE_PASSWORD_PENDING',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
  UPDATE_PROFILE_PENDING: 'UPDATE_PROFILE_PENDING',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',
  SET_REFERRER: 'SET_REFERRER',
  SET_AUTH_MAGIC_LINK: 'SET_AUTH_MAGIC_LINK',
  SET_INVITE_DATA: 'SET_INVITE_DATA',
  RESEND_INVITE_PENDING: 'RESEND_INVITE_PENDING',
  RESEND_INVITE_SUCCESS: 'RESEND_INVITE_SUCCESS',
  RESEND_INVITE_FAILURE: 'RESEND_INVITE_FAILURE',
  REMOVE_INVITE_FAILURE: 'REMOVE_INVITE_FAILURE',
  SET_LANGUAGE_PREFERENCE: 'SET_LANGUAGE_PREFERENCE',
  SET_SUCCESS_INVITE: 'SET_SUCCESS_INVITE',
  RESET_SUCCESS_INVITE: 'RESET_SUCCESS_INVITE',
  RESET_ERROR: 'RESET_ERROR',
  ADD_CLEANUP_TASK: 'ADD_CLEANUP_TASK'
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case (types.LOGIN_SUCCESS):
      return {
        ...state,
        initialized: true,
        loading: false,
        isAuthenticated: true,
        data: action.payload,
        error: {
          status: false,
          code: null
        }
      }
    case (types.RE_AUTH_SUCCESS):
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        error: {
          status: false,
          code: null
        },
        expired: false
      }
    case (types.FETCH_PERMISSIONS_SUCCESS):
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          permissions: action.payload
        }
      }
    case (types.FETCH_INVITE_SUCCESS):
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          inviteData: action.payload
        }
      }
    case (types.CREATE_USER_SUCCESS):
      return {
        ...state,
        data: action.payload,
        shouldRedirect: true
      }
    case (types.CHANGE_EMAIL_SUCCESS):
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        data: { ...state.data },
        error: {
          status: false,
          code: null
        }
      }
    case (types.CHANGE_PASSWORD_SUCCESS):
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        data: { ...state.data },
        error: {
          status: false,
          code: null
        }
      }
    case (types.UPDATE_PROFILE_SUCCESS):
      return {
        ...state,
        loading: false
      }
    case (types.LOGOUT_SUCCESS):
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        initialized: true
      }
    case (types.LOGIN_PENDING):
      return {
        ...state,
        loading: true,
        error: {
          status: false,
          code: null
        }
      }
    case (types.LOGOUT_PENDING):
    case (types.RE_AUTH_PENDING):
    case (types.CHANGE_EMAIL_PENDING):
    case (types.CHANGE_PASSWORD_PENDING):
    case (types.FETCH_PERMISSIONS_PENDING):
    case (types.FETCH_INVITE_PENDING):
    case (types.UPDATE_PROFILE_PENDING):
      return {
        ...state,
        loading: true
      }
    case (types.LOGIN_FAILURE):
    case (types.LOGOUT_FAILURE):
    case (types.FETCH_PERMISSIONS_FAILURE):
    case (types.RE_AUTH_FAILURE):
    case (types.FETCH_INVITE_FAILURE):
      return {
        ...state,
        initialized: true,
        loading: false,
        error: {
          status: true,
          code: action.payload
        }
      }
    case (types.CHANGE_EMAIL_FAILURE):
    case (types.CHANGE_PASSWORD_FAILURE):
    case (types.UPDATE_PROFILE_FAILURE):
    case (types.REMOVE_INVITE_FAILURE):
      return {
        ...state,
        loading: false,
        error: {
          status: true,
          code: action.payload
        }
      }
    case (types.SET_REFERRER):
      return {
        ...state,
        referrer: action.payload
      }
    case (types.SET_AUTH_MAGIC_LINK):
      return {
        ...state,
        isAuthMagicLink: action.payload
      }
    case (types.SET_INVITE_DATA):
      return {
        ...state,
        data: {
          ...action.payload ? { language: action.payload.language } : {},
          ...state.data
        },
        inviteData: action.payload
      }
    case (types.SET_LANGUAGE_PREFERENCE):
      return {
        ...state,
        data: {
          ...state.data,
          language: action.payload
        }
      }
    case (types.ADD_CLEANUP_TASK):
      return {
        ...state,
        cleanupTasks: [...state.cleanupTasks, action.payload]
      }
    case (types.SET_SUCCESS_INVITE):
      return {
        ...state,
        isFromSuccessInvite: true,
        inviteOrgId: action.payload
      }
    case (types.RESET_SUCCESS_INVITE):
      return {
        ...state,
        isFromSuccessInvite: false,
        inviteOrgId: null
      }
    case (types.RESET_ERROR):
      return {
        ...state,
        error: {
          status: false,
          code: null
        }
      }
    default:
      return state
  }
}

export {
  reducer,
  types,
  initialState
}
