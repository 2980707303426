const EditIOAttribute = (values, { name }) => {
  const {
    attributeId, isCustomDescription, description, reporting, value, ...rest
  } = values

  const isCustomName = rest.isCustomName === 'true'

  const config = {
    ...rest,
    isCustomName,
    ...!isCustomName && { name },
    description: isCustomDescription ? description : '',
    reporting: {
      isEnabled: reporting.isEnabled || false,
      ...reporting.isEnabled && { value: reporting.value }
    },
    value: {
      channelType: value.channelType,
      channelValue: value.channelType.includes('Output') ? '0-10 VDC' : value.channelValue
    }
  }
  return config
}

export default EditIOAttribute
