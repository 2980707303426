import React, {
  useContext, useState, useRef, useEffect
} from 'react'
import PropTypes from 'prop-types'
import { ThemeContext } from 'styled-components'
import Transition from 'react-transition-group/Transition'

import Icon from '@mdi/react'
import {
  mdiInformationOutline, mdiCheckCircleOutline, mdiAlertOctagonOutline, mdiAlertOutline, mdiClose
} from '@mdi/js'
import CircularProgress from '@material-ui/core/CircularProgress'

import { ToastContainer } from './Styles'

const ANIMATION_DURATION = 240

const Toast = ({
  category, timeout, onClose, zIndex, children
}) => {
  const [isShown, setIsShown] = useState(true)
  const [wrapperHeight, setWrapperHeight] = useState(0)
  const closeTimer = useRef(null)
  const theme = useContext(ThemeContext)

  const onRef = (ref) => {
    if (ref) {
      const { height } = ref.getBoundingClientRect()
      setWrapperHeight(height)
    }
  }

  const close = () => {
    setIsShown(false)
  }

  const startCloseTimer = () => {
    if (timeout) {
      closeTimer.current = setTimeout(() => {
        close()
      }, timeout * 1000)
    }
  }

  const clearCloseTimer = () => {
    if (closeTimer.current) {
      clearTimeout(closeTimer.current)
      closeTimer.current = null
    }
  }

  useEffect(() => {
    startCloseTimer()
    return () => clearCloseTimer()
  }, []) // eslint-disable-line

  const handleMouseEnter = () => clearCloseTimer()

  const handleMouseLeave = () => startCloseTimer()

  const renderIcon = (type) => {
    switch (type) {
      case 'success':
        return <Icon path={mdiCheckCircleOutline} size={0.9} color={theme.colors.s02} />
      case 'warning':
        return <Icon path={mdiAlertOutline} size={0.9} color={theme.colors.s03} />
      case 'error':
        return <Icon path={mdiAlertOctagonOutline} size={0.9} color={theme.colors.s05} />
      case 'inProgress':
        return <CircularProgress size="1.35rem" />
      case 'info':
      default:
        return <Icon path={mdiInformationOutline} size={0.9} color={theme.colors.b01} />
    }
  }

  return (
    <Transition
      appear
      unmountOnExit
      timeout={ANIMATION_DURATION}
      in={isShown}
      onExited={onClose}
    >
      {state => (
        <ToastContainer
          data-testid="toast-container"
          ref={onRef}
          data-state={state}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          style={{
            zIndex: 1000 + zIndex,
            marginBottom: isShown ? 0 : -wrapperHeight - 8
          }}
        >
          <span>{renderIcon(category)}&nbsp;</span>
          <span>{children}</span>
          <button type="button" onClick={close} data-testid="toast-close-button"><Icon path={mdiClose} size={0.9} display={category === 'inProgress' ? 'none' : 'flex'} /></button>
        </ToastContainer>
      )}
    </Transition>
  )
}

Toast.propTypes = {
  category: PropTypes.oneOf(['info', 'success', 'warning', 'error', 'inProgress']).isRequired,
  timeout: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  zIndex: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired
}

Toast.defaultProps = {
  timeout: null
}

export default Toast
