import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@mdi/react'
import { mdiUnfoldMoreHorizontal } from '@mdi/js'

import { SelectorButtonContainer } from './Styles'

const SelectButton = ({ name, selectedOption }) => (
  <SelectorButtonContainer>
    <span>{name} ({selectedOption.label})</span>
    <Icon path={mdiUnfoldMoreHorizontal} size={1} />
  </SelectorButtonContainer>
)

SelectButton.propTypes = {
  name: PropTypes.string.isRequired,
  selectedOption: PropTypes.object.isRequired
}

export default SelectButton
