import React from 'react'
import PropTypes from 'prop-types'
import { Label, Container, StyledSelect } from './Styles'

const Select = ({
  id, options, label, value, onChange, testId, ...rest
}) => (
  <Container>
    <Label data-testid="select">{label}</Label>
    <StyledSelect id={id} value={value} onChange={onChange} {...rest} data-testid={`${testId}-select-dropdown`}>
      {options.map((currentOption, index) => (
        <option value={currentOption.value} key={currentOption.value} data-testid={`select-option-${index}`}>
          {currentOption.label}
        </option>
      ))}
    </StyledSelect>
  </Container>
)

Select.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  testId: PropTypes.string.isRequired
}

Select.defaultProps = {
  id: undefined,
  value: undefined,
  label: undefined,
  onChange: () => null
}

export default Select
