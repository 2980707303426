import styled from 'styled-components'

export const ButtonContainer = styled.div(({ theme }) => ({
  '& > button': {
    marginBottom: '.5rem',
    width: '100%',
    '&: last-child': {
      marginBottom: 0,
      marginRight: 0
    },
    [`@media(min-width: ${theme.breakpoints.lg.minWidth})`]: {
      marginBottom: 0,
      marginRight: '.5rem',
      width: 'auto'
    }
  }
}))
