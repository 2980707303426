import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@mdi/react'
import {
  mdiAccountCircleOutline, mdiChevronUp, mdiChevronDown
} from '@mdi/js'

import { UserDropdownButtonContainer } from './Styles'

const UserDropdownButton = ({ isOpen }) => (
  <UserDropdownButtonContainer data-testid="user-dropdown-button-container">
    <Icon path={mdiAccountCircleOutline} size={1} />
    {isOpen
      ? <Icon path={mdiChevronUp} size={1} />
      : <Icon path={mdiChevronDown} size={1} />
    }
  </UserDropdownButtonContainer>
)

UserDropdownButton.propTypes = {
  isOpen: PropTypes.bool.isRequired
}

export default UserDropdownButton
