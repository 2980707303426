import React, { useReducer, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import NotificationsContext from '../Notifications/Context'
import Context from './Context'
import getActions from './Actions'
import getSelectors from './Selectors'
import { reducer, initialState } from './Reducer'

const UserProvider = ({ children }) => {
  const { actions: toast } = useContext(NotificationsContext)
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = getActions(state, dispatch, toast)
  const selectors = getSelectors(state)
  const { initializeAuthStateListener } = actions

  useEffect(initializeAuthStateListener, [])

  return (
    <Context.Provider value={{
      state,
      actions,
      selectors,
      dispatch
    }}
    >
      {children}
    </Context.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default UserProvider
