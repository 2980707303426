import React from 'react'
import PropTypes from 'prop-types'

import useTranslate from '../../../../Utils/Hooks/useTranslate'

import Delimited from '../../../Formik/Delimited'
import RadioGroup from '../../../Formik/RadioGroup'
import FieldSet from '../../../Form/Fieldset'

const NetworkSettings = ({ values, submitCount }) => {
  const translate = useTranslate('configForm')
  return (
    <FieldSet heading={translate('networkSettings.heading')}>
      <RadioGroup
        name="network.type"
        title={translate('networkSettings.typeInputLabel')}
        tiled
        options={[
          {
            value: 'dhcp',
            label: translate('networkSettings.typeInputOption.DHCP')
          },
          {
            value: 'static',
            label: translate('networkSettings.typeInputOption.static')
          }
        ]}
        testid="network-type"
      />
      {
        values.network.type === 'static' && (
          <>
            <Delimited delimiter="." delimitedTestId="network-settings" regions={4} label={translate('networkSettings.static.ipAddressInputLabel')} name="network.ipAddress" type="number" data-testid="network-ip-address" submitCount={submitCount} autoFocus />
            <Delimited delimiter="." delimitedTestId="network-settings" regions={4} label={translate('networkSettings.static.subnetMaskInputLabel')} name="network.subnetMask" type="number" data-testid="network-subnet-mask" submitCount={submitCount} />
            <Delimited delimiter="." delimitedTestId="network-settings" regions={4} label={translate('networkSettings.static.gatewayIpInputLabel')} name="network.gatewayIp" type="number" data-testid="network-gateway-ip" submitCount={submitCount} />
            <Delimited delimiter="." delimitedTestId="network-settings" regions={4} label={translate('networkSettings.static.dnsIpInputLabel')} name="network.dnsIp" type="number" data-testid="network-dns-ip" submitCount={submitCount} />
            <Delimited delimiter="." delimitedTestId="network-settings" regions={4} label={translate('networkSettings.static.dnsIpInputLabel2')} name="network.dnsIp2" type="number" data-testid="network-dns-ip" submitCount={submitCount} />
          </>
        )
      }
    </FieldSet>
  )
}

NetworkSettings.propTypes = {
  values: PropTypes.object.isRequired,
  submitCount: PropTypes.number.isRequired
}

export default NetworkSettings
