import React, { useContext, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'

import ModalContext from '../../../State/Modal/Context'
import DevicesContext from '../../../State/Devices/Context'
import NotificationsContext from '../../../State/Notifications/Context'

import useTranslate from '../../../Utils/Hooks/useTranslate'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'
import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'

import ModalContent from '../../Modal/Components/ModalContent'
import ModalFooter from '../../Modal/Components/ModalFooter'
import SubmitButton from '../../Modal/Components/SubmitButton'
import Button from '../../Button'
import { StyledDiv } from './Styles'


const DevicesDeactivate = () => {
  const { actions: { closeModal, setSubmitted } } = useContext(ModalContext)
  const { actions: { toggleDeviceStatus }, selectors: { getSelectedDeviceList } } = useContext(DevicesContext)
  const { actions: { addNotification } } = useContext(NotificationsContext)
  const translate = useTranslate('deactivateDevicesModal')
  const selectedHubs = getSelectedDeviceList()
  const [execute, , , result, hasError] = useAsyncCallback(toggleDeviceStatus)
  useErrorNotifier(hasError, result)
  const handleClick = () => {
    closeModal()
  }

  const labels = {
    submittingLabel: translate('labels.deactivating'),
    buttonLabel: translate('labels.deactivate')
  }

  const handleDeactivation = async () => {
    await execute(selectedHubs)
    closeModal()
    setSubmitted()
  }

  useEffect(() => {
    if (hasError === false) {
      addNotification(translate('notifications.success'), 'success', 2)
    }
  }, [hasError]) // eslint-disable-line

  return (
    <>
      <ModalContent>
        <StyledDiv>
          {translate('copy')}
        </StyledDiv>
        <ReactMarkdown>{translate('copyFooter')}</ReactMarkdown>
      </ModalContent>
      <ModalFooter>
        <Button inverted onClick={handleClick}>{translate('labels.cancel')}</Button>
        <SubmitButton labels={labels} onClick={handleDeactivation} />
      </ModalFooter>
    </>
  )
}

export default DevicesDeactivate
