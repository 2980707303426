import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { createRadioButtonLabel } from '../utils'

import useAsync from '../../../Utils/Hooks/useAsync'
import useTranslate from '../../../Utils/Hooks/useTranslate'
import useValidationSchema from '../../../Utils/Hooks/useValidationSchema'

import {
  RadioLabelContainer, RadioLabelBody, RadioLableHeader, JobAssignmentContainer
} from './Styles'
import InfoMesage from '../Components/InfoMessage'
import Fieldset from '../../Form/Fieldset'
import Text from '../../Formik/Text'
import RadioGroup from '../../Formik/RadioGroup'
import Select from '../../Form/Select'
import PanelContext from '../../../State/Panel/Context'
import UserContext from '../../../State/User/Context'
import OragnizationContext from '../../../State/Organization/Context'
import NotificationContext from '../../../State/Notifications/Context'
import PanelFooter from '../Components/PanelFooter'
import PanelRibbon from '../Components/PanelRibbon'
import CheckboxGroup from '../../Formik/CheckboxGroup'


const InviteUser = ({ setIsDirty }) => {
  const { actions: { closePanel, setSubmitted } } = useContext(PanelContext)
  const { actions: { createInvite, fetchJobsites } } = useContext(OragnizationContext)
  const { selectors: { getOrganization } } = useContext(UserContext)
  const { actions: { addNotification } } = useContext(NotificationContext)
  const { organizationId } = useParams()
  const [, , jobsites] = useAsync(fetchJobsites, organizationId)
  const mappedJobsites = jobsites ? jobsites.map(current => ({ value: current.jobsiteId, label: current.name })) : []
  const createMultilineLabel = createRadioButtonLabel(RadioLabelContainer, RadioLableHeader, RadioLabelBody)
  const { getCurrentSchema } = useValidationSchema()
  const translate = useTranslate('userPanel')

  const initialValues = {
    jobsites: [],
    email: '',
    language: 'en',
    role: 'admin',
    organizationId
  }

  const labels = {
    buttonLabel: translate('inviteButton'),
    submittingLabel: translate('submittingButton'),
    cancelLabel: translate('cancelButton')
  }

  const Roles = [
    {
      label: createMultilineLabel(translate('roleOption.administrator'), translate('roleOption.administratorDescription')),
      value: 'admin'
    },
    {
      label: createMultilineLabel(translate('roleOption.installer'), translate('roleOption.installerDescription')),
      value: 'installer'
    }
  ]
  const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Español' },
    { value: 'de', label: 'Deutsch' },
    { value: 'fr', label: 'Français' }
  ]

  const handleLanguageChange = (event, setFieldValue) => {
    setFieldValue(event.target.id, event.target.value)
    event.preventDefault()
  }

  const onSubmit = async (values, { resetForm }) => {
    await createInvite({ organizationId, ...values, name: getOrganization(organizationId).name })
    addNotification(translate('inviteEmailSent'), 'success', 2)
    setSubmitted()
    closePanel()
    resetForm()
  }

  return (
    <Formik
      validationSchema={getCurrentSchema('users/invite')}
      enableReinitialize
      initialValues={initialValues}
      validateOnMount={false}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit, values, errors, isSubmitting, setFieldValue, isValid, dirty, touched, submitCount
      }) => (
        <Form onChange={setIsDirty(dirty)}>
          <PanelRibbon errors={errors} touched={touched} submitCount={submitCount} />
          <Fieldset>
            <Text name="email" label={translate('emailInputLabel')} type="email" placeholder={translate('emailInputPlaceholder')} data-testid="email" submitCount={submitCount} />
            <Select
              testId="language"
              id="language"
              name="language"
              label={translate('languageInputLabel')}
              options={languageOptions}
              value={values.language}
              onChange={event => handleLanguageChange(event, setFieldValue)}
            />
          </Fieldset>
          <Fieldset heading={translate('roleSection.heading')} hasBorder={false}>
            <RadioGroup
              options={Roles}
              name="role"
              alignToTop
              data-testid="roles"
            />
            <JobAssignmentContainer>
              {values.role === 'admin' && (
                <InfoMesage heading={translate('assignedJobsInputLabel')} content={translate('adminAssignedJobs')} />
              )}
              {values.role === 'installer' && mappedJobsites.length === 0 && (
                <InfoMesage heading={translate('assignedJobsInputLabel')} isError content={translate('installerNoJobsitesCreated')} />
              )}
              {values.role === 'installer' && mappedJobsites.length > 0 && (
                <CheckboxGroup
                  heading={translate('assignedJobsInputLabel')}
                  options={mappedJobsites}
                  name="jobsites"
                  testIdRoot="jobsites"
                />
              )}
            </JobAssignmentContainer>
          </Fieldset>
          <PanelFooter
            handleReset={closePanel}
            handleSubmit={handleSubmit}
            errors={errors}
            labels={labels}
            isSubmitting={isSubmitting}
            isValid={isValid}
            testIdRoot="invite-user"
            submitCount={submitCount}
            jobsiteCount={mappedJobsites.length}
            isInviteUser
            isInstaller={values.role === 'installer'}
          />
        </Form>
      )}
    </Formik>
  )
}

InviteUser.propTypes = {
  setIsDirty: PropTypes.func.isRequired
}

export default InviteUser
