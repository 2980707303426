import styled from 'styled-components'

export const RadioLabelContainer = styled.div({
  paddingBottom: '1.5rem'
})

export const RadioLableHeader = styled.h4(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: '400',
  marginBottom: '0.5rem',
  color: theme.colors.n08
}))

export const RadioLabelBody = styled.p(({ theme }) => ({
  fontSize: '.75rem',
  fontWeight: '400',
  color: theme.colors.n07
}))

export const JobAssignmentContainer = styled.div({
  paddingBottom: '1.5rem'
})
