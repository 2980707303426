import useAsyncCallback from './useAsyncCallback'


const useFetchCallback = fetch => (
  useAsyncCallback(async (...args) => {
    const response = await fetch(...args)
    const text = await response.text()

    if (!response.ok) throw JSON.parse(text)
    return text ? JSON.parse(text) : {}
  })
)

export default useFetchCallback
