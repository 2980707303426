import styled from 'styled-components'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

export const StyledAddIcon = styled(AddIcon)({
  verticalAlign: 'bottom'
})

export const StyledRemoveIcon = styled(RemoveIcon)({
  verticalAlign: 'bottom'
})

export const StyledSpan = styled.span({
  lineHeight: '24px'
})

export const StyledLink = styled.a({
  margin: '0 0 1.5rem',
  display: 'block',
  fontWeight: 500,
  cursor: 'pointer'
})
