import React, { useContext } from 'react'

import useTranslate from '../../../Utils/Hooks/useTranslate'
import PanelContext from '../../../State/Panel/Context'
import TemplatesContext from '../../../State/Templates/Context'

import PanelFooter from '../../Panel/Components/PanelFooter'
import Button from '../../Button'
import Fieldset from '../../Form/Fieldset'
import ReadOnlyFields from '../Components/ReadOnlyFields'

const ViewTemplate = () => {
  const { actions: { closePanel } } = useContext(PanelContext)
  const { selectors: { getSelectedTemplateId, getTemplate } } = useContext(TemplatesContext)
  const templateId = getSelectedTemplateId()
  const template = getTemplate(templateId)
  const translate = useTranslate('templatePanel')
  const translateConfig = useTranslate('configForm')

  const {
    name,
    description,
    namingConvention: {
      isEnabled,
      value
    },
    config: {
      unitsOfMeasure
    }
  } = template

  const translatedValue = value === 'prefix'
    ? translate('hubNamingConvention.namingConventionInputOption.prefix')
    : translate('hubNamingConvention.namingConventionInputOption.suffix')

  const getTranslatedTemperatureUnits = () => {
    switch (unitsOfMeasure.temperature) {
      case 'F':
        return translateConfig('unitsOfMeasure.temperatureInputOption.fahrenheit')
      case 'C':
      default:
        return translateConfig('unitsOfMeasure.temperatureInputOption.celsius')
    }
  }
  const getTranslatedLightUnits = () => {
    switch (unitsOfMeasure.temperature) {
      case 'fc':
        return translateConfig('unitsOfMeasure.lightLevelsInputOption.footCandle')
      case 'lx':
      default:
        return translateConfig('unitsOfMeasure.lightLevelsInputOption.lux')
    }
  }

  const templatesFields = [
    {
      header: translate('nameInputLabel'),
      value: name,
      testId: 'view-template-name'
    },
    {
      header: translate('descriptionInputLabel'),
      value: description,
      testId: 'view-template-description'
    },
    {
      header: translate('hubNamingConvention.heading'),
      value: isEnabled ? translatedValue : translate('hubNamingConvention.namingConventionInputOption.none'),
      testId: 'view-template-naming-convention'
    },
    {
      header: translate('unitsOfMeasureHeading.temperature'),
      value: getTranslatedTemperatureUnits(),
      testId: 'units-of-measure-temperature'
    },
    {
      header: translate('unitsOfMeasureHeading.light'),
      value: getTranslatedLightUnits(),
      testId: 'units-of-measure-light'
    }
  ]

  return (
    <>
      <Fieldset hasBorder={false}>
        <ReadOnlyFields fields={templatesFields} />
      </Fieldset>
      <PanelFooter>
        <Button onClick={closePanel} inverted data-testid="view-template-close-button">{translate('buttons.close')}</Button>
      </PanelFooter>
    </>
  )
}

export default ViewTemplate
