const getSelectors = state => ({
  isOpenSelector() {
    return state.isOpen
  },
  currentViewSelector() {
    return state.currentView
  },
  isClickOutsideDisabled() {
    return state.isClickOutsideDisabled
  },
  getState() {
    return state
  },
  dataIsStale() {
    return state.isStale
  }
})

export default getSelectors
