import { object, string } from 'yup'
import JobsiteClient from '../../../State/Jobs/Client'
import useTranslateWithLanguage from '../../Hooks/useTranslateWithLanguage'

async function test(value = '') {
  const { jobsiteId } = this.parent
  const zoneNameCount = await JobsiteClient.verifyUniqueZoneName(jobsiteId, value)
  return zoneNameCount === 0
}

const EditZoneSchema = (language) => {
  const translate = useTranslateWithLanguage('formValidation', language)

  return object().shape({
    name: string().required(translate('zoneNameIsRequired')).test('name', translate('zoneNameMustBeUnique'), test)
  })
}

export default EditZoneSchema
