import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { mdiPencilOutline, mdiFileDocumentBoxOutline } from '@mdi/js'
import Icon from '@mdi/react'


import PanelContext from '../../../../../State/Panel/Context'
import JobContext from '../../../../../State/Jobs/Context'

const EditButton = ({ row }) => {
  const { actions: { changeView } } = useContext(PanelContext)
  const rowIndex = row.index + 1
  const { actions: { setSelectedJobsiteId } } = useContext(JobContext)
  const { original: { jobsiteId, status } } = row
  const isArchived = status && (status === 'inactive') ? status === 'inactive' : false

  const handleClick = () => {
    setSelectedJobsiteId(jobsiteId)
    changeView(isArchived ? 'jobs/view' : 'jobs/edit')
  }
  return (
    <button type="button" onClick={handleClick} data-testid={`jobs-edit-button-${rowIndex}`}>
      <Icon path={isArchived ? mdiFileDocumentBoxOutline : mdiPencilOutline} size={1} />
    </button>
  )
}

EditButton.propTypes = {
  row: PropTypes.object.isRequired
}

export default EditButton
