import { types } from './Reducer'

const getActions = (state, dispatch) => {
  const addNotification = (text, category, timeout) => {
    dispatch({ type: types.ADD_TOAST, payload: { text, category, timeout } })
  }

  const dismissNotification = (toastId) => {
    dispatch({ type: types.REMOVE_TOAST, payload: toastId })
  }

  return {
    addNotification,
    dismissNotification
  }
}


export default getActions
