import Styled from 'styled-components'

export const Container = Styled.div(({ theme }) => ({
  padding: '1.5rem 0.5rem',
  borderTop: `1px solid ${theme.colors.n04}`,
  textAlign: 'center',
  '& > button': {
    width: '100%',
    marginBottom: '1rem'
  },
  '& > button:last-child': {
    marginRight: 0
  },
  [`@media (min-width: ${theme.breakpoints.sm.minWidth})`]: {
    padding: '1.5rem',
    textAlign: 'right',
    '& > button': {
      width: 'auto',
      marginRight: '1rem',
      marginBottom: 0
    },
    '& > button:last-child': {
      marginRight: 0
    }
  }
}))
