import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'

import useConfigTransform from '../../../Utils/Hooks/useConfigTransform'
import useTranslate from '../../../Utils/Hooks/useTranslate'
import ModalContext from '../../../State/Modal/Context'
import TemplatesContext from '../../../State/Templates/Context'
import NotificationsContext from '../../../State/Notifications/Context'
import ModalContent from '../../Modal/Components/ModalContent'
import ModalFooter from '../../Modal/Components/ModalFooter'
import SubmitButton from '../../Modal/Components/SubmitButton'
import Button from '../../Button'


const DuplicateTemplate = () => {
  const { actions: { closeModal, setSubmitted } } = useContext(ModalContext)
  const { actions: { duplicateTemplate, fetchTemplates }, selectors: { getSelectedTemplate, isLoading, hasError } } = useContext(TemplatesContext)
  const { actions: { addNotification } } = useContext(NotificationsContext)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const translate = useTranslate('duplicateTemplate')
  const { organizationId } = useParams()
  const selectedTemplate = getSelectedTemplate()

  const labels = {
    submittingLabel: translate('submittingButton'),
    buttonLabel: translate('duplicateButton')
  }
  const { getCurrentTransform } = useConfigTransform()
  const configTransform = getCurrentTransform('template/duplicate')
  const handleClick = async () => {
    setIsSubmitting(true)
    const config = configTransform(selectedTemplate, organizationId)
    await duplicateTemplate(config)
    if (!isLoading() && !hasError()) {
      fetchTemplates(organizationId, { state: 'active' })
      setIsSubmitting(false)
      addNotification(translate('successNotification'), 'success', 2)
      setSubmitted()
      closeModal()
    } else {
      addNotification(translate('errorNotification'), 'error', 2)
      closeModal()
    }
  }

  return (
    <>
      <ModalContent>
        {translate('modalBody')}
      </ModalContent>
      <ModalFooter>
        <Button onClick={closeModal} inverted data-testid="forgot-password-cancel">{translate('cancelButton')}</Button>
        <SubmitButton handleSubmit={handleClick} labels={labels} isSubmitting={isSubmitting} />
      </ModalFooter>
    </>
  )
}

export default DuplicateTemplate
