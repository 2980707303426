import { string } from 'yup'
import RequiredField from './RequiredField'

const urlConditionalRequirement = (requiredMessage, validUrlMessage) => string().when(['brokerType', 'addressType'], {
  is: (brokerType, addressType) => brokerType === 'custom' && addressType === 'brokerUrl',
  then: RequiredField(requiredMessage).url(validUrlMessage),
  otherwise: string().notRequired()
})

export default urlConditionalRequirement
