import React from 'react'
import PropTypes from 'prop-types'

import useTranslate from '../../../../Utils/Hooks/useTranslate'

import { TableCell } from '../../../Table'

const Role = ({ row }) => {
  const {
    userId, inviteId, role, isPrimary
  } = row.original || {}
  const translate = useTranslate('usersPage')

  const roleTranslations = {
    admin: translate('administratorRole'),
    primaryAdmin: translate('primaryAdministratorRole'),
    installer: translate('installerRole')
  }

  return (
    <TableCell data-testid={`users-role-${userId || inviteId}`}>{isPrimary ? roleTranslations.primaryAdmin : roleTranslations[role]}</TableCell>
  )
}

Role.propTypes = {
  row: PropTypes.object.isRequired
}

export default Role
