const initialState = {
  selectedCheckboxes: []
}

const types = {
  SET_SELECTED_CHECKBOXES: 'SET_SELECTED_CHECKBOXES'
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case (types.SET_SELECTED_CHECKBOXES):
      return {
        ...state,
        selectedCheckboxes: action.payload
      }
    default:
      return state
  }
}

export {
  reducer,
  types,
  initialState
}
