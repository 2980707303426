import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'

import useAsync from '../../../Utils/Hooks/useAsync'
import useTranslate from '../../../Utils/Hooks/useTranslate'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'

import UserContext from '../../../State/User/Context'
import PanelContext from '../../../State/Panel/Context'
import ModalContext from '../../../State/Modal/Context'
import useValidationSchema from '../../../Utils/Hooks/useValidationSchema'
import Text from '../../Formik/Text'
import PanelFooter from '../Components/PanelFooter'
import Fieldset from '../../Form/Fieldset'
import PanelRibbon from '../Components/PanelRibbon'

import { ButtonLinkWrapper, ForgotPasswordButton } from './Styles'

const ChangePassword = ({ setIsDirty }) => {
  const { actions: { closePanel } } = useContext(PanelContext)
  const { actions: { changeModalView } } = useContext(ModalContext)
  const {
    actions: {
      isAuthSessionFresh, reAuthenticate, changePassword, setAuthMagicLink
    },
    selectors: {
      getUserEmail, hasError, getErrorCode, getAuthMagicLink
    }
  } = useContext(UserContext)
  const [isInit, isLoading, isTokenFresh] = useAsync(isAuthSessionFresh) /* eslint-disable-line */
  const { getCurrentSchema } = useValidationSchema()
  const translate = useTranslate('myAccountPanel')
  const isMagicLink = getAuthMagicLink()
  useErrorNotifier(hasError(), getErrorCode(), 'error', 4)

  const initialValues = {
    email: getUserEmail(),
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    isTokenFresh
  }

  const labels = {
    buttonLabel: translate('changeButton'),
    submittingLabel: translate('submittingButton'),
    cancelLabel: translate('cancelButton')
  }

  const onSubmit = async (values) => {
    if (isTokenFresh && isMagicLink) {
      await changePassword(values.newPassword, translate('changePassword.passwordChangedSuccessfully'))
      setAuthMagicLink(null)
    } else {
      const authenticated = await reAuthenticate(values.currentPassword)
      if (authenticated) {
        await changePassword(values.newPassword, translate('changePassword.passwordChangedSuccessfully'))
      }
    }
    closePanel()
  }

  const openModal = () => {
    changeModalView('forgot-password/request')
  }

  if (isLoading) {
    return <h2>Loading...</h2>
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={getCurrentSchema('password/change')}
        onSubmit={onSubmit}
        onReset={closePanel}
      >
        {({
          isSubmitting, handleSubmit, handleReset, errors, isValid, dirty, submitCount
        }) => (
          <Form onChange={setIsDirty(dirty)}>
            <PanelRibbon errors={errors} submitCount={submitCount} />
            <Text type="email" name="email" label="Email" disabled hidden />
            {!isMagicLink
              && (
                <Fieldset>
                  <Text name="currentPassword" label={translate('changePassword.currentPasswordInputLabel')} autoComplete="off" isPassword data-testid="current-password" submitCount={submitCount} />
                  <ButtonLinkWrapper>
                    <ForgotPasswordButton type="button" onClick={openModal} data-testid="forgot-password">{translate('changePassword.forgotPasswordButton')}</ForgotPasswordButton>
                  </ButtonLinkWrapper>
                </Fieldset>
              )
            }
            <Fieldset>
              <Text name="newPassword" label={translate('changePassword.newPasswordInputLabel')} autoComplete="new-password" isPassword data-testid="new-password" textHelper={translate('passwordChangeDescription')} submitCount={submitCount} />
              <Text name="confirmPassword" label={translate('changePassword.confirmNewPasswordInputLabel')} autoComplete="new-password" isPassword data-testid="create-password" textHelper={translate('passwordChangeDescription')} submitCount={submitCount} />
            </Fieldset>
            <PanelFooter
              handleReset={handleReset}
              handleSubmit={handleSubmit}
              errors={errors}
              labels={labels}
              isSubmitting={isSubmitting}
              isValid={isValid}
              testIdRoot="change-password"
              submitCount={submitCount}
              isChangePassword
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

ChangePassword.propTypes = {
  setIsDirty: PropTypes.func.isRequired
}

export default ChangePassword
