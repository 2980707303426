import { useEffect, useState } from 'react'


const useAsync = (func, ...args) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [result, setResult] = useState(null)
  const stringifiedArgs = JSON.stringify(args)

  const success = (data) => {
    setResult(data)
    setIsLoading(false)
  }

  const error = (data) => {
    setHasError(true)
    setResult(data)
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    setIsInitialized(true)
    func(...args)
      .then(success)
      .catch(error)
  }, [stringifiedArgs]) // eslint-disable-line

  return [isInitialized, isLoading, result, hasError]
}

export default useAsync
