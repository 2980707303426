import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import PanelContext from '../../../State/Panel/Context'
import useTranslate from '../../../Utils/Hooks/useTranslate'

import PanelFooter from '../../Panel/Components/PanelFooter'
import Button from '../../Button'

import Ribbon from '../../Ribbon'

const ErrorPanel = ({ setHasError }) => {
  const translate = useTranslate('errorPanel')
  const { actions: { closePanel, resetView } } = useContext(PanelContext)
  const handleClose = () => {
    setHasError()
    closePanel()
    resetView()
  }
  return (
    <>
      <Ribbon type="error" isSticky>{translate('errorNotification')}</Ribbon>
      <PanelFooter>
        <Button onClick={handleClose} inverted data-testid="view-template-close-button">{translate('buttons.close')}</Button>
      </PanelFooter>
    </>
  )
}

ErrorPanel.propTypes = {
  setHasError: PropTypes.func.isRequired
}

export default ErrorPanel
