import React, { useContext, useState } from 'react'

import useTranslate from '../../../Utils/Hooks/useTranslate'

import UserContext from '../../../State/User/Context'
import NotificationContext from '../../../State/Notifications/Context'
import OrganizationContext from '../../../State/Organization/Context'
import ModalContext from '../../../State/Modal/Context'
import ModalContent from '../../Modal/Components/ModalContent'
import ModalFooter from '../../Modal/Components/ModalFooter'
import Button from '../../Button'
import SubmitButton from '../../Modal/Components/SubmitButton'

const ResendInviteLink = () => {
  const { actions: { resendInviteEmail } } = useContext(UserContext)
  const { selectors: { getSelectedUser } } = useContext(OrganizationContext)
  const { actions: { closeModal, changeModalView } } = useContext(ModalContext)
  const { actions: { addNotification } } = useContext(NotificationContext)
  const selectedUser = getSelectedUser()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const translate = useTranslate('resendInvite')

  const { inviteId, email } = selectedUser

  const labels = {
    submittingLabel: translate('sendingInviteButton'),
    buttonLabel: translate('sendInviteButton')
  }

  const handleClick = async () => {
    setIsSubmitting(true)
    const response = await resendInviteEmail(inviteId)
    if (response.status === 200) {
      changeModalView('resend-invite-link/success')
      setIsSubmitting(false)
    } else if (response.status === 429) {
      addNotification(translate('tooFrequentResendsError'), 'error', 2)
      closeModal()
    } else {
      addNotification(translate('error'), 'error', 2)
      closeModal()
    }
  }

  return (
    <>
      <ModalContent>
        {translate('description')} <strong>{email}</strong>.
      </ModalContent>
      <ModalFooter>
        <Button onClick={closeModal} inverted data-testid="resend-invite-link-cancel">{translate('cancelButton')}</Button>
        <SubmitButton handleSubmit={handleClick} labels={labels} isSubmitting={isSubmitting} testIdRoot="resend-invite-link" />
      </ModalFooter>
    </>
  )
}

export default ResendInviteLink
