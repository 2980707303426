import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { format } from 'date-fns'

import Icon from '@mdi/react'
import { mdiAccountCircleOutline } from '@mdi/js'

import useTranslate from '../../../Utils/Hooks/useTranslate'

import Breadcrumbs from '../../Breadcrumbs'
import PageInfo from '../../PageInfo'
import PageHeader from '../../PageHeader'
import PageCaption from '../../PageCaption'
import Panel from '../../Panel'
import UserContext from '../../../State/User/Context'
import PanelContext from '../../../State/Panel/Context'
import PageMetaData from '../../PageMetaData'
import { SectionHeader, SectionWrapper, SectionRow } from './Styles'

const MyAccount = ({ setState }) => {
  setState('Content/Overview.html')
  const { actions: { openPanel, changeView, resetView }, selectors: { dataIsStale } } = useContext(PanelContext)
  const { actions: { hydrateUserData, resetError }, selectors: { getUser } } = useContext(UserContext)
  const {
    userId, firstName, lastName, email, passwordUpdatedAt, language
  } = getUser()

  const history = useHistory()
  const match = useRouteMatch({ path: '/my-account/change-password' })
  const stringifiedPath = match && JSON.stringify(match.pathname)
  const translate = useTranslate('myAccountPage')
  const translateMetaData = useTranslate('pageMetaData')

  useEffect(() => {
    if (match) {
      changeView('password/change')
    }
  }, [stringifiedPath]) // eslint-disable-line

  useEffect(() => {
    hydrateUserData({ uid: userId })
    if (dataIsStale()) {
      resetView()
    }
  }, [dataIsStale()]) // eslint-disable-line

  const getLangName = (langCode) => {
    switch (langCode) {
      case 'en':
      default:
        return 'English'
      case 'es':
        return 'Español'
      case 'de':
        return 'Deutsch'
      case 'fr':
        return 'Français'
    }
  }

  const handleChangePassword = () => {
    history.push('/my-account/change-password')
    resetError()
    openPanel()
  }

  const onClose = () => {
    history.push('/my-account')
  }

  return (
    <>
      <PageMetaData pageMetaData={{ title: `${translateMetaData('myAccountTitle')}`, description: 'TODO' }} />
      <Breadcrumbs />
      <PageHeader>
        <PageInfo>
          <Icon path={mdiAccountCircleOutline} size={1} />
          <PageCaption>
            <h1>{translate('heading')}</h1>
            <h4>
              <span>{translate('description')}</span>
            </h4>
          </PageCaption>
        </PageInfo>
        <Panel onClose={onClose} unmountOnExit />
      </PageHeader>
      <SectionHeader>{translate('profile.heading')}</SectionHeader>
      <SectionWrapper>
        <SectionRow>
          <h4>{translate('profile.nameLabel')}</h4>
          <span>{firstName} {lastName}</span>
          <button onClick={() => changeView('profile/edit')} type="button" data-testid="my-account-edit-name">
            {translate('profile.nameEditButton')}
          </button>
        </SectionRow>
        <SectionRow>
          <h4>{translate('profile.emailLabel')}</h4>
          <span>{email}</span>
          <button type="button" disabled />
        </SectionRow>
      </SectionWrapper>
      <SectionHeader>{translate('security.heading')}</SectionHeader>
      <SectionWrapper>
        <SectionRow>
          <h4>{translate('security.passwordLabel')}</h4>
          <span>
            {
              passwordUpdatedAt
                ? `${translate('security.passwordLastChanged')} ${format(passwordUpdatedAt.seconds * 1000, 'MMM dd, yyyy')}`
                : '********'
            }
          </span>
          <button onClick={handleChangePassword} type="button" data-testid="my-account-edit-password">
            {translate('security.passwordEditButton')}
          </button>
        </SectionRow>
      </SectionWrapper>
      <SectionHeader>{translate('preferences.heading')}</SectionHeader>
      <SectionWrapper>
        <SectionRow>
          <h4>{translate('preferences.languageLabel')}</h4>
          <span>{getLangName(language)}</span>
          <button onClick={() => changeView('preferences/edit')} type="button" data-testid="my-account-edit-language">
            {translate('preferences.languageEditButton')}
          </button>
        </SectionRow>
      </SectionWrapper>
    </>
  )
}

MyAccount.propTypes = {
  setState: PropTypes.func.isRequired
}

export default MyAccount
