import * as firebase from 'firebase/app'
import 'firebase/firestore'

const jobsiteClient = {
  getJobsitesData(orgId, filters) {
    let query = firebase.firestore()
      .collection('jobsites')
      .where('organizationId', '==', orgId)

    if ('status' in filters) {
      query = query.where('status', '==', filters.status)
    }

    query = query.orderBy('createdAt')

    return query.get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.reduce((accumulator, current) => {
          accumulator.push({ jobsiteId: current.id, ...current.data() })
          return accumulator
        }, [])
        return Promise.resolve(data)
      })
  },
  fetchJobsite(jobsiteId) {
    return firebase.firestore().collection('jobsites').doc(jobsiteId).get()
      .then(doc => doc.data())
  },
  createJobsite(formData) {
    return firebase.firestore().collection('jobsites').doc().set({ ...formData, createdAt: firebase.firestore.Timestamp.fromDate(new Date()) })
  },
  async updateJobSite(jobsiteId, formData, toUpdate = []) {
    const toReset = toUpdate.reduce((accumulator, current) => ({
      ...accumulator,
      [current]: {}
    }), {})
    await firebase.firestore().collection('jobsites').doc(jobsiteId).update(toReset)
    return firebase.firestore().collection('jobsites').doc(jobsiteId).set(formData, { merge: true })
  },
  fetchJobsiteZones(jobsiteId) {
    if (!jobsiteId) return {}
    return firebase.firestore().collection('zones').where('jobsiteId', '==', jobsiteId).get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.reduce((accumulator, current) => ({
          ...accumulator,
          [current.id]: { name: current.data().name }
        }), {})
        return data
      })
  },
  fetchJobsiteTemplates(organizationId) {
    const query = firebase.firestore()
      .collection('templates')
      .where('organizationId', 'in', [organizationId, 'default'])
    return query.get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.reduce((acc, current) => {
          const { name } = current.data()
          acc.push({ templateId: current.id, name })
          return acc
        }, [])
        return data
      })
  },
  async verifyUniqueZoneName(jobsiteId = '', zoneName = '') {
    const query = firebase.firestore()
      .collection('zones')
      .where('jobsiteId', '==', jobsiteId)
      .where('name', '==', zoneName)
    const queryLength = await query.get().then(querySnapshot => querySnapshot.docs.length)
    return queryLength
  },
  toggleJobsiteStatus(jobsites) {
    const db = firebase.firestore()
    const batch = db.batch()
    jobsites.forEach((jobsite) => {
      const { jobsiteId, status } = jobsite
      const jobsiteRef = db.doc(`jobsites/${jobsiteId}`)

      batch.update(jobsiteRef, {
        status: status === 'active' ? 'inactive' : 'active'
      })
    })
    return batch.commit()
  },
  async deactivateJobSitesByOrgAsync(organization) {
    const db = firebase.firestore()
    const batch = db.batch()
    const jobsites = await firebase.firestore().collection('jobsites').get().then(({ docs }) => docs)
    const orgJobsites = jobsites.filter(jobsite => jobsite.data().organizationId === organization.id)
    orgJobsites.forEach((jobsite) => {
      const jobsiteRef = db.doc(`jobsites/${jobsite.id}`)
      batch.update(jobsiteRef, {
        status: 'inactive'
      })
    })
    return batch.commit()
  },
  async deleteJobSitesAndRelatedDevicesByOrgAsync(organization) {
    const db = firebase.firestore()
    const batch = db.batch()
    const jobsites = await firebase.firestore().collection('jobsites').get().then(({ docs }) => docs)
    const orgJobsites = jobsites.filter(jobsite => jobsite.data().organizationId === organization.id)
    orgJobsites.forEach((jobsite) => {
      const jobsiteRef = db.doc(`jobsites/${jobsite.id}`)
      if (jobsite.data().deviceCount > 0) {
        jobsite.data().devices.forEach((deviceId) => {
          const deviceRef = db.doc(`devices/${deviceId}`)
          batch.delete(deviceRef)
        })
      }
      batch.delete(jobsiteRef)
    })
    return batch.commit()
  },
  deleteJobsites(jobsites) {
    const db = firebase.firestore()
    const batch = db.batch()
    jobsites.forEach((jobsite) => {
      const { jobsiteId } = jobsite
      const jobsiteRef = db.doc(`jobsites/${jobsiteId}`)
      batch.delete(jobsiteRef)
    })
    return batch.commit()
  }
}

export default jobsiteClient
