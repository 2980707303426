import styled from 'styled-components'
import Checkbox from '@material-ui/core/Checkbox'

const getRowBorderColor = (theme, isSelected, isHover, isLastRow) => {
  if (isSelected) {
    return {
      borderColor: isLastRow ? theme.colors.n05 : theme.colors.n04,
      backgroundColor: theme.colors.n03
    }
  }

  if (isHover) {
    return {
      borderColor: isLastRow ? theme.colors.n05 : theme.colors.n03,
      backgroundColor: theme.colors.n02
    }
  }

  return {
    borderColor: isLastRow ? theme.colors.n05 : theme.colors.n03,
    backgroundColor: '#fff'
  }
}


export const TableContainer = styled.table(({ theme }) => ({
  borderBottom: '1px solid',
  borderColor: theme.colors.n05,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  width: '100%',
  borderSpacing: 0,
  overflow: 'auto'
}))

export const TableHeader = styled.thead(({ theme, scrollbarWidth }) => ({
  display: 'flex',
  textAlign: 'left',
  fontSize: 14,
  fontWeight: 500,
  color: theme.colors.n09,
  '& > tr': {
    display: 'flex',
    flex: '1 0 auto',
    alignItems: 'stretch',
    minWidth: 1160
  },
  '&::after': {
    content: '""',
    width: scrollbarWidth,
    borderBottom: '2px solid',
    borderColor: theme.colors.n05
  }
}))

export const TableHeaderCell = styled.th(({
  theme, isSorted, cellWidth, flex, align
}) => ({
  flex: `${flex ? cellWidth : 0} 0 auto`,
  width: cellWidth,
  display: 'inline-flex',
  justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
  alignItems: 'center',
  textAlign: align,
  padding: '10px 16px',
  fontWeight: 500,
  borderBottom: '2px solid',
  borderColor: isSorted ? theme.colors.n08 : theme.colors.n05,
  svg: {
    verticalAlign: '-3px',
    color: theme.colors.n08
  }
}))

export const TableBody = styled.tbody(({ controlsHeight }) => ({
  display: 'flex',
  flex: '10000 1 auto',
  flexDirection: 'column',
  height: controlsHeight ? `calc(100vh - ${controlsHeight}px - 60px)` : 'calc(100vh - 235px)'
}))

export const TableBodyRow = styled.tr(({ theme, isSelected, isLastRow }) => ({
  display: 'flex',
  alignItems: 'stretch',
  minHeight: '3.5rem',
  fontWeight: 400,
  borderBottom: '1px solid',
  minWidth: 1160,
  ...getRowBorderColor(theme, isSelected, false, isLastRow),
  '&:hover': {
    ...getRowBorderColor(theme, isSelected, true, isLastRow)
  }
}))

export const TableBodyCell = styled.td(({
  theme, cellWidth, flex
}) => ({
  display: 'inline-flex',
  flex: `${flex ? cellWidth : 0} 0 auto`,
  alignItems: 'center',
  height: '3.5rem',
  width: cellWidth,
  padding: '0 1rem',
  fontSize: 14,
  lineHeight: '1.5rem',
  letterSpacing: '0.3px',
  color: theme.colors.n07,
  i: {
    letterSpacing: '0.26px',
    color: theme.colors.n06
  },
  div: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  button: {
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    width: '100%',
    svg: {
      verticalAlign: '-7px',
      color: theme.colors.n08
    }
  },
  svg: {
    verticalAlign: '-3px'
  }
}))

export const CellWrapper = styled.span(({ width, align }) => ({
  display: 'block',
  width: width || '100%',
  textAlign: align,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}))

export const MaterialCheckbox = styled(Checkbox)(({ theme, disabled }) => ({
  padding: '0 !important',
  borderRadius: '10% !important',
  cursor: disabled ? 'not-allowed' : 'inherit',
  svg: {
    color: disabled ? theme.colors.n05 : theme.colors.n08,
    cursor: disabled ? 'not-allowed' : 'inherit'
  }
}))

export const Status = styled.span(({ isConfigured, theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  color: isConfigured ? theme.colors.s02 : 'inherit',
  svg: {
    marginRight: '0.5rem',
    color: isConfigured ? theme.colors.s02 : 'inherit',
    cursor: 'not-allowed'
  }
}))

export const EmptyListMessageContainer = styled.div({
  margin: '1.5rem 1rem'
})

export const EmptyListLink = styled.a(({ theme }) => ({
  color: theme.colors.b01,
  textDecoration: 'underline',
  cursor: 'pointer'
}))
