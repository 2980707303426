import React from 'react'
import PropTypes from 'prop-types'

import { StyledConfirmationHeader } from './Styles'

const ConfirmationHeader = ({ header }) => (
  <>
    <StyledConfirmationHeader>{header}</StyledConfirmationHeader>
  </>
)

ConfirmationHeader.propTypes = {
  header: PropTypes.string.isRequired
}

export default ConfirmationHeader
