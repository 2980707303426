import UserClient from './Client'
import JobClient from '../Jobs/Client'
import { types } from './Reducer'

const getActions = (state, dispatch, toast) => {
  const addCleanupTask = (task) => {
    dispatch({ type: types.ADD_CLEANUP_TASK, payload: task })
  }

  const hydrateUserData = async (user) => {
    try {
      dispatch({ type: types.LOGIN_PENDING })
      const { uid: userId } = user
      const { data: userRecord, unsubscribe } = await UserClient.getUserData(userId)
      addCleanupTask(unsubscribe)
      const permissions = UserClient.getUserPermissions(userId)
      const [userRecordResult, permissionsResult] = await Promise.all([userRecord, permissions])
      dispatch({ type: types.LOGIN_SUCCESS, payload: { userId, ...userRecordResult, permissions: permissionsResult } })
    } catch (error) {
      dispatch({ type: types.LOGIN_FAILURE, payload: error.code })
    }
  }

  const dehydrateUserData = () => {
    dispatch({ type: types.LOGOUT_SUCCESS })
  }

  const checkForUser = (user) => {
    if (user) {
      hydrateUserData(user)
    } else {
      dehydrateUserData()
    }
  }

  const login = async (email, password) => {
    try {
      dispatch({ type: types.LOGIN_PENDING })
      await UserClient.login(email, password)
    } catch (error) {
      dispatch({ type: types.LOGIN_FAILURE, payload: error.code })
    }
  }

  const logout = async () => {
    try {
      state.cleanupTasks.forEach(task => task())
      dispatch({ type: types.LOGOUT_PENDING })
      await UserClient.logout()
    } catch (error) {
      dispatch({ type: types.LOGOUT_FAILURE, payload: error.code })
    }
  }

  const reAuthenticate = async (password) => {
    let response
    try {
      dispatch({ type: types.RE_AUTH_PENDING })
      response = await UserClient.reAuthenticate(password)
      dispatch({ type: types.RE_AUTH_SUCCESS })
    } catch (error) {
      dispatch({ type: types.RE_AUTH_FAILURE, payload: error.code })
    }
    return response
  }

  const initializeAuthStateListener = () => {
    UserClient.onAuthStateChanged(checkForUser)
  }

  const fetchUserPermissions = async (userId) => {
    try {
      dispatch({ type: types.FETCH_PERMISSIONS_PENDING })
      const permissions = await UserClient.getUserPermissions(userId)
      dispatch({ type: types.FETCH_PERMISSIONS_SUCCESS, payload: permissions })
    } catch (error) {
      dispatch({ type: types.FETCH_PERMISSIONS_FAILURE, payload: error.code })
    }
  }

  const setReferrer = (referrer) => {
    dispatch({ type: types.SET_REFERRER, payload: referrer })
  }

  const setAuthMagicLink = (isAuthMagicLink) => {
    dispatch({ type: types.SET_AUTH_MAGIC_LINK, payload: isAuthMagicLink })
  }

  const setInviteData = (invite) => {
    dispatch({ type: types.SET_INVITE_DATA, payload: invite })
  }

  const fetchInvite = inviteId => UserClient.getInviteData(inviteId)
  const processInvite = (inviteId, values) => UserClient.processInvite(inviteId, values)
  const createUser = values => UserClient.createUser(values)
  const isAuthSessionFresh = () => UserClient.isAuthSessionFresh()

  const fetchUserAdmin = async userId => UserClient.getUserAdmin(userId)
  const fetchDeltaControlsUser = async userId => UserClient.getDeltaControlsOrg(userId)
  const updateAdmin = async (orgId, userId, formData) => UserClient.updateAdmin(orgId, userId, formData)

  const sendSignInEmail = async (email, language) => UserClient.sendSignInLinkToEmail(email, language)

  const signInWithEmailLink = async (email, link) => {
    try {
      dispatch({ type: types.LOGIN_PENDING })
      await UserClient.signInWithEmailLink(email, link)
      setAuthMagicLink(true)
    } catch (error) {
      dispatch({ type: types.LOGIN_FAILURE, payload: error.code })
    }
  }

  const changeEmail = async (newEmail) => {
    try {
      dispatch({ type: types.CHANGE_EMAIL_PENDING })
      const payload = await UserClient.changeEmail(newEmail)
      dispatch({ type: types.CHANGE_EMAIL_SUCCESS, payload })
    } catch (error) {
      dispatch({ type: types.CHANGE_EMAIL_FAILURE, payload: error })
    }
  }

  const changePassword = async (newPassword, successMessage) => {
    try {
      dispatch({ type: types.CHANGE_PASSWORD_PENDING })
      const payload = await UserClient.changePassword(newPassword)
      dispatch({ type: types.CHANGE_PASSWORD_SUCCESS, payload })
      toast.addNotification(successMessage, 'success', 4)
    } catch (error) {
      dispatch({ type: types.CHANGE_PASSWORD_FAILURE, payload: error })
    }
  }

  const updateProfile = async (formData, successMessage) => {
    try {
      dispatch({ type: types.UPDATE_PROFILE_PENDING })
      await UserClient.updateProfile(formData)
      dispatch({ type: types.UPDATE_PROFILE_SUCCESS })
      toast.addNotification(successMessage, 'success', 4)
    } catch (error) {
      dispatch({ type: types.UPDATE_PROFILE_FAILURE, payload: error.code })
    }
  }
  const resendInviteEmail = async (inviteId) => {
    let response
    try {
      dispatch({ type: types.RESEND_INVITE_PENDING })
      response = await UserClient.resendInviteEmail(inviteId)
      dispatch({ type: types.RESEND_INVITE_SUCCESS })
    } catch (error) {
      dispatch({ type: types.RESEND_INVITE_FAILURE })
    }
    return response
  }

  const removeInviteEmail = async (inviteId) => {
    let response
    try {
      dispatch({ type: types.REMOVE_INVITE_PENDING })
      response = await UserClient.deleteInvite(inviteId)
      dispatch({ type: types.REMOVE_INVITE_SUCCESS })
    } catch (error) {
      dispatch({ type: types.REMOVE_INVITE_FAILURE })
    }
    return response
  }

  const setLanguagePreference = (languageChoice) => {
    dispatch({ type: types.SET_LANGUAGE_PREFERENCE, payload: languageChoice })
  }

  const setSuccessInvite = (inviteOrgId) => {
    dispatch({ type: types.SET_SUCCESS_INVITE, payload: inviteOrgId })
  }

  const resetError = () => {
    dispatch({ type: types.RESET_ERROR })
  }

  const resetSuccessInvite = () => {
    dispatch({ type: types.RESET_SUCCESS_INVITE })
  }

  const updateStatus = async (organizationId, user) => {
    let response
    try {
      dispatch({ type: types.UPDATE_PROFILE_PENDING, payload: user })
      response = await UserClient.updateStatus(organizationId, user)
      dispatch({ type: types.UPDATE_PROFILE_SUCCESS })
    } catch (error) {
      dispatch({ type: types.UPDATE_PROFILE_FAILURE, payload: error.code })
    }
    return response
  }

  const updatePermissionOrganizationStatus = async (organizations) => {
    try {
      dispatch({ type: types.UPDATE_PROFILE_PENDING })
      const promises = organizations.map(async (organization) => {
        await UserClient.updatePermissionOrganizationStatus(organization)
        if (organization.status === 'active') {
          await UserClient.deleteAllOrgInvites(organization)
          await JobClient.deactivateJobSitesByOrgAsync(organization)
        }
      })
      await Promise.all(promises)
      dispatch({ type: types.UPDATE_PROFILE_SUCCESS })
    } catch (error) {
      dispatch({ type: types.UPDATE_PROFILE_FAILURE, payload: error.code })
    }
  }

  return {
    login,
    logout,
    reAuthenticate,
    hydrateUserData,
    dehydrateUserData,
    initializeAuthStateListener,
    fetchUserAdmin,
    fetchUserPermissions,
    fetchInvite,
    fetchDeltaControlsUser,
    createUser,
    processInvite,
    isAuthSessionFresh,
    sendSignInEmail,
    signInWithEmailLink,
    changeEmail,
    changePassword,
    updateProfile,
    setReferrer,
    setInviteData,
    resendInviteEmail,
    removeInviteEmail,
    checkForUser,
    setLanguagePreference,
    setSuccessInvite,
    setAuthMagicLink,
    resetError,
    resetSuccessInvite,
    updateAdmin,
    updateStatus,
    updatePermissionOrganizationStatus
  }
}

export default getActions
