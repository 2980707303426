import React from 'react'
import {
  Container, SpinnerWrapper, OuterCircle, InnerCircle, InnerCircleWrapper, Three
} from './Styled'

const Spinner = () => (
  <Container>
    <SpinnerWrapper x="0px" y="0px" viewBox="0 0 150 150">
      <Three fillRule="evenodd">
        <path d="M116.183 121.943c-3.154 0-5.835-.722-8.044-2.168-2.207-1.444-3.837-3.428-4.888-5.948l5.1-1.966c1.541 3.361 4.152 5.041 7.832 5.041 2.103 0 3.83-.48 5.179-1.437 1.349-.957 2.024-2.394 2.024-4.31 0-1.645-.588-2.998-1.761-4.057-1.175-1.058-2.936-1.588-5.284-1.588h-3.545v-5.04h3.545c1.717 0 3.093-.445 4.127-1.336 1.033-.89 1.55-2.041 1.55-3.453 0-1.646-.508-2.923-1.524-3.831-1.017-.907-2.453-1.361-4.31-1.361-2.945 0-5.118 1.328-6.52 3.982l-5.1-1.865c.877-2.218 2.331-3.964 4.364-5.241 2.033-1.278 4.504-1.916 7.413-1.916 3.294 0 6.01.95 8.149 2.848 2.137 1.899 3.207 4.192 3.207 6.88 0 3.562-1.578 6.116-4.732 7.661 4.065 1.547 6.098 4.252 6.098 8.116 0 3.462-1.191 6.158-3.575 8.09-2.383 1.933-5.485 2.899-9.305 2.899" />
      </Three>
      <OuterCircle cx="75" cy="75" r="46" />
      <InnerCircleWrapper>
        <InnerCircle cx="75" cy="75" r="46" />
      </InnerCircleWrapper>
    </SpinnerWrapper>
  </Container>
)

export default Spinner
