import React from 'react'
import PropTypes from 'prop-types'

import { StyledConfirmationFooter } from './Styles'

const ConfirmationFooter = ({ footer }) => (
  <>
    <StyledConfirmationFooter>{footer}</StyledConfirmationFooter>
  </>
)

ConfirmationFooter.propTypes = {
  footer: PropTypes.string.isRequired
}

export default ConfirmationFooter
