import styled from 'styled-components'

export const StyledHeaderContainer = styled.div(({ theme }) => ({
  height: '64px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 1rem',
  borderBottom: `1px solid ${theme.colors.n04}`
}))

export const CloseLink = styled.a({
  cursor: 'pointer'
})
