import React from 'react'
import PropTypes from 'prop-types'
import Radio from './Radio'
import {
  Title,
  RadioGroupContainer,
  GridContainer
} from './Styled'


const RadioGroup = ({
  options,
  name,
  value,
  title,
  onChange,
  onBlur,
  tiled,
  testid,
  hasBottomMargin,
  ...rest
}) => (
  <RadioGroupContainer hasBottomMargin={hasBottomMargin}>
    {title && <Title>{title}</Title>}
    <GridContainer tiled={tiled} data-testid={testid}>
      {options.map(({
        value: optVal, label, hasNoZones, isExistingCheckbox, testId
      }, idx) => (
        <Radio
          onChange={onChange}
          onBlur={onBlur}
          id={optVal + idx}
          name={name}
          key={optVal}
          value={optVal}
          label={label}
          hasNoZones={hasNoZones}
          isExistingCheckbox={isExistingCheckbox}
          checked={value === optVal}
          testId={testId}
          {...rest}
        />
      ))}
    </GridContainer>
  </RadioGroupContainer>
)

RadioGroup.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  tiled: PropTypes.bool,
  testid: PropTypes.string,
  hasBottomMargin: PropTypes.bool
}

RadioGroup.defaultProps = {
  value: undefined,
  title: null,
  tiled: false,
  testid: null,
  hasBottomMargin: true
}

export default RadioGroup
