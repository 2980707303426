import React, { useContext } from 'react'

import useTranslate from '../../../Utils/Hooks/useTranslate'

import ModalContext from '../../../State/Modal/Context'
import ModalContent from '../../Modal/Components/ModalContent'
import ModalFooter from '../../Modal/Components/ModalFooter'
import Button from '../../Button'
import Ribbon from '../../Ribbon'

const RecoveryLinkSent = () => {
  const { actions: { closeModal } } = useContext(ModalContext)
  const translate = useTranslate('recoveryLinkSent')

  return (
    <>
      <Ribbon type="success">{translate('successRibbon')}</Ribbon>
      <ModalContent>
        <p>{translate('description')}</p>
      </ModalContent>
      <ModalFooter>
        <Button onClick={closeModal} inverted data-testid="recovery-close">{translate('closeButton')}</Button>
      </ModalFooter>
    </>
  )
}

export default RecoveryLinkSent
