import React from 'react'
import PropTypes from 'prop-types'
import { Header } from './Components/Header'

const Heading = ({
  tag, level, children, isOnlyHeading
}) => (
  <Header as={tag} level={level} isOnlyHeading={isOnlyHeading} data-testid="heading">
    {children}
  </Header>
)

Heading.propTypes = {
  tag: PropTypes.string,
  level: PropTypes.number,
  children: PropTypes.node,
  isOnlyHeading: PropTypes.bool
}

Heading.defaultProps = {
  tag: undefined,
  level: undefined,
  children: undefined,
  isOnlyHeading: false
}

export default Heading
