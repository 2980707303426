import React, { useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { Container } from './Styled'

const PanelFooter = ({ children }) => {
  const portal = document.getElementById('panelFooter')
  const el = useRef(document.createElement('div'))
  const refWrapper = useRef(null)

  const target = el.current

  useEffect(() => {
    if (portal) {
      portal.appendChild(target)
    }
    return () => target.remove()
  }, [portal]) // eslint-disable-line

  return createPortal((
    <Container ref={refWrapper} data-testid="panel-footer">{children}</Container>
  ), target)
}

export default PanelFooter
