import React from 'react'
import PropTypes from 'prop-types'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
import {
  CheckboxContainer, CheckboxInput, Checked, UnChecked, LabelText
} from './Styles'

const Checkbox = ({
  name,
  value,
  id,
  label,
  onChange,
  index,
  testIdRoot,
  disabled,
  ...rest
}) => (
  <>
    <CheckboxContainer htmlFor={id} data-testid={`${testIdRoot}-${index}`}>
      <CheckboxInput disabled={disabled} id={id} onChange={onChange} name={name} value={value} type="checkbox" data-testid={`checkbox-${index}`} {...rest} />
      <Checked disabled={disabled}><CheckBox /></Checked>
      <UnChecked disabled={disabled}><CheckBoxOutlineBlank /></UnChecked>
      <LabelText data-testid={`checkbox-label-${index}`}>{label}</LabelText>
    </CheckboxContainer>
  </>
)

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  testIdRoot: PropTypes.string,
  index: PropTypes.number,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
}

Checkbox.defaultProps = {
  disabled: false,
  name: undefined,
  value: undefined,
  id: undefined,
  index: undefined,
  testIdRoot: undefined
}

export default Checkbox
