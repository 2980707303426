import { format, differenceInDays } from 'date-fns'

export default function formatTime(timestamp, todayString = 'Today', yesterdayString = 'Yesterday') {
  if (!timestamp) return ''

  const { seconds } = timestamp
  const time = seconds * 1000
  const difference = differenceInDays(time, new Date())

  if (difference === 0) {
    return `${todayString}, ${format(time, 'h:mm aa')}`
  }

  if (difference === 1) {
    return `${yesterdayString}, ${format(time, 'h:mm aa')}`
  }

  return format(time, 'MMM d, yyyy')
}
