import EditDevice from '../ConfigTransformations/EditDevice'
import CreateDevice from '../ConfigTransformations/CreateDevice'
import EditJobsite from '../ConfigTransformations/EditJobsite'
import EditOrganization from '../ConfigTransformations/EditOrganization'
import CreateJobsite from '../ConfigTransformations/CreateJobsite'
import CreateOrganization from '../ConfigTransformations/CreateOrganization'
import DuplicateTemplate from '../ConfigTransformations/DuplicateTemplate'
import EditIOAttribute from '../ConfigTransformations/EditIOAttribute'
import EditTemplate from '../ConfigTransformations/EditTemplate'
import ReadOnlyAttribute from '../ConfigTransformations/ReadOnlyAttribute'
import BooleanAttribute from '../ConfigTransformations/BooleanAttribute'
import OffSelectAttribute from '../ConfigTransformations/OffSelectAttribute'
import NullAttribute from '../ConfigTransformations/NullAttribute'
import ReportingEnabled from '../ConfigTransformations/ReportingEnabled'
import ProgressiveField from '../ConfigTransformations/ProgressiveField'

const useConfigTransform = () => {
  const ConfigTransformations = {}

  ConfigTransformations['devices/edit'] = EditDevice
  ConfigTransformations['devices/create'] = CreateDevice
  ConfigTransformations['jobsite/edit'] = EditJobsite
  ConfigTransformations['jobsite/create'] = CreateJobsite
  ConfigTransformations['organization/edit'] = EditOrganization
  ConfigTransformations['organization/create'] = CreateOrganization
  ConfigTransformations['template/duplicate'] = DuplicateTemplate
  ConfigTransformations['attributes/editIO'] = EditIOAttribute
  ConfigTransformations['template/edit'] = EditTemplate
  ConfigTransformations['attributes/readOnly'] = ReadOnlyAttribute
  ConfigTransformations['attributes/booleanField'] = BooleanAttribute
  ConfigTransformations['attributes/offSelectField'] = OffSelectAttribute
  ConfigTransformations['attributes/nullField'] = NullAttribute
  ConfigTransformations['attributes/reportingEnabled'] = ReportingEnabled
  ConfigTransformations['attributes/progressive'] = ProgressiveField

  const getCurrentTransform = type => ConfigTransformations[type]
  return {
    getCurrentTransform
  }
}

export default useConfigTransform
