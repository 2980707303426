import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import PanelContext from '../../State/Panel/Context'

import { StyledLink } from './Styles'

const Link = ({ ReactRouterComponent, children, ...rest }) => {
  const { actions: { resetView } } = useContext(PanelContext)

  return (
    <>
      <StyledLink
        {...rest}
        onClick={resetView}
        as={ReactRouterComponent || 'a'}
      >
        {children}
      </StyledLink>
    </>
  )
}

Link.propTypes = {
  ReactRouterComponent: PropTypes.object,
  children: PropTypes.node.isRequired
}

Link.defaultProps = {
  ReactRouterComponent: undefined
}

export default Link
