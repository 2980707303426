import { string } from 'yup'

const ipRegex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/

const NetworkConditionalIP = (conditionalField, conditionalValue, requiredMessage, validIpMessage) => string().when(conditionalField, {
  is: conditionalValue,
  then: string().matches(ipRegex, validIpMessage),
  otherwise: string().notRequired()
})

export default NetworkConditionalIP
