import React from 'react'

import useTranslate from '../../../Utils/Hooks/useTranslate'

import ForgotPassword from '../ForgotPassword'
import RecoveryLinkSent from '../RecoveryLinkSent'
import DuplicateTemplate from '../DuplicateTemplate'
import ResendInviteLink from '../ResendInviteLink'
import ResendInviteLinkSent from '../ResendInviteLinkSent'
import RemoveInviteLink from '../RemoveInviteLink'
import CancelConfirmation from '../CancelConfirmation'
import MQTTOptOut from '../MQTTOptOut'
import UsersArchive from '../UsersArchive'
import OrganizationsArchive from '../OrganizationsArchive'
import DeleteOrganizations from '../DeleteOrganizations'
import DevicesActivate from '../DevicesActivate'
import DevicesDeactivate from '../DevicesDeactivate'
import DevicesDelete from '../DevicesDelete'
import JobsArchive from '../JobsArchive'
import JobsUnarchive from '../JobsUnarchive'
import JobsDelete from '../JobsDelete'
import TemplatesDelete from '../TemplatesDelete'

const ModalViewFactory = () => {
  const translate = useTranslate('modalHeader')

  const ModalViews = {}
  ModalViews.blank = {
    view: () => (<></>),
    header: ''
  }
  ModalViews['forgot-password/request'] = {
    header: translate('forgotPassword'),
    view: ForgotPassword
  }
  ModalViews['forgot-password/success'] = {
    header: translate('forgotPassword'),
    view: RecoveryLinkSent
  }
  ModalViews['templates/duplicate'] = {
    header: translate('duplicateTemplate'),
    view: DuplicateTemplate
  }
  ModalViews['templates/delete'] = {
    header: translate('deleteTemplates'),
    view: TemplatesDelete
  }
  ModalViews['resend-invite-link/request'] = {
    header: translate('resendInvite'),
    view: ResendInviteLink
  }
  ModalViews['resend-invite-link/success'] = {
    header: translate('resendInvite'),
    view: ResendInviteLinkSent
  }
  ModalViews['remove-invite-link/request'] = {
    header: translate('removeInvite'),
    view: RemoveInviteLink
  }
  ModalViews['cancel-confirmation/request'] = {
    header: translate('unsavedChanges'),
    view: CancelConfirmation
  }
  ModalViews['mqtt-opt-out/request'] = {
    header: translate('mqttOptOut'),
    view: MQTTOptOut
  }
  ModalViews['users/archive'] = {
    header: translate('deactivateUsers'),
    view: UsersArchive
  }
  ModalViews['users/reactivate'] = {
    header: translate('reactivateUsers'),
    view: UsersArchive
  }
  ModalViews['organizations/archive'] = {
    header: translate('deactivateOrganizations'),
    view: OrganizationsArchive
  }
  ModalViews['organizations/reactivate'] = {
    header: translate('reactivateOrganizations'),
    view: OrganizationsArchive
  }
  ModalViews['organizations/delete'] = {
    header: translate('deleteOrganizations'),
    view: DeleteOrganizations
  }
  ModalViews['devices/activate'] = {
    header: translate('activateDevices'),
    view: DevicesActivate
  }
  ModalViews['devices/deactivate'] = {
    header: translate('deactivateDevices'),
    view: DevicesDeactivate
  }
  ModalViews['devices/delete'] = {
    header: translate('deleteDevices'),
    view: DevicesDelete
  }
  ModalViews['jobs/archive'] = {
    header: translate('archiveJobsites'),
    view: JobsArchive
  }
  ModalViews['jobs/unarchive'] = {
    header: translate('unarchiveJobsites'),
    view: JobsUnarchive
  }
  ModalViews['jobs/delete'] = {
    header: translate('deleteJobsite'),
    view: JobsDelete
  }
  const getCurrentView = (type) => {
    const modalView = Object.keys(ModalViews).includes(type) ? type : 'blank'
    return ModalViews[modalView]
  }

  return {
    getCurrentView
  }
}

export default ModalViewFactory
