import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'

import useTranslate from '../../../Utils/Hooks/useTranslate'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'

import UserContext from '../../../State/User/Context'
import PanelContext from '../../../State/Panel/Context'

import Fieldset from '../../Form/Fieldset'
import Select from '../../Form/Select'
import PanelFooter from '../Components/PanelFooter'

const languageOptions = [
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Español' },
  { value: 'de', label: 'Deutsch' },
  { value: 'fr', label: 'Français' }
]

const EditLanguage = ({ setIsDirty }) => {
  const { actions: { closePanel, setSubmitted } } = useContext(PanelContext)
  const { selectors: { getUser, hasError, getErrorCode }, actions: { updateProfile } } = useContext(UserContext)
  const { language } = getUser()
  const initialValues = { language }
  const translate = useTranslate('myAccountPanel')
  useErrorNotifier(hasError(), getErrorCode())

  const labels = {
    buttonLabel: translate('saveButton'),
    submittingLabel: translate('submittingButton'),
    cancelLabel: translate('cancelButton')
  }

  const onSubmit = async (values) => {
    await updateProfile({ language: values.language }, translate('editLanguage.languageUpdatedSuccessfully', values.language))
    setSubmitted()
    closePanel()
  }

  const handleChange = (event, setFieldValue) => {
    setFieldValue(event.target.id, event.target.value)
    event.preventDefault()
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        onReset={closePanel}
      >
        {({
          values, errors, isSubmitting, handleSubmit, setFieldValue, handleReset, isValid, dirty
        }) => (
          <Form onChange={setIsDirty(dirty)}>
            <Fieldset>
              <Select
                id="language"
                name="language"
                label={translate('editLanguage.languageInputLabel')}
                options={languageOptions}
                value={values.language}
                onChange={event => handleChange(event, setFieldValue)}
                testId="language"
              />
            </Fieldset>
            <PanelFooter
              handleReset={handleReset}
              handleSubmit={handleSubmit}
              errors={errors}
              labels={labels}
              isSubmitting={isSubmitting}
              isValid={isValid}
              testIdRoot="edit-language"
            />
          </Form>
        )}
      </Formik>

    </>
  )
}

EditLanguage.propTypes = {
  setIsDirty: PropTypes.func.isRequired
}

export default EditLanguage
