import styled from 'styled-components'

export const StyledButtonIcon = styled.button(({ theme, inverted, styles }) => ({
  ...styles,
  padding: 0,
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  svg: {
    height: '1.5rem',
    minWidth: '1.5rem',
    maxWidth: '1.5rem',
    color: inverted ? theme.colors.n04 : theme.colors.n08
  },
  '&:disabled': {
    svg: {
      color: theme.colors.n03
    }
  },
  '&:hover': {
    svg: {
      color: inverted ? theme.colors.n06 : theme.colors.n09
    }
  },
  '&:focus': {
    borderRadius: '2px',
    boxShadow: `0 0 3px 1px ${theme.colors.n05}`
  }
}))
