import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import TextInput from '../../Form/Text'

const Text = (props) => {
  const [field, meta] = useField(props)
  const { submitCount } = props
  const hasError = (meta.touched && meta.error && meta.value !== meta.initialValue) || submitCount > 0

  return (
    <>
      <TextInput
        {...props}
        {...field}
        {...(hasError ? { error: meta.error } : {})}
      />
    </>
  )
}

Text.propTypes = {
  submitCount: PropTypes.number
}

Text.defaultProps = {
  submitCount: undefined
}

export default Text
