import useValidationSchema from '../../Hooks/useValidationSchema'
import useConfigTransform from '../../Hooks/useConfigTransform'
import TimeComponent from './Components/TimeComponent'

const TimeField = () => {
  const { getCurrentSchema } = useValidationSchema()
  const { getCurrentTransform } = useConfigTransform()

  return {
    validationSchema: getCurrentSchema('attributes/timeField'),
    configTransform: getCurrentTransform('attributes/readOnly'),
    Component: TimeComponent,
    CoVComponent: () => null
  }
}

export default TimeField
