const getSelectors = state => ({
  getState() {
    return state
  },
  getSelectedTemplate() {
    return state.selectedTemplate
  },
  getSelectedTemplateId() {
    return state.selectedTemplateId
  },
  getSelectedTemplatesList() {
    return state.selectedTemplatesList
  },
  getSelectedAttribute() {
    return state.selectedAttribute
  },
  getTemplate(templateId) {
    return state.data && state.data.find(template => template.templateId === templateId)
  },
  getTemplates() {
    return state.data
  },
  isLoading() {
    return state.loading
  },
  hasError() {
    return state.error.code
  }
})

export default getSelectors
