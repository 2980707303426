import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

const StatefulRoute = ({
  component: Component, setState, setOrgSelectState, ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      <Component setState={setState} setOrgSelectState={setOrgSelectState} isFromAuthed {...props} />
    )}
  />
)

StatefulRoute.propTypes = {
  setState: PropTypes.func.isRequired,
  component: PropTypes.elementType.isRequired,
  setOrgSelectState: PropTypes.func
}

StatefulRoute.defaultProps = {
  setOrgSelectState: () => {}
}

export default StatefulRoute
