import uuid from 'uuid/v4'

const initialState = {
  toasts: []
}

const types = {
  ADD_TOAST: 'ADD_TOAST',
  REMOVE_TOAST: 'REMOVE_TOAST'
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case (types.ADD_TOAST):
      return {
        ...state,
        toasts: [
          ...state.toasts,
          {
            id: uuid(),
            text: action.payload.text,
            category: action.payload.category || 'info',
            timeout: action.payload.timeout
          }
        ]
      }
    case (types.REMOVE_TOAST):
      return {
        ...state,
        toasts: state.toasts.filter(item => item.id !== action.payload)
      }
    default:
      return state
  }
}

export {
  reducer,
  types,
  initialState
}
