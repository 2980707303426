import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'

import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useTranslate from '../../../Utils/Hooks/useTranslate'
import useValidationSchema from '../../../Utils/Hooks/useValidationSchema'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'
import useConfigTransform from '../../../Utils/Hooks/useConfigTransform'

import PanelContext from '../../../State/Panel/Context'
import OrganizationsContext from '../../../State/Organization/Context'
import NotificationContext from '../../../State/Notifications/Context'
import PanelFooter from '../Components/PanelFooter'
import Organization from '../Components/Organization'
// import Subscription from '../Components/Subscription'
import PanelRibbon from '../Components/PanelRibbon'

const EditOrganization = ({ setIsDirty }) => {
  const { actions: { closePanel, setSubmitted } } = useContext(PanelContext)
  const { selectors: { getSelectedOrganization }, actions: { updateOrganization, createInvite } } = useContext(OrganizationsContext)
  const { actions: { addNotification } } = useContext(NotificationContext)
  const organization = getSelectedOrganization()
  const [execute, , , result, hasError] = useAsyncCallback(updateOrganization)
  const { getCurrentTransform } = useConfigTransform()
  const configTransform = getCurrentTransform('organization/edit')
  const { getCurrentSchema } = useValidationSchema()
  const translate = useTranslate('organizationPanel')
  useErrorNotifier(hasError, result)

  const {
    name,
    accountId,
    email,
    city,
    provinceOrState,
    country
  } = organization

  const initialValues = {
    name,
    accountId,
    email,
    city,
    provinceOrState,
    country,
    status: 'active'
  }

  const onSubmit = async (values) => {
    const formData = configTransform(values)
    const inviteData = await execute(organization.id, formData)
    if (inviteData) {
      await createInvite(inviteData)
      addNotification(translate('inviteEmailSent'), 'success', 2)
    }
    setSubmitted()
    closePanel()
  }
  const labels = {
    buttonLabel: translate('saveButton'),
    cancelLabel: translate('cancelButton')
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getCurrentSchema('organizations/edit')}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({
        handleSubmit, isSubmitting, errors, isValid, dirty, submitCount
      }) => (
        <Form onChange={setIsDirty(dirty)}>
          <PanelRibbon errors={errors} submitCount={submitCount} />
          <Organization submitCount={submitCount} />
          {/*
            subscription commented out until requirements are made clear: CD-409
            <Subscription values={values} setFieldValue={setFieldValue} />
          */}
          <PanelFooter
            handleReset={closePanel}
            handleSubmit={handleSubmit}
            errors={errors}
            labels={labels}
            isSubmitting={isSubmitting}
            isValid={isValid}
            testIdRoot="edit-organization"
            submitCount={submitCount}
          />
        </Form>
      )}
    </Formik>
  )
}

EditOrganization.propTypes = {
  setIsDirty: PropTypes.func.isRequired
}

export default EditOrganization
