import React, {
  useState,
  useEffect,
  useContext,
  useMemo
} from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import useSearch from '../../../Utils/Hooks/useSearch'
import useTranslate from '../../../Utils/Hooks/useTranslate'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'
import calculatePercentage from '../../../Utils/calculatePercentage'
import useSorter from '../../../Utils/Hooks/useSorter'

import JobsContext from '../../../State/Jobs/Context'
import PanelContext from '../../../State/Panel/Context'
import ModalContext from '../../../State/Modal/Context'
import FilterWrapper from '../../FilterWrapper'
import { Tab, TabGroup } from '../../TabGroup'
import Search from '../../Search'
import Table from '../../Table'
import HeaderCheckbox from '../../Table/Components/HeaderCheckbox'
import RowCheckbox from '../../Table/Components/RowCheckbox'
import {
  JobsiteName, Address, DateCreated, RightAligned, ConfiguredPercentage
} from './Components'
import EditButton from './Components/EditButton'


const searchFields = ['name', 'address.displayAddress']

const JobsitesTable = ({
  selectedCheckboxes, setSelectedCheckboxes, status, setStatus
}) => {
  const { actions: { changeView }, selectors: { dataIsStale } } = useContext(PanelContext)
  const { selectors: { dataIsStale: modalDataIsStale } } = useContext(ModalContext)
  const [searchQuery, setSearchQuery] = useState('')
  const [, setFilters] = useState({})
  const {
    selectors: {
      getJobsites, isLoading, hasError, getErrorCode
    },
    actions: { fetchJobsites }
  } = useContext(JobsContext)
  const { organizationId } = useParams()
  const activeJobsites = getJobsites() && getJobsites().filter(jobsite => jobsite.status === 'active')
  const archivedJobsites = getJobsites() && getJobsites().filter(jobsite => jobsite.status === 'inactive')
  const results = useSearch(status === 'inactive' ? archivedJobsites : activeJobsites, searchQuery, searchFields)
  const isFiltered = !!searchQuery
  const translate = useTranslate('jobsPage')
  const translateTable = useTranslate('tableFiltering')
  useErrorNotifier(hasError(), getErrorCode())
  const sortJobName = useSorter('rowName')

  const columns = useMemo(
    () => [
      {
        id: 'selection',
        groupByBoundary: true,
        Header: HeaderCheckbox,
        Cell: ({ row, ...rest }) => <RowCheckbox row={row} {...rest} />, // eslint-disable-line
        width: 56
      },
      {
        Header: translate('tableNameColumn'),
        accessor: 'name',
        Cell: JobsiteName,
        width: 320,
        flex: true,
        sortType: sortJobName
      },
      {
        Header: translate('tableAddressColumn'),
        accessor: 'address.displayAddress',
        Cell: Address,
        width: 248
      },
      {
        Header: translate('tableHubsColumn'),
        accessor: 'devices.length',
        Cell: RightAligned,
        width: 144,
        align: 'right'
      },
      {
        Header: `${translate('tableConfiguredColumn')} (%)`,
        accessor: ({ configuredDevices, devices }) => calculatePercentage(configuredDevices.length, devices ? devices.length : 0),
        Cell: ConfiguredPercentage,
        width: 144,
        align: 'right'
      },
      {
        Header: translate('tableDateCreatedColumn'),
        accessor: ({ createdAt }) => (createdAt ? createdAt.seconds : 0),
        Cell: DateCreated,
        width: 168
      },
      {
        Header: translate('tableDetailsColumn'),
        id: 'edit',
        Cell: EditButton,
        width: 80
      }
    ], [selectedCheckboxes] // eslint-disable-line
  )

  useEffect(() => {
    fetchJobsites(organizationId)
  }, [dataIsStale(), modalDataIsStale()]) // eslint-disable-line

  const onSearch = (value) => {
    setSearchQuery(value)
  }

  const onFilter = (value) => {
    setFilters(value)
  }

  const handleCreateClick = () => {
    changeView('jobs/create')
  }

  const emptyList = {
    filteredMessage: translate('noResults'),
    unfilteredMessage: translate('emptyListMessage'),
    emptyArchive: translate('emptyArchive'),
    ctaLabel: translate('createJobButton'),
    ctaFunc: handleCreateClick
  }

  return (
    <>
      <TabGroup>
        <Tab active={status === 'active'} type="button" onClick={() => setStatus('active')} data-testid="jobs-active-tab">
          {translateTable('active')}
        </Tab>
        <Tab active={status === 'inactive'} type="button" onClick={() => setStatus('inactive')} data-testid="jobs-archive-tab">
          {translateTable('archived')}
        </Tab>
      </TabGroup>
      <FilterWrapper>
        <Search
          searchFields={searchFields}
          onSearch={onSearch}
          onFilter={onFilter}
          placeholderText={translateTable('filterByNameOrAddress')}
          searchTestId="jobsite"
        />
      </FilterWrapper>
      <Table
        isLoading={isLoading()}
        columns={columns}
        data={results}
        isFiltered={isFiltered}
        emptyList={emptyList}
        status={status}
        setSelectedCheckboxes={setSelectedCheckboxes}
        initialSortBy="name"
      />
    </>
  )
}

JobsitesTable.propTypes = {
  selectedCheckboxes: PropTypes.array.isRequired,
  setSelectedCheckboxes: PropTypes.func.isRequired,
  status: PropTypes.string,
  setStatus: PropTypes.func
}

JobsitesTable.defaultProps = {
  status: '',
  setStatus: () => null
}

export default JobsitesTable
