import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { useParams } from 'react-router-dom'

import useAsync from '../../../Utils/Hooks/useAsync'
import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'
import useTranslate from '../../../Utils/Hooks/useTranslate'
import useValidationSchema from '../../../Utils/Hooks/useValidationSchema'
import useErrorNotifier from '../../../Utils/Hooks/useErrorNotifier'
import useConfigTransform from '../../../Utils/Hooks/useConfigTransform'

import Fieldset from '../../Form/Fieldset'
import JobsiteContext from '../../../State/Jobs/Context'
import DeviceContext from '../../../State/Devices/Context'
import PanelContext from '../../../State/Panel/Context'
import FirmwareContext from '../../../State/Firmware/Context'

import PanelFooter from '../Components/PanelFooter'
import MQTTSettings from '../Components/MQTTSettings'
import NetworkSettings from '../Components/NetworkSettings'
import BACnetSettings from '../Components/BACnetSettings'
import ZoneSelection from '../Components/ZoneSelection'
import ReadOnlyFields from '../Components/ReadOnlyFields'
import PanelRibbon from '../Components/PanelRibbon'
import Firmware from '../Components/Firmware'


const EditHub = ({ setIsDirty }) => {
  const { selectors: { getSelectedDevice }, actions: { createZone, updateDevice } } = useContext(DeviceContext)
  const { actions: { fetchJobsiteZones } } = useContext(JobsiteContext)
  const { actions: { closePanel, setSubmitted } } = useContext(PanelContext)
  const { selectors: { getFirmwares } } = useContext(FirmwareContext)
  const { jobsiteId } = useParams()
  const device = getSelectedDevice()
  const { getCurrentTransform } = useConfigTransform()
  const configTransform = getCurrentTransform('devices/edit')
  const { getCurrentSchema } = useValidationSchema()
  const validationSchema = getCurrentSchema('devices/edit')
  const translate = useTranslate('devicePanel')
  const translateConfig = useTranslate('configForm')
  const [execute, , , result, hasError] = useAsyncCallback(updateDevice)
  const [,, zones] = useAsync(fetchJobsiteZones, jobsiteId)
  const mappedZones = zones ? Object.keys(zones).map(currentZoneId => ({ value: currentZoneId, label: zones[currentZoneId].name })) : []
  const firmwares = getFirmwares()
  useErrorNotifier(hasError, result)

  const {
    zoneId,
    name,
    status,
    phase,
    system: {
      macAddress,
      firmwareVersion
    },
    config: {
      network, bacnet, blePin, mqtt: {
        isEnabled, sensorReportType, fixedSampleRate, sensorReportRate, broker
      },
      firmware: {
        isEnabled: isFirmwareEnabled,
        version,
        location
      }
    }
  } = device

  const {
    brokerType, addressType, ip: ipAddress, url, port
  } = broker || {}

  const initialValues = {
    device: {
      deviceName: name
    },
    zone: {
      isNewZone: 'false',
      zoneName: '',
      id: zoneId,
      jobsiteId
    },
    network: {
      type: network ? network.type : 'dhcp',
      ipAddress: network ? network.ipAddress : '',
      subnetMask: network ? network.subnetMask : '',
      gatewayIp: network ? network.gatewayIp : '',
      dnsIp: network ? network.dnsIp : '',
      dnsIp2: network.dnsIp2 ? network.dnsIp2 : ''
    },
    bacnet: {
      ...bacnet
    },
    mqtt: {
      isEnabled,
      sensorReportType: sensorReportType || 'changeOfValue',
      fixedSampleRate: fixedSampleRate || 5,
      sensorReportRate: sensorReportRate || 60,
      broker: {
        brokerType: brokerType || 'default',
        ...brokerType === 'custom' ? {
          addressType: addressType === 'ip' ? 'brokerIp' : 'brokerUrl',
          port,
          ...addressType === 'ip' ? { brokerIp: ipAddress } : { brokerUrl: url }
        } : {}
      }
    },
    firmware: {
      isEnabled: isFirmwareEnabled,
      version,
      location
    }
  }

  const labels = {
    buttonLabel: translate('saveButton'),
    submittingLabel: translate('submittingButton'),
    cancelLabel: translate('cancelButton')
  }

  const onSubmit = async (values, { resetForm }) => {
    const config = await configTransform(values, device, createZone)
    await execute(config)
    setSubmitted()
    closePanel()
    resetForm()
  }

  const getFirmwareVersion = (firmware) => {
    if (!isEnabled) {
      return translateConfig('firmware.unavailable.mqttDisabled')
    }

    if (broker.brokerType === 'custom') {
      return translateConfig('firmware.unavailable.mqttCustom')
    }

    return firmware || translateConfig('firmware.pendingNetworkSync')
  }

  const readOnlyFields = [
    {
      header: translateConfig('securitySettings.bleMacAddressLabel'),
      value: phase === 'configured' ? macAddress : translateConfig('notConfigured'),
      testId: 'ble-mac-address'
    },
    {
      header: translateConfig('securitySettings.bluetoothPinInputLabel'),
      value: blePin,
      testId: 'ble-pin'
    },
    {
      header: translateConfig('firmware.deviceFirmwareLabel'),
      value: getFirmwareVersion(firmwareVersion),
      testId: 'firmware-version'
    }
  ]

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({
          setFieldValue, values, handleSubmit, isSubmitting, errors, isValid, dirty, submitCount
        }) => (
          <Form onChange={setIsDirty(dirty)}>
            <PanelRibbon errors={errors} submitCount={submitCount} />
            <ZoneSelection values={values} setFieldValue={setFieldValue} zones={mappedZones} submitCount={submitCount} />
            <NetworkSettings values={values} submitCount={submitCount} />
            <BACnetSettings values={values} submitCount={submitCount} />
            <MQTTSettings values={values} setFieldValue={setFieldValue} submitCount={submitCount} />
            <Firmware values={values} firmwares={firmwares} setFieldValue={setFieldValue} isEditPanel status={status} />
            <Fieldset>
              <ReadOnlyFields fields={readOnlyFields} />
            </Fieldset>
            <PanelFooter
              handleReset={closePanel}
              handleSubmit={handleSubmit}
              errors={errors}
              labels={labels}
              isSubmitting={isSubmitting}
              isValid={isValid}
              testIdRoot="edit-hub"
              submitCount={submitCount}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

EditHub.propTypes = {
  setIsDirty: PropTypes.func.isRequired
}

export default EditHub
