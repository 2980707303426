import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import ErrorPage from '../Navigator/Components/ErrorPage'


class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.log(error) /* eslint-disable-line */
    console.log(errorInfo.componentStack) /* eslint-disable-line */
  }

  render() {
    const { history, children } = this.props
    const { hasError } = this.state

    history.listen(() => {
      if (hasError) {
        this.setState({
          hasError: false
        })
      }
    })

    if (hasError) return <ErrorPage />

    return children
  }
}

ErrorBoundary.propTypes = {
  history: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
}

export default withRouter(ErrorBoundary)
