import useValidationSchema from '../../Hooks/useValidationSchema'
import useConfigTransform from '../../Hooks/useConfigTransform'
import CoVColorComponent from './Components/CoVColorComponent'

const CoVColorField = () => {
  const { getCurrentSchema } = useValidationSchema()
  const { getCurrentTransform } = useConfigTransform()

  return {
    validationSchema: getCurrentSchema('attributes/covColor'),
    configTransform: getCurrentTransform('attributes/reportingEnabled'),
    Component: () => null,
    CoVComponent: CoVColorComponent
  }
}

export default CoVColorField
