import React from 'react'
import PropTypes from 'prop-types'

import { RibbonContainer } from './Styles'

const Ribbon = ({
  type, isFixed, isCentered, isSticky, children
}) => (
  <RibbonContainer type={type} isFixed={isFixed} isCentered={isCentered} isSticky={isSticky} data-testid="ribbon-container">
    {children}
  </RibbonContainer>
)

Ribbon.propTypes = {
  isFixed: PropTypes.bool,
  isCentered: PropTypes.bool,
  type: PropTypes.string,
  isSticky: PropTypes.bool,
  children: PropTypes.node
}

Ribbon.defaultProps = {
  isCentered: false,
  type: undefined,
  isFixed: false,
  isSticky: false,
  children: undefined
}

export default Ribbon
