import React from 'react'
import PropTypes from 'prop-types'

import useTranslate from '../../../../../Utils/Hooks/useTranslate'

import { TableCell } from '../../../../Table'

const AttributeType = ({ row }) => {
  const { original: { type } } = row
  const translate = useTranslate('templateAttributesPage')
  return (
    <TableCell>
      {translate(`${type}TypeOption`)}
    </TableCell>
  )
}

AttributeType.propTypes = {
  row: PropTypes.object.isRequired
}

export default AttributeType
