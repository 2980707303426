import React from 'react'
import PropTypes from 'prop-types'

import { Field, FieldHeader, FieldContainer } from './Styles'

const ReadOnlyFields = ({ fields }) => fields.map(field => (
  <FieldContainer key={field.testId}>
    <FieldHeader data-testid={field.testId}>{field.header}</FieldHeader>
    <Field>{field.value}</Field>
  </FieldContainer>
))


ReadOnlyFields.propTypes = {
  fields: PropTypes.array.isRequired
}

export default ReadOnlyFields
