import Styled from 'styled-components'

export const ErrorContainer = Styled.div(({ theme, errorPadding }) => ({
  color: theme.colors.s05,
  fontSize: '.875rem',
  display: 'flex',
  padding: errorPadding ? '0.5rem 0' : '1.5px 0',
  '& > p': {
    color: `${theme.colors.s05} !important`,
    marginLeft: '.5rem',
    marginBottom: '0 !important',
    lineHeight: 'normal !important',
    lineSpacing: 'normal !important'
  },
  '& > svg': {
    height: '1rem !important',
    width: '1rem !important'
  }
}))
